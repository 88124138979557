/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-useless-escape */
import InputTailwind from "@/components/token/Input/inputTailwind";
import { useInputValidation, useTextUtf8 } from "@/hooks/validation/useRegexp";

export default function ServiceTitle({
  serviceTitle,
  setServiceTitle,
  titleError,
  setTitleError,
  ...props
}) {
  const regexp = useTextUtf8();
  const handleChange = useInputValidation({
    setInputValue: setServiceTitle,
    setErrorValue: setTitleError,
  });

  return (
    <InputTailwind
      label="Titre"
      id="TitleInput"
      type="text"
      placeholder="Indiquez un titre"
      isRequired={true}
      name="title"
      value={serviceTitle}
      onChange={(e) => handleChange(e, regexp)}
      error={titleError}
      maxLength={1000}
      {...props}
    ></InputTailwind>
  );
}
