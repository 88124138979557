// import { useUser } from "@/contexts/UserContext";
import { faro } from "@/AppRouter";
import FetchError from "@/utils/FetchError";
import {
  getFirstOryError,
  getOryGlobalError,
  getOryResponseStatus,
} from "@/utils/ory.frontend";
import { FrontendApi, Configuration } from "@ory/client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const ory = new FrontendApi(
  new Configuration({
    basePath: process.env.REACT_APP_ORY_URL,
    baseOptions: {
      withCredentials: true,
    },
  })
);

export const OryLoginErrors = {
  ORY_LOGIN_GLOBAL_ERROR: "ORY_LOGIN_GLOBAL_ERROR",
  ORY_LOGIN_INVALID_CREDENTIALS: "ORY_LOGIN_INVALID_CREDENTIALS",
  ORY_LOGIN_FIRST_ERROR: "ORY_LOGIN_FIRST_ERROR",
  ORY_LOGIN_UNKNOWN: "ORY_LOGIN_UNKNOWN",
  ORY_LOGIN_FLOW_EXPIRED: "ORY_LOGIN_FLOW_EXPIRED",
};

function handleOryLoginError(error) {
  const status = getOryResponseStatus(error);

  const globalError = getOryGlobalError(error);
  if (globalError) {
    const c = (() => {
      if (globalError.id === "self_service_flow_expired") {
        return OryLoginErrors.ORY_LOGIN_FLOW_EXPIRED;
      }
      return OryLoginErrors.ORY_LOGIN_GLOBAL_ERROR;
    })();

    const fetchError = new FetchError(
      `${globalError.message}: ${globalError.reason}`,
      status,
      c,
      undefined,
      "ory-login-flow",
      true
    );

    faro.api.pushError(fetchError, {
      type: "ory",
    });

    throw fetchError;
  }

  const firstError = getFirstOryError(error);

  if (firstError) {
    const { errorCode, errorMessage } = (() => {
      if (firstError.id === 4000006) {
        return {
          errorCode: OryLoginErrors.ORY_LOGIN_INVALID_CREDENTIALS,
          errorMessage: "Email ou mot de passe incorrect",
        };
      }
      return {
        errorCode: OryLoginErrors.ORY_LOGIN_FIRST_ERROR,
        errorMessage: firstError.text,
      };
    })();

    const fetchError = new FetchError(
      errorMessage,
      status,
      errorCode,
      undefined,
      "ory-login-flow",
      true
    );

    faro.api.pushError(fetchError, {
      type: "ory",
    });

    throw fetchError;
  }

  const fetchError = new FetchError(
    "Impossible d'authentifier l'utilisateur",
    status,
    OryLoginErrors.ORY_LOGIN_UNKNOWN,
    undefined,
    "ory-login-flow"
  );

  faro.api.pushError(fetchError, {
    type: "ory",
  });

  throw fetchError;
}

export const handleOryLogin = async ({
  loginFlow,
  csrf_token,
  email,
  password,
}) => {
  return ory.updateLoginFlow(
    {
      flow: loginFlow.id,
      updateLoginFlowBody: {
        csrf_token: csrf_token,
        identifier: email,
        method: "password",
        password: password,
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

async function getLoginFlow() {
  const { data: loginFlow } = await ory.createBrowserLoginFlow(
    {
      refresh: true,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  // Submit the login form
  const attributes = loginFlow.ui.nodes.find(
    (node) => node.group === "default"
  )?.attributes;
  const csrf_token = attributes.name === "csrf_token" ? attributes.value : null;
  return { loginFlow, csrf_token };
}

export function useOryLoginFlow(onSuccess) {
  const action = useMutation({
    mutationFn: async () => await getLoginFlow(),
    onSuccess: async (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
  });

  return action;
}

export function useOryLogin(
  queryKeyToInvalidate,
  multipleQueryKeysToInvalidate,
  onSuccess,
  onError
) {
  const queryClient = useQueryClient();
  // const userContext = useUser();

  const action = useMutation({
    mutationFn: async (data) => {
      try {
        localStorage.clear();
        await handleOryLogin(data);
      } catch (error) {
        handleOryLoginError(error);
      }
    },
    onSuccess: async (/*data*/) => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (onSuccess) {
        onSuccess();
      }
      // const referrer = document.referrer;

      /* We only want to return to the previous page if it was a page from the app */
      // if (
      //   referrer.includes(window.location.origin) &&
      //   !referrer.includes("/forbidden", "/not-whitelisted", "/login", "/sign")
      // ) {
      //   navigate(-1);
      // } else {
      //   navigate("/home");
      // }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });

  return action;
}
