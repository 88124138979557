import React from "react";
import {
  QueryClient,
  QueryClientProvider,
  MutationCache,
  QueryCache,
} from "@tanstack/react-query";
import toast, { Toaster } from "react-hot-toast";
import { UserProvider } from "@/contexts/UserContext";
import Notification from "@/components/token/toaster/notification";
import { OryAuthProvider } from "./contexts/OryAuthContext";
import ErrorFallbackComponent from "./utils/ErrorFallbackComponent";
import { OnboardingProvider } from "@/contexts/OnboardingContext";
import { AppRouter } from "./AppRouter";
import { FaroErrorBoundary } from "@grafana/faro-react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

function AppQueryProvided() {
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => {
        if (error?.statusCode === 401) {
          if (
            error?.url != "ory-session" &&
            error?.url != "login" &&
            error?.url != "logout"
          ) {
            if (!error?.disableToast) {
              toast.custom(() => (
                <Notification
                  isSuccess={false}
                  message={"Votre session a expiré"}
                  errorCode={error.code}
                  traceId={error.traceId}
                  httpStatusCode={error.statusCode}
                />
              ));
            }
            /* Do not navigate to login if already on login page */
            if (!window.location.pathname.includes("/login")) {
              window.history.pushState({}, "", "/login");
              window.location.reload();
            }
          }
        } else if (error?.statusCode === 403) {
          toast.custom(() => (
            <Notification
              isSuccess={false}
              message={"Vous n'êtes pas autorisé"}
              errorCode={error.code}
              traceId={error.traceId}
              httpStatusCode={error.statusCode}
            />
          ));
          if (error?.code === "AUTH0005") {
            window.history.pushState({}, "", "/not-whitelisted");
            window.location.reload();
          } else {
            window.history.pushState({}, "", "/forbidden");
            window.location.reload();
          }
        }
      },
    }),
    defaultOptions: {
      queries: {
        retry: (failureCount, error) => {
          if (error?.statusCode === 401 || error?.statusCode === 403) {
            return false;
          }
          return failureCount < parseInt(process.env.MAX_RETRIES);
        },
      },
    },
    mutationCache: new MutationCache({
      onError: (error /*, variables, context, mutation*/) => {
        if (error?.statusCode === 401) {
          const isLoginAttempt =
            error.url?.includes("/login") || error.url?.includes("login");
          const isLogoutAttempt =
            error.url?.includes("/logout") || error.url?.includes("logout");
          const isErrorFromRecovery =
            window.location.pathname.includes("/recovery");
          const isUserUpdateAttempt = error.url?.includes("/users/info");

          if (
            !error?.disableToast &&
            !(isLogoutAttempt && isErrorFromRecovery)
          ) {
            toast.custom(() => (
              <Notification
                isSuccess={false}
                message={
                  isLoginAttempt
                    ? "Données de connexion incorrecte"
                    : "Votre session a expiré"
                }
                errorCode={error.code}
                traceId={error.traceId}
                httpStatusCode={error.statusCode}
              />
            ));
          }
          if (!isLoginAttempt && !isLogoutAttempt && !isUserUpdateAttempt) {
            window.history.pushState({}, "", "/logout");
            window.location.reload();
          }
        } else if (error?.statusCode === 403) {
          if (!error?.disableToast) {
            toast.custom(() => (
              <Notification
                isSuccess={false}
                message={"Vous n'êtes pas autorisé"}
                errorCode={error.code}
                traceId={error.traceId}
                httpStatusCode={error.statusCode}
              />
            ));
          }
        } else {
          if (!error?.disableToast) {
            toast.custom(() => (
              <Notification
                isSuccess={false}
                message={error.message}
                errorCode={error.code}
                traceId={error.traceId}
                httpStatusCode={error.statusCode}
              />
            ));
          }
        }
      },
    }),
  });

  return (
    <FaroErrorBoundary
      fallback={(error, resetBoundary) => (
        <ErrorFallbackComponent
          error={error}
          resetErrorBoundary={resetBoundary}
          context="global"
        />
      )}
    >
      <QueryClientProvider client={queryClient}>
        <OryAuthProvider>
          <UserProvider>
            <OnboardingProvider>
              <Toaster
                toastOptions={{
                  className: "",
                  background: "green",
                  style: {
                    border: "1px solid #713200",
                    padding: "16px",
                    color: "#713200",
                  },
                }}
              />
              <AppRouter />
              <ReactQueryDevtools initialIsOpen={false} />
            </OnboardingProvider>
          </UserProvider>
        </OryAuthProvider>
      </QueryClientProvider>
    </FaroErrorBoundary>
  );
}

export default AppQueryProvided;
