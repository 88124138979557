import {
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import InputTailwind from "../Input/inputTailwind";
import SubscriptionStatus from "./SubscriptionStatus";
import { Country } from "@/components/elements/stakeholder/address/Country";

export function SubscriptionAdminView({ subscription }) {
  const creationDate = new Date(subscription.created);
  const creationLocaleDate = creationDate.toLocaleDateString();
  const creationLocaleTime = creationDate.toLocaleTimeString();
  const updateDate = new Date(subscription.updated);
  const updateLocaleDate = updateDate.toLocaleDateString();
  const updateLocaleTime = updateDate.toLocaleTimeString();
  const trialStartDate = new Date(subscription.trialStart);
  const trialStartLocaleDate = trialStartDate.toLocaleDateString();
  const trialStartLocaleTime = trialStartDate.toLocaleTimeString();
  const trialEndDate = new Date(subscription.trialEnd);
  const trialEndLocaleDate = trialEndDate.toLocaleDateString();
  const trialEndLocaleTime = trialEndDate.toLocaleTimeString();
  const trialDaysRemaining = Math.floor(
    (trialEndDate - new Date()) / (1000 * 60 * 60 * 24)
  );
  const trialHoursRemaining = Math.floor(
    (trialEndDate - new Date()) / (1000 * 60 * 60)
  );
  const trialMinutesRemaining = Math.floor(
    (trialEndDate - new Date()) / (1000 * 60)
  );

  return (
    <div
      className={
        "flex flex-col gap-2 bg-right-bottom bg-no-repeat rounded-lg border border-neutral-300 dark:border-neutral-700 shadow-sm px-6 py-8 flex flex-col gap-6"
      }
    >
      <div className="flex flex-row w-full justify-between items-top">
        <div className="flex flex-col gap-2">
          <p className={"text-xl font-bold"}>{subscription.subscriptionType}</p>
          <div className={"flex flex-col gap-2 text-xs text-gray-400"}>
            <table>
              <tbody>
                <tr>
                  <td className="text-left font-bold">{"Création"}</td>
                  <td>:</td>
                  <td className="text-left">{`${creationLocaleDate}`}</td>
                  <td className="text-left">{`${creationLocaleTime}`}</td>
                </tr>
                <tr>
                  <td className="text-left font-bold">{"Mise à jour"}</td>
                  <td>:</td>
                  <td className="text-left">{`${updateLocaleDate}`}</td>
                  <td className="text-left">{`${updateLocaleTime}`}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-col gap-2 items-end">
          <SubscriptionStatus
            status={subscription.status}
            textSize={"text-large"}
          />
          {subscription.status === "trialing" && (
            <div
              className={"flex flex-col gap-2 text-xs text-right text-gray-400"}
            >
              <table>
                <tbody>
                  <tr>
                    <td className="text-left font-bold">{"Début"}</td>
                    <td>:</td>
                    <td className="text-right">{`${trialStartLocaleDate}`}</td>
                    <td className="text-right">{`${trialStartLocaleTime}`}</td>
                  </tr>
                  <tr>
                    <td className="text-left font-bold">{"Fin"}</td>
                    <td>:</td>
                    <td className="text-right">{`${trialEndLocaleDate}`}</td>
                    <td className="text-right">{`${trialEndLocaleTime}`}</td>
                  </tr>
                </tbody>
              </table>
              {trialDaysRemaining > 0 ? (
                <p>{`${trialDaysRemaining} jour${
                  trialDaysRemaining > 1 ? "s" : ""
                } restant${trialDaysRemaining > 1 ? "s" : ""}`}</p>
              ) : trialHoursRemaining > 0 ? (
                <p>{`${trialHoursRemaining} heure${
                  trialHoursRemaining > 1 ? "s" : ""
                } restante${trialHoursRemaining > 1 ? "s" : ""}`}</p>
              ) : (
                <p>{`${trialMinutesRemaining} minute${
                  trialMinutesRemaining > 1 ? "s" : ""
                } restante${trialMinutesRemaining > 1 ? "s" : ""}`}</p>
              )}
            </div>
          )}
        </div>
      </div>

      <div className={"flex flex-row gap-2"}>
        <div className={"w-full flex flex-col gap-2 items-left relative"}>
          <span className="flex justify-between w-full block text-sm font-medium leading-6">
            {"Identifiant de souscription (Stripe)"}
          </span>
          <a
            href={subscription.subscriptionUrl}
            className="flex flex-row gap-2 cursor-pointer text-sm text-left hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ArrowTopRightOnSquareIcon className="h-4 w-4 text-black" />
            {subscription.subscriptionId}
          </a>
        </div>
        <InputTailwind
          readOnly={true}
          id={subscription.id}
          label="Identifiant de souscription (Lidap)"
          placeholder=""
          value={subscription.id}
        />
      </div>

      <div className={"flex flex-row gap-2"}>
        <div className={"w-full flex flex-col gap-2 items-left relative"}>
          <span className="flex justify-between w-full block text-sm font-medium leading-6">
            {"Identifiant de client (Stripe)"}
          </span>
          <a
            href={subscription.customerUrl}
            className="flex flex-row gap-2 cursor-pointer text-sm text-left hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ArrowTopRightOnSquareIcon className="h-4 w-4 text-black" />
            {subscription.customerId}
          </a>
        </div>
        <InputTailwind
          readOnly={true}
          id={subscription.id}
          label="Identifiant d'entreprise (Lidap)"
          placeholder=""
          value={subscription.enterpriseId}
        />
      </div>
      <div className={"flex flex-row gap-2"}>
        <InputTailwind
          readOnly={true}
          value={subscription.email}
          label="Email"
          placeholder=""
        />
        <Country readOnly={true} initialCountry={subscription.country} />
      </div>
      <div className={"flex flex-col gap-2"}>
        <div className={"flex flex-row gap-2 items-center"}>
          {subscription.isPaymentMethodSet ? (
            <CheckCircleIcon className="h-5 w-5 text-green-700" />
          ) : (
            <XCircleIcon className="h-5 w-5 text-red-700" />
          )}
          <span className={"text-sm"}>{"Moyen de paiement"}</span>
        </div>
        {subscription.cancelAtPeriodEnd ? (
          <div className={"flex flex-row gap-2 items-center"}>
            <ExclamationTriangleIcon className="h-5 w-5 text-red-700" />
            <span className={"text-sm"}>
              {"Annulation à la fin de la période"}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
}
