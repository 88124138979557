import { useMutation, useQueryClient } from "@tanstack/react-query";
import Lidapi from "@/utils/lidapi";
// import { useUser } from "@/contexts/UserContext";

export function useLogout(
  queryKeyToInvalidate,
  reactQueryConfig,
  onSuccess,
  disableToast
) {
  const queryClient = useQueryClient();
  const api = new Lidapi(disableToast);
  // const userContext = useUser();

  const action = useMutation({
    mutationFn: async () => await api.logout(),
    onSuccess: () => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: async () => {},
    ...reactQueryConfig,
  });

  return action;
}
