import { useQuery, useQueryClient } from "@tanstack/react-query";
import { stringify } from "@/utils/stringify";
import Lidapi from "@/utils/lidapi";

export function getCompanyInfoFromReferential({
  country,
  identifierType,
  identifier,
  queryParam,
  disableToast,
}) {
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";
  const api = new Lidapi(disableToast);

  return {
    queryKey: [
      "referential",
      { country, identifierType, identifier },
      queryParam,
    ],
    queryFn: () =>
      api.getReferential(country, identifierType, identifier, queryString),
  };
}

export function useReferential({
  country,
  identifierType,
  identifier,
  queryParam,
  reactQueryConfig,
  disableToast,
}) {
  const queryClient = useQueryClient();
  const { queryFn, queryKey } = getCompanyInfoFromReferential({
    country,
    identifierType,
    identifier,
    queryParam,
    disableToast,
  });

  const query = useQuery(queryKey, {
    queryFn: queryFn,
    initialData: () => {
      const dataFromCache = queryClient.getQueryData(queryKey);
      return dataFromCache;
    },
    ...reactQueryConfig,
    retry: (failureCount, error) => {
      // Only retry up to 2 times and not on 404 errors
      return (
        failureCount < 3 && (!error.response || error.response.status !== 404)
      );
    },
  });

  return query;
}

export function searchCompanyInfoFromReferential({
  identifier,
  queryParam,
  disableToast,
}) {
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";
  const api = new Lidapi(disableToast);

  return {
    queryKey: ["referential", "search", { identifier }, queryParam],
    queryFn: () => api.searchReferential(identifier, queryString),
  };
}

export function useSearchReferential({
  identifier,
  queryParam,
  reactQueryConfig,
  disableToast,
}) {
  const queryClient = useQueryClient();
  const { queryFn, queryKey } = searchCompanyInfoFromReferential({
    identifier,
    queryParam,
    disableToast,
  });

  const query = useQuery(queryKey, {
    queryFn: queryFn,
    initialData: () => {
      const dataFromCache = queryClient.getQueryData(queryKey);
      return dataFromCache;
    },
    ...reactQueryConfig,
    retry: (failureCount, error) => {
      // Only retry up to 2 times and not on 404 errors
      return (
        failureCount < 3 && (!error.response || error.response.status !== 404)
      );
    },
  });

  return query;
}
