// export function vatConditions(country) {
//   switch (country) {
//     case "FR":
//       return [
//         { name: "Assujetti", value: "Assujetti" },
//         {
//           name: "TVA non applicable, art 293 du CGI",
//           value: "TVA non applicable, art 293 du CGI",
//         },
//         {
//           name: "Régime particulier - Biens d’occasion - article 297 A du CGI et directive communautaire 2006/112/CE",
//           value:
//             "Régime particulier - Biens d’occasion - article 297 A du CGI et directive communautaire 2006/112/CE",
//         },
//         {
//           name: "Régime particulier - Objets d’art - article 297 A du CGI et directive communautaire 2006/112/CE",
//           value:
//             "Régime particulier - Objets d’art - article 297 A du CGI et directive communautaire 2006/112/CE",
//         },
//         {
//           name: "Régime particulier - Objets de collection ou d’antiquité - article 297 A du CGI et directive communautaire 2006/112/CE",
//           value:
//             "Régime particulier - Objets de collection ou d’antiquité - article 297 A du CGI et directive communautaire 2006/112/CE",
//         },
//         {
//           name: "Régime particulier - Agence de voyage - article 297 A du CGI et directive communautaire 2006/112/CE",
//           value:
//             "Régime particulier - Agence de voyage - article 297 A du CGI et directive communautaire 2006/112/CE",
//         },
//         {
//           name: "TVA non applicable - art. 259-1 du CGI",
//           value: "TVA non applicable - art. 259-1 du CGI",
//         },
//         {
//           name: "Exonération de TVA en application de l’art. 259B du CGI, TVA due par le preneur",
//           value:
//             "Exonération de TVA en application de l’art. 259B du CGI, TVA due par le preneur",
//         },
//         {
//           name: "Exonération de TVA, article 44 de la directive 2006/112/CE",
//           value: "Exonération de TVA, article 44 de la directive 2006/112/CE",
//         },
//         {
//           name: "Exonération de TVA, article 262 ter, I du CGI",
//           value: "Exonération de TVA, article 262 ter, I du CGI",
//         },
//         {
//           name: "Exonération de TVA, article 283-2 du CGI",
//           value: "Exonération de TVA, article 283-2 du CGI",
//         },
//         {
//           name: "TVA due par le preneur assujetti ; autoliquidation en application de l’article 242 nonies A, I-13° de l’annexe II du CGI",
//           value:
//             "TVA due par le preneur assujetti ; autoliquidation en application de l’article 242 nonies A, I-13° de l’annexe II du CGI",
//         },
//         {
//           name: "TVA non applicable selon l’article 261-4-1° du Code Général des impôts",
//           value:
//             "TVA non applicable selon l’article 261-4-1° du Code Général des impôts",
//         },
//         {
//           name: "TVA non applicable selon l’article 261-4-4° du Code Général des impôts",
//           value:
//             "TVA non applicable selon l’article 261-4-4° du Code Général des impôts",
//         },
//         {
//           name: "Autoliquidation par le preneur (Art. 283-2 du CGI et Art. 44 de la directive 2008/8)",
//           value:
//             "Autoliquidation par le preneur (Art. 283-2 du CGI et Art. 44 de la directive 2008/8)",
//         },
//         {
//           name: "Autoliquidation par le preneur (Art. 44 et Art. 196 de la directive 2006/112/CE)",
//           value:
//             "Autoliquidation par le preneur (Art. 44 et Art. 196 de la directive 2006/112/CE)",
//         },
//         {
//           name: "Exonération de TVA, article 262 du CGI",
//           value: "Exonération de TVA, article 262 du CGI",
//         },
//         {
//           name: "Taux de TVA réduit, article 279-i du CGI",
//           value: "Taux de TVA réduit, article 279-i du CGI",
//         },
//         {
//           name: "TVA non applicable, art. 261 C du CGI",
//           value: "TVA non applicable, art. 261 C du CGI",
//         },
//       ];

//     case "BE":
//       return [
//         { name: "Assujetti", value: "Assujetti" },
//         {
//           name: "Régime particulier de franchise des petites entreprises – TVA non applicable",
//           value:
//             "Régime particulier de franchise des petites entreprises – TVA non applicable",
//         },
//         {
//           name: "Autoliquidation – AR CTVA n° 1, article 20",
//           value: "Autoliquidation – AR CTVA n° 1, article 20",
//         },
//         {
//           name: "Autoliquidation – article 196 de la Directive TVA 2006/112/CE",
//           value:
//             "Autoliquidation – article 196 de la Directive TVA 2006/112/CE",
//         },
//         {
//           name: "Autoliquidation – article 138 par.1 de la directive TVA 2006/112/CE",
//           value:
//             "Autoliquidation – article 138 par.1 de la directive TVA 2006/112/CE",
//         },
//         {
//           name: "Exonération – art. 146 de la directive TVA 2006/112/CE",
//           value: "Exonération – art. 146 de la directive TVA 2006/112/CE",
//         },
//       ];

//     default:
//       return "Country not supported";
//   }
// }

export function vatDisclaimers(country) {
  switch (country) {
    case "FR":
      return [
        "Assujetti",
        "TVA non applicable, art 293 du CGI",
        "Régime particulier - Biens d’occasion - article 297 A du CGI et directive communautaire 2006/112/CE",
        "Régime particulier - Objets d’art - article 297 A du CGI et directive communautaire 2006/112/CE",
        "Régime particulier - Objets de collection ou d’antiquité - article 297 A du CGI et directive communautaire 2006/112/CE",
        "Régime particulier - Agence de voyage - article 297 A du CGI et directive communautaire 2006/112/CE",
        "TVA non applicable - art. 259-1 du CGI",
        "Exonération de TVA en application de l’art. 259B du CGI, TVA due par le preneur",
        "Exonération de TVA, article 44 de la directive 2006/112/CE",
        "Exonération de TVA, article 262 ter, I du CGI",
        "Exonération de TVA, article 283-2 du CGI",
        "TVA due par le preneur assujetti ; autoliquidation en application de l’article 242 nonies A, I-13° de l’annexe II du CGI",
        "TVA non applicable selon l’article 261-4-1° du Code Général des impôts",
        "TVA non applicable selon l’article 261-4-4° du Code Général des impôts",
        "Autoliquidation par le preneur (Art. 283-2 du CGI et Art. 44 de la directive 2008/8)",
        "Autoliquidation par le preneur (Art. 44 et Art. 196 de la directive 2006/112/CE)",
        "Exonération de TVA, article 262 du CGI",
        "Taux de TVA réduit, article 279-i du CGI",
        "TVA non applicable, art. 261 C du CGI",
      ];

    case "BE":
      return [
        "Assujetti",
        "Régime particulier de franchise des petites entreprises – TVA non applicable",
        "Autoliquidation – AR CTVA n° 1, article 20",
        "Autoliquidation – article 196 de la Directive TVA 2006/112/CE",
        "Autoliquidation – article 138 par.1 de la directive TVA 2006/112/CE",
        "Exonération – art. 146 de la directive TVA 2006/112/CE",
      ];

    default:
      return "Country not supported";
  }
}
