import React from "react";
import { countriesCurrency } from "@/data/countryCodeCorrespondance";
import "@/assets/css/InvoicePreview.css";
import { useNextInvoiceNumber } from "@/hooks/useInvoices";
import CouldNotLoad from "@/components/elements/CouldNotLoad";
import Base64Image from "@/components/elements/ImageImport/Base64Image";
import { PaymentMethodLabel } from "../MoyenDePaiement/PaymentMethodLabel";
import { CompanyPreview } from "./CompanyPreview";
import { LidapDocumentType } from "@/components/token/documents/DocumentType";
import { useDocumentPreview } from "../document/useDocumentForm";
import { formatNumberForDocument } from "@/utils/numbers/useFormatNumber";

export const DocumentPreviewContext = {
  CREATION: "CREATION",
  DOWNLOAD: "DOWNLOAD",
};
export default function InvoicePreviewV2({
  documentData,
  companyLogo,
  enterprise,
  selectedClient,
  previewContext = DocumentPreviewContext.CREATION,
}) {
  const {
    enterpriseId,
    issuingDateAsDDMMYYYY,
    dueDateAsDDMMYYYY,
    documentNumber,
    documentType,
    additionalData,
    documentTitle,
    documentBodyColor,
    documentTitleColor,
    issuerEmail,
    issuerPhone,
    addresses,
    vatDisclaimer,
    documentConditions,
    creditNoteReason,
    creditNotePaymentApplicationMethod,
    accountsToCredit,
    formattedSubtotalHT,
    formattedTotalTVA,
    formattedTotalTTC,
    currency,
    tvaVentilation,
    getUnitLabel,
    refundedInvoice,
  } = useDocumentPreview({
    documentData,
  });

  const nextInvoiceNumber = useNextInvoiceNumber({
    enterpriseId,
    queryParam: documentType ? { documentType } : null,
    reactQueryConfig: {
      enabled:
        !!enterpriseId && previewContext === DocumentPreviewContext.CREATION,
    },
  });

  return (
    <div
      id="invoice-preview-main-box"
      className={`${documentData?.className} bg-white`}
    >
      <section className="flex flex-col justify-between mt-16 mx-12">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col text-left w-max">
            {companyLogo?.imageContent ? (
              <div className="invoice-user-documentData-logo">
                <Base64Image
                  base64={companyLogo.imageContent}
                  format={companyLogo.imageFormat}
                  alt={"Le logo de votre entreprise"}
                  className={"w-24 h-12 object-contain"}
                />
              </div>
            ) : null}
            <div className="mt-2">
              <p
                className="text-xl font-bold"
                style={{
                  color: documentTitleColor,
                }}
              >
                {" "}
                {documentTitle}
              </p>
              <div
                className="text-xl text-neutral-500 font-bold"
                style={{
                  color: documentTitleColor,
                }}
              >
                {documentNumber ? (
                  documentNumber
                ) : nextInvoiceNumber.isInitialLoading ? (
                  <div className="animate-pulse">
                    <div className="h-6 bg-neutral-200 dark:bg-neutral-800 rounded w-32"></div>
                  </div>
                ) : nextInvoiceNumber.isNextInvoiceNumberError ? (
                  <CouldNotLoad message="Erreur de chargement du numéro de facture" />
                ) : (
                  nextInvoiceNumber.nextInvoiceNumberData.payload
                    .nextInvoiceNumber
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col text-left">
            <div className="flex flex-row mb-3 gap-2 w-full">
              <div className="flex flex-col w-32 leading-3 text-sm">
                <p className="text-[8px] font-semibold text-black">
                  {"Date d'émission"}
                </p>
                <span className="text-[8px] text-neutral-500">
                  {issuingDateAsDDMMYYYY ? issuingDateAsDDMMYYYY : ""}
                </span>
              </div>

              {dueDateAsDDMMYYYY ? (
                <div className="flex flex-col w-32 leading-3 text-sm">
                  <p className="text-[8px] font-semibold text-black">
                    {documentData.documentType === LidapDocumentType.QUOTE
                      ? "Date limite de validité"
                      : "Date d'échéance"}
                  </p>
                  <span className="text-[8px] text-neutral-500">
                    {dueDateAsDDMMYYYY}
                  </span>
                </div>
              ) : null}
            </div>

            <div className="flex flex-row gap-2">
              <div className=" flex flex-col w-28 w-[128px]">
                <p className="text-[8px] font-semibold text-bold ">{"De"}</p>
                <div className="text-[8px] text-neutral-500">
                  <CompanyPreview
                    additionalData={additionalData}
                    addresses={addresses}
                    country={enterprise.country}
                    identifiers={enterprise.identifiers}
                    key={enterprise.id}
                    type={"ISSUER"}
                  />
                </div>
                <div
                  className={`flex flex-col text-left text-[8px] text-neutral-500`}
                >
                  <span>{issuerEmail}</span>
                  <span>{issuerPhone}</span>
                </div>
              </div>

              <div className=" flex flex-col w-28">
                <p className="text-[8px] font-semibold text-semiblack">{"À"}</p>
                <div className="text-[8px] text-neutral-500">
                  <CompanyPreview
                    country={selectedClient?.country}
                    additionalData={additionalData}
                    addresses={addresses}
                    identifiers={selectedClient?.identifiers}
                    type="RECEIVER"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {documentData?.documentType === LidapDocumentType.CREDIT_NOTE && (
          <div className="flex flex-col justify-between mt-4 items-start gap-3 w-full max-w-32">
            <span className="text-[8px] font-semibold">
              {"Note de crédit sur facture"}{" "}
              {refundedInvoice?.invoiceNumber || ""}{" "}
              {refundedInvoice?.issuingDateAsDDMMYYYY ? "du" : null}{" "}
              {refundedInvoice?.issuingDateAsDDMMYYYY
                ? issuingDateAsDDMMYYYY
                : null}
            </span>
            <div className="text-[8px] font-semibold flex flex-col gap-1">
              <p className="text-left">
                {"Raison de l'émission de cette note de crédit"}
              </p>
              {creditNoteReason ? (
                <li className="text-black font-normal normal-case text-left">
                  {creditNoteReason}
                </li>
              ) : null}
            </div>
          </div>
        )}
      </section>

      <section id="invoice-services-section" className="mt-8">
        <div className="flex flex-row justify-between text-[8px] mx-12 text-black pb-4 mb-4 border-b border-gray-200">
          <div className="w-[190px] text-left">
            <span>{"Service(s)"}</span>
          </div>
          <div className="flex justify-evenly text-right">
            <div className="w-[47px]">{"Quantité"}</div>
            <div className="w-[47px]">{"Unité"}</div>
            <div className="w-[58px]">{"Coût unit."}</div>
            <div className="w-[40px]">{"TVA"}</div>
            <div className="w-[58px]">{"Total HT"}</div>
            <div className="w-[58px]">{"Total TTC"}</div>
          </div>
        </div>
        {documentData?.services?.map((service) => (
          <div
            className="flex flex-col gap-4 mt-2"
            key={"service" + service.id}
          >
            <div className="flex flex-row justify-between mx-12 text-[8px]">
              <div className="flex flex-col justify-start w-[190px]">
                <div className="justify-start text-left font-semibold break-words">
                  {service.title}
                </div>
                <div className="text-left font-normal mt-[2px] whitespace-pre-line break-words">
                  {service?.description}
                </div>
              </div>
              <div className="flex flex-row text-black text-right">
                <div className="w-[47px]">
                  {service?.quantity?.amount ? service?.quantity?.amount : 0}{" "}
                </div>
                <div className="w-[47px]">
                  {service?.quantity?.unit
                    ? getUnitLabel(
                        service?.quantity?.unit,
                        service?.quantity?.amount
                      )
                    : ""}
                </div>
                <div className="w-[58px]">
                  {service?.unitPrice?.amount ? service?.unitPrice?.amount : 0}{" "}
                  {countriesCurrency[service?.unitPrice?.currency]}
                </div>
                <div className="w-[40px]">
                  {service?.vatRate?.taxRate
                    ? formatNumberForDocument(service?.vatRate?.taxRate * 100)
                    : 0}
                  %
                </div>
                <div className="w-[58px]">
                  {service?.quantity?.amount && service?.unitPrice?.amount
                    ? formatNumberForDocument(
                        service?.quantity?.amount * service?.unitPrice?.amount
                      )
                    : 0}{" "}
                  {countriesCurrency[service?.unitPrice?.currency]}
                </div>
                <div className="w-[58px]">
                  {service?.quantity?.amount && service?.unitPrice?.amount
                    ? formatNumberForDocument(
                        service?.quantity?.amount * service?.unitPrice?.amount +
                          service?.quantity?.amount *
                            service?.unitPrice?.amount *
                            service?.vatRate?.taxRate
                      )
                    : 0}{" "}
                  {countriesCurrency[service?.unitPrice?.currency]}
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>

      {documentData?.services &&
        documentData.services.map((service) => {
          const vatRate = service?.vatRate?.taxRate || 0;
          if (vatRate > 0) {
            const existingVentilation = tvaVentilation.find(
              (vent) => vent.VAT === vatRate
            );
            const serviceTotal =
              service?.quantity?.amount * service?.unitPrice?.amount;
            const serviceTVA = serviceTotal * vatRate;

            if (existingVentilation) {
              existingVentilation.total += serviceTVA;
            } else {
              tvaVentilation.push({
                VAT: vatRate,
                total: serviceTVA,
              });
            }
          }
        })}

      <section className="flex flex-col gap-1 mx-12 border-t pt-4 border-gray-200 mt-4">
        <div className="flex flex-row justify-between text-[8px] text-neutral-500">
          <div>{"Sous-total (HT)"}</div>
          <div>
            {formattedSubtotalHT}{" "}
            {countriesCurrency[documentData?.total?.currency || currency]}
          </div>
        </div>
        {tvaVentilation.length > 0 && (
          <div>
            {tvaVentilation.map((vent, index) => (
              <div
                key={`vent-${index}`}
                className="flex flex-row justify-between text-[8px] text-neutral-500"
              >
                <div>{`TVA ${vent.VAT * 100}%`}</div>
                <div>
                  {formatNumberForDocument(vent.total)}{" "}
                  {
                    countriesCurrency[
                      documentData?.totalWithTax?.currency || currency
                    ]
                  }
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="flex flex-row justify-between text-[8px] text-neutral-500">
          <div>
            {"Total TVA"}
            {vatDisclaimer ? <span className="align-top">{"*"}</span> : ""}
          </div>

          <div>
            {formattedTotalTVA}{" "}
            {
              countriesCurrency[
                documentData?.totalWithTax?.currency || currency
              ]
            }
          </div>
        </div>
      </section>

      <section
        className="flex flex-row justify-between text-[9px] px-4 py-2 mx-12 mt-4 rounded text-black"
        style={{
          backgroundColor: documentBodyColor,
        }}
      >
        <div>{"Total (TTC)"}</div>
        <div>
          {formattedTotalTTC}{" "}
          {countriesCurrency[documentData?.totalRounded?.currency || currency]}
        </div>
      </section>

      {vatDisclaimer ? (
        <div className="flex flex-row items-start text-[6px] px-4 mx-12 mt-2 rounded text-black">
          <span className="align-top">{"*"}</span>
          <span>{vatDisclaimer}</span>
        </div>
      ) : null}

      {documentType !== LidapDocumentType.CREDIT_NOTE ? (
        <section
          className="flex flex-col gap-1 text-left mx-12 mt-12 text-[8px] text-black"
          id="payment-section"
        >
          <p className="font-bold">{"Moyen de paiement"}</p>
          <div className="payment-documentData">
            {accountsToCredit.map((account) => (
              <div key={"account:" + account.externalId}>
                <span style={{ fontWeight: "700" }}>
                  {PaymentMethodLabel[account.additionalDataSubType] ||
                    account.additionalDataSubType}
                  :{" "}
                </span>
                {account.additionalData}
              </div>
            ))}
          </div>
        </section>
      ) : null}

      {documentConditions ? (
        <section
          className="flex flex-col gap-1 text-left mx-12 my-6 text-[8px] text-black"
          id="conditions-section"
        >
          <p className="font-bold">Conditions</p>
          <div className="w-full break-words">
            <span className="whitespace-pre-line">{documentConditions}</span>
          </div>
        </section>
      ) : null}
      {creditNotePaymentApplicationMethod ? (
        <section
          className="flex flex-col gap-1 text-left mx-12 my-8 text-[8px] text-black"
          id="conditions-section"
        >
          <p className="font-bold">{"Moyen d'application du crédit"}</p>
          <div className="conditions-documentData w-full break-words">
            {creditNotePaymentApplicationMethod}
          </div>
        </section>
      ) : null}

      {documentData?.purchaseOrder ? (
        <section className="flex flex-col gap-1 text-left mx-12 my-8 text-[8px] text-black">
          <p className="font-bold">{"Numéro de bon de commande"}</p>
          <div className="conditions-documentData w-full break-words">
            <span>{documentData?.purchaseOrder}</span>
          </div>
        </section>
      ) : null}

      {documentData?.documentType === LidapDocumentType.CREDIT_NOTE ||
      documentData?.documentType === LidapDocumentType.QUOTE ? (
        <section className="flex justify-end gap-1 text-left mx-12 my-8 text-[8px] text-black">
          <p className="font-bold">{"Signature"}</p>
        </section>
      ) : null}
    </div>
  );
}
