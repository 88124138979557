import ButtonTailwind from "@/components/token/button/buttonTailwind";
import { CheckIcon } from "@heroicons/react/24/outline";

export default function SubscriptionCard({
  subscriptionName,
  isLoading = false,
  disabled = false,
  subscriptionPrice,
  subscriptionCurrency = "€",
  onClick,
  submitWording = "Gérer mon abonnement",
  extraWording,
}) {
  return (
    <div className="flex flex-col gap-8">
      <div className="bg-white bg-[url('https://uploads-ssl.webflow.com/659306df7780e23c50d2e1e3/668eb801ef10e473b0a83884_bg-offer.svg')] bg-right-bottom bg-no-repeat rounded-lg border border-neutral-300 dark:border-neutral-700 shadow-sm px-6 py-8 flex flex-col gap-6">
        <div className="flex flex-row justify-between">
          <div>
            <p className="font-bold text-base">{subscriptionName}</p>
            <p className="text-neutral-500">Facturation & analyse comptable</p>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <p className="text-neutral-400 text-base line-through">
              10€ / mois
            </p>
            <p className="text-black font-bold text-lg">
              <span className="font-bold text-black">
                {subscriptionPrice}
                {subscriptionCurrency}{" "}
              </span>
              / mois
            </p>
          </div>
        </div>
        <div className="flex flex-row gap-8">
          <div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2 items-center">
                <CheckIcon className="h-5 w-5 text-black" />
                <p className="text-sm">{"Dashboard des revenus"}</p>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <CheckIcon className="h-5 w-5 text-black" />
                <p className="text-sm">{"Devis, factures & notes de crédit"}</p>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <CheckIcon className="h-5 w-5 text-black" />
                <p className="text-sm">{"Branding personnalisé"}</p>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <CheckIcon className="h-5 w-5 text-black" />
                <p className="text-sm">{"Bibliothèque d'articles"}</p>
              </div>
            </div>
          </div>

          <div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2 items-center">
                <CheckIcon className="h-5 w-5 text-black" />
                <p className="text-sm">{"Portefeuille de clients"}</p>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <CheckIcon className="h-5 w-5 text-black" />
                <p className="text-sm">{"Recherche intelligente de clients"}</p>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <CheckIcon className="h-5 w-5 text-neutral-400" />
                <p className="text-sm text-neutral-400">
                  {"Connectez votre banque"}
                </p>
                <div className="bg-neutral-50 px-2 py border border-neutral-200 rounded-md">
                  <p className="text-sm text-neutral-400">{"Bientôt"}</p>
                </div>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <CheckIcon className="h-5 w-5 text-neutral-400" />
                <p className="text-sm text-neutral-400">
                  {"Rapprochez vos transactions"}
                </p>
                <div className="bg-neutral-50 px-2 py border border-neutral-200 rounded-md">
                  <p className="text-sm text-neutral-400">{"Bientôt"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <ButtonTailwind
            style="w-max"
            onClick={onClick}
            loading={isLoading}
            disabled={disabled}
          >
            {submitWording}
          </ButtonTailwind>
          {extraWording ? (
            <p className="text-xs text-red-500 my-auto">{extraWording}</p>
          ) : null}
        </div>
      </div>
      <div className="w-full border-t border-grey-400"></div>
    </div>
  );
}
