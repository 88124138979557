// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.changeSettingsFile {
  display: flex;
  gap: 2rem;
  align-items: center;
  display: none;
}

#logoSection {
  width: 4rem;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 !important;
  background-color: #d9d9d9;
}

#modifyAvatar {
  margin: 0 !important;
  display: flex;
  text-align: center;
  padding: 0.625rem 1rem;
  border-radius: 0.25rem;
  -webkit-user-select: none;
          user-select: none;
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
  cursor: pointer;
}

#settings-profile-form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

.settings-align-inputs {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.settings-input-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.settings-input-box > input {
  padding: 0.625rem 1rem;
  border-radius: 0.25rem;
  border: solid 1px var(--neutral-400);
  color: var(--neutral-400);
  cursor: text;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/profile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;EACpB,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;EACpB,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,sBAAsB;EACtB,yBAAiB;UAAjB,iBAAiB;EACjB,2BAAmB;EAAnB,mBAAmB;EACnB,0BAAkB;EAAlB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAS;EACT,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,oCAAoC;EACpC,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".changeSettingsFile {\n  display: flex;\n  gap: 2rem;\n  align-items: center;\n  display: none;\n}\n\n#logoSection {\n  width: 4rem;\n  border-radius: 50%;\n  overflow: hidden;\n  margin: 0 !important;\n  background-color: #d9d9d9;\n}\n\n#modifyAvatar {\n  margin: 0 !important;\n  display: flex;\n  text-align: center;\n  padding: 0.625rem 1rem;\n  border-radius: 0.25rem;\n  user-select: none;\n  height: max-content;\n  width: max-content;\n  cursor: pointer;\n}\n\n#settings-profile-form {\n  display: flex;\n  flex-direction: column;\n  gap: 0.75rem;\n  width: 100%;\n}\n\n.settings-align-inputs {\n  display: flex;\n  gap: 1rem;\n  width: 100%;\n}\n\n.settings-input-box {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.settings-input-box > input {\n  padding: 0.625rem 1rem;\n  border-radius: 0.25rem;\n  border: solid 1px var(--neutral-400);\n  color: var(--neutral-400);\n  cursor: text;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
