export const DocumentAdditionalDataType = {
  CREDIT_NOTE_REASON: "CREDIT_NOTE_REASON",
  CREDIT_NOTE_COMMENT: "CREDIT_NOTE_COMMENT",
  CREDIT_NOTE_CONDITIONS: "CREDIT_NOTE_CONDITIONS",
  CREDIT_NOTE_PAYMENT_APPLICATION_METHOD:
    "CREDIT_NOTE_PAYMENT_APPLICATION_METHOD",
  INVOICE_COMMENT: "INVOICE_COMMENT",
  INVOICE_CONDITIONS: "INVOICE_CONDITIONS",
  INVOICE_PAYMENT_CONDITIONS: "INVOICE_PAYMENT_CONDITIONS",
  ISSUER_VAT_NUMBER: "ISSUER_VAT_NUMBER",
  ISSUER_NAME: "ISSUER_NAME",
  RECEIVER_VAT_NUMBER: "RECEIVER_VAT_NUMBER",
  RECEIVER_NAME: "RECEIVER_NAME",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  ISSUER_PHONE: "ISSUER_PHONE",
  ISSUER_EMAIL: "ISSUER_EMAIL",
  LOGO: "LOGO",
  DOCUMENT_TITLE: "DOCUMENT_TITLE",
  VAT_DISCLAIMER: "VAT_DISCLAIMER",
  DOCUMENT_TITLE_COLOR: "DOCUMENT_TITLE_COLOR",
  DOCUMENT_BODY_COLOR: "DOCUMENT_BODY_COLOR",
  ISSUER_COMPANY_NUMBER: "ISSUER_COMPANY_NUMBER",
  RECEIVER_COMPANY_NUMBER: "RECEIVER_COMPANY_NUMBER",
};
