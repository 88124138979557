import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { stringify } from "@/utils/stringify";
import Lidapi, { manageLidapResponse } from "@/utils/lidapi";

export function useClients({
  enterpriseId,
  clientId,
  queryParam,
  conditionallyEnabled,
  reactQueryConfig,
}) {
  const clientIdParam = clientId ? `/${clientId}` : "";
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  const { isLoading, isError, data, error, refetch, isInitialLoading } =
    useQuery({
      queryKey: ["clients", { enterpriseId, clientId }, queryParam],
      queryFn: async () => {
        const response = await fetch(
          `${process.env.BASE_API_URL}/${enterpriseId}/clients${clientIdParam}${queryString}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );
        return manageLidapResponse(response, "Could not load clients");
      },
      enabled: conditionallyEnabled && !!clientId && !!enterpriseId,
      ...reactQueryConfig,
    });

  return {
    isClientsLoading: isLoading,
    isClientsError: isError,
    clientsData: data,
    clientsError: error,
    refetchClients: refetch,
    isInitialLoading: isInitialLoading,
  };
}

export function getRecentClients({ enterpriseId, queryParam }) {
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  return {
    queryKey: ["clients", "recent", { enterpriseId }, queryParam],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/${enterpriseId}/clients/recent${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return manageLidapResponse(response, "Could not load recent clients");
    },
  };
}

export function useRecentClients({
  enterpriseId,
  queryParam,
  reactQueryConfig,
}) {
  const queryClient = useQueryClient();
  const { queryFn, queryKey } = getRecentClients({ enterpriseId, queryParam });
  const query = useQuery(queryKey, {
    queryFn: queryFn,
    initialData: () => {
      const dataFromCache = queryClient.getQueryData(queryKey);
      return dataFromCache;
    },
    ...reactQueryConfig,
  });

  return query;
}

export function useGetOneClient(queryKeyToInvalidate, onSuccessCallback) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async ({ enterpriseId, clientId, queryParam }) =>
      await api.getOneClient(enterpriseId, clientId, stringify(queryParam)),
    onSuccess: (data) => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    },
  });

  return action;
}
