import React from "react";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import Select from "@/components/token/select/select";
import InputTailwind from "@/components/token/Input/inputTailwind";
import { useFormCreatePaymentInformation } from "@/hooks/forms/useFormCreatePaymentInformation";

export default function CreatePaymentInformation(props) {
  const {
    paymentType,
    selectedPayment,
    setSelectedPayment,
    accountWording,
    setAccountWording,
    secondInputValue,
    setSecondInputValue,
    handleSubmit,
    AnnulerMoyenDePaiement,
    createPaymentInformation,
    isDuplicateTitle,
    check,
  } = useFormCreatePaymentInformation({
    setIsAccountFormDisplayed: props.setIsAccountFormDisplayed,
  });

  return (
    <div className="flex flex-col gap-3">
      <div className="z-50">
        <div
          className="bg-black opacity-50 absolute top-0 left-0 w-screen h-screen"
          onClick={() => props.setIsAccountFormDisplayed(false)}
        ></div>
        <div className="flex flex-col absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-12 bg-white rounded-2xl w-[28rem]">
          <h1 className="text-[2rem] mb-8 text-center">Ajouter un compte</h1>
          <div className="flex flex-col gap-4">
            <div>
              <Select
                data={paymentType}
                selectedItem={selectedPayment}
                setSelectedItem={setSelectedPayment}
                label="Moyen de paiement"
              />
            </div>
            {selectedPayment.isInputHidden ? null : (
              <InputTailwind
                label="Intitulé personnel"
                type="text"
                placeholder={selectedPayment.placeholderAccountWording}
                value={accountWording}
                onChange={(event) => setAccountWording(event.target.value)}
                isRequired={true}
                icon={false}
                error={
                  isDuplicateTitle(accountWording)
                    ? "Un moyen de paiement avec ce titre existe déjà"
                    : ""
                }
              />
            )}
            {selectedPayment.secondInput ? (
              <InputTailwind
                label={selectedPayment.label}
                type="text"
                placeholder={selectedPayment.placeholder}
                value={secondInputValue}
                onChange={(event) => setSecondInputValue(event.target.value)}
                isRequired={true}
                icon={false}
                error={check.isValid || !secondInputValue ? "" : check.message}
              />
            ) : null}
          </div>

          <div className="flex w-full gap-4 mt-8">
            {
              <>
                <ButtonTailwind
                  onClick={() => AnnulerMoyenDePaiement()}
                  variant={"outlined2"}
                  style="w-full"
                  disabled={createPaymentInformation.isLoading}
                >
                  Annuler
                </ButtonTailwind>
                <ButtonTailwind
                  onClick={handleSubmit}
                  loading={createPaymentInformation.isLoading}
                  style="w-full"
                >
                  Enregistrer
                </ButtonTailwind>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
