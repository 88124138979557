import { DocumentAdditionalDataType } from "../document/DocumentAdditionalDataType";
import { DocumentAddressType } from "../document/DocumentAddressType";
import { extractDocumentAdditionalData } from "../document/helpers/extractDocumentAdditionalData";
import { extractDocumentAddress } from "../document/helpers/extractDocumentAddress";

export function useCompanyNameAndAddress({ additionalData, addresses, type }) {
  const nameType =
    type === "ISSUER"
      ? DocumentAdditionalDataType.ISSUER_NAME
      : DocumentAdditionalDataType.RECEIVER_NAME;
  const addressType =
    type === "ISSUER"
      ? DocumentAddressType.ISSUER_BILLING
      : DocumentAddressType.RECEIVER_BILLING;

  const address = extractDocumentAddress({
    addresses,
    addressType,
  });

  const name = extractDocumentAdditionalData({
    additionalDataArray: additionalData,
    additionalDataType: nameType,
  });

  return { name, address };
}

export function useCompanyIdentifiers({
  additionalData,
  country,
  type,
  identifiers,
}) {
  if (identifiers) {
    return extractCompanyIdentifiers({ identifiers, country });
  }

  return extractCompanyIdentifiersFromDocument({
    additionalData,
    type,
    country,
  });
}

export function extractCompanyIdentifiersFromDocument({
  additionalData,
  type,
  country,
}) {
  const vatNumberType =
    type === "ISSUER"
      ? DocumentAdditionalDataType.ISSUER_VAT_NUMBER
      : DocumentAdditionalDataType.RECEIVER_VAT_NUMBER;
  const enterpriseNumberType =
    type === "ISSUER"
      ? DocumentAdditionalDataType.ISSUER_ENTERPRISE_NUMBER
      : DocumentAdditionalDataType.RECEIVER_ENTERPRISE_NUMBER;

  const vatNumber = extractDocumentAdditionalData({
    additionalDataArray: additionalData,
    additionalDataType: vatNumberType,
  });

  const { enterpriseNumber, enterpriseNumberLabel } = (() => {
    const identifierType = country === "FR" ? "SIREN" : "COMPANY_NUMBER"; //TODO! unify with below function
    const enterpriseNumber = extractDocumentAdditionalData({
      additionalDataArray: additionalData,
      additionalDataType: enterpriseNumberType,
    });

    const enterpriseNumberLabel = identifierType === "SIREN" ? "SIREN" : "N°";

    return {
      enterpriseNumber,
      enterpriseNumberLabel,
    };
  })();
  return { vatNumber, enterpriseNumber, enterpriseNumberLabel };
}

export function extractCompanyIdentifiers({ identifiers, country }) {
  const vatNumber = identifiers?.find(
    (id) => id.identifierType == "VAT"
  )?.identifier;

  const { enterpriseNumber, enterpriseNumberLabel } = (() => {
    const identifierType = country === "FR" ? "SIREN" : "COMPANY_NUMBER"; //TODO! unify with below function
    const enterpriseNumber = identifiers?.find(
      (id) => id.identifierType === identifierType
    );

    const enterpriseNumberLabel =
      enterpriseNumber?.identifierType === "SIREN" ? "SIREN" : "N°";

    return {
      enterpriseNumber: enterpriseNumber?.identifier,
      enterpriseNumberLabel,
    };
  })();

  return { vatNumber, enterpriseNumber, enterpriseNumberLabel };
}
