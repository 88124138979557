import { Outlet } from "react-router-dom";
import ErrorFallbackComponent from "@/utils/ErrorFallbackComponent";
import { FaroErrorBoundary } from "@grafana/faro-react";

export function ShellLayout() {
  return (
    <FaroErrorBoundary
      fallback={(error, resetBoundary) => (
        <ErrorFallbackComponent
          error={error}
          resetErrorBoundary={resetBoundary}
          context="router"
        />
      )}
    >
      <Outlet />
    </FaroErrorBoundary>
  );
}
