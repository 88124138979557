import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Document,
  Font,
  Svg,
} from "@react-pdf/renderer";
import {
  countriesName,
  countriesCurrency,
} from "@/data/countryCodeCorrespondance";
import InterThin from "@/assets/Fonts/Inter/static/Inter-Light.ttf";
import InterRegular from "@/assets/Fonts/Inter/static/Inter-Regular.ttf";
import IntersSemiBold from "@/assets/Fonts/Inter/static/Inter-SemiBold.ttf";
import InterBold from "@/assets/Fonts/Inter/static/Inter-Bold.ttf";
import { LidapDocumentType } from "@/components/token/documents/DocumentType";
import { useDocumentPreview } from "../document/useDocumentForm";
import { PaymentMethodLabel } from "../MoyenDePaiement/PaymentMethodLabel";
import { formatNumberForDocument } from "@/utils/numbers/useFormatNumber";

export default function DocumentPDFV2({ companyLogo, documentData }) {
  const {
    issuingDateAsDDMMYYYY,
    dueDateAsDDMMYYYY,
    documentType,
    documentNumber,
    documentTitle,
    documentBodyColor,
    documentTitleColor,
    issuerEmail,
    issuerPhone,
    vatDisclaimer,
    documentConditions,
    creditNoteReason,
    creditNotePaymentApplicationMethod,
    accountsToCredit,
    formattedSubtotalHT,
    formattedTotalTVA,
    formattedTotalTTC,
    tvaVentilation,
    getUnitLabel,
    services,
    refundedInvoice,
    issuerName,
    issuerAddress,
    issuerVatNumber,
    issuerEnterpriseNumber,
    issuerEnterpriseNumberLabel,
    receiverName,
    receiverAddress,
    receiverVatNumber,
    receiverEnterpriseNumber,
    receiverEnterpriseNumberLabel,
  } = useDocumentPreview({
    documentData,
  });

  Font.register({
    family: "Inter",
    fonts: [
      { src: InterThin, fontStyle: "normal", fontWeight: 100 },
      { src: InterRegular, fontStyle: "normal", fontWeight: 400 },
      { src: IntersSemiBold, fontStyle: "normal", fontWeight: 600 },
      { src: InterBold, fontStyle: "normal", fontWeight: "bold" },
    ],
  });

  const invoiceStyle = StyleSheet.create({
    page: {
      padding: "52px 24px",
    },
    invoicePreviewMainBox: {
      // padding: "64px 40px",
      fontFamily: "Inter",
      fontWeight: "600",
    },
    invoicePreviewHeader: {
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "0 24px 48px 24px",
    },
    creditNoteHeadInfos: {
      flexDirection: "column",
      textAlign: "left",
      padding: "16px 24px 0 24px",
    },
    creditReasonTitle: {
      fontSize: "8px",
      fontWeight: "600",
      color: "#000",
      marginBottom: "12px",
    },
    creditReasonWrapper: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      fontWeight: "400",
    },
    creditReason: {
      textAlign: "left",
      fontSize: "8px",
      fontWeight: "400",
    },
    invoiceUserInfosLogo: {
      flexDirection: "row",
    },
    invoiceUserInfosLogoSpace: {
      flexDirection: "row",
      width: "96px",
      height: "48px",
    },
    invoiceLogo: {
      width: "96px",
      height: "48px",
      objectFit: "contain",
    },
    invoiceUserInfosWrapper: {
      flexDirection: "row",
      textAlign: "left",
      fontSize: "8px",
      color: "#8C8C8C",
      padding: "0px 32px 0px 0px",
    },
    invoiceUserInfos: {
      justifyContent: "center",
      flexDirection: "column",
      gap: "2px",
      textAlign: "left",
    },
    invoiceIdBox: {
      marginTop: "8px",
    },
    textInvoice: {
      fontSize: "20px",
      color: documentTitleColor,
      fontWeight: 700,
    },
    invoiceId: {
      fontSize: "20px",
      color: documentTitleColor,
      fontWeight: 700,
    },
    invoiceClientInfosWrapper: {
      flexDirection: "column",
      textAlign: "left",
    },
    invoiceClientInfosGroup: {
      flexDirection: "row",
      marginBottom: "12px",
      width: "256px",
    },
    invoiceClientInfosBox: {
      flexDirection: "column",
      width: "128px",
      maxWidth: "100%",
      gap: "2px",
    },
    invoiceClientInfosTitle: {
      fontSize: "8px",
      fontWeight: "600",
      color: "#000",
    },
    invoiceClientInfos: {
      fontSize: "8px",
      color: "#737373",
      fontWeight: "500",
      lineHeight: "135%",
    },
    invoiceServicesSection: {
      flexDirection: "column",
      textAlign: "left",
      // marginTop: "48px",
      padding: "0 24px",
    },
    namesBox: {
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "8px",
      fontWeight: "500",
      paddingBottom: "16px",
      marginBottom: "16px",
      borderBottom: "1px solid #E5E5E5",
    },
    namesBoxRight: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      textAlign: "right",
    },
    service: {
      width: "192px",
      textAlign: "left",
    },
    serviceQuantity: {
      width: "55px",
      textAlign: "right",
    },
    serviceUnit: {
      width: "45px",
      textAlign: "right",
    },
    servicePrice: {
      width: "55px",
      textAlign: "right",
    },
    serviceTVA: {
      width: "40px",
      textAlign: "right",
    },
    serviceTotal: {
      width: "55px",
      textAlign: "right",
    },
    serviceTotalVAT: {
      width: "55px",
      textAlign: "right",
    },
    serviceBox: {
      flexDirection: "column",
      padding: "8px 0px 0px 0px",
    },
    serviceTitleInfos: {
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "8px",
      marginBottom: "2px",
    },
    serviceTitle: {
      fontSize: "8px",
      color: "#000",
      fontWeight: "600",
    },
    serviceInfos: {
      flexDirection: "row",
      fontWeight: "400",
    },
    serviceQuantityWrapper: {
      flexDirection: "column",
      justifyContent: "flex-end",
      textAlign: "right",
      gap: "2px",
      width: "61px",
    },
    serviceTitleWrapper: {
      flexDirection: "column",
      justifyContent: "flex-start",
      gap: "2px",
      width: "192px",
    },
    bulletPoint: {
      fontSize: "8px",
      fontWeight: "400",
      color: "#000",
      marginRight: "4px",
      marginLeft: "4px",
    },

    serviceDescription: {
      marginTop: "2px",
      textAlign: "left",
      fontSize: "8px",
      fontWeight: "400",
      whiteSpace: "pre-line",
      width: "192px",
    },
    invoiceTotalSection: {
      flexDirection: "column",
      gap: "4px",
      margin: "16px 24px 0 24px",
      paddingTop: "12px",
      borderTop: "1px solid #E5E5E5",
    },
    totalBox: {
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "8px",
      fontWeight: "500",
    },
    asterisk: {
      alignContent: "top",
    },
    invoiceTotalsSum: {
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "8px",
      color:
        // invoiceContent?.additionalData?.find(
        //   (data) => data.additionalDataType === "DOCUMENT_BODY_COLOR"
        // )?.additionalData ||
        "#000000", // Default to black
      backgroundColor: documentBodyColor,
      margin: "16px 8px 0px 8px",
      padding: "12px 16px",
      borderRadius: "4px",
    },
    vatDisclaimer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "between",
      fontSize: "6px",
      padding: "0 4px",
      margin: "8px 16px 0 16px",
    },
    paymentSection: {
      fontSize: "8px",
      flexDirection: "column",
      textAlign: "left",
      marginTop: "48px",
      padding: "0 24px",
    },
    invoiceh6: {
      fontSize: "8px",
      fontWeight: 600,
      color: "#000",
      marginBottom: "8px",
    },
    paymentInfos: {
      flexDirection: "column",
      gap: "6px",
    },
    bank: {
      flexDirection: "row",
      gap: "12px",
    },
    imgBox: {
      height: "12px",
      width: "18px",
      justifyContent: "center",
    },
    bankImage: {
      height: "12px",
      objectFit: "contain",
    },
    bankInfosComponent: {
      flexDirection: "row",
      gap: "4px",
    },
    bankInfosTitle: {
      fontSize: "8px",
      fontWeight: "600",
      color: "#000",
    },
    paypal: {
      flexDirection: "row",
      gap: "12px",
      fontWeight: "400",
    },
    conditionsSection: {
      flexDirection: "column",
      margin: "24px 24px 0 24px",
      textAlign: "left",
      fontSize: "8px",
    },
    conditionsInfos: {
      fontSize: "8px",
      fontWeight: "400",
    },
    invoiceSignature: {
      textAlign: "right",
      justifyContent: "right",
      fontSize: "8px",
      fontWeight: "bold",
      margin: "24px 24px 0 24px",
    },
    pageNumber: {
      position: "absolute",
      fontSize: "10px",
      bottom: 24,
      right: 24,
      fontWeight: "400",
    },
  });

  const logoSrc = companyLogo?.imageContent
    ? `data:image/${companyLogo.imageFormat};base64,${companyLogo.imageContent}`
    : undefined;
  const isSvg = companyLogo?.imageFormat?.startsWith("svg");

  return (
    <Document>
      <Page style={invoiceStyle.page}>
        <View
          style={invoiceStyle.invoicePreviewMainBox}
          className={documentData?.className}
        >
          <View style={invoiceStyle.invoicePreviewHeader} fixed>
            <View style={invoiceStyle.invoiceInfos}>
              <View
                style={{
                  ...invoiceStyle.invoiceUserInfosWrapper,
                  ...(logoSrc
                    ? { padding: "0px 32px 0px 0px" }
                    : { padding: "0px 40px 0px 0px" }),
                }}
              >
                {logoSrc ? (
                  <View style={invoiceStyle.invoiceUserInfosLogo}>
                    {isSvg ? (
                      <Svg></Svg>
                    ) : (
                      <Image
                        src={logoSrc}
                        style={invoiceStyle.invoiceLogo}
                      ></Image>
                    )}
                  </View>
                ) : null}
              </View>

              <View style={invoiceStyle.invoiceIdBox}>
                <Text style={invoiceStyle.textInvoice}>{documentTitle}</Text>
                <Text style={invoiceStyle.invoiceId}>{documentNumber}</Text>
              </View>
            </View>

            <View style={invoiceStyle.invoiceClientInfosWrapper}>
              <View style={invoiceStyle.invoiceClientInfosGroup}>
                <View style={invoiceStyle.invoiceClientInfosBox}>
                  <Text style={invoiceStyle.invoiceClientInfosTitle}>
                    {"Date d'émission"}
                  </Text>
                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {issuingDateAsDDMMYYYY}
                  </Text>
                </View>
                {dueDateAsDDMMYYYY ? (
                  <View style={invoiceStyle.invoiceClientInfosBox}>
                    <Text style={invoiceStyle.invoiceClientInfosTitle}>
                      {"Date d'échéance"}
                    </Text>

                    <Text style={invoiceStyle.invoiceClientInfos}>
                      {dueDateAsDDMMYYYY}
                    </Text>
                  </View>
                ) : null}
              </View>

              <View style={invoiceStyle.invoiceClientInfosGroup}>
                <View style={invoiceStyle.invoiceClientInfosBox}>
                  <Text style={invoiceStyle.invoiceClientInfosTitle}>De</Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {issuerName}
                  </Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {issuerAddress.streetName}
                  </Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {issuerAddress.postalCode}, {issuerAddress.city}
                  </Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {countriesName[issuerAddress.country]}
                  </Text>
                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {issuerEnterpriseNumberLabel} {issuerEnterpriseNumber}
                  </Text>
                  {issuerVatNumber ? (
                    <Text style={invoiceStyle.invoiceClientInfos}>
                      {"N° TVA : "}
                      {issuerVatNumber}
                    </Text>
                  ) : null}
                  <View>
                    <Text style={invoiceStyle.invoiceClientInfos}>
                      {issuerEmail}
                    </Text>
                    <Text style={invoiceStyle.invoiceClientInfos}>
                      {issuerPhone}
                    </Text>
                  </View>
                </View>

                <View style={invoiceStyle.invoiceClientInfosBox}>
                  <Text style={invoiceStyle.invoiceClientInfosTitle}>
                    {"À"}
                  </Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {receiverName}
                  </Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {receiverAddress.streetName}
                  </Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {receiverAddress.postalCode}, {receiverAddress.city}
                  </Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {countriesName[receiverAddress.country]}
                  </Text>
                  {receiverEnterpriseNumber ? (
                    <Text style={invoiceStyle.invoiceClientInfos}>
                      {receiverEnterpriseNumberLabel} {receiverEnterpriseNumber}
                    </Text>
                  ) : null}
                  {receiverVatNumber ? (
                    <Text style={invoiceStyle.invoiceClientInfos}>
                      {"N° TVA : "}
                      {receiverVatNumber}
                    </Text>
                  ) : null}
                </View>
              </View>
            </View>
          </View>

          {documentType === LidapDocumentType.CREDIT_NOTE ? (
            <View style={invoiceStyle.creditNoteHeadInfos}>
              <Text style={invoiceStyle.creditReasonTitle}>
                {"Note de crédit sur facture"} {refundedInvoice.invoiceNumber}{" "}
                {"du"} {refundedInvoice.issuingDateAsDDMMYYYY}
              </Text>
              <View>
                <Text style={invoiceStyle.creditReasonTitle}>
                  {"Raison de l'émission de cette note de crédit"}
                </Text>
                <View style={invoiceStyle.creditReasonWrapper}>
                  <Text style={invoiceStyle.bulletPoint}>•</Text>
                  <Text style={invoiceStyle.creditReason}>
                    {creditNoteReason}
                  </Text>
                </View>
              </View>
            </View>
          ) : null}

          <View style={invoiceStyle.invoiceServicesSection}>
            <View style={invoiceStyle.namesBox}>
              <View style={invoiceStyle.service}>
                <Text>{"Service(s)"}</Text>
              </View>
              <View style={invoiceStyle.namesBoxRight}>
                <Text style={invoiceStyle.serviceQuantity}>{"Quantité"}</Text>
                <Text style={invoiceStyle.serviceUnit}>{"Unité"}</Text>
                <Text style={invoiceStyle.servicePrice}>{"Coût unit."}</Text>
                <Text style={invoiceStyle.serviceTVA}>{"TVA"}</Text>
                <Text style={invoiceStyle.serviceTotal}>{"Total HT"}</Text>
                <Text style={invoiceStyle.serviceTotal}>{"Total TVA"}</Text>
              </View>
            </View>
            {services?.map((service) => (
              <View style={invoiceStyle.serviceBox} key={service.id}>
                <View style={invoiceStyle.serviceTitleInfos}>
                  <View style={invoiceStyle.serviceTitleWrapper}>
                    <Text style={invoiceStyle.serviceTitle}>
                      {service.title}
                    </Text>
                    <Text style={invoiceStyle.serviceDescription}>
                      {service.description}
                    </Text>
                  </View>

                  <View style={invoiceStyle.serviceInfos}>
                    <Text style={invoiceStyle.serviceQuantity}>
                      {service.quantity.amount}
                    </Text>
                    <Text style={invoiceStyle.serviceUnit}>
                      {service?.quantity?.unit
                        ? getUnitLabel(
                            service?.quantity?.unit,
                            service?.quantity?.amount
                          )
                        : ""}
                    </Text>
                    <Text style={invoiceStyle.servicePrice}>
                      {service.unitPrice.amount}
                      {countriesCurrency[service?.unitPrice?.currency]}
                    </Text>
                    <Text style={invoiceStyle.serviceTVA}>
                      {service?.vatRate?.taxRate * 100}%
                    </Text>
                    <Text style={invoiceStyle.serviceTotal}>
                      {formatNumberForDocument(
                        service.quantity.amount * service.unitPrice.amount
                      )}
                      {countriesCurrency[service?.unitPrice?.currency]}
                    </Text>
                    <Text style={invoiceStyle.serviceTotalVAT}>
                      {service?.quantity?.amount && service?.unitPrice?.amount
                        ? formatNumberForDocument(
                            service?.quantity?.amount *
                              service?.unitPrice?.amount *
                              service?.vatRate?.taxRate +
                              service?.quantity?.amount *
                                service?.unitPrice?.amount
                          )
                        : 0}
                      {countriesCurrency[service?.unitPrice?.currency]}
                    </Text>
                  </View>
                </View>
              </View>
            ))}
          </View>

          {services &&
            services.map((service) => {
              const vatRate = service?.vatRate?.taxRate || 0;
              if (vatRate > 0) {
                const existingVentilation = tvaVentilation.find(
                  (vent) => vent.VAT === vatRate
                );
                const serviceTotal =
                  service?.quantity?.amount * service?.unitPrice?.amount;
                const serviceTVA = serviceTotal * vatRate;

                if (existingVentilation) {
                  existingVentilation.total += serviceTVA;
                } else {
                  tvaVentilation.push({
                    VAT: vatRate,
                    total: serviceTVA,
                  });
                }
              }
            })}

          <View style={invoiceStyle.invoiceTotalSection}>
            <View style={invoiceStyle.totalBox}>
              <Text>Sous-total (HT)</Text>
              <Text>
                {formattedSubtotalHT}
                {countriesCurrency[documentData.total?.currency]}
              </Text>
            </View>
            {tvaVentilation.length > 0 && (
              <View
              // className="flex flex-row justify-between text-[8px] text-neutral-500"
              >
                {tvaVentilation.map((vent, index) => (
                  <View key={`vent-${index}`} style={invoiceStyle.totalBox}>
                    <View>
                      <Text>{`TVA ${vent.VAT * 100}%`}</Text>
                    </View>
                    <View>
                      <Text>
                        {formatNumberForDocument(vent.total)}{" "}
                        {countriesCurrency[documentData.totalWithTax?.currency]}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            )}
            <View style={invoiceStyle.totalBox}>
              <Text>
                {"Total TVA"}
                {vatDisclaimer ? (
                  <Text style={invoiceStyle.asterisk}>{"*"}</Text>
                ) : (
                  ""
                )}
              </Text>
              <Text>
                {formattedTotalTVA}
                {countriesCurrency[documentData?.totalWithTax?.currency]}
              </Text>
            </View>
          </View>

          <View style={invoiceStyle.invoiceTotalsSum}>
            <Text>{"Total (TTC)"}</Text>
            <Text>
              {formattedTotalTTC}
              {countriesCurrency[documentData.totalRounded?.currency]}
            </Text>
          </View>
          {vatDisclaimer ? (
            <Text style={invoiceStyle.vatDisclaimer}>
              {"*"}
              {vatDisclaimer}
            </Text>
          ) : null}

          <View style={invoiceStyle.paymentSection} wrap={false}>
            <Text style={invoiceStyle.invoiceh6}>{"Moyens de paiement"}</Text>
            <View style={invoiceStyle.paymentInfos}>
              {accountsToCredit?.map((account, index) => (
                <Text
                  key={"payment:" + index}
                  style={invoiceStyle.bankInfosComponent}
                >
                  <Text style={invoiceStyle.bankInfosTitle}>
                    {PaymentMethodLabel[account.additionalDataSubType] ||
                      account.additionalDataSubType}
                    :{" "}
                  </Text>
                  {account.additionalData}
                </Text>
              ))}
            </View>
          </View>

          {documentConditions ? (
            <View style={invoiceStyle.conditionsSection}>
              <Text style={invoiceStyle.invoiceh6}>{"Conditions"}</Text>
              <Text style={invoiceStyle.conditionsInfos}>
                {documentConditions}
              </Text>
            </View>
          ) : null}

          {documentType === LidapDocumentType.CREDIT_NOTE ? (
            <View style={invoiceStyle.conditionsSection}>
              <Text style={invoiceStyle.invoiceh6}>
                {"Moyen d'application du crédit"}
              </Text>
              <Text style={invoiceStyle.conditionsInfos}>
                {creditNotePaymentApplicationMethod}
              </Text>
            </View>
          ) : null}

          {documentData.purchaseOrder ? (
            <View style={invoiceStyle.conditionsSection}>
              <Text style={invoiceStyle.invoiceh6}>
                {"Numéro de bon de commande"}
              </Text>
              <Text style={invoiceStyle.conditionsInfos}>
                {documentData.purchaseOrder}
              </Text>
            </View>
          ) : null}

          {documentType !== LidapDocumentType.INVOICE ? (
            <View style={invoiceStyle.invoiceSignature}>
              <Text style={invoiceStyle.signatureH6}>{"Signature"}</Text>
            </View>
          ) : null}
        </View>
        <Text
          style={invoiceStyle.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
