import { CRUDL } from "@/utils/crudl";
import { notify } from "@/utils/notify";
import { useState } from "react";
import { useDefaultClient } from "./defaults/useDefaultClient";

export default function useFormClientData({
  onCancel,
  onSubmit,
  context,
  isLoading,
  isError,
  data,
}) {
  /* Default values */
  const { defaultClientType, defaultNation, isDefaultClientLoading } =
    useDefaultClient();
  const emptySimpleField = { success: null, data: "", isDirty: false };
  const countryIdentifierType = (country) => {
    if (country === "BE") {
      return "COMPANY_NUMBER";
    } else if (country === "FR") {
      return "SIREN";
    } else {
      return "COMPANY_NUMBER";
    }
  };

  /* Value from prop */
  const _nation = data?.country || defaultNation;
  const _clientType = data?.clientType || defaultClientType;
  const _clientName = data?.name || "";
  const _firstName = data?.person?.firstName || "";
  const _lastName = data?.person?.lastName || "";
  const _email = data?.emails?.find((e) => e.isMain);
  const _mail = _email?.email || "";
  const _phone = data?.phones?.find((p) => p.isMain);
  const _address = data?.addresses?.find((a) => a.isMain);
  const _streetName = _address?.streetName || "";
  const _city = _address?.city || "";
  const _postalCode = _address?.postalCode || "";
  const _companyIdentifier = data?.identifiers?.find(
    (i) =>
      i.identifierType === countryIdentifierType(_nation) &&
      i.countryOrRegion === _nation
  );
  const _vatNumber = data?.identifiers?.find(
    (i) => i.identifierType === "VAT" && i.countryOrRegion === "EU"
  );
  const defaultCompanyIdentifier = {
    identifierType:
      _companyIdentifier?.identifierType || countryIdentifierType(_nation),
    countryOrRegion: _companyIdentifier?.countryOrRegion || _nation,
    identifier: _companyIdentifier?.identifier || "",
  };
  const defaultVatNumber = {
    identifierType: "VAT_NUMBER",
    countryOrRegion: _vatNumber?.countryOrRegion || _nation,
    identifier: _vatNumber?.identifier || "",
  };

  /* State */
  // Form content
  const [selectedNation, setSelectedNation] = useState(null);
  const [streetName, setStreetName] = useState({
    ...emptySimpleField,
    data: null,
  });
  const [city, setCity] = useState({ ...emptySimpleField, data: null });
  const [postalCode, setPostalCode] = useState({
    ...emptySimpleField,
    data: null,
  });
  const [clientType, setClientType] = useState(_clientType);
  const [clientName, setClientName] = useState({
    ...emptySimpleField,
    data: null,
  });
  const [firstName, setFirstName] = useState({
    ...emptySimpleField,
    data: null,
  });
  const [lastName, setLastName] = useState({
    ...emptySimpleField,
    data: null,
  });
  const [mail, setMail] = useState({ ...emptySimpleField, data: null });
  const [phone, setPhone] = useState({ ...emptySimpleField, data: null });
  const [companyIdentifier, setCompanyIdentifier] = useState({
    success: false,
    data: null,
    isDirty: false,
  });
  const [vatNumber, setVatNumber] = useState({
    success: false,
    data: null,
    isDirty: false,
  });

  // Form control
  const [isInvalidFormSubmitted, setIsInvalidFormSubmitted] = useState(false);

  /* Derived */
  const displayError = isInvalidFormSubmitted || isError;
  const isFormDirty = (() => {
    return (
      clientName.isDirty ||
      firstName.isDirty ||
      lastName.isDirty ||
      mail.isDirty ||
      phone.isDirty ||
      streetName.isDirty ||
      city.isDirty ||
      (postalCode.isDirty && postalCode?.data !== _postalCode) ||
      companyIdentifier.isDirty ||
      vatNumber.isDirty ||
      selectedNation !== null ||
      clientType !== _clientType
    );
  })();

  /* Functions */
  const handleNationChange = (c) => {
    setSelectedNation(c);
    setCompanyIdentifier((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        identifierType: countryIdentifierType(c),
        countryOrRegion: c,
      },
    }));
    setPostalCode((prev) => ({ ...prev, isDirty: true }));
    setVatNumber((prev) => ({
      ...prev,
      data: {
        identifier: "",
        ...prev.data,
        identifierType: "VAT_NUMBER",
        countryOrRegion: c,
      },
    }));
  };

  const isValid = (() => {
    if (context === CRUDL.READ || context === CRUDL.DELETE) return true;
    if (context === CRUDL.UPDATE) {
      if (clientName.isDirty && !clientName.success) return false;
      if (firstName.isDirty && !firstName.success) return false;
      if (lastName.isDirty && !lastName.success) return false;
      if (mail.isDirty && !mail.success) return false;
      if (streetName.isDirty && !streetName.success) return false;
      if (city.isDirty && !city.success) return false;
      if (postalCode.isDirty && !postalCode.success) return false;
      if (companyIdentifier.isDirty && !companyIdentifier.success) return false;
      if (vatNumber.isDirty && !vatNumber.success) return false;
      if (clientType !== _clientType) return false;
      return true;
    }

    if (clientType === "COMPANY") {
      return (
        clientName.success &&
        companyIdentifier.success &&
        (mail.success || !mail.isDirty) &&
        streetName.success &&
        city.success &&
        postalCode.success &&
        (vatNumber.success || !vatNumber.isDirty) &&
        (selectedNation || _nation)
      );
    } else {
      return (
        firstName.success &&
        lastName.success &&
        (mail.success || !mail.isDirty) &&
        streetName.success &&
        city.success &&
        postalCode.success &&
        (selectedNation || _nation)
      );
    }
  })();

  function handleSubmit() {
    if (context === CRUDL.READ) {
      onSubmit();
      return;
    }

    if (!isFormDirty && context === CRUDL.UPDATE) {
      notify({
        isSuccess: false,
        message: "Aucune modification n'a été apportée",
      });
      return;
    }

    if (!isValid) {
      setIsInvalidFormSubmitted(true);
      return;
    }

    const addresses = [];
    if (streetName.data !== "") {
      addresses.push({
        isMain: true,
        isBillingAddress: true,
        country: selectedNation || _nation,
        streetName: streetName?.data || _streetName,
        postalCode: postalCode?.data || _postalCode,
        city: city.data || _city,
        id: _address?.id,
      });
    }

    const emails = [];
    if (mail.data) {
      emails.push({
        email: mail.data || _mail,
        isMain: true,
        isBillingEmail: true,
        id: _email?.id,
      });
    }

    const phones = [];
    if (phone.data) {
      phones.push({
        phoneNumber: phone?.data.phoneNumber || _phone?.phoneNumber,
        geoCode: phone?.data.geoCode || _phone?.geoCode,
        isMain: true,
        isBillingPhone: true,
        id: _phone?.id,
      });
    }

    const identifiers = [];
    const selectIdentifier = (i, j) => {
      if (i.data) {
        return i.data;
      }
      return j;
    };
    const ci = selectIdentifier(companyIdentifier, _companyIdentifier);
    const vn = selectIdentifier(vatNumber, _vatNumber);
    if (clientType === "COMPANY") {
      if (ci?.identifier && ci.identifier !== "") {
        const identifierValue = (() => {
          if (ci.countryOrRegion === "BE" || ci.countryOrRegion === "FR") {
            return ci.identifier.replace(/\D/g, "");
          }
          return ci.identifier;
        })();
        identifiers.push({
          identifierType: ci.identifierType,
          countryOrRegion: ci.countryOrRegion,
          identifier: identifierValue,
          id: _companyIdentifier?.id,
        });
      }
      if (vn?.identifier && vn.identifier !== "") {
        const vatCountry = vn.identifier.substring(0, 2);
        const vatNumberDigits = vn.identifier.substring(2);
        const vatIdentifier = vatCountry + vatNumberDigits.replace(/\D/g, "");

        identifiers.push({
          identifierType: "VAT",
          countryOrRegion: "EU",
          identifier: vatIdentifier,
          id: _vatNumber?.id,
        });
      }
    }

    const newClientData = {
      clientType: clientType,
      stakeholder: {
        alias:
          clientType === "COMPANY"
            ? clientName.data || _clientName
            : (firstName.data || _firstName) +
              " " +
              (lastName.data || _lastName),
        name:
          clientType === "COMPANY"
            ? clientName.data || _clientName
            : (firstName.data || _firstName) +
              " " +
              (lastName.data || _lastName),

        person: {
          ...(clientType !== "COMPANY"
            ? {
                firstName: firstName.data || _firstName,
                lastName: lastName.data || _lastName,
                commonName:
                  (firstName.data || _firstName) +
                  " " +
                  (lastName.data || _lastName),
              }
            : { legalName: clientName.data || _clientName }),
        },

        stakeholderCategory:
          clientType === "COMPANY" ? "LEGAL_PERSON" : "NATURAL_PERSON",
        stakeholderType: "CLIENT",
        country: selectedNation || _nation,
        emails: [...emails],
        addresses: [...addresses],
        identifiers: [...identifiers],
        phones: [...phones],
      },
    };

    const body = {
      params: { enterpriseId: data?.enterpriseId, clientId: data?.id },
      body: newClientData,
    };

    onSubmit(body);
  }

  const displayName = (() => {
    if (context === CRUDL.CREATE) return "Nouveau client";

    if (clientType === "COMPANY") {
      return clientName.data;
    } else {
      return firstName.data + " " + lastName.data;
    }
  })();

  return {
    /* State */
    clientType,
    setClientType,
    clientName,
    setClientName,
    defaultClientName: _clientName,
    firstName,
    setFirstName,
    defaultFirstName: _firstName,
    lastName,
    setLastName,
    defaultLastName: _lastName,
    mail,
    setMail,
    defaultMail: _mail,
    phone,
    setPhone,
    defaultPhone: _phone,
    streetName,
    setStreetName,
    defaultStreetName: _streetName,
    city,
    setCity,
    defaultCity: _city,
    postalCode,
    setPostalCode,
    defaultPostalCode: _postalCode,
    companyIdentifier,
    setCompanyIdentifier,
    defaultCompanyIdentifier,
    vatNumber,
    setVatNumber,
    defaultVatNumber,
    selectedNation,
    setSelectedNation,
    defaultNation: _nation,
    isInvalidFormSubmitted,
    setIsInvalidFormSubmitted,
    displayError,
    isFormDirty,
    isValid,
    isLoading,
    displayName,
    isLastClientLoading: isDefaultClientLoading,

    /* Functions */
    handleNationChange,
    handleSubmit,
    onCancel,
  };
}
