import React, { useState } from "react";
import NationSelect from "@/components/token/selectNation/selectNation";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import { StreetName } from "@/components/elements/stakeholder/address/StreetName";
import { City } from "@/components/elements/stakeholder/address/City";
import { PostalCode } from "@/components/elements/stakeholder/address/PostalCode";
import { CRUDL } from "@/utils/crudl";
import InputTailwind from "@/components/token/Input/inputTailwind";
import { Country } from "@/components/elements/stakeholder/address/Country";
import useFormClientData from "@/hooks/forms/useFormClientData";
import LoadingBlock from "@/components/elements/LoadingBlock";
import { BuildingOffice2Icon, UserIcon } from "@heroicons/react/24/outline";
import { EnterpriseReferentialSearch } from "@/feature/referential/EnterpriseReferentialSearch";
import { ClientCompany } from "./ClientCompany";
import { ClientPrivate } from "./ClientPrivate";

export default function ClientData({
  onCancel,
  onSubmit,
  context,
  isLoading,
  isError,
  data,
}) {
  const smartSearchCountries = process.env.SMART_SEARCH_COUNTRIES
    ? process.env.SMART_SEARCH_COUNTRIES.split(",")
    : [];

  const [referentialData, setReferentialData] = useState(null);
  const [displayClientForm, setDisplayClientForm] = useState(
    !smartSearchCountries.includes(data?.country)
  );

  const clientForm = useFormClientData({
    onCancel,
    onSubmit,
    context,
    isLoading,
    isError,
    data: {
      ...data,
      ...referentialData,
    },
  });

  const nation = clientForm.selectedNation || clientForm.defaultNation;

  /* Render */
  return (
    <div className="flex flex-row mx-auto">
      <div
        className={
          "flex flex-col w-[512px] max-w-[768px] p-8 bg-white rounded-lg mt-12 mb-24 h-max gap-6"
        }
      >
        <div className="w-full">
          <p className="tw-h2">{clientForm.displayName}</p>
        </div>

        <form
          className="flex flex-col overflow-y-auto"
          onSubmit={(e) => {
            e.preventDefault();
            clientForm.handleSubmit();
          }}
        >
          <div id="form-without-button" className="flex flex-col gap-2">
            <div>
              {context === CRUDL.CREATE ? (
                <div className="flex flex-row gap-2 items-center">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Type de client :
                  </label>
                  {/* <div className="flex bg-neutral-200 rounded-full justify-center items-center w-4 h-4">
                  <p className="text-xs text-neutral-500 font-medium select-none">
                    i
                  </p>
                </div> */}
                </div>
              ) : null}
              <div className="flex flex-row gap-2 w-full items-stretch">
                {context === CRUDL.CREATE ? (
                  <>
                    <div className="flex w-full gap-1 bg-neutral-100 rounded-lg p-1 select-none text-sm mt-1 ">
                      <button
                        className={`flex items-center justify-center p-2 px-4 gap-2 rounded w-full ${
                          clientForm.clientType === "COMPANY"
                            ? "bg-white text-neutral-600 shadow-sm"
                            : "text-neutral-500 hover:bg-neutral-50"
                        }`}
                        onClick={() => clientForm.setClientType("COMPANY")}
                        disabled={isLoading}
                      >
                        <BuildingOffice2Icon
                          className={`h-4 w-4 text-neutral-600`}
                        />
                        Entreprise
                      </button>

                      <button
                        className={`flex items-center justify-center p-2 px-4 gap-2 rounded w-full ${
                          clientForm.clientType === "COMPANY"
                            ? "text-neutral-500 hover:bg-neutral-50"
                            : "bg-white text-neutral-600 shadow-sm"
                        }`}
                        onClick={() => clientForm.setClientType("PRIVATE")}
                        disabled={isLoading}
                      >
                        <UserIcon className="h-4 w-4 text-gray-500" />
                        Particulier
                      </button>
                    </div>
                  </>
                ) : (
                  <InputTailwind
                    value={
                      clientForm.clientType === "COMPANY"
                        ? "Entreprise"
                        : "Particulier"
                    }
                    disabled={true}
                    label={"Type de client"}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <div className="flex flex-col justify-between gap-x-2">
                {context === CRUDL.READ || context === CRUDL.DELETE ? (
                  <Country
                    initialCountry={nation}
                    disabled={true}
                    isLoading={isLoading}
                    name="billingCountry"
                  />
                ) : clientForm.isLastClientLoading ? (
                  <LoadingBlock />
                ) : (
                  <NationSelect
                    selectedNation={nation}
                    setSelectedNation={(n) => {
                      clientForm.handleNationChange(n);
                      if (smartSearchCountries.includes(n)) {
                        setDisplayClientForm(false);
                      } else setDisplayClientForm(true);
                    }}
                  />
                )}
              </div>
              {clientForm.clientType === "COMPANY" ? (
                smartSearchCountries.includes(nation) && !displayClientForm ? (
                  <EnterpriseReferentialSearch
                    onSelect={(data) => {
                      setReferentialData({
                        ...data.referential,
                        clientType: "COMPANY",
                      });
                      setDisplayClientForm(true);
                    }}
                    onWaive={() => {
                      setDisplayClientForm(true);
                    }}
                    country={nation}
                  />
                ) : (
                  <ClientCompany
                    clientForm={clientForm}
                    context={context}
                    isLoading={isLoading}
                    forceValidate={!!referentialData}
                  />
                )
              ) : (
                <div style={{ display: "flex", gap: "12px" }}>
                  <ClientPrivate
                    clientForm={clientForm}
                    isLoading={isLoading}
                    context={context}
                  />
                </div>
              )}
            </div>

            <div className="flex flex-col gap-3">
              {clientForm.clientType !== "COMPANY" || displayClientForm ? (
                <>
                  <StreetName
                    initialStreetName={
                      clientForm.streetName.data || clientForm.defaultStreetName
                    }
                    disabled={
                      isLoading ||
                      context === CRUDL.READ ||
                      context === CRUDL.DELETE
                    }
                    onChange={(sn) => {
                      clientForm.setStreetName((prev) => ({
                        ...prev,
                        ...sn,
                      }));
                    }}
                    isLoading={false}
                    name="streetName"
                    required={true}
                    displayError={
                      clientForm.streetName.isDirty || clientForm.displayError
                    }
                    forceValidate={
                      !clientForm.streetName.isDirty &&
                      (context === CRUDL.UPDATE ||
                        (context === CRUDL.CREATE && !!referentialData))
                    }
                    context={context}
                    isDirty={clientForm.streetName.isDirty}
                  />

                  <div className="flex flex-row justify-between gap-x-3">
                    <PostalCode
                      country={nation}
                      initialPostalCode={
                        clientForm.postalCode?.data ||
                        clientForm.defaultPostalCode
                      }
                      disabled={
                        isLoading ||
                        context === CRUDL.READ ||
                        context === CRUDL.DELETE
                      }
                      onChange={(pc) => {
                        clientForm.setPostalCode((prev) => ({
                          ...prev,
                          ...pc,
                        }));
                      }}
                      isLoading={clientForm.isLastClientLoading}
                      name="postalCode"
                      required={true}
                      displayError={
                        (clientForm.postalCode.isDirty &&
                          ((clientForm.postalCode.data !== "" &&
                            clientForm.postalCode.data !== null) ||
                            clientForm.isInvalidFormSubmitted)) ||
                        clientForm.displayError
                      }
                      forceValidate={
                        !clientForm.postalCode.isDirty &&
                        (context === CRUDL.UPDATE ||
                          (context === CRUDL.CREATE && !!referentialData))
                      }
                      context={context}
                    />
                    <City
                      initialCity={
                        clientForm.city?.data || clientForm.defaultCity
                      }
                      disabled={
                        isLoading ||
                        context === CRUDL.READ ||
                        context === CRUDL.DELETE
                      }
                      onChange={(c) => {
                        clientForm.setCity((prev) => ({
                          ...prev,
                          ...c,
                        }));
                      }}
                      isLoading={false}
                      name="city"
                      required={true}
                      displayError={
                        clientForm.city.isDirty || clientForm.displayError
                      }
                      forceValidate={
                        !clientForm.city.isDirty &&
                        (context === CRUDL.UPDATE ||
                          (context === CRUDL.CREATE && !!referentialData))
                      }
                      context={context}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="relative flex flex-row mt-8 bottom-0 w-full gap-2">
            <ButtonTailwind
              variant="outlined2"
              onClick={onCancel}
              disabled={isLoading}
            >
              {context === CRUDL.CREATE || context === CRUDL.DELETE
                ? "Annuler"
                : "Retour"}
            </ButtonTailwind>

            <ButtonTailwind variant="filled" loading={isLoading} type="submit">
              {context === CRUDL.CREATE
                ? "Enregistrer le client"
                : context === CRUDL.DELETE
                ? "Supprimer le client"
                : context === CRUDL.UPDATE
                ? "Enregistrer les modifications"
                : "Modifier le client"}
            </ButtonTailwind>
          </div>
        </form>
      </div>
    </div>
  );
}
