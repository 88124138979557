import { Name } from "@/components/elements/User/Name";
import { CRUDL } from "@/utils/crudl";

export function ClientPrivate({ clientForm, isLoading, context }) {
  return (
    <>
      <Name
        name="firstName"
        label="Prénom"
        placeholder="John"
        id="firstName"
        initialName={clientForm.firstName.data || clientForm.defaultFirstName}
        disabled={
          isLoading || context === CRUDL.READ || context === CRUDL.DELETE
        }
        onChange={(c) => {
          clientForm.setFirstName((prev) => ({
            ...prev,
            ...c,
          }));
        }}
        required={true}
        displayError={clientForm.firstName.isDirty || clientForm.displayError}
        forceValidate={
          !clientForm.firstName.isDirty && context === CRUDL.UPDATE
        }
        minLength={1}
      />

      <Name
        name="lastName"
        label="Nom de famille"
        placeholder="Doe"
        id="lastName"
        initialName={clientForm.lastName.data || clientForm.defaultLastName}
        disabled={
          isLoading || context === CRUDL.READ || context === CRUDL.DELETE
        }
        onChange={(c) => {
          clientForm.setLastName((prev) => ({
            ...prev,
            ...c,
          }));
        }}
        required={true}
        displayError={clientForm.lastName.isDirty || clientForm.displayError}
        forceValidate={!clientForm.lastName.isDirty && context === CRUDL.UPDATE}
        minLength={1}
      />
    </>
  );
}
