import React, { useState } from "react";
import Toggle from "@/components/token/button/toggle";
import LoadingBlock from "@/components/elements/LoadingBlock";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import Select from "@/components/token/select/select";
import InputTailwind from "@/components/token/Input/inputTailwind";
import ModifyPaymentForm from "@/components/elements/ModifyPaymentForm/ModifyPaymentForm";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useFormCreatePaymentInformation } from "@/hooks/forms/useFormCreatePaymentInformation";

export default function CompteACrediter({
  accountsToCredit,
  setAccountsToCredit,
  accounts,
  paymentInformation,
}) {
  const [isAddPaymentDisplayed, setIsAddPaymentDisplayed] = useState(false);

  const {
    paymentType,
    selectedPayment,
    setSelectedPayment,
    accountWording,
    setAccountWording,
    secondInputValue,
    setSecondInputValue,
    paymentToModify,
    setPaymentToModify,
    isDuplicateTitle,
    handleSubmit,
    createPaymentInformation,
    AnnulerMoyenDePaiement,
    check,
  } = useFormCreatePaymentInformation({
    setIsAccountFormDisplayed: () => setIsAddPaymentDisplayed(false),
  });

  function toggleAccountInAccountsToCredit(account) {
    if (accountsToCredit.some((acc) => acc.externalId === account.id)) {
      const filteredAccounts = accountsToCredit.filter(
        (acc) => acc.externalId !== account.id
      );
      setAccountsToCredit(filteredAccounts);
    } else {
      const updatedAccounts = [...accountsToCredit, account];
      setAccountsToCredit(updatedAccounts);
    }
  }

  return (
    <div className="flex flex-col gap-3">
      {isAddPaymentDisplayed ? (
        <div className="z-50">
          <div
            className="bg-[rgba(0,0,0,0.5)] backdrop-blur-sm absolute top-0 left-0 w-screen h-screen"
            onClick={() => setIsAddPaymentDisplayed(false)}
          ></div>
          <div className="flex flex-col absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-12 bg-white rounded-2xl w-[28rem]">
            <h1 className="text-[2rem] mb-8 text-center">Ajouter un compte</h1>
            <div className="flex flex-col gap-4">
              <div>
                <Select
                  data={paymentType}
                  selectedItem={selectedPayment}
                  setSelectedItem={setSelectedPayment}
                  label="Moyen de paiement"
                />
              </div>
              <InputTailwind
                label="Intitulé"
                type="text"
                placeholder={selectedPayment.placeholderAccountWording}
                value={accountWording}
                onChange={(event) => setAccountWording(event.target.value)}
                isRequired={true}
                icon={false}
                error={
                  isDuplicateTitle(accountWording)
                    ? "Un moyen de paiement avec ce titre existe déjà"
                    : ""
                }
              />
              {selectedPayment.secondInput ? (
                <InputTailwind
                  label={selectedPayment.label}
                  type="text"
                  placeholder={selectedPayment.placeholder}
                  value={secondInputValue}
                  onChange={(event) => setSecondInputValue(event.target.value)}
                  isRequired={true}
                  icon={false}
                  error={
                    check.isValid || !secondInputValue ? "" : check.message
                  }
                />
              ) : null}
            </div>

            <div className="flex w-full gap-4 mt-8">
              {
                <>
                  <ButtonTailwind
                    onClick={() => {
                      AnnulerMoyenDePaiement();
                      setIsAddPaymentDisplayed(false);
                    }}
                    variant={"outlined2"}
                    style="w-full"
                    disabled={createPaymentInformation.isLoading}
                  >
                    Annuler
                  </ButtonTailwind>
                  <ButtonTailwind
                    onClick={handleSubmit}
                    loading={createPaymentInformation.isLoading}
                    style="w-full"
                  >
                    Enregistrer
                  </ButtonTailwind>
                </>
              }
            </div>
          </div>
        </div>
      ) : null}
      <label className="text-black font-bold">Compte à créditer</label>

      {paymentInformation.isLoading ? (
        <LoadingBlock message="Chargement des comptes..." />
      ) : (
        accounts.map((account) => {
          // Filter the stakeholderPaymentInformation for the current account
          const filteredPaymentInfo =
            paymentInformation.data?.payload?.stakeholderPaymentInformation.filter(
              (info) => info.id == account.id
            );

          const isToggled = accountsToCredit.some(
            (acc) => acc.externalId == account.id
          );

          return (
            <div key={account.id} className="flex justify-between">
              <div
                className="flex gap-2 items-center"
                onClick={() => setPaymentToModify(account.id)}
              >
                <label className="text-neutral-400 text-sm font-medium">
                  {account.name}
                </label>
                <EditOutlinedIcon
                  className="cursor-pointer"
                  sx={{ color: "#BFBFBF", height: "1.5rem", width: "1.5rem" }}
                />
                {paymentToModify == account.id ? (
                  <ModifyPaymentForm
                    paymentInfos={filteredPaymentInfo[0]}
                    setPaymentToModify={setPaymentToModify}
                    setAccountsToCredit={setAccountsToCredit}
                  />
                ) : null}
              </div>
              <Toggle
                onClick={() => toggleAccountInAccountsToCredit(account)}
                isToggled={isToggled}
              />
            </div>
          );
        })
      )}

      <ButtonTailwind
        variant="outlined2"
        width="100%"
        onClick={() => setIsAddPaymentDisplayed(true)}
      >
        Ajouter un compte
      </ButtonTailwind>
    </div>
  );
}
