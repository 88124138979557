import InputTailwind from "@/components/token/Input/inputTailwind";

import { validateParsePhone } from "./phoneValidator";
// import ReactFlagsSelect from "react-flags-select";
import {
  AsYouType,

  //   getCountries,
  //   getCountryCallingCode,
} from "libphonenumber-js";
import { PhonePlaceholders } from "./phonePlaceholders";

export function Phone({
  initialPhone,
  disabled,
  onChange,
  isLoading,
  required,
  name = "phone",
  displayError = true,
  contextCountry = "BE",
}) {
  const phone = initialPhone;
  const phoneNumberAsString = (() => {
    if (typeof phone?.phoneNumber === "number") {
      return phone.phoneNumber.toString();
    } else {
      return phone?.phoneNumber;
    }
  })();

  const hasPrefix = phoneNumberAsString?.substring(0, 1) === "+";
  const phoneValue = hasPrefix
    ? phone?.phoneNumber
    : phone
    ? `+${phone?.geoCode}${phone?.phoneNumber}`
    : "";
  const displayedPhone = new AsYouType().input(phoneValue);
  const feedback = validateParsePhone(displayedPhone, required);

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const newPhoneFeedback = validateParsePhone(value, required);

    onChange({
      success: newPhoneFeedback.success,
      data: newPhoneFeedback.success
        ? {
            phoneNumber: newPhoneFeedback.data.phoneNumber,
            country: newPhoneFeedback.data.country,
            geoCode: newPhoneFeedback.data.geoCode?.replace("+", ""),
          }
        : {
            phoneNumber: value,
            country: "",
            geoCode: phone?.geoCode,
          },
      isDirty: true,
    });
  };

  //   const countries = getCountries();
  //   const countriesToGeoCode = countries.map((country) => {
  //     return {
  //       [country]: `+${getCountryCallingCode(country)}`,
  //     };
  //   });

  return (
    <>
      {/* <div className="flex flex-col">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Préfixe
        </label>
        <div className="mt-1">
          <ReactFlagsSelect
            label="Préfixe"
            className={
              "relative mt-1 rounded-md shadow-sm block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
            }
            searchable={true}
            defaultCountry={phone.country}
            selected={phone.country}
            onSelect={setPhone}
            disabled={disabled}
            countries={countries}
            customLabels={{ ...countriesToGeoCode }}
            // placeholder={"BE"}
          />
        </div>
      </div> */}
      <InputTailwind
        label="Numéro de téléphone"
        type="text"
        placeholder={
          PhonePlaceholders[contextCountry] || "Votre numéro de téléphone"
        }
        value={displayedPhone}
        onChange={(e) => handlePhoneChange(e)}
        loading={isLoading}
        disabled={disabled}
        error={feedback.success || !displayError ? null : feedback.message}
        isRequired={required}
        name={name}
        maxLength={25}
      ></InputTailwind>
    </>
  );
}
