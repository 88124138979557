// import { useMutation, useQueryClient } from "@tanstack/react-query";
// import Lidapi from "@/utils/lidapi";

// export function useCreateNumerotationDocument(
//   queryKeyToInvalidate,
//   onSuccessCallback
// ) {
//   const queryClient = useQueryClient();
//   const api = new Lidapi();

//   const action = useMutation({
//     mutationFn: async (documentData) =>
//       await api.createNumerotationType(documentData),

//     onSuccess: () => {
//       if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
//         queryClient.invalidateQueries({
//           queryKey: [...queryKeyToInvalidate],
//         });
//       }
//       if (onSuccessCallback) {
//         onSuccessCallback();
//       }
//     },
//   });

//   return action;
// }

import { useMutation, useQueryClient } from "@tanstack/react-query";
import Lidapi from "@/utils/lidapi";

export function useCreateNumerotationDocument(
  queryKeyToInvalidate,
  onSuccessCallback
) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async ({ documentData, enterpriseId }) =>
      await api.createNumerotationType(enterpriseId, documentData),

    onSuccess: () => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
  });

  return action;
}
