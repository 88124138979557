import React, { useCallback, useReducer, useState } from "react";
import InvoiceFormV2 from "@/feature/invoiceForm/InvoiceFormV2";
import DocumentChoice from "@/feature/documentsForms/DocumentChoice";
import InvoicePreviewV2 from "@/feature/invoicePreview/InvoicePreviewV2";
import { DocumentAdditionalDataType } from "@/feature/document/DocumentAdditionalDataType";
import { DocumentAdditionalDataSubType } from "@/feature/document/DocumentAdditionalDataSubType";
import { LidapDocumentType } from "@/components/token/documents/DocumentType";
import ClientData from "@/feature/clients/manageClient/ClientData";
import { CRUDL } from "@/utils/crudl";
import { useCreateUpdateClient } from "@/hooks/useCreateUpdateClient";
import { notify } from "@/utils/notify";
import { mergeDocumentAdditionalData } from "@/feature/document/helpers/mergeDocumentAdditionalData";
import { mergeDocumentAddresses } from "@/feature/document/helpers/mergeDocumentAddresses";
import { extractPaymentInformationValue } from "../NewPaymentForm/extractPaymentInformationValue";
import { useCreateInvoice } from "@/hooks/useCreateInvoice";
import { useNavigate } from "react-router-dom";
import { DocumentStatus } from "@/components/token/documents/DocumentStatus";
import DocumentParametersV2 from "@/feature/createInvoice/DocumentParametersV2";
import {
  buildServicesFromExistingDocument,
  documentDateBuilder,
  documentInitial,
} from "./helpers/documentInitial";
import { buildClientDataForDocument } from "./helpers/buildClientDataForDocument";
import { CreditNoteFormV2 } from "@/feature/CreditNote/CreditNoteFormV2";
import { extractDocumentAdditionalDataWithError } from "@/feature/document/helpers/extractDocumentAdditionalData";
import { ServiceUnitFixedPrice } from "../Article/Units";
import { updateDocumentErrors } from "./helpers/updateDocumentErrors";

export const DocumentActions = {
  INITIALIZE: "INITIALIZE",
  SET_DOCUMENT_TYPE: "SET_DOCUMENT_TYPE",
  SET_CLIENT: "SET_CLIENT",
  SET_SERVICE: "SET_SERVICE",
  ADD_SERVICE: "ADD_SERVICE",
  REMOVE_SERVICE: "REMOVE_SERVICE",
  UPDATE_SERVICE: "UPDATE_SERVICE",
  VALIDATE_SERVICES: "VALIDATE_SERVICES",
  SET_SIMPLE_FIELD: "SET_SIMPLE_FIELD",
  SET_SIMPLE_FIELD_WITH_ERROR: "SET_SIMPLE_FIELD_WITH_ERROR",
  SET_UNIQUE_ADDITIONAL_DATA: "SET_UNIQUE_ADDITIONAL_DATA",
  TOGGLE_PAYMENT: "TOGGLE_PAYMENT",
  REMOVE_PAYMENT: "REMOVE_PAYMENT",
  TOGGLE_CONTACT: "TOGGLE_CONTACT",
  SET_LINKED_DOCUMENT: "SET_LINKED_DOCUMENT",
};

function documentReducer(documentData, action) {
  if (action.type === DocumentActions.INITIALIZE) return {};
  if (action.type === DocumentActions.SET_DOCUMENT_TYPE) {
    return {
      ...documentData,
      ...action.payload,
    };
  }

  if (action.type === DocumentActions.SET_CLIENT) {
    const { id, addresses, additionalData } = buildClientDataForDocument(
      action.payload
    );

    const mergedAdditionalData = mergeDocumentAdditionalData(
      documentData.additionalData,
      additionalData
    );
    const mergedAddresses = mergeDocumentAddresses(
      documentData.addresses,
      addresses
    );

    return {
      ...documentData,
      clientId: id,
      addresses: mergedAddresses,
      additionalData: mergedAdditionalData,
    };
  }

  if (action.type === DocumentActions.SET_SERVICE) {
    const updatedService = action.payload;
    return {
      ...documentData,
      services: [...documentData.services, updatedService],
    };
  }

  if (action.type === DocumentActions.REMOVE_SERVICE) {
    return {
      ...documentData,
      services: documentData.services.filter(
        (service) => service.id !== action.payload
      ),
    };
  }

  if (action.type === DocumentActions.UPDATE_SERVICE) {
    const service = action.payload;
    const updatedServices = documentData.services.map((s) =>
      s.id === service.id ? service : s
    );
    return {
      ...documentData,
      services: updatedServices,
    };
  }

  if (action.type === DocumentActions.REMOVE_SERVICE) {
    return {
      ...documentData,
      services: documentData.services.filter(
        (service) => service.id !== action.payload
      ),
    };
  }

  if (action.type === DocumentActions.ADD_SERVICE) {
    const emptyService = {
      title: "",
      description: "",
      quantity: {
        amount: 1,
        unit: ServiceUnitFixedPrice.unitValue,
      },
      unitPrice: {
        amount: 0,
        currency: "EUR",
      },
      vatRate: {
        taxRate: 0,
        countryOrRegion: documentData.enterprise.country,
      },
      id: action.payload.id,
      isDisplayed: true,
    };

    return {
      ...documentData,
      services: [...documentData.services, emptyService],
    };
  }

  if (action.type === DocumentActions.VALIDATE_SERVICES) {
    const servicesFeedbacks = action.payload;
    const updatedServices = documentData.services.map((service) => {
      const feedback = servicesFeedbacks.find(
        (feedback) => feedback.id === service.id
      );

      return {
        ...service,
        isValid: feedback.isValid,
        errors: feedback.errors,
      };
    });

    return {
      ...documentData,
      services: updatedServices,
    };
  }

  if (action.type === DocumentActions.SET_SIMPLE_FIELD) {
    return {
      ...documentData,
      [action.field]: action.payload,
    };
  }

  if (action.type === DocumentActions.SET_SIMPLE_FIELD_WITH_ERROR) {
    const { error, value } = action.payload;
    const errors = updateDocumentErrors({
      documentData,
      error,
      field: action.field,
    });

    return {
      ...documentData,
      [action.field]: value,
      errors,
    };
  }

  if (action.type === DocumentActions.SET_UNIQUE_ADDITIONAL_DATA) {
    const { additionalData, additionalDataType, additionalDataSubType, error } =
      action.payload;

    const existingData = documentData.additionalData.find(
      (data) =>
        data.additionalDataType === additionalDataType &&
        data.additionalDataSubType === additionalDataSubType
    );

    const newAdditionalData = {
      additionalData,
      additionalDataType,
      additionalDataSubType,
    };

    if (error) newAdditionalData.error = error;

    if (!existingData) {
      return {
        ...documentData,
        additionalData: [...documentData.additionalData, newAdditionalData],
      };
    }

    const updatedAdditionalData = documentData.additionalData.map((data) => {
      if (
        data.additionalDataType === additionalDataType &&
        data.additionalDataSubType === additionalDataSubType
      ) {
        /* Do not retain the error from the previous value */
        // eslint-disable-next-line no-unused-vars
        const { error, ...existingData } = data;
        return {
          ...existingData,
          ...newAdditionalData,
        };
      }
      return data;
    });

    return {
      ...documentData,
      additionalData: updatedAdditionalData,
    };
  }

  if (action.type === DocumentActions.TOGGLE_PAYMENT) {
    const { paymentMethod, value, id } = extractPaymentInformationValue({
      paymentInformationDetails: action.payload.paymentInformationDetails,
      id: action.payload.id,
      title: action.payload.title,
    });
    const existingData = documentData.additionalData.find(
      (data) =>
        data.additionalDataType === DocumentAdditionalDataType.PAYMENT_METHOD &&
        data.externalId === id
    );

    if (!existingData) {
      return {
        ...documentData,
        additionalData: [
          ...documentData.additionalData,
          {
            additionalData: value,
            additionalDataType: DocumentAdditionalDataType.PAYMENT_METHOD,
            additionalDataSubType: paymentMethod,
            externalId: id,
          },
        ],
      };
    }

    const updatedAdditionalData = documentData.additionalData.filter(
      (data) =>
        !(
          data.additionalDataType ===
            DocumentAdditionalDataType.PAYMENT_METHOD && data.externalId === id
        )
    );

    return {
      ...documentData,
      additionalData: updatedAdditionalData,
    };
  }

  if (action.type === DocumentActions.REMOVE_PAYMENT) {
    const { id } = action.payload;
    const updatedAdditionalData = documentData.additionalData.filter(
      (data) =>
        data.externalId !== id &&
        data.additionalDataType !== DocumentAdditionalDataType.PAYMENT_METHOD
    );

    return {
      ...documentData,
      additionalData: updatedAdditionalData,
    };
  }

  if (action.type === DocumentActions.TOGGLE_CONTACT) {
    const { contactType, contactValue } = action.payload;
    const isEmailOnDocument =
      contactType === DocumentAdditionalDataType.ISSUER_EMAIL
        ? !documentData.isEmailOnDocument
        : documentData.isEmailOnDocument;
    const isPhoneOnDocument =
      contactType === DocumentAdditionalDataType.ISSUER_PHONE
        ? !documentData.isPhoneOnDocument
        : documentData.isPhoneOnDocument;

    const existingData = documentData.additionalData.find(
      (data) =>
        data.additionalDataType === contactType &&
        data.additionalDataSubType ===
          DocumentAdditionalDataSubType.NOT_APPLICABLE
    );

    if (!existingData) {
      return {
        ...documentData,
        additionalData: [
          ...documentData.additionalData,
          {
            additionalData: contactValue,
            additionalDataType: contactType,
            additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
          },
        ],
        isEmailOnDocument,
        isPhoneOnDocument,
      };
    }

    const updatedAdditionalData = documentData.additionalData.filter(
      (data) =>
        !(
          data.additionalDataType === contactType &&
          data.additionalDataSubType ===
            DocumentAdditionalDataSubType.NOT_APPLICABLE
        )
    );

    return {
      ...documentData,
      additionalData: updatedAdditionalData,
      isEmailOnDocument,
      isPhoneOnDocument,
    };
  }

  if (action.type === DocumentActions.SET_LINKED_DOCUMENT) {
    const linkedDocument = action.payload;
    const preloadedServices = buildServicesFromExistingDocument({
      existingDocument: linkedDocument,
    });

    return {
      ...documentData,
      services: preloadedServices,
      selectedInvoice: linkedDocument,
    };
  }

  /* We should not arrive here */
  if (process.env.ENV === "development") {
    notify({
      isSuccess: false,
      message: `Action ${action.type} not handled in document.\nThis message is only shown to Lidap developers in development mode`,
    });
  }

  return documentData;
}

export function DocumentForm({
  context,
  enterprise,
  documentType,
  documentSettings,
  enterprisePhones,
  companyLogo,
  preload,
  draftDocument,
  linkedDocument,
  selectedClient,
  setDocumentChoice,
  searchParams,
  setSearchParams,
  setIsPageNew,
  articles,
  paymentInformation,
  isParametersOpen,
  setIsParametersOpen,
  lastDocumentOfSameType,
}) {
  /* Main form state */
  const [documentData, dispatch] = useReducer(
    documentReducer,
    {
      context,
      enterprise,
      documentType,
      documentSettings,
      enterprisePhones,
      companyLogo,
      draftDocument,
      preload,
      selectedClient,
      paymentInformation,
      linkedDocument,
    },
    documentInitial
  );

  /* Client creation modal */
  const [isClientCreationOpen, setIsClientCreationOpen] = useState(false);

  /* Derived state */
  const effectiveDocumentType = documentData.documentType;
  const paymentMethods =
    effectiveDocumentType === LidapDocumentType.CREDIT_NOTE
      ? []
      : paymentInformation.stakeholderPaymentInformation;

  /* Mutation */
  const manageDocument = useCreateInvoice(["invoices"], undefined, (data) => {
    notify({
      isSuccess: true,
      message:
        data.payload.invoiceStatus === DocumentStatus.DRAFT
          ? "Brouillon enregistré avec succès"
          : `Document créé avec succès`,
    });

    if (data.payload.invoiceStatus === DocumentStatus.DRAFT) {
      navigate("/documents");
      return;
    }

    const invoice = data.payload;
    const invoiceNumber = invoice.invoiceNumber;

    navigate(`/documents/${invoiceNumber}/download`);
  });

  const createClient = useCreateUpdateClient(["clients"], (data) => {
    notify({
      isSuccess: true,
      message: `Client créé avec succès`,
    });
    const client = data.payload;
    searchParams.set("clientId", client.id);
    setSearchParams(searchParams);
    dispatch({
      type: DocumentActions.SET_CLIENT,
      payload: client,
    });
    setIsPageNew(false);
    setIsClientCreationOpen(false);
  });

  /* Navigation hooks */
  const navigate = useNavigate();

  /* Handler */
  const handleDocumentChoice = useCallback(
    (choice) => {
      setIsPageNew(false);
      setDocumentChoice(choice);
      const payload = {
        documentType: choice,
      };

      if (choice === LidapDocumentType.QUOTE) {
        const { dueDate } = documentDateBuilder({
          choice,
          documentSettings,
        });
        payload.dueDate = new Date(dueDate).toISOString();
      }

      dispatch({
        type: DocumentActions.SET_DOCUMENT_TYPE,
        payload,
      });
    },
    [setDocumentChoice, documentSettings, setIsPageNew]
  );

  const handleDocumentSubmit = useCallback(
    (status) => {
      if (!documentData.clientId) {
        notify({
          isSuccess: false,
          message:
            "Vous devez sélectionner un client pour pouvoir sauver le document",
        });
        return;
      }

      const errors = documentData.errors || [];
      const servicesErrors = documentData.services
        .filter((service) => !!service.error)
        .map((service) => service.error);
      const documentDataErrors = documentData.additionalData
        .filter((data) => !!data.error)
        .map((data) => {
          return { field: data.additionalDataType, error: data.error };
        });
      errors.push(...documentDataErrors);
      errors.push(...servicesErrors);
      const errorMessages = errors.map((error) => error.error);
      if (errorMessages.length > 0) {
        notify({
          isSuccess: false,
          message:
            "Veuillez corriger les erreurs avant de sauvegarder le document",
          list: errorMessages,
        });
        return;
      }

      const services = documentData.services.map((service) => {
        const {
          // eslint-disable-next-line no-unused-vars
          id,
          // eslint-disable-next-line no-unused-vars
          isDisplayed,
          // eslint-disable-next-line no-unused-vars
          isValid,
          // eslint-disable-next-line no-unused-vars
          errors,
          // eslint-disable-next-line no-unused-vars
          enterpriseId,
          // eslint-disable-next-line no-unused-vars
          templateId,
          // eslint-disable-next-line no-unused-vars
          dirtyFields,
          // eslint-disable-next-line no-unused-vars
          field,
          ...rest
        } = service;
        return rest;
      });

      const addresses = documentData.addresses.map((address) => {
        const { addressId, addressType } = address;
        return { addressId, addressType };
      });

      const documentAdditionalData = documentData.additionalData.filter(
        (data) => !data.error && data.additionalData !== ""
      );

      const documentPayload = {
        clientId: documentData.clientId,
        invoiceNumber: documentData.invoiceNumber,
        issuingDate: documentData.issuingDate,
        purchaseOrder: documentData.purchaseOrder?.value,
        services: services,
        addresses: addresses,
        additionalData: documentAdditionalData,
        comment: documentData.comment,
        invoiceStatus: status,
        documentType: documentData.documentType,
      };

      if (documentData.dueDate) {
        documentPayload.dueDate = documentData.dueDate;
      }

      if (documentData.receiptDate) {
        documentPayload.receiptDate = documentData.receiptDate;
      }

      if (documentData.documentType === LidapDocumentType.CREDIT_NOTE) {
        documentPayload.linkedInvoice =
          documentData.selectedInvoice?.invoiceNumber;
      }

      manageDocument.mutate({
        data: documentPayload,
        enterpriseId: documentData.enterpriseId,
      });
    },
    [documentData, manageDocument]
  );

  /* Render */
  return (
    <div id="invoice-page-box">
      {!effectiveDocumentType ? (
        <DocumentChoice setChoice={handleDocumentChoice} />
      ) : null}
      {isClientCreationOpen ? (
        <ClientData
          onSubmit={(data) => createClient.mutate(data)}
          isLoading={createClient.isLoading}
          isError={createClient.isError}
          context={CRUDL.CREATE}
          data={{
            enterpriseId: enterprise.id,
          }}
          onCancel={() => setIsClientCreationOpen(false)}
        />
      ) : (
        <>
          {isParametersOpen ? (
            <>
              <DocumentParametersV2
                enterprise={enterprise}
                setIsParametersOpen={setIsParametersOpen}
                documentData={documentData}
                setDocumentData={dispatch}
                phoneData={enterprisePhones}
                emailData={enterprise.emails}
                documentSettings={documentSettings}
              />
            </>
          ) : null}
          <>
            {effectiveDocumentType === LidapDocumentType.INVOICE ||
            effectiveDocumentType === LidapDocumentType.QUOTE ? (
              <InvoiceFormV2
                documentData={documentData}
                setDocumentData={dispatch}
                enterprise={enterprise}
                clientId={documentData.clientId}
                setSearchParams={setSearchParams}
                searchParams={searchParams}
                setIsPageNew={setIsPageNew}
                articles={articles}
                onClientCreation={() => setIsClientCreationOpen(true)}
                paymentInformation={paymentInformation}
                handleDocumentSubmit={handleDocumentSubmit}
                isDocumentCreationLoading={manageDocument.isLoading}
                isParametersOpen={isParametersOpen}
                setIsParametersOpen={setIsParametersOpen}
                lastDocumentOfSameType={lastDocumentOfSameType}
                documentSettings={documentSettings}
              />
            ) : null}
            {effectiveDocumentType === LidapDocumentType.CREDIT_NOTE ? (
              <CreditNoteFormV2
                documentData={documentData}
                setDocumentData={dispatch}
                enterprise={enterprise}
                setIsPageNew={setIsPageNew}
                articles={articles}
                handleDocumentSubmit={handleDocumentSubmit}
                isDocumentCreationLoading={manageDocument.isLoading}
                isParametersOpen={isParametersOpen}
                setIsParametersOpen={setIsParametersOpen}
                invoiceNumber={linkedDocument?.invoiceNumber}
                creditNoteReason={extractDocumentAdditionalDataWithError({
                  additionalDataArray: documentData.additionalData,
                  additionalDataType:
                    DocumentAdditionalDataType.CREDIT_NOTE_REASON,
                  additionalDataSubType:
                    DocumentAdditionalDataSubType.NOT_APPLICABLE,
                })}
                lastDocumentOfSameType={lastDocumentOfSameType}
                documentSettings={documentSettings}
              />
            ) : null}
          </>

          {effectiveDocumentType ? (
            <section className="bg-neutral-100 min-h-screen w-full overflow-y-auto justify-center h-screen p-16 hidden xl:flex ">
              <InvoicePreviewV2
                documentData={documentData}
                companyLogo={companyLogo}
                enterprise={enterprise}
                selectedClient={selectedClient}
                paymentInformation={paymentMethods}
              />
            </section>
          ) : null}
        </>
      )}
    </div>
  );
}
