import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";

export function useLidapTableInit({ chainingActions }) {
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    results: 10,
  });
  const pageFromUrl = parseInt(searchParams.get("page"));
  const page = pageFromUrl || 1;
  const numberOfRecords = parseInt(searchParams.get("results")) || 10;

  const rowRefs = useRef({});

  const handleClickOutside = (event) => {
    let isOutside = true;

    Object.keys(rowRefs.current).forEach((key) => {
      if (
        rowRefs.current[key].current &&
        rowRefs.current[key].current.contains(event.target)
      ) {
        isOutside = false;
      }
    });
    if (isOutside) setSelectedRowId(null);
  };

  // Object.keys(editCellsRef).forEach((key) => {
  //   if (editCellsRef.current[key] && editCellsRef.current[key].current) {
  //     editCellsRef.current[key].current.style.backgroundColor = "red";
  //   }
  // });

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const Chaining = ({ row, selectedRowId }) => {
    if (!row) return null;
    const rowId = row.id;

    // Ensure ref is created for each row
    if (!rowRefs.current[rowId]) {
      rowRefs.current[rowId] = React.createRef();
    }

    return (
      <div
        ref={rowRefs.current[rowId]}
        className={`${
          selectedRowId === rowId ? "absolute flex" : "hidden"
        } flex-col p-1 text-left bg-white rounded-md shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none z-[100] ${
          row.index === 0 ? "top-8" : "bottom-8"
        } right-12`}
      >
        {chainingActions.map((action) => (
          <action.render row={row} key={action.name} />
        ))}
      </div>
    );
  };

  const handleEditClick = useCallback(
    (rowId) => {
      if (rowId === selectedRowId) {
        setSelectedRowId(null);
      } else {
        setSelectedRowId(rowId);
      }
    },
    [selectedRowId, setSelectedRowId]
  );

  return {
    page,
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    handleEditClick,
    Chaining,
    searchParams,
    setSearchParams,
    numberOfRecords,
    selectedRowId,
    setSelectedRowId,
  };
}

export function useLidapTableBuild({ query, columns, lidapTableInit }) {
  const pageCount = useMemo(() => {
    if (!query.data) return 1;
    return Math.ceil(
      query.data.payload?.totalCount / lidapTableInit.numberOfRecords
    );
  }, [query.data, lidapTableInit.numberOfRecords]);

  const totalNumberOfResults = useMemo(() => {
    if (!query.data) return 0;
    return query.data.payload?.totalCount;
  }, [query.data]);
  const getRowId = (row) => row.id;

  const table = useReactTable({
    columns,
    data: query?.data?.payload?.results || [],
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: pageCount,
    state: {
      globalFilter: lidapTableInit.globalFilter,
      columnFilters: lidapTableInit.columnFilters,
    },
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: lidapTableInit.setGlobalFilter,
    onColumnFiltersChange: lidapTableInit.setColumnFilters,
    globalFilterFn: "includesString",
    getRowId,
  });

  return {
    table,
    pageCount,
    totalNumberOfResults,
    lidapTableInit,
    query,
  };
}
