// Functions to return status and style based on documentType and status
const getStatus = () => {
  const baseStatus = {
    active: "Actif",
    trialing: "Essai",
    past_due: "Échu",
    paused: "En pause",
    incomplete: "Incomplet",
    canceled: "Annulé",
    unpaid: "Non payé",
    incomplete_expired: "Incomplet, expiré",
  };

  return baseStatus;
};

const getStyle = ({ textSize = "text-xs" }) => {
  //"active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "paused" | "trialing" | "unpaid"
  const baseStyle = {
    active: `text-green-700 bg-green-50 ring-green-600/20 rounded-md py-1 px-2 ${textSize} font-medium ring-1 ring-inset w-max`,
    trialing: `text-yellow-600 bg-yellow-50 ring-yellow-500/10 rounded-md py-1 px-2 ${textSize} font-medium ring-1 ring-inset w-max`,
    past_due: `text-orange-600 bg-orange-50 ring-orange-500/10 rounded-md py-1 px-2 ${textSize} font-medium ring-1 ring-inset w-max`,
    paused: `text-orange-600 bg-orange-50 ring-orange-500/10 rounded-md py-1 px-2 ${textSize} font-medium ring-1 ring-inset w-max`,
    incomplete: `text-orange-600 bg-orange-50 ring-orange-500/10 rounded-md py-1 px-2 ${textSize} font-medium ring-1 ring-inset w-max`,
    canceled: `text-gray-600 bg-gray-50 ring-gray-500/10 rounded-md py-1 px-2 ${textSize} font-medium ring-1 ring-inset w-max`,
    unpaid: `text-red-700 bg-red-50 ring-red-600/10 rounded-md py-1 px-2 ${textSize} font-medium ring-1 ring-inset w-max`,
    incomplete_expired: `text-red-700 bg-red-50 ring-red-600/10 rounded-md py-1 px-2 ${textSize} font-medium ring-1 ring-inset w-max`,
  };

  return baseStyle;
};

export default function SubscriptionStatus({ status, textSize }) {
  const currentStatus = getStatus()[status];
  const currentStyle = getStyle({ textSize })[status];

  return <div className={currentStyle}>{currentStatus}</div>;
}
