import InputTailwind from "@/components/token/Input/inputTailwind";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import React, { useEffect, useMemo, useState } from "react";
import { useDeletePaymentInformation } from "@/hooks/useDeletePaymentInformation";
import { useUser } from "@/contexts/UserContext";
import { useCreateUpdatePaymentInformation } from "@/hooks/useCreateUpdatePaymentInformation";
import { notify } from "@/utils/notify";
import DeletePaymentFormModal from "@/components/token/DeletePaymentFormModal/DeletePaymentFormModal";
import { PaymentMethod } from "@/components/elements/NewPaymentForm/PaymentMethod";
import { validatePaymentInformation } from "@/components/elements/NewPaymentForm/validatePaymentInformation";
import { LidapIcon } from "@/components/token/loading/LidapIcon";
import { TrashIcon } from "@heroicons/react/24/outline";

export default function ModifyPaymentFormV2({
  setPaymentToModify,
  setAccountsToCredit,
  accounts,
  accountBeingModified,
}) {
  const [title, setTitle] = useState("");
  const [valuePaymentForm, setValuePaymentForm] = useState("");
  const [paymentType, setPaymentType] = useState(PaymentMethod.CASH);
  const [isModified, setIsModified] = useState(false);
  const [deleteScreen, setDeleteScreen] = useState(false);

  const userContext = useUser();
  const user = userContext.state.user;
  const country = user?.country || "BE";
  const deletePayment = useDeletePaymentInformation(
    ["payment-information"],
    undefined,
    () => {
      notify({
        isSuccess: true,
        message: "Le compte a bien été supprimé",
      });
      setAccountsToCredit((prev) => {
        return prev.filter((account) => account.id !== accountBeingModified.id);
      });
    }
  );
  const modifyPayment = useCreateUpdatePaymentInformation(
    ["payment-information"],
    () => {
      notify({
        isSuccess: true,
        message: "Le compte a bien été modifié",
      });

      setAccountsToCredit &&
        setAccountsToCredit((prev) => {
          return prev.filter(
            (account) => account.id !== accountBeingModified.id
          );
        });

      setPaymentToModify(null);
    }
  );

  const paymentProperties = useMemo(
    () => ({
      PAYMENT_LINK: {
        isTitle: true,
        label: "Intitulé personnel",
        placeholder: "Stripe",
        titleOnChange: handleTitleChange,
        isSecondInput: true,
        label2: "Lien de paiement",
        secondInputTitle:
          accountBeingModified?.paymentInformationDetails?.url || "",
        secondInputPlaceholder: "https://stripe.com",
      },
      IBAN: {
        isTitle: true,
        label: "Intitulé personnel",
        placeholder: country === "BE" ? "Belfius" : "Compte Société Générale",
        titleOnChange: handleTitleChange,
        isSecondInput: true,
        label2: "Compte bancaire",
        secondInputTitle:
          accountBeingModified?.paymentInformationDetails?.iban?.replace(
            /\s/g,
            ""
          ) || "",
        secondInputPlaceholder:
          country === "BE"
            ? "BE71 0961 2345 6769"
            : "FR763 0001 0079 4123 4567 8901 85",
      },
      CASH: {
        isTitle: false,
        label: "Intitulé",
        label2: "Espèces",
        placeholder: "Compte Société Générale",
        titleOnChange: handleTitleChange,
        isSecondInput: false,
      },
      CHEQUE: {
        isTitle: true,
        label: "Intitulé personnel",
        placeholder: "Compte Société Générale",
        titleOnChange: handleTitleChange,
        isSecondInput: true,
        label2: "À l'ordre de",
        secondInputTitle:
          accountBeingModified?.paymentInformationDetails?.inFavourOf || "",
        secondInputPlaceholder: "John Doe",
      },
    }),
    [country, accountBeingModified?.paymentInformationDetails]
  );

  useEffect(() => {
    const method =
      accountBeingModified?.paymentInformationDetails?.paymentMethod;
    setPaymentType(method);

    const newTitle =
      accountBeingModified?.title !== undefined || null
        ? accountBeingModified?.title
        : "";
    setTitle(newTitle);

    const newSecondInputTitle =
      paymentProperties[method]?.secondInputTitle || "";
    setValuePaymentForm(newSecondInputTitle);
  }, [
    accountBeingModified?.paymentInformationDetails?.paymentMethod,
    accountBeingModified?.paymentInformationDetails,
    accountBeingModified?.title,
    paymentProperties,
  ]);

  useEffect(() => {
    const paymentInfoDetails = accountBeingModified?.paymentInformationDetails;

    const isTitleChanged = title !== "" && title !== paymentInfoDetails?.title;

    const isSecondInputChanged = paymentProperties[
      paymentInfoDetails?.paymentMethod
    ]?.secondInputTitle
      ? valuePaymentForm !==
        paymentProperties[paymentInfoDetails?.paymentMethod]?.secondInputTitle
      : true;

    if (isTitleChanged || isSecondInputChanged) {
      setIsModified(true);
    }
  }, [
    title,
    valuePaymentForm,
    accountBeingModified?.paymentInformationDetails,
    paymentProperties,
  ]);

  function handleTitleChange(e) {
    setTitle(e.target.value);
  }

  if (!paymentType) return null;

  const isDuplicateTitle = accounts.some(
    (a) => a.title === title && a.id !== accountBeingModified.id
  );

  const handleSaveClick = () => {
    if (!user.enterpriseId || !accountBeingModified.id) {
      return;
    }

    if (!title) {
      notify({
        isSuccess: false,
        message: "Veuillez renseigner un intitulé",
      });
      return;
    }

    const paymentInformationDetails = {
      paymentMethod: paymentType,
    };

    const check = validatePaymentInformation({
      paymentMethod: paymentType,
      paymentData: valuePaymentForm,
    });

    if (!check.isValid) {
      notify({
        isSuccess: false,
        message: check.message,
      });
      return;
    }

    if (isDuplicateTitle) {
      notify({
        isSuccess: false,
        message: "Un moyen de paiement avec ce titre existe déjà",
      });

      return {
        isValid: false,
        payload: null,
      };
    }

    switch (paymentType) {
      case PaymentMethod.PAYMENT_LINK:
        paymentInformationDetails["url"] = valuePaymentForm;
        break;
      case PaymentMethod.IBAN:
        paymentInformationDetails["iban"] = valuePaymentForm?.replace(
          /\s/g,
          ""
        );
        break;
      case PaymentMethod.CHEQUE:
        paymentInformationDetails["inFavourOf"] = valuePaymentForm;
        break;
      default:
        break;
    }

    modifyPayment.mutate({
      stakeholderId: user.enterpriseId,
      id: accountBeingModified.id,
      paymentInformation: {
        isMain: false,
        paymentInformation: {
          title,
          paymentInformationDetails,
        },
      },
    });
  };

  const check = validatePaymentInformation({
    paymentMethod: paymentType,
    paymentData: valuePaymentForm,
  });

  return (
    <>
      {deleteScreen ? (
        <DeletePaymentFormModal
          deletePayment={deletePayment}
          accountBeingModified={accountBeingModified}
          user={user}
          setPaymentToDelete={setPaymentToModify}
          paymentInfos={accountBeingModified}
        />
      ) : (
        <div
          className="absolute top-0 left-0 z-50 bg-[rgba(0,0,0,0.25)] backdrop-blur-sm flex justify-center items-center w-screen h-screen"
          onMouseDown={() => setPaymentToModify(null)}
        >
          <div
            className="bg-white px-8 py-12 flex flex-col items-start gap-8 rounded-2xl w-[32rem]"
            onMouseDown={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center w-full">
              <h1 className="text-black font-bold text-[2rem]">
                Modifier un compte
              </h1>
              <div className="w-12 h-12 flex justify-center items-center cursor-pointer">
                <LidapIcon
                  Icon={TrashIcon}
                  handleAction={() => setDeleteScreen(true)}
                  loading={deletePayment.isLoading}
                  value={accountBeingModified}
                  className="h-5 w-5 text-gray-400 stroke-2 cursor-pointer"
                  toolTipText="Supprimer"
                />
              </div>
            </div>
            <div className="flex flex-col w-full gap-4">
              {paymentProperties[paymentType].isTitle && (
                <InputTailwind
                  type="text"
                  label="Moyen de paiement"
                  value={paymentProperties[paymentType].label2}
                  readOnly
                />
              )}
              <InputTailwind
                type="text"
                label={paymentProperties[paymentType].label}
                placeholder={paymentProperties[paymentType].placeholder}
                id="title"
                value={title}
                onChange={paymentProperties[paymentType].titleOnChange}
                error={
                  isDuplicateTitle
                    ? "Un moyen de paiement avec ce titre existe déjà"
                    : ""
                }
              />
              {paymentProperties[paymentType].isSecondInput && (
                <InputTailwind
                  type="text"
                  label={paymentProperties[paymentType].label2}
                  placeholder={
                    paymentProperties[paymentType].secondInputPlaceholder
                  }
                  id="secondInput"
                  value={valuePaymentForm}
                  onChange={(e) => setValuePaymentForm(e.target.value)}
                  error={
                    check.isValid || !valuePaymentForm ? "" : check.message
                  }
                />
              )}
            </div>
            <div className="flex gap-4 w-full">
              <ButtonTailwind
                variant="outlined"
                style="flex-1"
                onClick={(e) => {
                  e.stopPropagation(); // Stop propagation for this specific button
                  setPaymentToModify(null);
                }}
              >
                Annuler
              </ButtonTailwind>
              <ButtonTailwind
                variant={isModified ? "filled" : "disabled"}
                style="flex-1"
                onClick={handleSaveClick}
                loading={modifyPayment.isLoading}
              >
                Enregistrer
              </ButtonTailwind>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
