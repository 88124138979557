// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings {
  position: absolute;
  background-color: var(--background-color);
  top: 0;
  left: 0;
  display: flex;
  height: 100vh;
  width: 100vw;
  z-index: 2000;
}

.settings-menu-link: hover;
`, "",{"version":3,"sources":["webpack://./src/feature/Settings/settingsMenu.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yCAAyC;EACzC,MAAM;EACN,OAAO;EACP,aAAa;EACb,aAAa;EACb,YAAY;EACZ,aAAa;AACf;;AAEA,0BAA0B","sourcesContent":[".settings {\n  position: absolute;\n  background-color: var(--background-color);\n  top: 0;\n  left: 0;\n  display: flex;\n  height: 100vh;\n  width: 100vw;\n  z-index: 2000;\n}\n\n.settings-menu-link: hover;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
