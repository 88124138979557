import React from "react";

const LidapLogo = ({ height, maxWidth, ...props }) => (
  <svg
    viewBox="0 0 98 30"
    fill="currentColor"
    height={height}
    style={{ maxWidth }}
    {...props}
  >
    <path d="M43.4,6.3h-3.1v17.5h10.4V21h-7.3V6.3z" />
    <path d="M54.4,9.6c1,0,1.8-0.8,1.8-1.8S55.4,6,54.4,6s-1.8,0.8-1.8,1.8S53.4,9.6,54.4,9.6z M52.9,23.8h2.9V12h-2.9V23.8z" />
    <path
      d="M63.6,24.1c1.8,0,3.3-0.8,4.1-2.2l0.2,1.8h2.7V6h-2.9v7.5c-0.7-1.2-2.2-1.9-3.9-1.9c-3.6,0-5.7,2.6-5.7,6.3
            C58,21.6,60.2,24.1,63.6,24.1z M64.2,21.4c-2,0-3.3-1.5-3.3-3.6s1.2-3.6,3.3-3.6c2,0,3.4,1.5,3.4,3.6C67.6,20,66.3,21.4,64.2,21.4z"
    />
    <path
      d="M76.6,24.1c1.8,0,3.5-0.8,4-2.1l0.2,1.8h2.6v-7.2c0-3.3-2-5-5.2-5c-3.2,0-5.3,1.7-5.3,4.2h2.5c0-1.2,1-2,2.7-2
            c1.5,0,2.4,0.6,2.4,2.3v0.3l-3.6,0.3c-2.8,0.2-4.4,1.6-4.4,3.8C72.6,22.7,74.1,24.1,76.6,24.1z M77.6,21.9c-1.3,0-2-0.5-2-1.6
            c0-1,0.7-1.6,2.5-1.7l2.5-0.2V19C80.5,20.8,79.4,21.9,77.6,21.9z"
    />
    <path
      d="M85.5,28.7h2.9v-6.5c0.7,1.2,2.3,1.9,4.1,1.9c3.5,0,5.5-2.7,5.5-6.4s-2.2-6.1-5.5-6.1c-1.8,0-3.4,0.8-4.1,2.2L88.2,12h-2.7
            V28.7z M88.4,17.9c0-2.1,1.3-3.6,3.3-3.6c2.1,0,3.3,1.5,3.3,3.6s-1.2,3.6-3.3,3.6C89.7,21.5,88.4,20,88.4,17.9z"
    />
    <path d="M3.8,5C3.7,5,3.7,5,3.6,5v0H3.8z" />
    <path d="M22.6,10h-2.5v20h2.5V10z" />
    <path d="M17.6,10h-2.5v20h2.5V10z" />
    <path d="M26.4,0H6.3v2.5h20.1V0z" />
    <path d="M12.6,10h-2.5v20h2.5V10z" />
    <path
      d="M29,5H3.8C3.7,5,3.7,5,3.6,5C1.6,5.1,0,6.8,0,8.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0-0.5-0.1-0.9-0.2-1.3h18.1
            c-0.1,0.4-0.2,0.8-0.2,1.3c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C32.8,6.7,31,5,29,5z M3.8,10c-0.7,0-1.3-0.6-1.3-1.2
            c0-0.6,0.6-1.2,1.3-1.2C4.5,7.5,5,8.1,5,8.8S4.5,10,3.8,10z M29,10c-0.7,0-1.3-0.6-1.3-1.2c0-0.6,0.6-1.2,1.3-1.2s1.3,0.6,1.3,1.2
            C30.3,9.4,29.7,10,29,10z"
    />
    <path d="M3.8,5C3.7,5,3.7,5,3.6,5v0H3.8z" />
    <path d="M22.6,10h-2.5v20h2.5V10z" />
    <path d="M17.6,10h-2.5v20h2.5V10z" />
    <path d="M26.4,0H6.3v2.5h20.1V0z" />
    <path d="M12.6,10h-2.5v20h2.5V10z" />
  </svg>
);

export default LidapLogo;
