import SelectFilter from "@/components/token/selectFilter/selectFilter";
import { useUser } from "@/contexts/UserContext";
import { useRecentDocuments } from "@/hooks/useRecentDocuments";
import { useCallback, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { countriesCurrency } from "@/data/countryCodeCorrespondance";
import InvoiceStatus from "@/components/token/InvoiceStatus/InvoiceStatus";
import { InvoiceStart } from "@/feature/invoices/invoiceCreation/InvoiceStart";
import LidapTable from "@/components/token/table/LidapTable";
import { useLidapTableBuild, useLidapTableInit } from "@/hooks/useLidapTable";
import { usePatchInvoiceStatus } from "@/hooks/usePatchInvoiceStatus";
import {
  CheckCircleIcon,
  EyeIcon,
  DocumentDuplicateIcon,
  XCircleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import PaymentDateModal from "@/components/token/PaymentDateModal/PaymentDateModal";
import DocumentTypeStatus from "@/components/token/documents/DocumentType";
import { useDeleteInvoice } from "@/hooks/useDeleteInvoice";
import { notify } from "@/utils/notify";
import DeleteConfirmationModal from "@/components/token/modals/DeleteConfirmationModal";
import { useQueryClient } from "@tanstack/react-query";
import { getInvoices } from "@/hooks/useInvoices";
import RequireSubscriptionButton from "@/components/token/button/RequireSubscriptionButton";
import StatusButton from "@/components/token/StatusButton/StatusButton";
import EllipsisMenu from "@/components/token/EllipsisMenu/EllipsisMenu";
import { DocumentNumberTitle } from "@/components/token/documents/DocumentNumberTitle";

export function InvoiceOverview() {
  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;
  const navigate = useNavigate();

  const [displayPaymentDateModal, setDisplayPaymentDateModal] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState(null);

  const [tabFilter, setTabFilter] = useState("ALL");

  const [searchParams, setSearchParams] = useSearchParams();
  const statusFilter = searchParams.get("documentStatus");

  const queryClient = useQueryClient();

  const delItem = useDeleteInvoice(
    undefined,
    [["documents"], ["invoices"]],
    () => {
      notify({
        isSuccess: true,
        message: "Le document a bien été supprimé",
      });
      setDisplayDeleteModal(false);
    }
  );
  const handleDelete = () => {
    delItem.mutate({
      enterpriseId: enterpriseId,
      invoiceNumber: invoiceNumber,
    });
  };
  const patchInvoiceStatus = usePatchInvoiceStatus(
    undefined,
    [["invoices"], ["documents"]],
    () => {
      notify({
        isSuccess: true,
        message: "Le statut de la facture a bien été mis à jour",
      });
      setDisplayPaymentDateModal(false);
    }
  );

  const changeInvoiceStatus = useCallback(
    (invoiceNumber, status, date) => {
      const invoiceStatusRequest = {
        enterpriseId: enterpriseId,
        invoiceNumber: invoiceNumber,
      };
      const params = {
        invoiceStatus: status,
        ...(date ? { receiptDate: date } : {}),
      };
      patchInvoiceStatus.mutate({ invoiceStatusRequest, queryParam: params });
    },
    [enterpriseId, patchInvoiceStatus]
  );

  const lidapTableInit = useLidapTableInit({
    chainingActions: [
      // Conditional rendering based on the invoice status
      {
        name: "Action",
        render: ({ row }) => {
          const { invoiceStatus, invoiceNumber } = row.original;
          return (
            <>
              {invoiceStatus === "PAID" && (
                <button
                  className="flex items-center gap-4 hover:bg-gray-100 dark:hover:bg-neutral-900 p-2 px-4 rounded-md select-none text-black"
                  onClick={() => changeInvoiceStatus(invoiceNumber, "CREATED")}
                >
                  <XCircleIcon
                    className="h-4 w-4 text-neutral-600"
                    aria-hidden="true"
                  />
                  {invoiceNumber.includes("D")
                    ? "Devis non validé"
                    : "Facture non payée"}
                </button>
              )}
              {invoiceStatus !== "PAID" && invoiceStatus == "CREATED" && (
                <button
                  className="flex items-center gap-4 hover:bg-gray-100 dark:hover:bg-neutral-900 p-2 px-4 rounded-md select-none text-black"
                  onClick={() => {
                    setDisplayPaymentDateModal(true);
                    setInvoiceNumber(invoiceNumber);
                  }}
                >
                  <CheckCircleIcon
                    className="h-4 w-4 text-neutral-600"
                    aria-hidden="true"
                  />
                  {invoiceNumber.includes("D")
                    ? "Devis validé"
                    : "Facture payée"}
                </button>
              )}
              {["CREATED", "PAID"].includes(invoiceStatus) && (
                <>
                  <Link
                    to={`/documents/${invoiceNumber}/download`}
                    className="text-black flex items-center gap-4 hover:bg-gray-100 dark:hover:bg-neutral-900 p-2 px-4 rounded-md select-none"
                  >
                    <EyeIcon
                      className="h-4 w-4 text-neutral-600"
                      aria-hidden="true"
                    />
                    Consulter
                  </Link>

                  {/* <Link
                    to={`/documents/${invoiceNumber}/archive`}
                    className="text-black flex items-center gap-4 hover:bg-gray-100 dark:hover:bg-neutral-900 p-2 px-4 rounded-md select-none"
                  >
                    <ArchiveBoxArrowDownIcon
                      className="h-4 w-4 text-neutral-600"
                      aria-hidden="true"
                    />
                    Archiver
                  </Link> */}

                  {/* TODO :A RETABLIR ET FAIRE FONCTIONNER
                  
                  <Link
                    to={`/documents/${invoiceNumber}/credit-note`}
                    className="text-black flex items-center gap-4 hover:bg-gray-100 dark:hover:bg-neutral-900 p-2 px-4 rounded-md select-none"
                  >
                    <DocumentChartBarIcon
                      className="h-4 w-4 text-neutral-600"
                      aria-hidden="true"
                    />
                    Créer une note de crédit
                  </Link>*/}

                  {/* <Link
                    to={`/documents/${invoiceNumber}/cancel`}
                    className="text-black flex items-center gap-4 hover:bg-gray-100 dark:hover:bg-neutral-900 p-2 px-4 rounded-md select-none"
                  >
                    <XCircleIcon
                      className="h-4 w-4 text-neutral-600"
                      aria-hidden="true"
                    />
                    Annuler la facture
                  </Link> */}
                </>
              )}
              {/* {invoiceStatus === "DRAFT" && (
                <Link
                  to={`/documents/${invoiceNumber}/edit`}
                  className="text-black flex items-center gap-4 hover:bg-gray-100 dark:hover:bg-neutral-900 p-2 px-4 rounded-md select-none"
                >
                  <PencilIcon
                    className="h-4 w-4 text-neutral-600"
                    aria-hidden="true"
                  />
                  Modifier
                </Link>
              )} */}

              {(invoiceNumber.includes("D") || invoiceNumber.includes("F")) && (
                <Link
                  to={`/documents/create?preload=${invoiceNumber}`}
                  className="flex items-center gap-4 hover:bg-gray-100 dark:hover:bg-neutral-900 p-2 px-4 rounded-md select-none text-black"
                >
                  <DocumentDuplicateIcon
                    className="h-4 w-4 text-neutral-600"
                    aria-hidden="true"
                  />
                  Dupliquer
                </Link>
              )}

              {invoiceStatus === "DRAFT" && (
                <button
                  onClick={() => {
                    setDisplayDeleteModal(true);
                    setInvoiceNumber(invoiceNumber);
                  }}
                  className="text-black flex items-center gap-4 hover:bg-gray-100 dark:hover:bg-neutral-900 p-2 px-4 rounded-md select-none"
                >
                  <TrashIcon
                    className="h-4 w-4 text-neutral-600"
                    aria-hidden="true"
                  />
                  Supprimer
                </button>
              )}
            </>
          );
        },
      },
    ],
  });

  const documentStatus = statusFilter ? [statusFilter] : [];
  const recentDocuments = useRecentDocuments({
    enterpriseId,
    queryParam: {
      pageNumber: lidapTableInit.page - 1,
      numberOfRecords: lidapTableInit.numberOfRecords,
      documentStatus: documentStatus,
    },
    reactQueryConfig: {
      keepPreviousData: true,
    },
  });

  function formatDate(isoDateString) {
    return new Date(isoDateString).toLocaleDateString();
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "client.name", // accessor is now accessorKey
        header: "Client",
        cell: ({ cell }) => cell.getValue(),
        className: {
          cell: "w-64 truncate py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
          header:
            "w-64 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",
        },
      },
      {
        accessorKey: "invoiceNumber",
        header: "Numéro",
        cell: ({ cell }) => (
          <DocumentNumberTitle
            invoiceNumber={cell.getValue()}
            documentAdditionalData={cell.row.original.additionalData}
          />
        ),
        className: {
          cell: "w-48 truncate px-3 py-4 text-sm text-gray-500",
          header:
            "w-48 px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
        },
      },
      {
        accessorKey: "issuingDate",
        header: "Date d'émission",
        cell: (info) => {
          const value = info.getValue();
          return value ? formatDate(value) : "N/A";
        },
        className: {
          cell: "w-48 truncate px-3 py-4 text-sm text-gray-500",
          header:
            "w-48 px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
        },
      },
      {
        accessorKey: "invoiceStatus",
        header: "Statut",
        cell: ({ cell }) => (
          <InvoiceStatus
            status={cell.getValue()}
            documentType={cell.row.original.documentType}
          />
        ),
        className: {
          cell: "w-48 truncate px-3 py-4 text-sm text-gray-500",
          header:
            "w-48 px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
        },
        filterFn: "equalsString",
        enableColumnFilter: true,
      },
      {
        accessorKey: "documentType",
        header: "Type",
        cell: ({ cell }) => <DocumentTypeStatus status={cell.getValue()} />,
        className: {
          cell: "w-48 truncate px-3 py-4 text-sm text-gray-500",
          header:
            "w-48 px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
        },
        filterFn: "equalsString",
        enableColumnFilter: true,
      },
      {
        accessorKey: "total",
        header: "Montant",
        cell: (info) => {
          const value = info.getValue();
          return `${value.amount.toFixed(2).toString()} ${
            countriesCurrency[value.currency]
          }`;
        },
        className: {
          cell: "w-48 truncate px-3 py-4 text-sm text-gray-500",
          header:
            "w-48 px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
        },
        // enableGlobalFilter: false,
      },
      {
        id: "edit",
        header: () => {
          return <span className="sr-only">Edit</span>;
        },
        cell: ({ cell }) => {
          const invoiceNumber = cell.row.original.invoiceNumber;

          const handleEllipsisClick = () => {
            lidapTableInit.handleEditClick(cell.row.id);
            const { queryFn: invoiceQueryFn, queryKey: invoiceQueryKey } =
              getInvoices({
                enterpriseId,
                invoiceNumber: cell.row.original.invoiceNumber,
              });

            queryClient.prefetchQuery(invoiceQueryKey, invoiceQueryFn, {
              staleTime: 1000 * 60 * 5,
            });
          };

          const invoiceStatus = cell.row.original.invoiceStatus;
          return (
            <div className="flex justify-end ml-auto rounded-md border-neutral-200 border w-max">
              {invoiceStatus !== "PAID" && invoiceStatus === "CREATED" && (
                <StatusButton
                  icon={CheckCircleIcon}
                  tooltipText="Marquer comme payé"
                  onClick={() => {
                    setDisplayPaymentDateModal(true);
                    setInvoiceNumber(invoiceNumber);
                  }}
                />
              )}
              {invoiceStatus === "PAID" && (
                <StatusButton
                  icon={XCircleIcon}
                  tooltipText="Marquer comme non payé"
                  onClick={() => changeInvoiceStatus(invoiceNumber, "CREATED")}
                />
              )}
              {invoiceStatus === "DRAFT" && (
                <StatusButton
                  icon={PencilIcon}
                  tooltipText="Modifier"
                  linkTo={`/documents/${invoiceNumber}/edit`}
                />
              )}
              {(invoiceNumber.includes("D") || invoiceNumber.includes("F")) && (
                <StatusButton
                  icon={DocumentDuplicateIcon}
                  tooltipText="Dupliquer"
                  linkTo={`/documents/create?preload=${invoiceNumber}`}
                />
              )}
              <EllipsisMenu
                onClick={handleEllipsisClick}
                tooltipText="Autres options"
              >
                <lidapTableInit.Chaining
                  row={cell.row}
                  selectedRowId={lidapTableInit.selectedRowId}
                />
              </EllipsisMenu>
            </div>
          );
        },
        className: {
          cell: "w-16 select-none relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 text-gray-500 hover:text-indigo-900 cursor-pointer whitespace-nowrap px-3 py-4 text-sm text-gray-500",
          header: "w-16 relative py-3.5 pl-3 pr-4 sm:pr-6",
        },
      },
    ],
    [lidapTableInit, queryClient, enterpriseId, changeInvoiceStatus]
  );

  const lidapTableBuild = useLidapTableBuild({
    query: recentDocuments,
    columns,
    lidapTableInit,
  });

  /* In case no filter is applied and there is no invoice, we show the invoice creation screen */
  if (
    documentStatus.length === 0 &&
    recentDocuments.isSuccess &&
    recentDocuments.data.payload.results.length === 0 &&
    lidapTableBuild.totalNumberOfResults === 0
  ) {
    return <InvoiceStart />;
  }

  const statusArray = [
    // name est affiché, value est la valeur utilisée pour le filtre (correspond à l'API)
    { id: 0, name: "Filtres", value: "" },
    { id: 1, name: "Tous", value: "" },
    { id: 2, name: "Brouillon", value: "DRAFT" },
    { id: 3, name: "Créé", value: "CREATED" },
    { id: 4, name: "Annulé", value: "CANCELLED" },
    { id: 5, name: "À valider", value: "TO_VALIDATE" },
  ];

  return (
    <>
      {displayDeleteModal && (
        <DeleteConfirmationModal
          setDeleteItem={setDisplayDeleteModal}
          handleConfirm={handleDelete}
          message="Voulez vous vraiment <br /> supprimer ce document ?"
          loading={delItem.isLoading}
        />
      )}
      {displayPaymentDateModal && (
        <PaymentDateModal
          setDisplayPaymentDateModal={setDisplayPaymentDateModal}
          changeInvoiceStatus={changeInvoiceStatus}
          invoiceNumber={invoiceNumber}
          isLoading={patchInvoiceStatus.isLoading}
        />
      )}
      <LidapTable
        lidapTableInit={lidapTableInit}
        lidapTableBuild={lidapTableBuild}
        additionalFilters={[
          {
            name: "Statut",
            render: (
              <SelectFilter
                status={statusArray}
                filter={statusArray.find(
                  (s) =>
                    s.value ===
                    (documentStatus ||
                      lidapTableBuild.table
                        .getColumn("invoiceStatus")
                        .getFilterValue())
                )}
                setFilter={(status) => {
                  lidapTableBuild.table
                    .getColumn("invoiceStatus")
                    .setFilterValue(status.value);
                  if (!status?.value) {
                    searchParams.delete("documentStatus");
                  } else {
                    searchParams.set("documentStatus", status.value);
                  }
                  setSearchParams(searchParams);
                }}
              />
            ),
          },
        ]}
        additionalActionsOnPaginate={() => {}}
        actionButtons={[
          {
            name: "Nouvelle facture",
            render: (
              <RequireSubscriptionButton
                onClick={() => {
                  navigate("/documents/create");
                }}
              >
                Nouveau document
              </RequireSubscriptionButton>
            ),
          },
        ]}
        tabFilters={{
          filterColumn: "documentType",
          defaultFilter: "ALL",
          tabs: [
            {
              name: "Tous",
              filter: "ALL",
            },
            {
              name: "Devis",
              filter: "QUOTE",
            },
            {
              name: "Factures",
              filter: "INVOICE",
            },
            {
              name: "Notes de crédit",
              filter: "CREDIT_NOTE",
            },
          ],
        }}
        tabFilter={tabFilter}
        setTabFilter={setTabFilter}
      />
    </>
  );
}
