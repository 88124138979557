import React, { useState } from "react";
import { Datepicker } from "flowbite-react";
import ButtonTailwind from "../button/buttonTailwind";
import { inlineDatePickerTheme } from "@/data/themes.js";
import Modal from "@/components/elements/Modal/Modal";

export default function PaymentDateModal(props) {
  const [paymentDate, setPaymentDate] = useState(new Date());
  const paymentDateISO = paymentDate ? paymentDate.toISOString() : null;

  return (
    <Modal>
      <h1 className="text-3xl">
        Marquer ma facture
        <br /> comme payée
      </h1>
      <p>Votre dashboard de revenus sera mis-à-jour</p>
      <div>
        <Datepicker
          inline
          placeholder="Date d'encaissement"
          dateformat="dd/MM/yyyy"
          selected={paymentDate}
          onSelectedDateChanged={(date) => setPaymentDate(date)}
          language="fr-FR"
          theme={inlineDatePickerTheme}
          labelTodayButton="Aujourd'hui"
          labelClearButton="Annuler"
          className="dark:[&_input]:bg-black focus:dark:[&_input]:ring-gray-700"
        />
      </div>
      <div className="flex justify-end gap-4">
        <ButtonTailwind
          variant="outlined"
          onClick={() => props.setDisplayPaymentDateModal(false)}
        >
          Annuler
        </ButtonTailwind>
        <ButtonTailwind
          variant={paymentDate ? "filled" : "disabled"}
          onClick={() =>
            props.changeInvoiceStatus(
              props.invoiceNumber,
              "PAID",
              paymentDateISO
            )
          }
          loading={props.isLoading}
        >
          Enregistrer
        </ButtonTailwind>
      </div>
    </Modal>
  );
}
