import { useCallback } from "react";

export function useFormatNumber() {
  const formatNumber = useCallback((number) => {
    return new Intl.NumberFormat("fr-FR").format(number);
  }, []);

  return { formatNumber };
}

export function formatNumberForDocument(number) {
  if (number === undefined || isNaN(number)) return number;
  const parsedNumber = parseFloat(number);
  return parsedNumber % 1 === 0
    ? parsedNumber.toFixed(0)
    : parsedNumber.toFixed(2);
}
