import LidapLogo from "@/components/token/logo-lidap-horizontal";
import OryChangePassword from "@/components/token/ory/OryChangePassword";

export default function OryChangePasswordRecovery() {
  return (
    <div className="flex flex-col w-screen h-screen bg-neutral-100 items-center justify-center">
      <div className="bg-white p-12 shadow-sm rounded-lg border border-neutral-200 flex flex-col items-center justify-center gap-8 w-[448px]">
        <div className="w-32">
          <LidapLogo
            alt="logo de Lidap"
            id="menu-logo-lidap"
            className="text-gray-900"
            maxWidth="6rem"
            height="100%"
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div id="message">Réinitialiser le mot de passe</div>
          <OryChangePassword />
        </div>
      </div>
    </div>
  );
}
