import React, { useState, useEffect } from "react";
import InvoicePreview from "@/feature/invoicePreview/InvoicePreview";
import DocumentsForms from "@/feature/documentsForms/DocumentsForms";
import InvoiceForm from "@/feature/invoiceForm/InvoiceForm";
import EstimateForm from "@/feature/estimateForm/EstimateForm";
import CreditForm from "@/feature/creditForm/CreditForm";
import CreateInvoiceParameters from "@/feature/createInvoice/CreateInvoiceParameters";
import toast from "react-hot-toast";
import Notification from "@/components/token/toaster/notification";
import ClientData from "@/feature/clients/manageClient/ClientData";
import { useUser } from "@/contexts/UserContext";
import { useInvoices } from "@/hooks/useInvoices";
import { useEnterprises } from "@/hooks/useEnterprises";
import { useCreateInvoice } from "@/hooks/useCreateInvoice";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CRUDL } from "@/utils/crudl";
import { usePhones } from "@/hooks/usePhones";
import { useImages } from "@/hooks/useImages";
import { invoiceTabs } from "@/feature/invoices/invoiceTabs";
import { notify } from "@/utils/notify";
import { useCreateUpdateClient } from "@/hooks/useCreateUpdateClient";
import { useDefaultDocument } from "@/hooks/forms/defaults/useDefaultDocument";
import { useGetSettings } from "@/hooks/useSettings";
import { isValidColorHex } from "@/components/token/ColorPicker/colorValidator";
import CenteredLoadingBlock from "@/components/elements/CenteredLoadingBlock";

export function CreateInvoice(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = (() => {
    const documentType = searchParams.get("documentType");
    if (
      documentType === "INVOICE" ||
      searchParams?.get("preload")?.includes("F")
    ) {
      return invoiceTabs.INVOICE;
    } else if (
      documentType === "QUOTE" ||
      searchParams?.get("preload")?.includes("D")
    ) {
      return invoiceTabs.QUOTE;
    } else if (documentType === "CREDIT_NOTE") {
      return invoiceTabs.CREDIT_NOTE;
    } else {
      return invoiceTabs.DOCUMENTS_SELECTION;
    }
  })();

  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;
  const enterprise = useEnterprises({
    enterpriseId,
    queryParam: {
      emails: true,
      addresses: true,
    },
  });

  const documentSettings = useGetSettings({
    parentId: enterpriseId,
    settingsType: "document",
  });

  const documentSettingsData =
    documentSettings.data?.payload?.document?.DOCUMENT_CONDITIONS
      ?.settingsValue || "";

  const documentBodyColor = isValidColorHex(
    documentSettings.data?.payload?.document?.DOCUMENT_BODY_COLOR?.settingsValue
  )
    ? documentSettings.data?.payload?.document?.DOCUMENT_BODY_COLOR
        ?.settingsValue
    : "#f5f5f5";
  const documentTitleColor = isValidColorHex(
    documentSettings.data?.payload?.document?.DOCUMENT_TITLE_COLOR
      ?.settingsValue
  )
    ? documentSettings.data?.payload?.document?.DOCUMENT_TITLE_COLOR
        ?.settingsValue
    : "#000000";

  const allEnterprises = useEnterprises({
    queryParam: {
      emails: true,
      addresses: true,
    },
  });

  const phones = usePhones({
    stakeholderId: enterpriseId,
    reactQueryOptions: {
      refetchOnWindowFocus: false,
    },
  });

  const companyLogo = useImages({
    enterpriseId,
    purpose: "company-logo",
    reactQueryOptions: {
      refetchOnWindowFocus: false,
    },
    errorOnMissing: false,
  });

  const isLoading = enterprise.isLoading || phones.isLoading;

  const createClient = useCreateUpdateClient(["clients"], (data) => {
    notify({
      isSuccess: true,
      message: `Client ${
        props.context
          ? props.context === CRUDL.CREATE
            ? "créé"
            : "mis à jour"
          : "actualisé"
      } avec succès`,
    });
    searchParams.set("clientId", data.payload.clientId);
    setSearchParams(searchParams);
    setClientCreationOpen(false);
    setDocumentData({
      data: {
        ...documentData.data,
        clientId: data.payload.clientId,
        step:
          documentData?.data?.documentType === "QUOTE" ||
          documentData?.data?.documentType === "INVOICE"
            ? 1
            : documentData?.data?.step,
      },
    });
  });

  const navigate = useNavigate();
  const createInvoice = useCreateInvoice(["invoices"], undefined, (data) => {
    notify({
      isSuccess: true,
      message:
        data.payload.invoiceStatus == "DRAFT"
          ? "Brouillon enregistré avec succès"
          : `Document créé avec succès`,
    });
  });

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const [isConditionsSet, setIsConditionsSet] = useState(false);

  const setTab = (tab) => {
    switch (tab) {
      case invoiceTabs.INVOICE:
        searchParams.set("documentType", "INVOICE");
        setSearchParams(searchParams);
        break;
      case invoiceTabs.QUOTE:
        searchParams.set("documentType", "QUOTE");
        setSearchParams(searchParams);
        break;
      case invoiceTabs.CREDIT_NOTE:
        searchParams.set("documentType", "CREDIT_NOTE");
        setSearchParams(searchParams);
        break;
      default:
        searchParams.delete("documentType");
        setSearchParams(searchParams);
        break;
    }
  };

  const isParametersTab = searchParams.get("p");
  const parametersOpen = isParametersTab === "true";
  const setParametersOpen = (b) => {
    searchParams.set("p", b);
    setSearchParams(searchParams);
  };
  // const [parametersOpen, setParametersOpen] = useState(false);
  const [clientCreationOpen, setClientCreationOpen] = useState(false);

  const [documentData, setDocumentData] = useState({});

  const documentType = searchParams.get("documentType");

  const lastDocumentCreated = useDefaultDocument({
    enterpriseId,
    documentType: documentType,
  });

  // Données de l'utilisateur / de l'entreprise
  const [enterpriseInformation, setEnterpriseInformation] = useState(undefined);
  const [userNumerotation] = useState("Auto");

  const [invoiceEmail, setInvoiceEmail] = useState(
    enterprise?.data?.payload?.emails?.find((email) => email.isMain)?.email ||
      ""
  );
  const [invoicePhone, setInvoicePhone] = useState("");

  const [setLogo] = useState("");
  const [selectedLogo, setSelectedLogo] = useState("");

  const [invoiceStyle] = useState({
    primaryColor: "",
    secondaryColor: "",
  });

  const { invoiceNumber } = useParams();
  const invoice = useInvoices({ enterpriseId, invoiceNumber });
  const invoiceContent = invoice.invoicesData?.payload?.invoice;

  useEffect(() => {
    const invoiceConditions = documentData?.data?.additionalData?.find(
      (data) =>
        data?.additionalDataType === "INVOICE_CONDITIONS" ||
        data?.additionalDataType === "CREDIT_NOTE_CONDITIONS"
    );

    if (
      (invoiceConditions?.additionalData === "" ||
        invoiceConditions?.additionalData?.length > 1) &&
      documentSettingsData &&
      !isConditionsSet
    ) {
      const newAddData = documentData?.data?.additionalData?.map((data) => {
        if (
          data?.additionalDataType === "INVOICE_CONDITIONS" ||
          data?.additionalDataType === "CREDIT_NOTE_CONDITIONS"
        ) {
          return {
            ...data,
            additionalData: documentSettingsData,
          };
        }
        return data;
      });

      setDocumentData({
        ...documentData,
        data: {
          ...documentData?.data,
          additionalData: newAddData,
        },
      });

      setIsConditionsSet(true);
    }
  }, [documentSettingsData, documentData, isConditionsSet]);

  useEffect(() => {
    if (
      enterprise?.data?.payload?.emails?.find((email) => email.isMain)?.email
    ) {
      setInvoiceEmail(
        enterprise?.data?.payload?.emails?.find((email) => email.isMain)?.email
      );
    }
  }, [enterprise]);

  useEffect(() => {
    const phoneData = phones?.data?.payload?.phones?.find(
      (phone) => phone.isMain
    );
    if (phoneData) {
      setInvoicePhone(`${phoneData.geoCode}${phoneData.phoneNumber}`);
    }

    if (
      phoneData &&
      !documentData?.data?.additionalData?.find(
        (data) => data?.additionalDataType === "ISSUER_PHONE"
      )?.additionalData &&
      !documentData?.data?.issuerPhone?.data
    ) {
      const newAddData = documentData?.data?.additionalData?.map((data) => {
        if (data?.additionalDataType === "ISSUER_PHONE") {
          return {
            ...data,
            additionalData: `${phoneData.geoCode}${phoneData.phoneNumber}`,
          };
        }
        return data;
      });
      setDocumentData({
        ...documentData,
        data: {
          ...documentData?.data,
          additionalData: newAddData,

          issuerPhone: {
            isShown: true,
            data: `${phoneData.geoCode}${phoneData.phoneNumber}`,
          },
        },
      });
    }
  }, [phones]);

  useEffect(() => {
    if (
      !documentData?.data?.additionalData?.find(
        (data) => data?.additionalDataType === "ISSUER_EMAIL"
      ).additionalData &&
      !documentData?.data?.issuerEmail?.data
    ) {
      const newAddData = documentData?.data?.additionalData?.map((data) => {
        if (data?.additionalDataType === "ISSUER_EMAIL") {
          return {
            ...data,
            additionalData: invoiceEmail,
          };
        }
        return data;
      });
      setDocumentData({
        ...documentData,
        data: {
          ...documentData?.data,
          additionalData: newAddData,

          issuerEmail: {
            isShown: true,
            data: invoiceEmail,
          },
        },
      });
    }
  }, [invoiceEmail]);

  useEffect(() => {
    if (companyLogo?.data?.payload?.imageContent) {
      documentData.data = {
        ...documentData.data,
        Logo: {
          imageContent: companyLogo?.data?.payload?.imageContent,
          imageFormat: companyLogo?.data?.payload?.imageFormat,
        },
      };
    }
  }, [companyLogo]);

  useEffect(() => {
    if (selectedLogo) {
      const imageRender = URL.createObjectURL(selectedLogo);
      setLogo(imageRender);
    }
  }, [selectedLogo]);

  useEffect(() => {
    return () => {
      if (selectedLogo) {
        URL.revokeObjectURL(selectedLogo);
      }
    };
  }, [selectedLogo]);

  if (!enterprise.isLoading && !enterprise.isError && !enterpriseInformation) {
    setEnterpriseInformation(enterprise.data.payload);
  }

  function toNormalDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Adds leading zero if necessary
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // JavaScript months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    if (props.context === CRUDL.UPDATE) {
      searchParams.set("documentType", invoiceContent?.documentType);
      setSearchParams(searchParams);

      const newDocumentData = {
        ...invoiceContent,
        issuingDate: toNormalDate(invoiceContent?.issuingDate),
        dueDate: toNormalDate(invoiceContent?.dueDate),
      };
      searchParams.set("clientId", invoiceContent?.clientId);
      setSearchParams(searchParams);
      setDocumentData({
        data: newDocumentData,
        enterpriseId: invoiceContent?.enterpriseId,
      });

      if (invoiceContent?.documentType === "INVOICE") {
        setTab(invoiceTabs.INVOICE);
      } else if (invoiceContent?.documentType === "QUOTE") {
        setTab(invoiceTabs.QUOTE);
      } else if (invoiceContent?.documentType === "CREDIT_NOTE") {
        setTab(invoiceTabs.CREDIT_NOTE);
      }
    }
  }, [invoiceContent]);

  const parametersTab = () => {
    return isLoading ? (
      <CenteredLoadingBlock />
    ) : (
      <CreateInvoiceParameters
        // Logo={companyLogo?.data?.imageContent}
        userNumerotation={userNumerotation}
        setSelectedLogo={setSelectedLogo}
        setParametersOpen={setParametersOpen}
        documentData={documentData}
        setDocumentData={setDocumentData}
        phoneData={phones?.data?.payload?.phones?.find((phone) => phone.isMain)}
        emailData={enterprise?.data?.payload?.emails?.find(
          (email) => email.isMain
        )}
        documentBodyColor={documentBodyColor}
        documentTitleColor={documentTitleColor}
      />
    );
  };

  const createClientTab = () => {
    return (
      <ClientData
        onSubmit={(data) => createClient.mutate(data)}
        isLoading={createClient.isLoading}
        isError={createClient.isError}
        context={CRUDL.CREATE}
        data={{
          enterpriseId,
        }}
        onCancel={() => setClientCreationOpen(false)}
      />
    );
  };

  const documentsTab = () => {
    return <DocumentsForms setTab={setTab} />;
  };

  const factureTab = () => {
    return (
      <InvoiceForm
        setTab={setTab}
        setDocumentData={setDocumentData}
        documentData={documentData}
        createInvoice={(e, g) => handleInvoiceCreation(e, g)}
        enterpriseInformation={enterpriseInformation}
        setParametersOpen={setParametersOpen}
        context={props.context}
        invoiceContent={invoiceContent}
        invoiceEmail={invoiceEmail}
        invoicePhone={invoicePhone}
        setClientCreationOpen={setClientCreationOpen}
        invoiceStyle={invoiceStyle}
        enterpriseNumber={{
          value:
            allEnterprises?.data?.payload?.enterprises[0]?.identifiers?.find(
              (id) => id.identifierType === "SIREN"
            )?.identifier ||
            allEnterprises?.data?.payload?.enterprises[0]?.identifiers?.find(
              (id) => id.identifierType === "COMPANY_NUMBER"
            )?.identifier ||
            undefined,
          type: allEnterprises?.data?.payload?.enterprises[0]?.identifiers?.find(
            (id) => id.identifierType === "SIREN"
          )?.identifier
            ? "SIREN"
            : "OTHER",
          vat:
            allEnterprises?.data?.payload?.enterprises[0]?.identifiers?.find(
              (id) => id.identifierType === "VAT"
            )?.identifier || undefined,
        }}
        isDocumentCreationLoading={createInvoice.isLoading}
        lastDocumentCreated={lastDocumentCreated}
      />
    );
  };

  const devisTab = () => {
    return (
      <EstimateForm
        setTab={setTab}
        setDocumentData={setDocumentData}
        documentData={documentData}
        createInvoice={(e, g) => handleInvoiceCreation(e, g)}
        enterpriseInformation={enterpriseInformation}
        setParametersOpen={setParametersOpen}
        context={props.context}
        invoiceContent={invoiceContent}
        invoiceEmail={invoiceEmail}
        invoicePhone={invoicePhone}
        setClientCreationOpen={setClientCreationOpen}
        invoiceStyle={invoiceStyle}
        isDocumentCreationLoading={createInvoice.isLoading}
        enterpriseNumber={{
          value:
            allEnterprises?.data?.payload?.enterprises[0]?.identifiers?.find(
              (id) => id.identifierType === "SIREN"
            )?.identifier ||
            allEnterprises?.data?.payload?.enterprises[0]?.identifiers?.find(
              (id) => id.identifierType === "COMPANY_NUMBER"
            )?.identifier ||
            undefined,
          type: allEnterprises?.data?.payload?.enterprises[0]?.identifiers?.find(
            (id) => id.identifierType === "SIREN"
          )?.identifier
            ? "SIREN"
            : "OTHER",
          vat:
            allEnterprises?.data?.payload?.enterprises[0]?.identifiers?.find(
              (id) => id.identifierType === "VAT"
            )?.identifier || undefined,
        }}
        lastDocumentCreated={lastDocumentCreated}
      />
    );
  };

  const avoirTab = () => {
    return (
      <CreditForm
        setTab={setTab}
        setIsSearchOpen={setIsSearchOpen}
        isSearchOpen={isSearchOpen}
        setDocumentData={setDocumentData}
        documentData={documentData}
        createInvoice={(e, g) => handleInvoiceCreation(e, g)}
        enterpriseInformation={enterpriseInformation}
        setParametersOpen={setParametersOpen}
        context={props.context}
        invoiceContent={invoiceContent}
        invoiceEmail={invoiceEmail}
        invoicePhone={invoicePhone}
        invoiceStyle={invoiceStyle}
        isDocumentCreationLoading={createInvoice.isLoading}
        enterpriseNumber={{
          value:
            allEnterprises?.data?.payload?.enterprises[0]?.identifiers?.find(
              (id) => id.identifierType === "SIREN"
            )?.identifier ||
            allEnterprises?.data?.payload?.enterprises[0]?.identifiers?.find(
              (id) => id.identifierType === "COMPANY_NUMBER"
            )?.identifier ||
            undefined,
          type: allEnterprises?.data?.payload?.enterprises[0]?.identifiers?.find(
            (id) => id.identifierType === "SIREN"
          )?.identifier
            ? "SIREN"
            : "OTHER",
          vat:
            allEnterprises?.data?.payload?.enterprises[0]?.identifiers?.find(
              (id) => id.identifierType === "VAT"
            )?.identifier || undefined,
        }}
        lastDocumentCreated={lastDocumentCreated}
      />
    );
  };

  const renderTab = (tab) => {
    switch (tab) {
      case invoiceTabs.DOCUMENTS_SELECTION:
        return documentsTab();
      case invoiceTabs.INVOICE:
        return parametersOpen
          ? parametersTab()
          : clientCreationOpen
          ? null
          : factureTab();
      case invoiceTabs.QUOTE:
        return parametersOpen
          ? parametersTab()
          : clientCreationOpen
          ? null
          : devisTab();

      case invoiceTabs.CREDIT_NOTE:
        return parametersOpen ? parametersTab() : avoirTab();
      // case 4:
      //   return parametersTab();
      default:
        return factureTab();
    }
  };

  function apiTransformDate(dateStr) {
    const [day, month, year] = dateStr.split("/");

    // Create a new Date object with the specified date and the current time
    const currentDate = new Date();
    const [h, m, s] = currentDate
      .toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Use 24-hour format
      })
      .split(":");

    const utc = new Date(year, month - 1, day, h, m, s);

    // Convert to ISO string and remove milliseconds
    const isoDate = utc.toISOString().replace(/\.\d{3}Z$/, "Z");

    return isoDate;
  }

  function saveInvoice(invoiceStatus) {
    const removeTitleFromAdditionalData = (additionalData) => {
      if (!additionalData || additionalData.length === 0) {
        return;
      }

      const filteredData = additionalData.filter(
        (data) =>
          data.additionalData !== undefined &&
          data.additionalData !== null &&
          data.additionalData !== "" &&
          data.additionalData !== ""
      );

      // eslint-disable-next-line no-unused-vars
      return filteredData.map(({ title, id, ...rest }) => ({
        ...rest,
      }));
    };

    const additionalData = removeTitleFromAdditionalData(
      documentData.data.additionalData
    );

    if (documentType === "CREDIT_NOTE") {
      if (
        !additionalData.find(
          (data) =>
            data.additionalDataType === "CREDIT_NOTE_PAYMENT_APPLICATION_METHOD"
        )
      ) {
        notify({
          message: "Veuillez renseigner la façon dont le crédit sera appliqué",
          isError: true,
        });
        return;
      }
    }

    /* Verify colors */
    const documentBodyColor = documentData?.data?.additionalData?.find(
      (data) => data.additionalDataType === "DOCUMENT_BODY_COLOR"
    )?.additionalData;
    const documentTitleColor = documentData?.data?.additionalData?.find(
      (data) => data.additionalDataType === "DOCUMENT_TITLE_COLOR"
    )?.additionalData;

    if (documentBodyColor && !isValidColorHex(documentBodyColor)) {
      notify({
        message:
          "Couleur principale invalide. Veuillez corriger dans les paramètres de la facture.",
        isError: true,
      });
      return;
    }

    if (documentTitleColor && !isValidColorHex(documentTitleColor)) {
      notify({
        message:
          "Couleur secondaire invalide. Veuillez corriger dans les paramètres de la facture.",
        isError: true,
      });
      return;
    }

    return {
      data: {
        clientId: documentData.data.clientId
          ? documentData.data.clientId
          : undefined,
        purchaseOrder: documentData.data.purchaseOrder,
        issuingDate: documentData.data.issuingDate
          ? apiTransformDate(documentData.data.issuingDate)
          : undefined,
        dueDate: documentData.data.dueDate
          ? apiTransformDate(documentData.data.dueDate)
          : undefined,
        services:
          documentData.data.services.filter((service) => {
            // Vérifier que tous les champs nécessaires sont présents et non vides
            return (
              service.title &&
              service.quantity &&
              service.quantity.amount &&
              service.unitPrice &&
              service.unitPrice.amount &&
              service.vatRate &&
              (service.vatRate.taxRate || service.vatRate.taxRate === 0)
            );
          }).length > 0
            ? documentData.data.services.map((service) => {
                return {
                  title: service.title,
                  description: service.description,
                  quantity: {
                    amount: service.quantity.amount,
                    unit: service.quantity.unit || "UNIT",
                  },
                  unitPrice: {
                    amount: service.unitPrice.amount,
                    currency: service?.unitPrice?.currency,
                  },
                  vatRate: {
                    countryOrRegion: userContext?.state?.user?.country || "BE",
                    taxRate: service.vatRate.taxRate,
                  },
                };
              })
            : [],
        addresses: [
          {
            addressId: documentData.data.addresses.find(
              (address) => address.addressType === "RECEIVER_BILLING"
            ).addressId,
            addressType: "RECEIVER_BILLING",
          },
          {
            addressId: enterpriseInformation.addresses.find(
              (address) => address.isMain === true
            ).id,
            addressType: "ISSUER_BILLING",
          },
        ],
        additionalData: additionalData,
        documentType: documentData.data.documentType,
        invoiceNumber: invoiceContent?.invoiceNumber || null,
        linkedInvoice:
          documentData.data.documentType == "CREDIT_NOTE"
            ? documentData.data.selectedInvoice.invoiceNumber
            : null,
        invoiceStatus:
          invoiceStatus || documentData.data.invoiceStatus || "DRAFT",
      },
      enterpriseId,
    };
  }

  if (
    createInvoice.isSuccess &&
    createInvoice.data.payload.invoiceStatus !== "DRAFT"
  ) {
    const createdInvoiceData = createInvoice.data;
    const invoiceNumber = createdInvoiceData.payload.invoiceNumber;

    navigate(`/invoices/${invoiceNumber}/download`);
  } else if (createInvoice.isSuccess) {
    navigate("/invoices");
  }

  function handleInvoiceCreation(invoiceStatus) {
    try {
      const invoiceData = saveInvoice(invoiceStatus);
      if (!invoiceData) return;
      createInvoice.mutate(invoiceData);
    } catch (err) {
      console.error(err);
      toast.custom(() => (
        <Notification
          isSuccess={false}
          message="Une erreur a eu lieu lors de la création de facture"
          // info={stringify(err)}
        />
      ));
      navigate("/invoices");
    }
  }

  if (isLoading) return <CenteredLoadingBlock />;

  return (
    <div id="invoice-page-box" onMouseDown={() => setIsSearchOpen(false)}>
      {!clientCreationOpen ? (
        renderTab(selectedTab)
      ) : (
        <div className="flex justify-center items-start w-full">
          {createClientTab()}
        </div>
      )}

      {!clientCreationOpen &&
        selectedTab !== invoiceTabs.DOCUMENTS_SELECTION && (
          <section className="bg-neutral-100 min-h-screen w-full overflow-y-auto justify-center h-screen p-16 hidden xl:flex ">
            <InvoicePreview
              documentData={documentData.data}
              documentBodyColor={documentBodyColor}
              documentTitleColor={documentTitleColor}
            />
          </section>
        )}
    </div>
  );
}
