import { matchRoutes } from "react-router-dom";
import {
  initializeFaro as coreInit,
  createReactRouterV6DataOptions,
  ReactIntegration,
  getWebInstrumentations,
} from "@grafana/faro-react";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";
import { faro } from "@/AppRouter";

export function initializeFaro() {
  const faro = coreInit({
    url: process.env.FARO_URL,
    app: {
      name: "lidap-web",
      version: `${process.env.LIDAP_FRONT_VERSION}`,
      environment: process.env.ENV,
    },

    instrumentations: [
      // Mandatory, omits default instrumentations otherwise.
      ...getWebInstrumentations(),

      // Tracing package to get end-to-end visibility for HTTP requests.
      new TracingInstrumentation(),

      // React integration for React applications.
      new ReactIntegration({
        router: createReactRouterV6DataOptions({
          matchRoutes,
        }),
      }),
    ],
  });

  faro.api.pushLog(["faro initialized"]);

  return faro;
}

export function faroWrapper() {
  const f = new Proxy(
    {},
    {
      get: function (target, prop) {
        if (process.env.FARO_ENABLED !== "true") {
          // If FARO is not enabled, return a no-op function
          return () => {};
        }
        // If FARO is enabled, return the corresponding function from the faro object
        return (...args) => faro[prop](...args);
      },
    }
  );

  return f;
}
