import React from "react";
import CardInfo from "@/components/token/CardInfo/CardInfo";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ToggleChartType from "@/components/token/button/ToggleChartType";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Tooltip,
} from "recharts";
import { useSearchParams } from "react-router-dom";
import { ChartDisplayType } from "@/utils/charts/ChartDisplayType";
import { useFormatNumber } from "@/utils/numbers/useFormatNumber";
import { BarChartsColor } from "@/components/colors/Colors";

const CustomYAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={4} // Adjust for vertical alignment
      fill="#B8B8BA"
      fontSize={10}
      textAnchor="end"
    >
      {`${payload.value} €`}
    </text>
  </g>
);

export default function Dashboard({ analytics }) {
  const barChartsColor = BarChartsColor;
  const [searchParams, setSearchParams] = useSearchParams();
  const chartType = searchParams.get("ct") || ChartDisplayType.LINE;

  const { formatNumber } = useFormatNumber();

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="flex flex-col bg-neutral-700 gap-1 p-2 rounded-lg">
          <p className="text-neutral-400 text-[0.625rem]">{label} 2024</p>
          <div className="flex gap-2 items-center">
            <div className="bg-[#95DE64] w-[6px] h-[6px] rounded-full"></div>
            <p className="text-neutral-400 text-xs">Revenus</p>
            <p className="text-neutral-50 text-xs font-bold">
              {formatNumber(payload[0].value)} €
            </p>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <section className="mx-auto w-full flex flex-col p-16 gap-12">
      <div className="flex flex-col gap-8">
        {/*
        <div className="flex justify-between">
           <p className="text-black text-2xl font-semibold w-max">Revenus</p>
          <div className="flex flex-row justify-end items-center gap-4 w-full max-w-[16rem]">
            <Select
                isLabel={false}
                selectedItem={selectedTime}
                setSelectedItem={setSelectedTime}
                data={[
                  { id: 1, name: "12 derniers mois", value: "12" },
                  { id: 2, name: "6 derniers mois", value: "6" },
                  { id: 3, name: "3 derniers mois", value: "3" },
                  { id: 4, name: "dernier mois", value: "1" },
                ]}
              ></Select> 
          </div>
        </div>*/}

        <div className=" flex items-start gap-6 self-stretch">
          <CardInfo
            title="Chiffre d'affaire annuel"
            value={
              analytics.confirmedRevenue
                ? `${formatNumber(analytics.confirmedRevenue)} €`
                : "0 €"
            }
            color="bg-green-500"
            percentage="+3%"
            change="sur le
              mois dernier"
            icon={<ArrowCircleUpIcon sx={{ color: "#29C275" }} />}
          ></CardInfo>

          <CardInfo
            title="À recevoir"
            value={
              analytics.expectedRevenue
                ? `${formatNumber(analytics.expectedRevenue)} €`
                : "0 €"
            }
            color="bg-red-500"
            percentage="+15%"
            change="potentiel sur le CA actuel"
            icon={<ArrowCircleDownIcon sx={{ color: "#FA3940" }} />}
          ></CardInfo>
        </div>
        <div className="flex flex-col gap-4 p-6 w-full bg-white border border-[#e5e7eb] dark:border-[#2b2b2b] rounded-lg shadow-sm">
          <div className="w-full flex items-end justify-between">
            <div></div>
            <ToggleChartType
              setChartType={(ct) => {
                searchParams.set("ct", ct);
                setSearchParams(searchParams);
              }}
              chartType={chartType}
            />
          </div>
          <div className="flex h-72">
            <ResponsiveContainer>
              {chartType === ChartDisplayType.LINE ? (
                <LineChart
                  width={600}
                  height={300}
                  data={analytics.revenueReceivedPerMonth}
                  color="#8C8C8C"
                >
                  <CartesianGrid
                    stroke="#f5f5f5"
                    horizontal={true}
                    vertical={false}
                  />

                  <Tooltip content={<CustomTooltip />} />
                  <Line
                    type="linear"
                    dataKey="CA"
                    stroke={barChartsColor}
                    strokeWidth={2}
                    dot={false}
                  />

                  <XAxis
                    tick={{ fill: "#B8B8BA", fontSize: 10 }}
                    tickLine={false}
                    axisLine={false}
                    dataKey="name"
                    padding={{ left: 36, right: 10 }}
                    interval={0}
                    tickMargin={10}
                  />
                  <YAxis
                    tick={<CustomYAxisTick />}
                    tickLine={false}
                    axisLine={false}
                    tickMargin={10}
                    padding={{ bottom: 0, top: 0 }}
                  />
                </LineChart>
              ) : (
                <BarChart
                  width={600}
                  height={300}
                  data={analytics.revenueReceivedPerMonth}
                  color="#8C8C8C"
                >
                  <CartesianGrid
                    stroke="#f5f5f5"
                    horizontal={true}
                    vertical={false}
                    strokeDasharray="3 3"
                  />
                  <Tooltip
                    content={<CustomTooltip />}
                    cursor={{ fill: "rgba(0,0,0,0)" }}
                  />
                  <XAxis
                    tick={{ fill: "#B8B8BA", fontSize: 10 }}
                    tickLine={false}
                    axisLine={false}
                    dataKey="name"
                    padding={{ left: 36, right: 10 }}
                    interval={0}
                    tickMargin={10}
                  />
                  <YAxis
                    tick={<CustomYAxisTick />}
                    tickLine={false}
                    axisLine={false}
                    tickMargin={10}
                    padding={{ bottom: 0, top: 0 }}
                  />
                  <Bar
                    dataKey="CA"
                    barSize={30}
                    radius={4}
                    isAnimationActive={false}
                    fill={barChartsColor}
                  />
                </BarChart>
              )}
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </section>
  );
}
