import ButtonTailwind from "@/components/token/button/buttonTailwind";
import { DocumentNumberingInput } from "./DocumentNumberingInput";
import useFormNumbering from "@/hooks/forms/useFormNumbering";
import { CRUDL } from "@/utils/crudl";
import { notify } from "@/utils/notify";
export function DocumentUpdater({
  currentNumbers,
  isLoading,
  isError,
  estimateDisabled,
  invoiceDisabled,
  creditNoteDisabled,
}) {
  const docNumbering = useFormNumbering({
    currentNumbers,
    isLoading: isLoading,
    isError: isError,
    context: CRUDL.UPDATE,
    onSubmit: () => {
      notify({
        isSuccess: true,
        message: "Numérotation des documents mise à jour avec succès",
      });
    },
  });

  const numberModified =
    docNumbering.quoteNumbering.isDirty ||
    docNumbering.invoiceNumbering.isDirty ||
    docNumbering.creditNoteNumbering.isDirty;

  return (
    <>
      <div className="flex flex-col gap-2">
        {estimateDisabled ? null : (
          <DocumentNumberingInput
            disabled={docNumbering.isUpdateLoading}
            forceValidate={!docNumbering.isFormDirty}
            initialValue={docNumbering.quoteNumbering.data}
            isLoading={docNumbering.isDataLoading}
            label={"Nombre de devis créés cette année"}
            onChange={docNumbering.setQuoteNumbering}
            required={true}
            name="quoteNumbering"
            placeholder="0"
            minValue={docNumbering.minValues.quote}
          />
        )}
        {invoiceDisabled ? null : (
          <DocumentNumberingInput
            disabled={docNumbering.isUpdateLoading}
            forceValidate={!docNumbering.isFormDirty}
            initialValue={docNumbering.invoiceNumbering.data}
            isLoading={docNumbering.isDataLoading}
            label={"Nombre de factures créées cette année"}
            onChange={docNumbering.setInvoiceNumbering}
            required={true}
            name="invoiceNumbering"
            placeholder="0"
            minValue={docNumbering.minValues.invoice}
          />
        )}
        {creditNoteDisabled ? null : (
          <DocumentNumberingInput
            disabled={docNumbering.isUpdateLoading}
            forceValidate={!docNumbering.isFormDirty}
            initialValue={docNumbering.creditNoteNumbering.data}
            isLoading={docNumbering.isDataLoading}
            label={"Nombre de notes de crédit créées cette année"}
            onChange={docNumbering.setCreditNoteNumbering}
            required={true}
            name="creditNoteNumbering"
            placeholder="0"
            minValue={docNumbering.minValues.creditNote}
          />
        )}
      </div>

      <div className="w-min mt-2">
        <ButtonTailwind
          variant={numberModified ? "filled" : "outlined2"}
          onClick={docNumbering.handleSubmit}
          loading={docNumbering.isUpdateLoading}
          disabled={docNumbering.isDataLoading || isLoading || isError}
        >
          Mettre à jour
        </ButtonTailwind>
      </div>
    </>
  );
}
