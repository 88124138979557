import { useState } from "react";
import { useCreateUpdatePaymentInformation } from "../useCreateUpdatePaymentInformation";
import { usePaymentInformation } from "../usePaymentInformation";
import { PaymentMethod } from "@/components/elements/NewPaymentForm/PaymentMethod";
import { validatePaymentInformation } from "@/components/elements/NewPaymentForm/validatePaymentInformation";
import { notify } from "@/utils/notify";
import { useEnterpriseId, useUser } from "@/contexts/UserContext";

export function useFormCreatePaymentInformationV2({ onSuccess, onCancel }) {
  const userContext = useUser();
  const country = userContext.state.user?.country || "BE";
  const enterpriseId = useEnterpriseId();
  const paymentInformation = usePaymentInformation({
    stakeholderId: enterpriseId,
  });
  const baseSelectPayment = {
    id: 1,
    name: "Sélectionnez un moyen de paiement",
    secondInput: false,
    isInputHidden: true,
  };
  const [paymentToModify, setPaymentToModify] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(baseSelectPayment);
  const [accountWording, setAccountWording] = useState("");
  const [secondInputValue, setSecondInputValue] = useState("");

  const createPaymentInformation = useCreateUpdatePaymentInformation(
    ["payment-information"],
    (data) => {
      onSuccess(data);
      setPaymentToModify(null);
      setSelectedPayment(baseSelectPayment);
      setAccountWording("");
      setSecondInputValue("");
    }
  );

  const accounts = (() => {
    if (paymentInformation.isLoading || paymentInformation.isError) {
      return [];
    }

    return paymentInformation.data.payload.stakeholderPaymentInformation.map(
      (p) => {
        const method = p.paymentInformationDetails.paymentMethod;
        return {
          id: p.title,
          title: p.title,
          paymentMethod: p.paymentInformationDetails.paymentMethod,
          iban:
            method === PaymentMethod.IBAN
              ? p.paymentInformationDetails.iban
              : undefined,
          inFavourOf:
            method === PaymentMethod.CHEQUE
              ? p.paymentInformationDetails.inFavourOf
              : undefined,
          url:
            method === PaymentMethod.PAYMENT_LINK
              ? p.paymentInformationDetails.url
              : undefined,
        };
      }
    );
  })();

  const paymentType = [
    {
      id: PaymentMethod.IBAN,
      name: "Compte bancaire",
      placeholderAccountWording: "Libellé du compte",
      secondInput: true,
      label: "IBAN",
      placeholder:
        country === "BE"
          ? "BE71 0961 2345 6769"
          : "FR763 0001 0079 4123 4567 8901 85",
    },
    {
      id: PaymentMethod.PAYMENT_LINK,
      name: "Lien de paiement",
      placeholderAccountWording: "Fournisseur de paiement",
      secondInput: true,
      label: "Lien",
      placeholder: "https://votrefournisseur.com/votrelien",
    },
    {
      id: PaymentMethod.CHEQUE,
      name: "Chèque",
      placeholderAccountWording: "Chèque",
      secondInput: true,
      label: "À l'ordre de",
      placeholder: "John Doe",
    },
    {
      id: PaymentMethod.CASH,
      name: "Espèces",
      placeholderAccountWording: "Espèces",
      secondInput: false,
    },
  ];

  function isDuplicateTitle(title = "") {
    return accounts.some(
      (a) => a.title?.toUpperCase() === title?.toUpperCase()
    );
  }

  function informationPayload() {
    const isMain = accounts.length === 0;
    const title = accountWording;
    const paymentMethod = selectedPayment.id;

    if (!PaymentMethod[paymentMethod]) {
      notify({
        isSuccess: false,
        message: "Veuillez sélectionner un moyen de paiement",
      });

      return {
        isValid: false,
        payload: null,
      };
    }

    let payload = {
      isMain,
      paymentInformation: {
        title,
        paymentInformationDetails: {
          paymentMethod,
        },
      },
    };

    const check = validatePaymentInformation({
      paymentMethod,
      paymentData: secondInputValue,
    });

    if (!check.isValid) {
      notify({
        isSuccess: false,
        message: check.message,
      });

      return {
        isValid: false,
        payload: null,
      };
    }

    if (isDuplicateTitle(title)) {
      notify({
        isSuccess: false,
        message: "Un moyen de paiement avec ce titre existe déjà",
      });

      return {
        isValid: false,
        payload: null,
      };
    }

    if (!title) {
      notify({
        isSuccess: false,
        message: "Veuillez renseigner un intitulé",
      });

      return {
        isValid: false,
        payload: null,
      };
    }

    switch (paymentMethod) {
      case PaymentMethod.IBAN:
        payload.paymentInformation.paymentInformationDetails.iban =
          secondInputValue?.replace(/\s/g, "");
        break;
      case PaymentMethod.CHEQUE:
        payload.paymentInformation.paymentInformationDetails.inFavourOf =
          secondInputValue;
        break;
      case PaymentMethod.PAYMENT_LINK:
        payload.paymentInformation.paymentInformationDetails.url =
          secondInputValue;
        break;
      case PaymentMethod.CASH:
      default:
        break;
    }

    return {
      isValid: true,
      payload,
    };
  }

  function AnnulerMoyenDePaiement() {
    setAccountWording("");
    setSecondInputValue("");
    onCancel();
  }

  function handleSubmit(e) {
    e.preventDefault();
    const result = informationPayload();
    if (!result.isValid) return;

    createPaymentInformation.mutate({
      stakeholderId: enterpriseId,
      paymentInformation: result.payload,
    });
  }

  const check = validatePaymentInformation({
    paymentMethod: selectedPayment.id,
    paymentData: secondInputValue,
  });

  return {
    paymentType,
    selectedPayment,
    setSelectedPayment,
    accountWording,
    setAccountWording,
    secondInputValue,
    setSecondInputValue,
    handleSubmit,
    AnnulerMoyenDePaiement,
    createPaymentInformation,
    isDuplicateTitle,
    check,
    paymentToModify,
    setPaymentToModify,
  };
}
