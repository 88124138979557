import { Subscription360 } from "@/Pages/app/Subscription360";
import { SubscriptionOverview } from "@/Pages/app/SubscriptionOverview";
import ProtectedRoute from "@/utils/ProtectedRouteV2";
import RequireEnterpriseRoute from "@/utils/RequireEnterpriseRoute";

export const subscriptionAdminRoutes = [
  {
    path: "subscriptions",
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute requireWhitelist={true} hideMenu={false}>
            <RequireEnterpriseRoute>
              <SubscriptionOverview />
            </RequireEnterpriseRoute>
          </ProtectedRoute>
        ),
      },
      {
        path: "users/:userId",
        element: (
          <ProtectedRoute requireWhitelist={true} hideMenu={false}>
            <RequireEnterpriseRoute>
              <Subscription360 />
            </RequireEnterpriseRoute>
          </ProtectedRoute>
        ),
      },
    ],
  },
];
