// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._lfLb_muGovEBHPdJTpn {
  background-color: rgba(0, 0, 0, 0);
  color: var(--darkGrey);
  border-radius: 4px;
  padding: 10px 0px;
  margin: 32px 0 24px 0;
  outline: none;
  border: 1px solid var(--grey3);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

._lfLb_muGovEBHPdJTpn:hover {
  border: 1px solid var(--black2);
  cursor: pointer;
}

.cmXBvBCXr_k_eDeR7DCg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
}

.yhNQzqh_Wg2F2ngKVqJ7 {
  font-family: "Inter-Regular";
  padding: 10px;
  min-width: 317px;
  min-height: 64px;
  border-radius: 4px;
  border: 1px solid var(--input-border-color);
  resize: none;
}
.yhNQzqh_Wg2F2ngKVqJ7:hover {
  border-color: var(--input-border-color-hover);
}
`, "",{"version":3,"sources":["webpack://./src/components/elements/ServiceForm/ServiceForm.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,qBAAqB;EACrB,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,+BAA+B;EAC/B,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE,4BAA4B;EAC5B,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,2CAA2C;EAC3C,YAAY;AACd;AACA;EACE,6CAA6C;AAC/C","sourcesContent":[".SubmitService {\n  background-color: rgba(0, 0, 0, 0);\n  color: var(--darkGrey);\n  border-radius: 4px;\n  padding: 10px 0px;\n  margin: 32px 0 24px 0;\n  outline: none;\n  border: 1px solid var(--grey3);\n  display: flex;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}\n\n.SubmitService:hover {\n  border: 1px solid var(--black2);\n  cursor: pointer;\n}\n\n.QuantityBox {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 12px;\n}\n\n.Description {\n  font-family: \"Inter-Regular\";\n  padding: 10px;\n  min-width: 317px;\n  min-height: 64px;\n  border-radius: 4px;\n  border: 1px solid var(--input-border-color);\n  resize: none;\n}\n.Description:hover {\n  border-color: var(--input-border-color-hover);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SubmitService": `_lfLb_muGovEBHPdJTpn`,
	"QuantityBox": `cmXBvBCXr_k_eDeR7DCg`,
	"Description": `yhNQzqh_Wg2F2ngKVqJ7`
};
export default ___CSS_LOADER_EXPORT___;
