import Notification from "@/components/token/toaster/notification";
import toast from "react-hot-toast";

export function notify({ isSuccess, message, info, list = [] }) {
  toast.custom(() => (
    <Notification
      isSuccess={isSuccess}
      message={message}
      info={info}
      list={list}
    />
  ));
}
