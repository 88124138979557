// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oLiJ2snhrMUdbpqIp4_p {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/token/ButtonsList/ButtonsList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,WAAW;AACb","sourcesContent":[".wrapVatButtons {\n  display: flex;\n  align-items: flex-end;\n  gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapVatButtons": `oLiJ2snhrMUdbpqIp4_p`
};
export default ___CSS_LOADER_EXPORT___;
