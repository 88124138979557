import ProtectedRoute from "@/utils/ProtectedRouteV2";
import { ShellLayout } from "@/utils/ShellLayout";
import { whitelistRoutes } from "./whitelistRoutes";
import { subscriptionAdminRoutes } from "./subscriptionAdminRoutes";

export const adminRoutes = [
  {
    path: "admin",
    element: <ShellLayout />,
    children: [
      {
        index: true,
        element: <ProtectedRoute showRibbon={true}></ProtectedRoute>,
      },
      ...whitelistRoutes,
      ...subscriptionAdminRoutes,
    ],
  },
];
