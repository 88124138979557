export function amountValidator(
  input,
  { isNegativeAllowed = true, numberOfDecimals = 2, returnEmptyAsZero = false }
) {
  if (input === "") {
    if (returnEmptyAsZero) {
      return { isValid: true, input: "0", errorMessage: "" };
    }
    return { isValid: true, input: "" };
  }

  const indexOfComma = input.indexOf(",");
  const indexOfDot = input.indexOf(".");
  if (indexOfComma > indexOfDot) {
    if (indexOfDot !== -1) {
      // In this case, we got a number like 1,000.00 and we transform it to 1000.00
      input = input.replace(",", "");
    } else {
      // We need to check if we received a number like 1,000 or 1,24
      // If we received a number like 1,000 we transform it to 1000
      // If we received a number like 1,24 we transform it to 1.24
      input.split(",").length === 2
        ? (input = input.replace(",", "."))
        : (input = input.replace(",", ""));
    }
  } else if (indexOfComma < indexOfDot && indexOfComma !== -1) {
    // In this case, we got a number like 1.000,00 and we transform it to 1000.00
    input = input.replace(/\./g, "");
    input = input.replace(",", ".");
  }

  if (isNegativeAllowed && input === "-") {
    return {
      isValid: false,
      input: "-",
      errorMessage: "Le montant est invalide",
    };
  }

  const negative = isNegativeAllowed ? "-?" : "";
  const decimals = numberOfDecimals ? `\\.\\d{1,${numberOfDecimals}}` : "";
  const regex = new RegExp(`^${negative}\\d+(${decimals})?$`);

  const isValid = regex.test(input);

  return {
    isValid,
    input,
    errorMessage: isValid ? "" : "Le montant est invalide",
  };
}
