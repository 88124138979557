import React from "react";

export default function Modal({
  mouseDownScreen,
  mouseDownModal,
  children,
  widthLarge,
}) {
  return (
    <div
      className="fixed left-0 top-0 w-screen h-screen max-h-screen overflow-y-auto z-[500] flex justify-center items-center bg-[rgba(0,0,0,0.5)] backdrop-blur"
      onMouseDown={mouseDownScreen}
    >
      <div
        className={`p-8 py-12 bg-white rounded-md flex gap-4 flex-col justify-between ${
          widthLarge ? "mx-8 overflow-hidden" : "max-w-lg"
        }`}
        onMouseDown={mouseDownModal}
      >
        {children}
      </div>
    </div>
  );
}
