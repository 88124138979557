import { BarChartsColor } from "@/components/colors/Colors";
import { useFormatNumber } from "@/utils/numbers/useFormatNumber";
import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  Cell,
} from "recharts";

export default function Analytics({ analytics }) {
  const barChartsColor = BarChartsColor;
  const {
    monthlyData,
    quarterlyData,
    annualData,
    revenueByMonth,
    totalRevenuePerClient,
    totalRevenuePerClientDescending,
    revenuePerClient,
    maxAbsoluteRevenueByMonth,
    maxAbsoluteRevenueByQuarter,
    maxAbsoluteRevenueByYear,
  } = analytics;

  const { formatNumber } = useFormatNumber();

  // Custom components and formatting
  const CustomXAxisTick = ({ x, y, payload }) => {
    const lines = payload.value.split(" ");
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={8}
          dy={16}
          fill="#B8B8BA"
          fontSize={10}
          textAnchor="middle"
        >
          {lines.map((line, index) => (
            <tspan key={index} x="0" dy={index === 0 ? 0 : 16}>
              {line}
            </tspan>
          ))}
        </text>
      </g>
    );
  };

  const CustomYAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} fill="#B8B8BA" fontSize={10} textAnchor="end">
        {`${payload.value.toLocaleString()} €`}
      </text>
    </g>
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="flex flex-col bg-neutral-700 gap-1 p-2 rounded-lg">
          <p className="text-neutral-400 text-[0.625rem]">{label}</p>
          <div className="flex gap-2 items-center">
            <div className="bg-[#95DE64] w-[6px] h-[6px] rounded-full"></div>
            <p className="text-neutral-400 text-xs">Revenus</p>
            <p className="text-neutral-50 text-xs font-bold">
              {formatNumber(payload[0].value)} €
            </p>
          </div>
        </div>
      );
    }

    return null;
  };

  const monthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const currentMonthRevenue = revenueByMonth[new Date().getMonth()];
  // const previousMonthIndex =
  //   new Date().getMonth() - 1 >= 0 ? new Date().getMonth() - 1 : 11;
  // const previousMonthRevenue = revenueByMonth[previousMonthIndex] || 0;
  // const percentageChange = previousMonthRevenue
  //   ? ((currentMonthRevenue - previousMonthRevenue) / previousMonthRevenue) *
  //     100
  //   : 0;

  // Define colors for the pie slices
  const COLORS = ["#FF8042", "#FFBB28", "#00C49F", "#0088FE", "#8884D8"];

  // Function to get total revenue by client

  const CustomTooltipClient = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0];
      return (
        <div className="flex flex-col bg-neutral-700 gap-1 p-2 rounded-lg">
          <p className="text-neutral-400 text-xs">{data.name}</p>
          <p className="text-neutral-50 text-xs font-bold">
            {formatNumber(data.value)} €
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <section className="mx-auto w-full flex flex-col gap-12 p-16 pr-12 overflow-y-auto">
      <div className="flex flex-col gap-8 w-full">
        <div className="flex w-full gap-8">
          <div className="flex flex-col w-full gap-8">
            <div className="flex flex-col p-[var(--input-padding-horizontal,1rem)] w-full bg-white border border-[#e5e7eb] rounded-lg shadow-sm gap-1">
              <div className="w-full flex items-end justify-between">
                <p className="text-neutral-600 text-xs">
                  {"Chiffre d'affaires mensuel - "}
                  {monthNames[new Date().getMonth()]} {new Date().getFullYear()}
                </p>
              </div>
              <p className="text-black text-2xl font-semibold">
                {formatNumber(currentMonthRevenue)} €
              </p>
            </div>
            <div className="flex flex-col gap-12 p-6 w-full h-full bg-white border border-[#e5e7eb] rounded-lg shadow-sm">
              <div className="w-full flex items-end justify-between">
                <p className="text-black text-md font-bold">
                  {"Chiffre d'affaires mensuel"}
                </p>
              </div>
              <div className="flex min-h-64 h-full">
                <ResponsiveContainer width="100%">
                  <BarChart data={monthlyData} barCategoryGap={10}>
                    <CartesianGrid
                      stroke="#f5f5f5"
                      horizontal={true}
                      vertical={false}
                      strokeDasharray="3 3"
                    />
                    <Tooltip
                      content={<CustomTooltip />}
                      cursor={{ fill: "rgba(0,0,0,0)" }}
                    />
                    <XAxis
                      tick={{ fill: "#B8B8BA", fontSize: 10 }}
                      tickLine={false}
                      axisLine={false}
                      dataKey="name"
                      padding={{ left: 0, right: 10 }}
                      interval={0}
                      tickMargin={10}
                    />
                    <YAxis
                      tick={<CustomYAxisTick />}
                      tickLine={false}
                      axisLine={false}
                      tickMargin={10}
                      padding={{ bottom: 0, top: 0 }}
                      domain={[0, Math.floor(maxAbsoluteRevenueByMonth * 1.2)]}
                    />
                    <Bar
                      dataKey="revenue"
                      radius={4}
                      isAnimationActive={false}
                      fill={barChartsColor}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full gap-8">
            <div className="flex flex-col gap-12 p-6 w-full bg-white border border-[#e5e7eb] rounded-lg shadow-sm">
              <div className="w-full flex items-end justify-between">
                <p className="text-black text-md font-bold">
                  {"Chiffre d'affaires trimestriel"}
                </p>
              </div>
              <div className="flex h-64">
                <ResponsiveContainer width="100%">
                  <BarChart data={quarterlyData} barCategoryGap={10}>
                    <CartesianGrid
                      stroke="#f5f5f5"
                      horizontal={true}
                      vertical={false}
                      strokeDasharray="3 3"
                    />
                    <Tooltip
                      content={<CustomTooltip />}
                      cursor={{ fill: "rgba(0,0,0,0)" }}
                    />
                    <XAxis
                      tickLine={false}
                      axisLine={false}
                      dataKey="name"
                      padding={{ left: 0, right: 10 }}
                      interval={0}
                      tick={<CustomXAxisTick />}
                      tickMargin={10}
                      height={40}
                    />

                    <YAxis
                      tick={<CustomYAxisTick />}
                      tickLine={false}
                      axisLine={false}
                      padding={{ bottom: 0, top: 0 }}
                      tickMargin={10}
                      domain={[
                        0,
                        Math.floor(maxAbsoluteRevenueByQuarter * 1.2),
                      ]}
                    />
                    <Bar
                      dataKey="revenue"
                      radius={4}
                      isAnimationActive={false}
                      fill={barChartsColor}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* Annual Revenue Chart (5 years) */}
            <div className="flex flex-col gap-12 p-6 w-full bg-white border border-[#e5e7eb] rounded-lg shadow-sm">
              <div className="w-full flex items-end justify-between">
                <p className="text-black text-md font-bold">
                  {"Chiffre d'affaires annuel"}
                </p>
              </div>
              <div className="flex h-64">
                <ResponsiveContainer width="100%">
                  <BarChart data={annualData} barCategoryGap={10}>
                    <CartesianGrid
                      stroke="#f5f5f5"
                      horizontal={true}
                      vertical={false}
                      strokeDasharray="3 3"
                    />
                    <Tooltip
                      content={<CustomTooltip />}
                      cursor={{ fill: "rgba(0,0,0,0)" }}
                    />
                    <XAxis
                      tick={{ fill: "#B8B8BA", fontSize: 10 }}
                      tickLine={false}
                      axisLine={false}
                      dataKey="name"
                      padding={{ left: 0, right: 10 }}
                      interval={0}
                      tickMargin={10}
                    />
                    <YAxis
                      tick={<CustomYAxisTick />}
                      tickLine={false}
                      axisLine={false}
                      padding={{ bottom: 0, top: 0 }}
                      tickMargin={10}
                      domain={[0, Math.floor(maxAbsoluteRevenueByYear * 1.2)]}
                    />

                    <Bar
                      dataKey="revenue"
                      radius={4}
                      isAnimationActive={false}
                      fill={barChartsColor}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-8 w-full">
          <div className="flex flex-col py-6 w-full bg-white border border-[#e5e7eb] rounded-lg shadow-sm gap-6">
            <p className="text-black text-md font-bold px-6">
              Répartition du CA par client
            </p>
            <div className="border-t border-[#e5e7eb] px-6 flex">
              <div style={{ width: "50%", minHeight: "300px" }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={revenuePerClient}
                      cx="50%"
                      cy="50%"
                      innerRadius={100}
                      outerRadius={120}
                      fill="#8884d8"
                      paddingAngle={0}
                      blendStroke={true}
                      dataKey="value"
                    >
                      {revenuePerClient.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip content={<CustomTooltipClient />} />
                  </PieChart>
                </ResponsiveContainer>
              </div>

              <div className="flex flex-col gap-4 grow w-full max-h-96 overflow-y-auto">
                <div className="flex flex-col gap-4 w-full py-12">
                  <div className="flex w-full h-2 mt-4 rounded-lg overflow-hidden gap-1">
                    {totalRevenuePerClientDescending.map((client, index) => (
                      <div
                        key={client.name}
                        style={{
                          width: `${
                            (client.value / totalRevenuePerClient) * 100
                          }%`,
                          backgroundColor: COLORS[index % COLORS.length],
                        }}
                        className="h-full"
                      ></div>
                    ))}
                  </div>

                  {totalRevenuePerClientDescending.map((client, index) => (
                    <div
                      key={client.name}
                      className="flex flex-col items-start gap-4"
                    >
                      {/* Client name */}
                      <div className="text-sm text-black">
                        {client.name} {client.value.toLocaleString()} €
                      </div>

                      {/* Bar for revenue */}
                      <div
                        className="h-2 rounded-md"
                        style={{
                          backgroundColor: COLORS[index % COLORS.length],
                          width: `${
                            (client.value / totalRevenuePerClient) * 100
                          }%`,
                        }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
