import React from "react";
import CompteACrediter from "@/components/elements/CompteACrediter/CompteACrediter";
import InputTailwind from "@/components/token/Input/inputTailwind";
import TextArea from "@/components/token/textareas/textareas";
import { Datepicker } from "flowbite-react";
import { datePickerTheme } from "@/data/themes.js";
import SelectMenu from "@/components/token/select/select";
import { vatDisclaimers } from "@/data/vatDisclaimers";
import { useUser } from "@/contexts/UserContext";
import { useManageSettings } from "@/hooks/useSettings";
import { notify } from "@/utils/notify";

export default function LastStepCreditForm(props) {
  function transformDate(date) {
    const dateObj = new Date(date);
    const day = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const month = dateObj.getDate().toString().padStart(2, "0");
    const year = dateObj.getFullYear();
    return `${month}/${day}/${year}`;
  }

  function convertStringToDate(dateString) {
    if (!dateString) return undefined;

    // Split the date string into its components
    const [day, month, year] = dateString.split("/");

    return new Date(year, month - 1, day); // month is 0-indexed in JavaScript
  }

  const userContext = useUser();
  const country = userContext.state.user.country;
  const enterpriseId = userContext.state.user.enterpriseId;

  const manageSettings = useManageSettings(["settings"], undefined, () => {
    notify({
      isSuccess: true,
      message: "Paramètres de facturation mis à jour avec succès",
    });
  });
  function handleSettings(conditionsMentions) {
    manageSettings.mutate({
      settingsType: "document",
      parentId: enterpriseId,
      settingsKey: "DOCUMENT_CONDITIONS",
      settingsValue: conditionsMentions,
    });
  }
  return (
    <div className="flex flex-col gap-8">
      <div className="w-full flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Date d&apos;émission
          </label>
          <Datepicker
            placeholder="JJ/MM/AAAA"
            dateformat="dd/MM/yyyy"
            onSelectedDateChanged={(date) =>
              props.setIssuingDate(transformDate(date))
            }
            defaultDate={convertStringToDate(props.issuingDate)}
            language="fr-FR"
            weekStart={2}
            theme={datePickerTheme}
            className="dark:[&_input]:bg-black focus:dark:[&_input]:ring-gray-700"
            labelTodayButton="Aujourd'hui"
            labelClearButton="Annuler"
          />
          <InputTailwind
            label="Numéro de bon de commande (optionnel)"
            id="purchaseOrderIn"
            type="text"
            placeholder="Indiquez un numéro de bon de commande"
            value={props.PurchaseOrderNumber}
            onChange={(event) =>
              props.setPurchaseOrderNumber(event.target.value)
            }
            isRequired={false}
            icon={false}
          ></InputTailwind>
        </div>
      </div>
      <CompteACrediter
        paymentInformation={props.paymentInformation}
        accounts={props.accounts}
        accountsToCredit={props.accountsToCredit}
        setAccountsToCredit={props.setAccountsToCredit}
      />
      <TextArea
        label="Conditions de paiement et mentions légales (optionnel)"
        placeholder="Numéro de TVA intracommunaire, mode de paiement, délai de paiement, frais applicables en cas de retard de paiement, etc..."
        value={props.conditionsMentions}
        onChange={(e) => props.setConditionsMentions(e.target.value)}
        save={true}
        onSave={handleSettings}
      />
      <InputTailwind
        label="Comment le crédit sera appliqué"
        type="text"
        placeholder="Remboursement, crédit sur futures transactions, etc...."
        value={props.creditAppliance}
        onChange={(e) => props.setCreditAppliance(e.target.value)}
        maxLength={500}
      ></InputTailwind>
      <SelectMenu
        selectedItem={props?.VATMentions}
        setSelectedItem={(e) => props?.setVATMentions(e)}
        data={vatDisclaimers(country)}
        label={"Mention de TVA"}
        placeholder={"Mention de TVA"}
      />
    </div>
  );
}
