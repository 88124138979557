import Modal from "@/components/elements/Modal/Modal";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import SelectMenu from "@/components/token/select/select";
import { useEnterpriseId } from "@/contexts/UserContext";
import { useManageExternalRevenue } from "@/hooks/useExternalRevenue";
import { notify } from "@/utils/notify";
import { amountValidator } from "@/utils/numbers/amountValidator";
import { useFormatNumber } from "@/utils/numbers/useFormatNumber";
import React, { useState } from "react";

export const listOfMonths = [
  "Janv.",
  "Févr.",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juil.",
  "Août",
  "Sept.",
  "Oct.",
  "Nov.",
  "Déc.",
];

export default function DataCompletionModal({
  setDisplayDataCompletionModal,
  analytics,
}) {
  const [year, setYear] = useState(new Date().getFullYear());
  const lastTenYears = Array.from(
    { length: 10 },
    (_, i) => new Date().getFullYear() - i
  );
  const [externalRevenueInput, setExternalRevenueInput] = useState({
    [year]: new Array(12).fill({ externalRevenueAmount: null, isValid: true }),
  });
  const [isOneInvalid, setIsOneInvalid] = useState(false);

  const { formatNumber } = useFormatNumber();
  const enterpriseId = useEnterpriseId();
  const revenuePerMonth = analytics.getRevenueByMonth(year);

  const manageRevenue = useManageExternalRevenue(
    ["analytics", "external", "revenue"],
    [["invoices"]],
    () => {
      setDisplayDataCompletionModal(false);
      notify({
        isSuccess: true,
        message: "Données enregistrées avec succès",
      });
    }
  );

  const totalRevenue = externalRevenueInput[year].map((item, index) => {
    const lidapRevenue = revenuePerMonth.lidapRevenueByMonth[index];
    const externalRevenueAmount =
      (item.externalRevenueAmount === null
        ? revenuePerMonth.externalRevenueByMonth[index]
        : parseFloat(item.externalRevenueAmount)) || 0;
    return lidapRevenue + externalRevenueAmount;
  });

  const handleInputChange = (value, index) => {
    // const sanitizedValue = value.replace(/[^0-9.-]/g, ""); // Validate an amount: ^-?\d+(\.\d{1,2})?$
    const check = amountValidator(value, {
      isNegativeAllowed: true,
      numberOfDecimals: 2,
      returnEmptyAsZero: false,
    });

    setIsOneInvalid(!check.isValid && check.input !== "0");
    setExternalRevenueInput((prev) => {
      const newRevenue = prev[year].map((item, i) => {
        if (i === index) {
          const j = i + 1;
          const m = j < 10 ? `0${j}` : `${j}`;
          return {
            externalRevenueAmount: check.input,
            month: m,
            isValid: check.isValid,
          };
        }
        return item;
      });

      return {
        ...prev,
        [year]: newRevenue,
      };
    });
  };

  const handleSubmit = () => {
    if (isOneInvalid) {
      notify({
        message: "Veuillez corriger les montants invalides",
        isError: true,
      });
      return;
    }
    const revenue = [];

    const years = Object.keys(externalRevenueInput);
    years.forEach((year) => {
      const yearlyRevenue = externalRevenueInput[year];
      const r = yearlyRevenue
        .filter((m) => {
          return (
            m.externalRevenueAmount !== null &&
            m.externalRevenueAmount !== "" &&
            m.isValid
          );
        })
        .map((item) => {
          return {
            year: `${year}`,
            month: `${item.month}`,
            amount: item.externalRevenueAmount,
            currency: "EUR",
          };
        });

      revenue.push(...r);
    });

    if (revenue.length === 0) {
      notify({
        message: "Veuillez renseigner au moins un montant",
        isError: true,
      });
      return;
    }

    manageRevenue.mutate({
      enterpriseId,
      revenue,
    });
  };

  return (
    <Modal widthLarge>
      <div className="w-full overflow-hidden">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Compléter mes données</h2>

          <SelectMenu
            selectedItem={year}
            setSelectedItem={(y) => {
              setYear(y);
              setExternalRevenueInput((prev) => {
                if (!prev[y]) {
                  return {
                    ...prev,
                    [y]: new Array(12).fill({
                      externalRevenueAmount: null,
                      isValid: true,
                    }),
                  };
                }
                return prev;
              });
            }}
            data={lastTenYears}
            label={""}
            placeholder={"Choisir une année"}
          />
        </div>
        <div className="overflow-auto max-h-96 border border-gray-300 rounded-md">
          <table className="table-fixed">
            <thead className="bg-neutral-100">
              <tr>
                <th className="p-4 pr-8 border text-start">Mois</th>
                {listOfMonths.map((month, index) => (
                  <th
                    key={index}
                    className=" px-4 py-3 border font-semibold text-left"
                  >
                    {month}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-neutral-100 p-4 border whitespace-nowrap">
                  CA sur Lidap
                </td>
                {revenuePerMonth.lidapRevenueByMonth.map((r, index) => (
                  <td key={index} className="bg-neutral-100 px-4 py-2 border">
                    <input
                      type="text"
                      placeholder="0"
                      value={`${formatNumber(r)} €`}
                      className="bg-transparent border-none focus:outline-none focus:ring-0 p-1 text-left"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td className="bg-neutral-100 p-4 pr-8 border whitespace-nowrap">
                  CA hors Lidap
                </td>
                {externalRevenueInput[year].map((r, index) => (
                  <td key={index} className="px-4 py-2 border">
                    <input
                      type="string"
                      placeholder="0 €"
                      value={
                        r.externalRevenueAmount === null
                          ? revenuePerMonth.externalRevenueByMonth[index] || ""
                          : r.externalRevenueAmount
                      }
                      onChange={(e) => {
                        e.preventDefault();
                        handleInputChange(e.target.value, index);
                      }}
                      className={` border-none focus:outline-none focus:ring-0 p-1 text-left ${
                        r.isValid ? "" : "text-red-500"
                      }`}
                    />
                  </td>
                ))}
              </tr>
              <tr className="bg-neutral-100">
                <td className="p-4 border whitespace-nowrap font-semibold">
                  Somme totale
                </td>
                {totalRevenue.map((total, index) => (
                  <td
                    key={index}
                    className="px-4 py-2 border font-semibold text-left truncate"
                  >
                    {`${formatNumber(total.toFixed(2))} €`}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex gap-4 justify-end mt-12">
          <ButtonTailwind
            onClick={setDisplayDataCompletionModal}
            variant="outlined"
            disabled={manageRevenue.isLoading}
          >
            Annuler
          </ButtonTailwind>
          <ButtonTailwind
            disabled={analytics.isExternalRevenueLoading}
            loading={manageRevenue.isLoading}
            onClick={handleSubmit}
          >
            Enregistrer
          </ButtonTailwind>
        </div>
      </div>
    </Modal>
  );
}
