//TODO!: rework this component to make it robust
export function LidapToolTip({
  toolTipText,
  tooltipBoxClassName = "absolute top-full z-50 mt-2 left-1/2 transform -translate-x-1/2 flex-shrink-0",
  tooltipBoxAdditionalClassName = "",
  isGrouped = true,
  isVisible = false,
}) {
  /* The properties must apply to the entire box (div) or only to the text container (span) depending on the length of the text, for optimal rendering */
  const numberOfLettersInToolTip = toolTipText ? toolTipText.length : 0;
  /* text-xs (default span text size) is 0.75 rem */
  const spanClassNameSizeInRem = 0.75 * numberOfLettersInToolTip;
  /* Width should be 12 rem (w-48) when the text is longer than that, but we take a margin */
  const isToolTipLarge = spanClassNameSizeInRem > 13.5;
  /* By default, the component works with a group hover with a relative group div
     Otherwise, it expects a boolean value indicating if the tooltip should be shown
     TODO:! address the fact that z-50 is a problem when the tooltip is not visible
  */
  const groupHover = isGrouped
    ? "opacity-0 group-hover:opacity-100 transition-opacity duration-300"
    : `${
        isVisible ? "opacity-100" : "opacity-0 hidden"
      } transition-opacity duration-300`;

  return (
    <>
      {isToolTipLarge ? (
        <LargeLidapToolTip
          groupHover={groupHover}
          toolTipText={toolTipText}
          tooltipBoxClassName={tooltipBoxClassName}
          tooltipBoxAdditionalClassName={tooltipBoxAdditionalClassName}
        />
      ) : (
        <SmallLidapToolTip
          groupHover={groupHover}
          toolTipText={toolTipText}
          tooltipBoxClassName={tooltipBoxClassName}
          tooltipBoxAdditionalClassName={tooltipBoxAdditionalClassName}
        />
      )}
    </>
  );
}

function LargeLidapToolTip({
  toolTipText,
  tooltipBoxClassName = "absolute top-full z-50 mt-2 left-1/2 transform -translate-x-1/2 flex-shrink-0",
  tooltipBoxAdditionalClassName = "",
  groupHover,
}) {
  return (
    <div
      className={`${tooltipBoxClassName} ${groupHover} ${tooltipBoxAdditionalClassName} w-max max-w-48 py-1 px-2 bg-gray-800 rounded`}
    >
      <span className={`text-white text-xs`}>{toolTipText}</span>
    </div>
  );
}

function SmallLidapToolTip({
  toolTipText,
  tooltipBoxClassName = "absolute top-full z-50 mt-2 left-1/2 transform -translate-x-1/2 flex-shrink-0",
  tooltipBoxAdditionalClassName = "",
  groupHover,
}) {
  return (
    <div
      className={`${tooltipBoxClassName} ${groupHover} ${tooltipBoxAdditionalClassName}`}
    >
      <span className={`text-white text-xs py-1 px-2 bg-gray-800 rounded`}>
        {toolTipText}
      </span>
    </div>
  );
}
