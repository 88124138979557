import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { countriesCurrency } from "@/data/countryCodeCorrespondance";
import { ArrowDownOnSquareIconWithLoading } from "../loading/ArrowDownOnSquareIconWithLoading";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectMenu({
  selectedItem,
  setSelectedItem,
  data,
  label,
  placeholder,
  onSave,
  loading,
}) {
  return (
    <Listbox
      value={selectedItem}
      onChange={(newValue) => setSelectedItem && setSelectedItem(newValue)}
    >
      {({ open }) => (
        <div className="flex flex-col">
          {label ? (
            <div className="flex justify-between items-center">
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                {label}
              </Listbox.Label>
              {onSave ? (
                <ArrowDownOnSquareIconWithLoading
                  handleAction={onSave}
                  value={selectedItem}
                  loading={loading}
                />
              ) : null}
            </div>
          ) : null}
          <div className="relative">
            <Listbox.Button
              className={`${
                selectedItem
                  ? "text-gray-900"
                  : placeholder
                  ? "text-neutral-400"
                  : "text-gray-900"
              } relative w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-black dark:focus:ring-black sm:text-sm sm:leading-6`}
            >
              <span className="block truncate">
                {selectedItem?.name || selectedItem || placeholder || null}{" "}
                {selectedItem &&
                selectedItem?.name !== countriesCurrency[selectedItem?.name]
                  ? countriesCurrency[selectedItem?.name]
                  : null}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {data
                  ? data
                      .filter((person) => person.id !== 0) // Filtrez l'option d'état initial
                      .map((person) => (
                        <Listbox.Option
                          key={person.id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "bg-neutral-100 text-gray-900"
                                : "text-gray-900",
                              "relative cursor-pointer select-none py-2 pl-3 pr-3"
                            )
                          }
                          value={person}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {person.name || person}{" "}
                                {countriesCurrency[person.name] !== person.name
                                  ? countriesCurrency[person.name]
                                  : null}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))
                  : null}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
