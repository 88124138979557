export function stringify(obj) {
  if (!obj) return "";

  const params = new URLSearchParams();

  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key) && obj[key]) {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((item) => {
          params.append(`${key}[]`, item);
        });
        continue;
      }
      params.append(key, obj[key]);
    }
  }

  return params.toString();
}
