export const identifierFormatRules = {
  BECOMPANY_NUMBER: (value) => {
    // Format: 0123.4567.89
    return value
      ? value
          .replace(/\D/g, "") // Remove non-digits
          .replace(/^(\d{4})(\d+)/, "$1.$2")
          .replace(/^(\d{4})\.(\d{3})(\d+)/, "$1.$2.$3")
      : ""; // Apply formatting
  },
  // Add more rules for other types and country codes
  BEVAT_NUMBER: (value) => {
    // Format: BE 0123.456.789
    const onlyDigits = value.replace(/^BE\s+|\D/g, "");
    return onlyDigits
      ? "BE " +
          onlyDigits
            .replace(/^(\d{4})(\d+)/, "$1.$2")
            .replace(/^(\d{4})\.(\d{3})(\d+)/, "$1.$2.$3")
      : value;
  },
  FRVAT_NUMBER: (value) => {
    // Format: FR XX 123 456 789
    const onlyDigits = value.replace(/^FR\s+|\D/g, "");
    return onlyDigits
      ? "FR" + onlyDigits.replace(/^(\d{2})(\d+)/, "$1 $2")
      : value;
  },
  FRSIREN: (value) => {
    // Format: 123 456 789
    return value
      ? value.replace(/\D/g, "").replace(/^(\d{3})(\d{3})(\d{3})/, "$1 $2 $3")
      : "";
  },
};
