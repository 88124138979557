import { useUser } from "@/contexts/UserContext";
import React, { useMemo, useRef } from "react";
import { CRUDL } from "@/utils/crudl";
import LidapTable from "@/components/token/table/LidapTable";
import { useLidapTableBuild, useLidapTableInit } from "@/hooks/useLidapTable";
import { useRecentArticles } from "@/hooks/useArticles";
import { ArticleStart } from "@/feature/articles/createArticle/ArticleStart";
import { PencilIcon, TrashIcon, EyeIcon } from "@heroicons/react/24/outline";
import { unitPriceReference } from "@/components/elements/Article/Units";
import { countriesCurrency } from "@/data/countryCodeCorrespondance";
import DeleteConfirmationModal from "@/components/token/modals/DeleteConfirmationModal";
import RequireSubscriptionButton from "@/components/token/button/RequireSubscriptionButton";

export default function ArticleOverview({
  articleAction,
  handleDelete,
  setDeleteArticle,
  deleteArticle,
}) {
  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;

  const editCellsRefs = useRef({});

  const lidapTableInit = useLidapTableInit(
    {
      chainingActions: [
        {
          name: "Action",
          render: ({ row }) => (
            <>
              <div className="flex items-center gap-4 hover:bg-gray-100 dark:hover:bg-neutral-900 p-2 px-4 rounded-md">
                <EyeIcon
                  className="h-4 w-4 text-neutral-600"
                  aria-hidden="true"
                />
                <button
                  className="text-black"
                  onClick={() => articleAction(CRUDL.READ, row.original)}
                >
                  Consulter
                </button>
              </div>
              <div className="flex items-center gap-4 hover:bg-gray-100 dark:hover:bg-neutral-900 p-2 px-4 rounded-md">
                <PencilIcon
                  className="h-4 w-4 text-neutral-600"
                  aria-hidden="true"
                />
                <button
                  className="text-black"
                  onClick={() => articleAction(CRUDL.UPDATE, row.original)}
                >
                  Modifier
                </button>
              </div>
              <div className="flex items-center gap-4 hover:bg-gray-100 dark:hover:bg-neutral-900 p-2 px-4 rounded-md">
                <TrashIcon
                  className="h-4 w-4 text-neutral-600"
                  aria-hidden="true"
                />
                <button
                  className="text-black"
                  onClick={() => articleAction(CRUDL.DELETE, row.original)}
                >
                  Supprimer
                </button>
              </div>
            </>
          ),
        },
      ],
    },
    editCellsRefs
  );

  const recentArticles = useRecentArticles({
    enterpriseId,
    queryParam: {
      pageNumber: lidapTableInit.page - 1,
      numberOfRecords: lidapTableInit.numberOfRecords,
    },
    reactQueryConfig: {
      keepPreviousData: true,
    },
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "title", // accessor is now accessorKey
        header: "Titre",
        cell: ({ cell }) => cell.getValue(),
        className: {
          cell: "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
          header:
            "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",
        },
      },
      {
        accessorFn: (row) =>
          unitPriceReference.find(
            (unit) => unit.unitValue === row.quantity.unit
          )?.label,
        header: "Unité",
        cell: ({ cell }) => cell.getValue(),
        className: {
          cell: "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
          header:
            "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",
        },
      },
      {
        accessorFn: (row) =>
          row.unitPrice.amount +
          " " +
          countriesCurrency[row.unitPrice.currency],
        header: "Prix unitaire (HT)",
        className: {
          cell: "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
          header: "px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
        },
      },
      {
        id: "edit",
        header: () => {
          return <span className="sr-only">Edit</span>;
        },
        cell: ({ cell }) => {
          if (!editCellsRefs.current[cell.row.id]) {
            editCellsRefs.current[cell.row.id] = React.createRef();
          }
          return (
            <div>
              •••
              <lidapTableInit.Chaining
                row={cell.row}
                selectedRowId={lidapTableInit.selectedRowId}
              />
            </div>
          );
        },
        className: {
          cell: "relative select-none whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 text-gray-500 hover:text-indigo-900 cursor-pointer whitespace-nowrap px-3 py-4 text-sm text-gray-500",
          header: "relative py-3.5 pl-3 pr-4 sm:pr-6",
        },
        onClick: (cell) => lidapTableInit.handleEditClick(cell.row.id),
      },
    ],
    [lidapTableInit]
  );

  const lidapTableBuild = useLidapTableBuild({
    query: recentArticles,
    lidapTableInit,
    columns,
  });

  if (
    recentArticles.isSuccess &&
    recentArticles.data.payload.results.length === 0 &&
    lidapTableBuild.totalNumberOfResults === 0
  ) {
    return (
      <ArticleStart
        onClick={() => {
          articleAction(CRUDL.CREATE);
        }}
      />
    );
  }

  return (
    <>
      {deleteArticle !== null && (
        <DeleteConfirmationModal
          handleConfirm={handleDelete}
          setDeleteItem={setDeleteArticle}
          message={"Voulez vous vraiment <br /> supprimer cet article ?"}
        />
      )}
      <LidapTable
        lidapTableInit={lidapTableInit}
        lidapTableBuild={lidapTableBuild}
        additionalFilters={[]}
        actionButtons={[
          {
            name: "Nouveau service",
            render: (
              <RequireSubscriptionButton
                onClick={() => {
                  articleAction(CRUDL.CREATE);
                }}
              >
                Nouveau service
              </RequireSubscriptionButton>
            ),
          },
        ]}
        additionalActionsOnPaginate={() => {}}
      />
    </>
  );

  // Rest of your component...
}
