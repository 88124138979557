export const ServiceUnitFixedPrice = {
  unitValue: "FIXED_PRICE",
  label: "Forfait",
  pluralLabel: "Forfaits",
};

export const ServiceUnitDay = {
  unitValue: "DAY",
  label: "Jour",
  pluralLabel: "Jours",
};

export const ServiceUnitHour = {
  unitValue: "HOUR",
  label: "Heure",
  pluralLabel: "Heures",
};

export const ServiceUnitUnit = {
  unitValue: "UNIT",
  label: "Unité",
  pluralLabel: "Unités",
};

export const ServiceUnitPerson = {
  unitValue: "PERSON",
  label: "Personne",
  pluralLabel: "Personnes",
};

export const ServiceUnitWord = {
  unitValue: "WORD",
  label: "Mot",
  pluralLabel: "Mots",
};

export const ServiceUnitCharacter = {
  unitValue: "CHARACTER",
  label: "Caractère",
  pluralLabel: "Caractères",
};

export const ServiceUnitArticle = {
  unitValue: "ARTICLE",
  label: "Article",
  pluralLabel: "Articles",
};

export const ServiceUnitPage = {
  unitValue: "PAGE",
  label: "Page",
  pluralLabel: "Pages",
};

export const ServiceUnitClick = {
  unitValue: "CLICK",
  label: "Clic",
  pluralLabel: "Clics",
};

export const unitPriceReference = [
  ServiceUnitFixedPrice,
  ServiceUnitDay,
  ServiceUnitHour,
  ServiceUnitUnit,
  ServiceUnitPerson,
  ServiceUnitWord,
  ServiceUnitCharacter,
  ServiceUnitArticle,
  ServiceUnitPage,
  ServiceUnitClick,
];

export const ServiceUnits = unitPriceReference.map(
  (unitPrice) => unitPrice.label
);
