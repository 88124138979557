import InputTailwind from "@/components/token/Input/inputTailwind";
import { CRUDL } from "@/utils/crudl";
import { useEffect } from "react";

export function Name({
  initialName,
  disabled,
  onChange,
  isLoading,
  required,
  minLength = 3,
  maxLength = 255,
  name = "name",
  label,
  displayError = true,
  placeholder = "Nom",
  forceValidate,
  context,
}) {
  const nm = initialName;
  const error = (nm) => {
    if (required && !nm) {
      return {
        success: false,
        message: "Ce champ est requis",
      };
    } else if (nm && nm.length < minLength) {
      return {
        success: false,
        message: `Le nom doit contenir au moins ${minLength} caractère`,
      };
    } else if (nm && nm.length > maxLength) {
      return {
        success: false,
        message: `Le nom doit contenir au maximum ${maxLength} caractères`,
      };
    } else {
      return {
        success: true,
        message: null,
      };
    }
  };

  useEffect(() => {
    if (
      forceValidate &&
      (!error(initialName).success || context === CRUDL.CREATE)
    ) {
      onChange({
        success: error(initialName).success,
        data: initialName,
        isDirty: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceValidate, onChange, initialName]);

  const handleNameChange = (e) => {
    const value = e.target.value;
    onChange({
      success: error(value).success,
      data: value,
      isDirty: true,
    });
  };

  return (
    <InputTailwind
      label={label ? label : "Prénom"}
      type="text"
      placeholder={placeholder}
      value={nm}
      onChange={(e) => handleNameChange(e)}
      loading={isLoading}
      disabled={disabled}
      error={displayError ? error(nm).message : null}
      isRequired={required}
      name={name}
      maxLength={maxLength}
      minLength={minLength}
      // showButton={true}
      // buttonText="Encodage intelligent" // utiliser onButtonClick="", pour rediriger
    ></InputTailwind>
  );
}
