import React, { useState, useEffect, useMemo } from "react";
import SettingsHeader from "@/components/token/settingsHeader/SettingsHeader";
import "@/assets/css/profile.css";
import InputTailwind from "@/components/token/Input/inputTailwind";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import { useUser } from "@/contexts/UserContext";
import { useUpdateUser } from "@/hooks/useUpdateUser";
import { notify } from "@/utils/notify";
import useFormOryChangePassword from "@/hooks/forms/useFormOryChangePassword";
import { Password } from "./User/Password";
import OryConfirmAuthModal from "../token/ory/OryConfirmAuthModal";
import { Navigate, useSearchParams } from "react-router-dom";

export default function Profile() {
  /* Context */
  const userContext = useUser();
  const user = userContext.state.user;

  /* State */
  const [firstName, setFirstName] = useState(user ? user.firstName : "");
  const [lastName, setLastName] = useState(user ? user.lastName : "");
  const [email, setEmail] = useState(user ? user.email : "");

  const [Logo, setLogo] = useState("");
  const [selectedLogo, setSelectedLogo] = useState("");

  /* Params */
  const [searchParams] = useSearchParams();

  /* Hooks */
  const {
    isOryConfirmAuthModalDisplayed,
    setIsOryConfirmAuthModalDisplayed,
    handlePasswordChange,
    newPassword,
    setNewPassword,
    isLoading,
    passwordError,
  } = useFormOryChangePassword(() => {
    setNewPassword({ success: false, data: "", isDirty: false });
  });

  const updateUser = useUpdateUser(["users"], () => {
    notify({
      isSuccess: true,
      message: "Profil mis à jour avec succès",
    });
  });

  /* Use effects */
  useEffect(() => {
    if (selectedLogo) {
      const imageRender = URL.createObjectURL(selectedLogo);
      setLogo(imageRender);
    }
  }, [selectedLogo]);

  useEffect(() => {
    return () => {
      if (selectedLogo) {
        URL.revokeObjectURL(selectedLogo);
      }
    };
  }, [selectedLogo]);

  /* Memo */
  const MemoizedPassword = useMemo(
    () => (
      <Password
        initialPassword={newPassword.data}
        onChange={setNewPassword}
        isLoading={isLoading}
        required={true}
        name={"Nouveau mot de passe"}
        label={"Nouveau mot de passe"}
        id={"new-password"}
        displayError={
          newPassword.isDirty || (!newPassword.isDirty && passwordError)
        }
        bypassRules={false}
        placeholder={"•••••••••••"}
        disabled={isLoading}
        externalError={passwordError}
        dataFormType="password,new"
      />
    ),
    // Dependencies that could cause the component to re-render
    [
      newPassword.data,
      newPassword.isDirty,
      setNewPassword,
      isLoading,
      passwordError,
    ]
  );

  /* Handlers */
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedLogo(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!firstName) {
      notify({
        isSuccess: false,
        message: "Le prénom est requis",
      });
      return;
    }

    if (!lastName) {
      notify({
        isSuccess: false,
        message: "Le nom est requis",
      });
      return;
    }

    updateUser.mutate({
      firstName,
      lastName,
    });
  };

  /* Deduced values */
  const referrer = searchParams.get("referrer");
  if (referrer && referrer === "recovery") {
    return <Navigate to={`/security/change-password?referrer=${referrer}`} />;
  }

  /* Rendering */
  return (
    <>
      {isOryConfirmAuthModalDisplayed && (
        <OryConfirmAuthModal
          setIsOryConfirmAuthModalDisplayed={setIsOryConfirmAuthModalDisplayed}
          onSuccessfulAuth={() => {
            handlePasswordChange();
            setIsOryConfirmAuthModalDisplayed(false);
          }}
        />
      )}
      <div className="mt-12 mb-24 flex flex-col gap-8 w-full max-w-[768px] mx-auto p-8 bg-white rounded-lg h-max shadow-sm">
        <div>
          <SettingsHeader
            subTitle="Gérez les paramètres de votre compte Lidap"
            title="Profil"
          />
          <div className="w-full border-t border-grey-400"></div>
        </div>

        <div className="changeSettingsFile">
          <label htmlFor="inputFile" id="logoSection">
            {/* TODO: fix it (should be the initials based on the name of the user) */}
            {Logo ? <img src={Logo} id="selected-logo"></img> : "JS"}
          </label>
          <label htmlFor="inputFile" id="modifyAvatar">
            {" "}
            Changer d&apos;avatar
          </label>
          <input
            type="file"
            id="inputFile"
            accept=".png,.jpg,.svg"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </div>

        <form>
          <div id="settings-profile-form">
            <div className="settings-align-inputs">
              <InputTailwind
                label="Prénom"
                type="text"
                placeholder="Votre prénom"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                icon={false}
                minLength={1}
                maxLength={255}
                id="firstName"
              ></InputTailwind>

              <InputTailwind
                label="Nom"
                type="text"
                placeholder="Votre nom"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                icon={false}
                minLength={1}
                maxLength={255}
                id="lastName"
              ></InputTailwind>
            </div>

            <div>
              <InputTailwind
                label="Email"
                type="email"
                placeholder="Votre email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={true}
                info={
                  <div className="w-full flex justify-end mt-1">
                    <span className="text-sm text-neutral-400">
                      Pour modifier votre email, contactez le support à{" "}
                      <a
                        style={{ color: "#E16D64" }}
                        href="mailto:support@lidap.io"
                      >
                        support@lidap.io
                      </a>
                    </span>
                  </div>
                }
              ></InputTailwind>
            </div>
          </div>

          <div className="mt-4">
            <ButtonTailwind
              disabled={
                firstName === user.firstName && lastName === user.lastName
              }
              type="submit"
              onClick={handleSubmit}
              loading={updateUser.isLoading}
            >
              Mettre à jour
            </ButtonTailwind>
          </div>
        </form>

        <div className="w-full border-t border-grey-400"></div>

        <form data-form-type="change_password">
          <div className="settings-align-inputs">{MemoizedPassword}</div>

          <div className="mt-4">
            <ButtonTailwind
              loading={isLoading}
              disabled={
                !newPassword.data ||
                newPassword.success === false ||
                newPassword.isDirty === false
              }
              onClick={(e) => {
                e.preventDefault();
                handlePasswordChange();
              }}
            >
              Modifier mon mot de passe
            </ButtonTailwind>
          </div>
        </form>

        <div className="w-full border-t border-grey-400"></div>

        <div className="flex flex-col">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Zone de danger
          </label>
          {/*<div className="w-min mt-1">
             <ButtonTailwind variant="outlined2">
              Supprimer le compte
            </ButtonTailwind> */}
          <span className="mt-2 text-sm text-neutral-400">
            Veuillez contacter le support pour supprimer votre compte à:{" "}
            <a style={{ color: "#E16D64" }} href="mailto:support@lidap.io">
              support@lidap.io
            </a>
          </span>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
