import React from "react";

export default function CardInfo(props) {
  return (
    <div className="bg-white border border-[#e5e7eb] dark:border-[#2b2b2b] flex p-[var(--input-padding-horizontal,1rem)] items-center gap-2 flex-1 rounded-lg shadow-sm">
      {/* <div
        className={`${props.color} p-3 items-start rounded bg-opacity-10 w-max`}
      >
        {props.icon}
      </div> */}
      <div className="flex flex-col gap-1 w-full">
        <div className="flex flex-row w-full justify-between text-xs">
          <p className="text-neutral-600"> {props.title}</p>
          {/* <p className="text-neutral-400">
            <span className="text-green-400 font-semibold">
              {props.percentage}
            </span>
            {"   "}
            {props.change}
          </p> */}
        </div>
        <p className="font-semibold text-black text-2xl">{props.value}</p>
      </div>
    </div>
  );
}
