import React from "react";
import ButtonTailwind from "../token/button/buttonTailwind";

export default function StepForm({
  numberOfSteps,
  currentStep,
  TextSubmit,
  children,
  onSubmit,
  disableSubmit,
  loadingSubmit,
}) {
  return (
    <div className="w-full flex flex-col justify-center h-screen items-center gap-8">
      <div className="flex flex-col gap-8 bg-white p-8 rounded-lg">
        {/* Steps */}
        <div className="flex items-start gap-6 self-stretch w-96 mx-auto">
          {[...Array(numberOfSteps)].map((_, index) => (
            <div
              key={index}
              className={`w-full h-1 self-stretch rounded-full ${
                currentStep >= index ? "bg-black" : "bg-neutral-300"
              }`}
            ></div>
          ))}
        </div>
        <div className="flex flex-col items-center justify-center">
          {children}
        </div>
        <ButtonTailwind
          onClick={() => {
            // nextStep();
            onSubmit();
          }}
          disabled={disableSubmit}
          loading={loadingSubmit}
          width="100%"
          //TODO: disabled should be based on form validation
        >
          {currentStep + 1 === numberOfSteps ? TextSubmit : "Continuer"}
        </ButtonTailwind>
      </div>
    </div>
  );
}
