export function validatePassword(
  password,
  required = true,
  bypassRules = false
) {
  if (required && !password) {
    return {
      success: false,
      message: "Ce champ est requis",
      errorField: "password",
    };
  }

  if (bypassRules) return { success: true, data: password };

  const passwordRegexp = process.env.PASSWORD_RULES;
  if (password && !password.match(passwordRegexp)) {
    return {
      success: false,
      message: "Le mot de passe ne respecte pas les règles de sécurité",
      errorField: "password",
    };
  }

  return {
    success: true,
    data: {
      password: password,
    },
  };
}
