import React from "react";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import { useNavigate } from "react-router-dom";

export default function Forbidden() {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-screen h-screen flex flex-col justify-center items-center gap-4 justify-center items-center">
        <div className="flex flex-col gap-2 justify-center items-center">
          <p className="text-6xl font-bold text-neutral-300">403</p>
          <p className="text-base text-neutral-300 w-64 text-center">
            Désolé, vous n&apos;êtes pas autorisé à accéder à cette page
          </p>
        </div>

        <ButtonTailwind variant="outlined2" onClick={() => navigate("/home")}>
          Retour
        </ButtonTailwind>
      </div>
    </>
  );
}
