import { useUser } from "@/contexts/UserContext";
import { useEnterprises } from "./useEnterprises";
import { useCurrentInvoiceNumbers } from "./useInvoices";
import { useLocation } from "react-router";

export function useOneLastStep(enabled, target) {
  const location = useLocation();
  const userContext = useUser();
  const isEmailVerified = userContext.state.user?.isEmailVerified;
  const isOnWhitelist = userContext.state.user?.isOnWhitelist;

  const enterprise = useEnterprises({
    enterpriseId: undefined,
    queryParam: {
      emails: true,
      addresses: true,
    },
    reactQueryOptions: {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!isEmailVerified && !!isOnWhitelist && enabled,
    },
  });

  const enterpriseId = enterprise?.data?.payload?.enterprises?.[0]?.id;
  const currentInvoiceNumber = useCurrentInvoiceNumbers(
    {
      enterpriseId,
    },
    {
      enabled: !!enterpriseId && enabled,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  if (!isEmailVerified && isEmailVerified !== undefined) {
    if (location.pathname !== "/mail/verification") {
      return {
        enterprise: enterprise,
        currentInvoiceNumber: currentInvoiceNumber,
        target: "/mail/verification",
      };
    }

    return {
      enterprise: enterprise,
      currentInvoiceNumber: currentInvoiceNumber,
      target: undefined,
    };
  }

  if (
    enterprise.isLoading ||
    enterprise.isInitialLoading ||
    enterprise.isError ||
    currentInvoiceNumber.isInitialLoading ||
    currentInvoiceNumber.isError
  ) {
    return {
      enterprise,
      currentInvoiceNumber,
      target: undefined,
    };
  }

  if (
    enterprise.data &&
    enterprise.data.payload &&
    enterprise.data.payload.enterprises.length === 0
  ) {
    return {
      enterprise,
      currentInvoiceNumber,
      target: "/one-last-step/enterprise",
    };
  }

  if (
    enterprise.data &&
    enterprise.data.payload &&
    enterprise.data.payload.enterprises.length > 0 &&
    currentInvoiceNumber.data &&
    currentInvoiceNumber.data.payload &&
    currentInvoiceNumber.data.payload.currentInvoiceNumbers.length === 0
  ) {
    return {
      enterprise,
      currentInvoiceNumber,
      target: "/one-last-step/numbering",
    };
  }

  return {
    enterprise,
    currentInvoiceNumber,
    target: target,
  };
}
