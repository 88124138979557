import { Name } from "@/components/elements/User/Name";
import { City } from "@/components/elements/stakeholder/address/City";
import { PostalCode } from "@/components/elements/stakeholder/address/PostalCode";
import { StreetName } from "@/components/elements/stakeholder/address/StreetName";
import { Email } from "@/components/elements/stakeholder/contact/Email";
import { Phone } from "@/components/elements/stakeholder/contact/Phone";
import { Identifier } from "@/components/elements/stakeholder/identifier/Identifier";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import SelectNation from "@/components/token/selectNation/selectNation";
import useFormClientData from "@/hooks/forms/useFormClientData";
import { BuildingOffice2Icon, UserIcon } from "@heroicons/react/24/outline";

export default function ClientInformation({
  onCancel,
  onSubmit,
  context,
  isLoading,
  isError,
  data,
}) {
  const clientForm = useFormClientData({
    onCancel,
    onSubmit,
    context,
    isLoading,
    isError,
    data,
  });

  const nation = clientForm.selectedNation || clientForm.defaultNation;
  const companyIdentifier =
    clientForm.companyIdentifier?.data && clientForm.companyIdentifier?.isDirty
      ? clientForm.companyIdentifier?.data
      : clientForm.defaultCompanyIdentifier;
  const vatNumber =
    clientForm.vatNumber?.data && clientForm.vatNumber?.isDirty
      ? clientForm.vatNumber?.data
      : clientForm.defaultVatNumber;

  return (
    <div className="flex flex-row ">
      <div className={" w-full "}>
        {/* <div style={{ padding: "0rem 4rem 0rem 4rem" }}>
          <p className="tw-h2">{clientForm.displayName}</p>
        </div> */}

        <form
          className="flex flex-col overflow-y-visible gap-6"
          onSubmit={(e) => {
            e.preventDefault();
            clientForm.handleSubmit();
          }}
        >
          <div className="flex flex-col cursor-not-allowed">
            <label
              className={`block text-sm font-medium leading-6 text-gray-900`}
            >
              Type de client :
            </label>
            <div className="flex gap-1 bg-neutral-100 rounded-lg p-1 select-none text-sm mt-1 ">
              <div
                className={`flex items-center justify-center p-2 px-4 gap-2 rounded w-full ${
                  clientForm.clientType === "COMPANY"
                    ? "bg-white text-neutral-600 shadow-sm"
                    : "text-neutral-500 "
                }`}
              >
                <BuildingOffice2Icon className={`h-4 w-4 text-neutral-600`} />
                Entreprise
              </div>
              <div
                className={`flex items-center justify-center p-2 px-4 gap-2 rounded w-full ${
                  clientForm.clientType === "COMPANY"
                    ? "text-neutral-500 "
                    : "bg-white text-neutral-600 shadow-sm"
                }`}
              >
                <UserIcon className="h-4 w-4 text-gray-500" />
                Particulier
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            {clientForm.clientType === "COMPANY" ? (
              <Name
                name="clientName"
                setName={clientForm.setClientName}
                label="Nom du client"
                placeholder="Acme Inc."
                id="clientName"
                initialName={
                  clientForm.clientName.isDirty
                    ? clientForm.clientName.data
                    : clientForm.clientName.data || clientForm.defaultClientName
                }
                disabled={clientForm.isLoading}
                onChange={(c) => {
                  clientForm.setClientName((prev) => ({
                    ...prev,
                    ...c,
                  }));
                }}
                required={true}
                displayError={
                  clientForm.clientName.isDirty || clientForm.displayError
                }
                forceValidate={!clientForm.clientName.isDirty}
                minLength={1}
              />
            ) : (
              <div style={{ display: "flex", gap: "12px" }}>
                <Name
                  name="firstName"
                  label="Prénom"
                  placeholder="John"
                  id="firstName"
                  initialName={
                    clientForm.firstName.isDirty
                      ? clientForm.firstName.data
                      : clientForm.firstName.data || clientForm.defaultFirstName
                  }
                  disabled={clientForm.isLoading}
                  onChange={(c) => {
                    clientForm.setFirstName((prev) => ({
                      ...prev,
                      ...c,
                    }));
                  }}
                  required={true}
                  displayError={
                    clientForm.firstName.isDirty || clientForm.displayError
                  }
                  forceValidate={!clientForm.firstName.isDirty}
                  minLength={1}
                />

                <Name
                  name="lastName"
                  label="Nom de famille"
                  placeholder="Doe"
                  id="lastName"
                  initialName={
                    clientForm.lastName.isDirty
                      ? clientForm.lastName.data
                      : clientForm.lastName.data || clientForm.defaultLastName
                  }
                  disabled={clientForm.isLoading}
                  onChange={(c) => {
                    clientForm.setLastName((prev) => ({
                      ...prev,
                      ...c,
                    }));
                  }}
                  required={true}
                  displayError={
                    clientForm.lastName.isDirty || clientForm.displayError
                  }
                  forceValidate={!clientForm.lastName.isDirty}
                  minLength={1}
                />
              </div>
            )}
            <div className="flex flex-row gap-2">
              <Email
                name="email"
                label="Email"
                onChange={(m) =>
                  clientForm.setMail((prev) => ({
                    ...prev,
                    ...m,
                  }))
                }
                placeholder="client@email.com"
                required={false}
                initialEmail={
                  clientForm.mail.isDirty
                    ? clientForm.mail.data
                    : clientForm.mail.data || clientForm.defaultMail
                }
                displayError={
                  clientForm.mail.isDirty || clientForm.displayError
                }
                disabled={clientForm.isLoading}
                forceValidate={!clientForm.mail.isDirty}
              />
              <Phone
                name="phone"
                label="Téléphone"
                onChange={(p) =>
                  clientForm.setPhone((prev) => ({
                    ...prev,
                    ...p,
                  }))
                }
                required={false}
                initialPhone={
                  clientForm.phone.isDirty
                    ? clientForm.phone.data
                    : clientForm.phone.data
                    ? clientForm.phone.data
                    : clientForm.defaultPhone
                }
                displayError={
                  clientForm.phone.isDirty || clientForm.displayError
                }
                disabled={clientForm.isLoading}
                forceValidate={!clientForm.phone.isDirty}
                contextCountry={clientForm.selectedNation}
              />
            </div>
          </div>
          {clientForm.clientType === "COMPANY" ? (
            <div className="flex flex-row gap-2">
              <Identifier
                identifierType={companyIdentifier?.identifierType}
                countryOrRegion={companyIdentifier?.countryOrRegion}
                initialIdentifier={companyIdentifier?.identifier}
                disabled={clientForm.isLoading}
                onChange={(i) =>
                  clientForm.setCompanyIdentifier((prev) => {
                    return {
                      ...prev,
                      ...i,
                    };
                  })
                }
                isLoading={false}
                name={"companyNumber"}
                required={true}
                displayError={
                  clientForm.companyIdentifier.isDirty ||
                  clientForm.displayError
                }
                placeholder={"Numéro d'entreprise"}
                key={`${companyIdentifier?.identifierType}-${companyIdentifier?.countryOrRegion}`}
                forceValidate={!clientForm.companyIdentifier.isDirty}
                forceFormatting={!clientForm.companyIdentifier.isDirty}
              />
              <Identifier
                identifierType={vatNumber?.identifierType}
                countryOrRegion={nation}
                initialIdentifier={vatNumber?.identifier}
                disabled={clientForm.isLoading}
                onChange={(i) =>
                  clientForm.setVatNumber((prev) => {
                    return {
                      ...prev,
                      ...i,
                    };
                  })
                }
                isLoading={false}
                name={"vatNumber"}
                required={false}
                displayError={
                  clientForm.vatNumber.isDirty || clientForm.displayError
                }
                placeholder={"Numéro de TVA"}
                key={`${vatNumber?.identifierType}-${vatNumber?.countryOrRegion}`}
                forceValidate={!clientForm.vatNumber.isDirty}
                forceFormatting={!clientForm.vatNumber.isDirty}
              />
            </div>
          ) : null}

          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2">
              <SelectNation
                selectedNation={nation}
                setSelectedNation={clientForm.handleNationChange}
              />
              <StreetName
                initialStreetName={
                  clientForm.streetName.isDirty
                    ? clientForm.streetName.data
                    : clientForm.streetName.data || clientForm.defaultStreetName
                }
                disabled={clientForm.isLoading}
                onChange={(sn) => {
                  clientForm.setStreetName((prev) => ({
                    ...prev,
                    ...sn,
                  }));
                }}
                isLoading={false}
                name="streetName"
                required={true}
                displayError={
                  clientForm.streetName.isDirty || clientForm.displayError
                }
                forceValidate={!clientForm.streetName.isDirty}
              />
            </div>

            <div className="flex flex-row gap-2">
              <PostalCode
                country={nation}
                initialPostalCode={
                  clientForm.postalCode?.isDirty
                    ? clientForm.postalCode?.data
                    : clientForm.postalCode?.data ||
                      clientForm.defaultPostalCode
                }
                disabled={clientForm.isLoading}
                onChange={(pc) => {
                  clientForm.setPostalCode((prev) => ({
                    ...prev,
                    ...pc,
                  }));
                }}
                isLoading={false}
                name="postalCode"
                required={true}
                displayError={
                  (clientForm.postalCode.isDirty &&
                    (clientForm.postalCode.data !== "" ||
                      clientForm.isInvalidFormSubmitted)) ||
                  clientForm.displayError
                }
                forceValidate={!clientForm.postalCode.isDirty}
              />
              <City
                initialCity={
                  clientForm.city.isDirty
                    ? clientForm.city.data
                    : clientForm.city?.data || clientForm.defaultCity
                }
                disabled={clientForm.isLoading}
                onChange={(c) => {
                  clientForm.setCity((prev) => ({
                    ...prev,
                    ...c,
                  }));
                }}
                isLoading={false}
                name="city"
                required={true}
                displayError={
                  clientForm.city.isDirty || clientForm.displayError
                }
                forceValidate={!clientForm.city.isDirty}
              />
            </div>
          </div>

          <div>
            {/* {clientForm.isFormDirty ? (
            <ButtonTailwind
              variant="outlined2"
              onClick={clientForm.onCancel}
              disabled={clientForm.isLoading}
            >
              {"Annuler"}
            </ButtonTailwind>
          ) : null} */}

            <ButtonTailwind
              variant="filled"
              disabled={!clientForm.isFormDirty}
              loading={clientForm.isLoading}
              onClick={(e) => {
                e.preventDefault();
                clientForm.handleSubmit();
              }}
              type="submit"
            >
              {"Mettre à jour"}
            </ButtonTailwind>
          </div>
        </form>
      </div>
    </div>
  );
}
