import { isValidUrl } from "../url/isValidUrl";
import { PaymentMethod } from "./PaymentMethod";
import { IBAN as iban } from "ibankit";

export function validatePaymentInformation({
  paymentMethod,
  paymentData,
  isRequired = true,
}) {
  if (paymentMethod === PaymentMethod.IBAN) {
    const i = paymentData?.replace(/\s/g, "");
    if (isRequired && !i) {
      return {
        isValid: false,
        iban: paymentData,
        message: "L'IBAN est obligatoire",
      };
    }

    const isValid = iban.isValid(i);
    return {
      isValid,
      iban: paymentData,
      message: isValid ? "" : "L'IBAN est invalide",
    };
  }

  if (paymentMethod === PaymentMethod.PAYMENT_LINK) {
    const url = paymentData;

    if (isRequired && !url) {
      return {
        isValid: false,
        url: paymentData,
        message: "L'URL est obligatoire",
      };
    }

    if (!isValidUrl(url)) {
      const isUrlProtocolProvided =
        url.startsWith("http://") || url.startsWith("https://");
      return {
        isValid: false,
        url: paymentData,
        message: isUrlProtocolProvided
          ? "L'url est invalide"
          : "L'URL est invalide. Assurez-vous qu'elle commence par 'https://'",
      };
    }
    return {
      isValid: true,
      url: paymentData,
      message: "",
    };
  }

  if (paymentMethod === PaymentMethod.CHEQUE) {
    if (isRequired && !paymentData) {
      return {
        isValid: false,
        cheque: paymentData,
        message: "Le nom du bénéficiaire est obligatoire",
      };
    }

    return {
      isValid: true,
      cheque: paymentData,
      message: "",
    };
  }

  return {
    isValid: true,
    message: "",
  };
}
