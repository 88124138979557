import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import "./Pagination.css";

export function Pagination({
  totalResults,
  resultsPerPage,
  currentPage,
  setCurrentPage,
  setResultsPerPage,
  isLoading,
}) {
  const totalPages = Math.ceil(totalResults / resultsPerPage);
  const [inputPage, setInputPage] = useState(currentPage.toString());

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setInputPage(currentPage.toString());
    }
  }, [currentPage, setCurrentPage, totalPages]);

  const handlePageInputChange = (e) => {
    setInputPage(e.target.value);
  };

  const handleInputSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const pageNumber = parseInt(inputPage);
      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
      }
    }
  };

  const updateCurrentPage = (newPage) => {
    setCurrentPage(newPage);
    setInputPage(newPage.toString());
  };

  const displayedResults = () => {
    const firstResult = (currentPage - 1) * resultsPerPage + 1;
    const lastResult = Math.min(currentPage * resultsPerPage, totalResults);
    return `${firstResult} - ${lastResult} sur ${totalResults}`;
  };

  const PageButton = ({ page }) => (
    <button
      key={page}
      className={`flex items-center justify-center w-12 py-2 ${
        currentPage === page
          ? "bg-neutral-100 border-gray-300 border-r text-sm text-neutral-700"
          : "border-gray-300 border-r text-sm hover:bg-neutral-100 text-neutral-500"
      }`}
      onClick={() => updateCurrentPage(page)}
    >
      {page}
    </button>
  );

  const renderInputButton = () => {
    // Si la page actuelle est 1, 2, 3 ou la dernière, on laisse l'input vide
    if (
      currentPage === 1 ||
      currentPage === 2 ||
      currentPage === 3 ||
      currentPage === totalPages
    ) {
      return (
        <input
          type="text"
          placeholder="..."
          className="w-12 px-4 py-2 border-0 border-gray-300 border-r text-sm hover:bg-neutral-100 focus:ring-0 focus:ring-black text-center"
          value=""
          onChange={(e) => updateCurrentPage(Number(e.target.value))}
        />
      );
    } else {
      return (
        <input
          type="text"
          className="w-12 px-4 py-2 border-0 border-gray-300 border-r text-sm bg-neutral-100 hover:bg-neutral-200 focus:ring-0 focus:ring-black text-center"
          value={currentPage}
          onChange={(e) => updateCurrentPage(Number(e.target.value))}
        />
      );
    }
  };

  const renderPageButtons = () => {
    if (totalPages <= 5) {
      return [...Array(totalPages).keys()].map((i) => (
        <PageButton key={i + 1} page={i + 1} />
      ));
    } else {
      return (
        <>
          <PageButton page={1} />
          <PageButton page={2} />
          <PageButton page={3} />
          {renderInputButton()}
          <PageButton page={totalPages} />
        </>
      );
    }
  };

  return (
    <div className="flex justify-between w-full items-center">
      <ResultsPerPageButtons
        currentResultsPerPage={resultsPerPage}
        setResultsPerPage={setResultsPerPage}
      />

      <div className="flex flex-row items-center justify-center">
        <div className={`mr-4 h-4 w-4 ${isLoading ? "flex" : "hidden"}`}>
          <span className="spinner-xl w-full h-full"></span>
        </div>

        <span className="text-sm text-gray-700 dark:text-gray-300 mr-4">
          {displayedResults()}
        </span>

        <div className="flex flex-row border border-gray-300 rounded-md overflow-hidden bg-white">
          <button
            className="flex items-center justify-center w-12 py-2 border-gray-300 border-r hover:bg-neutral-100"
            onClick={() => updateCurrentPage(Math.max(currentPage - 1, 1))}
            disabled={currentPage === 1}
          >
            <ChevronLeftIcon className="h-4 w-4 text-neutral-500" />
          </button>

          {renderPageButtons()}

          <button
            className="flex items-center justify-center w-12 py-2 hover:bg-neutral-100"
            onClick={() =>
              updateCurrentPage(Math.min(currentPage + 1, totalPages))
            }
            disabled={currentPage === totalPages}
          >
            <ChevronRightIcon className="h-4 w-4 text-neutral-500" />
          </button>
        </div>
      </div>
    </div>
  );
}

function ResultsPerPageButtons({ currentResultsPerPage, setResultsPerPage }) {
  const options = [10, 25, 50, 100];

  return (
    <div className="flex items-center">
      <div className="border-r border-gray-300 rounded-md">
        {options.map((option) => (
          <button
            key={option}
            onClick={() => setResultsPerPage(option)}
            className={`px-4 py-2 text-sm border ${
              option === currentResultsPerPage
                ? "bg-neutral-100 text-neutral-700"
                : "bg-white text-neutral-500 hover:bg-neutral-100"
            } ${option === 10 ? "rounded-l-md" : ""} ${
              option === 100 ? "rounded-r-md" : ""
            } border-gray-300 border-r-0`}
          >
            {option}
          </button>
        ))}
      </div>
      <span className="ml-4 text-sm text-neutral-700">Éléments par page</span>
    </div>
  );
}

ResultsPerPageButtons.propTypes = {
  currentResultsPerPage: PropTypes.number.isRequired,
  setResultsPerPage: PropTypes.func.isRequired,
};

Pagination.propTypes = {
  totalResults: PropTypes.number.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setResultsPerPage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Pagination;
