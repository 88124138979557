import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import InputTailwind from "@/components/token/Input/inputTailwind";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import { ClientType } from "@/feature/clients/ClientType";
import { VatRegime } from "@/components/token/ButtonsList/VatRegime";

const countryVatValues = [
  { countryOrRegion: "BE", taxRates: ["0", "6", "12", "21"] },
  { countryOrRegion: "FR", taxRates: ["0", "2.1", "5.5", "10", "20"] },
  { countryOrRegion: "DE", taxRates: ["0", "7", "19"] },
  { countryOrRegion: "IT", taxRates: ["0", "4", "5", "10", "22"] },
  { countryOrRegion: "ES", taxRates: ["0", "4", "10", "21"] },
  { countryOrRegion: "NL", taxRates: ["0", "9", "21"] },
  { countryOrRegion: "PL", taxRates: ["0", "5", "8", "23"] },
  { countryOrRegion: "PT", taxRates: ["0", "6", "13", "23"] },
  { countryOrRegion: "SE", taxRates: ["0", "6", "12", "25"] },
  { countryOrRegion: "AT", taxRates: ["0", "10", "13", "20"] },
  { countryOrRegion: "DK", taxRates: ["0", "25"] },
  { countryOrRegion: "FI", taxRates: ["0", "10", "14", "24"] },
  { countryOrRegion: "GR", taxRates: ["0", "6", "13", "24"] },
  { countryOrRegion: "IE", taxRates: ["0", "9", "13.5", "23"] },
  { countryOrRegion: "CZ", taxRates: ["0", "10", "15", "21"] },
  { countryOrRegion: "HU", taxRates: ["0", "5", "18", "27"] },
  { countryOrRegion: "LU", taxRates: ["0", "3", "8", "17"] },
  { countryOrRegion: "BG", taxRates: ["0", "9", "20"] },
  { countryOrRegion: "CY", taxRates: ["0", "5", "9", "19"] },
  { countryOrRegion: "HR", taxRates: ["0", "5", "13", "25"] },
  { countryOrRegion: "EE", taxRates: ["0", "9", "20"] },
  { countryOrRegion: "RO", taxRates: ["0", "5", "9", "19"] },
  { countryOrRegion: "SI", taxRates: ["0", "5", "9.5", "22"] },
  { countryOrRegion: "LT", taxRates: ["0", "5", "9", "21"] },
  { countryOrRegion: "LV", taxRates: ["0", "5", "12", "21"] },
  { countryOrRegion: "MT", taxRates: ["0", "5", "7", "18"] },
  { countryOrRegion: "SK", taxRates: ["0", "10", "20"] },
];

export function VatButtonsList({
  sellerCountry,
  buyerCountry,
  vatRate,
  setVatRate,
  buyerType = ClientType.COMPANY,
  isB2CRevenueAbove10000 = false,
  isPhysicalGoodsSold = false,
}) {
  /* See https://stripe.com/fr-be/guides/introduction-to-eu-vat-and-european-vat-oss#:~:text=In%20these%20business%2Dto%2Dconsumer,the%20customer's%20country%20of%20residence. */
  const { applicableVATCountry } = useMemo(() => {
    if (buyerCountry === sellerCountry) {
      return {
        applicableVATCountry: sellerCountry,
        regime: VatRegime.NORMAL,
      };
    }

    if (buyerType === ClientType.COMPANY) {
      if (isPhysicalGoodsSold) {
        return {
          applicableVATCountry: buyerCountry,
          regime: VatRegime.ZERO_RATE,
        };
      } else {
        return {
          applicableVATCountry: sellerCountry,
          regime: VatRegime.AUTOLIQUIDATION,
        };
      }
    }

    if (isB2CRevenueAbove10000) {
      return {
        applicableVATCountry: buyerCountry,
        regime: VatRegime.ABOVE_THRESHOLD_B2C,
      };
    }

    return {
      applicableVATCountry: buyerCountry,
      regime: VatRegime.NORMAL,
    };
  }, [
    buyerCountry,
    buyerType,
    isB2CRevenueAbove10000,
    isPhysicalGoodsSold,
    sellerCountry,
  ]);

  const actualVatValues = countryVatValues.find(
    (c) => c.countryOrRegion === applicableVATCountry
  ).taxRates;

  const vatRateTimes100 = parseFloat(vatRate * 100);

  const { isValidVatRate, message } = useMemo(() => {
    return {
      isValidVatRate: !isNaN(vatRate) && vatRate >= 0 && vatRate <= 1,
      message: isNaN(vatRate)
        ? "Veuillez entrer un nombre valide"
        : vatRate < 0
        ? "Le taux de TVA ne peut pas être négatif"
        : "Le taux de TVA ne peut pas être supérieur à 100%",
    };
  }, [vatRate]);

  return (
    <Box className="flex items-end gap-2">
      <InputTailwind
        label="TVA"
        id="TVA Value"
        type="number"
        placeholder="Autre"
        value={vatRateTimes100}
        onChange={(e) => {
          e.preventDefault();
          setVatRate({
            taxRate: Math.abs(e.target.value / 100),
            countryOrRegion: applicableVATCountry,
          });
        }}
        error={!isValidVatRate ? message : ""}
      ></InputTailwind>

      {/* {["0", "6", "12", "21"].map((value, index) => ( */}
      {actualVatValues.map((value) => (
        <ButtonTailwind
          key={value}
          variant={
            vatRateTimes100 === parseFloat(value) ? "filled" : "outlined2"
          }
          value={value}
          onClick={(e) => {
            e.preventDefault();
            setVatRate({
              taxRate: value / 100,
              countryOrRegion: applicableVATCountry,
            });
          }}
        >
          {value}%
        </ButtonTailwind>
      ))}
    </Box>
  );
}
