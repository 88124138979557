import React, { useCallback, useMemo, useState } from "react";
import { useUser } from "@/contexts/UserContext";
import { useInvoices } from "@/hooks/useInvoices";
import { useClients } from "@/hooks/useClients";
import { usePatchInvoiceStatus } from "@/hooks/usePatchInvoiceStatus";
import CouldNotLoad from "@/components/elements/CouldNotLoad";
import { useParams } from "react-router-dom";
import "@/assets/css/Invoice.css";
import { pdf } from "@react-pdf/renderer";
import {
  CheckCircleIcon,
  XCircleIcon,
  ArrowDownTrayIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import PaymentDateModal from "@/components/token/PaymentDateModal/PaymentDateModal";
import { notify } from "@/utils/notify";
import { useImages } from "@/hooks/useImages";
import CenteredLoadingBlock from "@/components/elements/CenteredLoadingBlock";
import { useEnterprises } from "@/hooks/useEnterprises";
import { Link } from "react-router-dom";
import InvoicePreviewV2 from "@/feature/invoicePreview/InvoicePreviewV2";
import DocumentPDFV2 from "@/feature/createInvoice/DocumentPDFV2";

export default function DownloadDocumentPDFV2() {
  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;
  const enterprise = useEnterprises({
    // enterpriseId,
    queryParam: {
      emails: true,
      addresses: true,
    },
  });
  const patchInvoiceStatus = usePatchInvoiceStatus(
    ["invoices", { enterpriseId: enterpriseId }],
    undefined,
    () => {
      setDisplayPaymentDateModal(false);
      notify({
        isSuccess: true,
        message: "Statut modifié avec succès",
      });
    }
  );
  const { documentNumber } = useParams();
  const invoice = useInvoices(
    {
      enterpriseId,
      invoiceNumber: documentNumber,
      queryParam: { links: true },
    },
    { enabled: !!enterpriseId && !!documentNumber }
  );
  const invoiceContent = invoice.invoicesData?.payload?.invoice;
  const clientId = invoiceContent?.clientId;
  const clientFromInvoice = useClients({
    enterpriseId,
    clientId,
    queryParam: { addresses: true, emails: true, identifiers: true },
    reactQueryConfig: {
      enabled: !!clientId,
    },
  });
  const companyLogo = useImages({
    enterpriseId,
    purpose: "company-logo",
    reactQueryOptions: {
      refetchOnWindowFocus: false,
    },
    errorOnMissing: false,
  });

  const isDataLoading =
    invoice.isInitialLoading ||
    clientFromInvoice.isInitialLoading ||
    enterprise.isLoading;

  const [displayPaymentDateModal, setDisplayPaymentDateModal] = useState(false);

  const documentData = useMemo(() => {
    if (isDataLoading) return;

    return invoice.data.payload.invoice;
  }, [invoice.data, isDataLoading]);

  const downloadInvoice = useCallback(async () => {
    if (isDataLoading) return;
    const doc = (
      <DocumentPDFV2
        documentData={documentData}
        companyLogo={companyLogo.data?.payload}
        selectedClient={clientFromInvoice.clientsData.payload}
        enterprise={enterprise.data.payload}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${documentNumber}`;
    link.click();
    URL.revokeObjectURL(url);
  }, [
    isDataLoading,
    documentData,
    companyLogo.data,
    clientFromInvoice.clientsData,
    enterprise.data,
    documentNumber,
  ]);

  const invoiceStatus = invoiceContent?.invoiceStatus;
  const documentType = invoiceContent?.documentType;

  function changeInvoiceStatus(invoiceNumber, status, date) {
    const invoiceStatusRequest = {
      enterpriseId: enterpriseId,
      invoiceNumber: invoiceNumber,
    };
    const params = {
      invoiceStatus: status,
      ...(date ? { receiptDate: date } : {}),
    };
    patchInvoiceStatus.mutate({ invoiceStatusRequest, queryParam: params });
  }

  if (invoice.isInvoicesLoading) {
    return <CenteredLoadingBlock message="Chargement de la facture..." />;
  }

  if (invoice.isInvoicesError || !invoiceContent) {
    return (
      <CouldNotLoad
        message="Impossible de charger la facture"
        error={invoice.invoicesError}
      />
    );
  }

  if (clientFromInvoice.isClientsLoading) {
    return <CenteredLoadingBlock message="Chargement du client..." />;
  }

  if (clientFromInvoice.isClientsError) {
    return (
      <CouldNotLoad
        message="Impossible de charger le client"
        error={clientFromInvoice.clientsError}
      />
    );
  }

  if (enterprise.isLoading) {
    return <CenteredLoadingBlock message="Chargement de l'entreprise..." />;
  }

  if (enterprise.isError) {
    return (
      <CouldNotLoad
        message="Impossible de charger l'entreprise"
        error={enterprise.enterprisesError}
      />
    );
  }

  return (
    <>
      {displayPaymentDateModal && (
        <PaymentDateModal
          changeInvoiceStatus={changeInvoiceStatus}
          setDisplayPaymentDateModal={setDisplayPaymentDateModal}
          invoiceNumber={documentNumber}
          isLoading={patchInvoiceStatus.isLoading}
        />
      )}
      <div className="flex flex-row w-full max-h-screen overflow-y-scroll">
        <div className="w-full min-h-screen mx-auto flex justify-center items-center space-y-4 bg-[#F5F5F5] py-16 gap-8 h-max">
          <div className="h-max flex gap-8">
            <InvoicePreviewV2
              companyLogo={companyLogo.data?.payload}
              documentData={documentData}
              enterprise={enterprise.data.payload}
              selectedClient={clientFromInvoice.clientsData.payload}
            />

            <div className="flex flex-col py-24 gap-2">
              <div
                className={
                  "flex flex-col p-1 text-left bg-white rounded-md shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none"
                }
              >
                {documentType !== "DRAFT" && (
                  <button
                    className="text-black text-sm flex py-2 px-4 gap-4 hover:bg-neutral-100 rounded-md"
                    onClick={downloadInvoice}
                  >
                    <ArrowDownTrayIcon
                      className="h-4 w-4 text-neutral-600"
                      aria-hidden="true"
                    />
                    {"Télécharger"}
                  </button>
                )}
                {invoiceStatus === "PAID" && (
                  <button
                    className="text-black text-sm flex py-2 px-4 gap-4 hover:bg-neutral-100 rounded-md"
                    onClick={() =>
                      changeInvoiceStatus(documentNumber, "CREATED")
                    }
                  >
                    <XCircleIcon
                      className="h-4 w-4 text-neutral-600"
                      aria-hidden="true"
                    />
                    {"Facture non payée"}
                  </button>
                )}
                {invoiceStatus !== "PAID" &&
                  invoiceStatus === "CREATED" &&
                  !documentNumber.includes("D") && (
                    <button
                      className="text-black text-sm flex py-2 px-4 gap-4 hover:bg-neutral-100 rounded-md"
                      onClick={() => setDisplayPaymentDateModal(true)}
                    >
                      <CheckCircleIcon
                        className="h-4 w-4 text-neutral-600"
                        aria-hidden="true"
                      />
                      {"Facture payée"}
                    </button>
                  )}
                {documentType !== "DRAFT" && (
                  <Link
                    to={`/documents/create?preload=${documentNumber}`}
                    className="text-black text-sm flex py-2 px-4 gap-4 hover:bg-neutral-100 rounded-md"
                  >
                    <DocumentDuplicateIcon
                      className="h-4 w-4 text-neutral-600"
                      aria-hidden="true"
                    />
                    {"Dupliquer"}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
