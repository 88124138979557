import CouldNotLoad from "@/components/elements/CouldNotLoad";
import { useOneLastStep } from "@/hooks/useOneLastStep";
import { useNavigate } from "react-router-dom";
import CenteredLoadingBlock from "@/components/elements/CenteredLoadingBlock";
import { useEffect } from "react";

export default function RequireEnterpriseRoute({ children }) {
  const navigate = useNavigate();
  const { enterprise, currentInvoiceNumber, target } = useOneLastStep(true);

  useEffect(() => {
    if (target) {
      navigate(target);
    }
  }, [target, navigate]);

  if (enterprise.isError) {
    return (
      <CouldNotLoad
        message="Impossible de charger les données de l'enterprise"
        error={enterprise.error}
      />
    );
  }

  if (currentInvoiceNumber.isError) {
    return (
      <CouldNotLoad
        message="Impossible de charger les données de numérotation"
        error={currentInvoiceNumber.error}
      />
    );
  }

  return enterprise.isLoading || currentInvoiceNumber.isInitialLoading ? (
    <div className="flex w-full h-full items-center justify-center">
      <CenteredLoadingBlock />
    </div>
  ) : (
    <div className="flex flex-row w-full h-full bg-neutral-100">{children}</div>
  );
}
