// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#society-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#society-first-inputs-panel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.currencyWrap {
  flex: 1;
  display: "flex";
  flex-direction: "column";
}

.aligned-inputs {
  display: flex;
  gap: 0.625rem;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/Company.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,OAAO;EACP,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,aAAa;EACb,WAAW;AACb","sourcesContent":["#society-form {\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n}\n\n#society-first-inputs-panel {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.currencyWrap {\n  flex: 1;\n  display: \"flex\";\n  flex-direction: \"column\";\n}\n\n.aligned-inputs {\n  display: flex;\n  gap: 0.625rem;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
