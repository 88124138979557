import Presentation from "@/Pages/Presentation";
import AddClientIllustration from "@/assets/Images/AddClientIllustration.svg";

export function ArticleStart({ onClick }) {
  return (
    <div className="flex flex-row w-full h-full overflow-y-scroll">
      <Presentation
        title="Vos premiers services, pré-enregistrés."
        text="Centralisez vos prestations, et générez des documents en quelques clics à partir de votre bibliothèque."
        illustration={AddClientIllustration}
        buttonContent="Nouveau service"
        onClick={onClick}
      />
    </div>
  );
}
