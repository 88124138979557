import { PaymentMethod } from "./PaymentMethod";

export function extractPaymentInformationValue({
  id,
  paymentInformationDetails,
  title,
}) {
  const { paymentMethod } = paymentInformationDetails;
  switch (paymentMethod) {
    case PaymentMethod.CASH:
      return {
        paymentMethod,
        id,
        value: title,
      };
    case PaymentMethod.CHEQUE:
      return {
        paymentMethod,
        value: paymentInformationDetails.inFavourOf,
        id,
      };
    case PaymentMethod.IBAN:
      return {
        paymentMethod,
        value: paymentInformationDetails.iban,
        id,
      };
    case PaymentMethod.PAYMENT_LINK:
      return {
        paymentMethod,
        value: paymentInformationDetails.url,
        id,
      };
    default:
      return {
        paymentMethod,
        id,
      };
  }
}
