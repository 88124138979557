import { z } from "zod";

export function validateIdentifier(
  identifier,
  identifierType,
  countryOrRegion,
  required = true
) {
  if (!identifier) {
    if (required) {
      return {
        success: false,
        message: "Identifiant manquant",
      };
    } else {
      return {
        success: true,
        data: {
          identifier: identifier,
          identifierType: identifierType,
          countryOrRegion: countryOrRegion,
        },
      };
    }
  }

  if (countryOrRegion === "BE") {
    return validateIdentifierBE(identifier, identifierType, required);
  } else if (countryOrRegion === "FR") {
    // return {
    //   success: true,
    //   data: {
    //     identifier: identifier,
    //     identifierType: identifierType,
    //     countryOrRegion: countryOrRegion,
    //   },
    // };
    return validateIdentifierFR(identifier, identifierType, required);
  }

  return {
    success: true,
    data: {
      identifier: identifier,
      identifierType: identifierType,
      countryOrRegion: countryOrRegion,
    },
  };
}

function validateIdentifierBE(identifier, identifierType) {
  if (identifierType === "COMPANY_NUMBER") {
    return validateCompanyNumberBE(identifier);
  } else if (identifierType === "VAT_NUMBER") {
    return validateVatNumberBE(identifier);
  }
}

function validateIdentifierFR(identifier, identifierType) {
  if (identifierType === "SIRET") {
    return validateSIRET(identifier);
  } else if (identifierType === "SIREN") {
    return validateSIREN(identifier);
  } else if (identifierType === "VAT_NUMBER") {
    return validateVatNumberFR(identifier);
  }
}

function validateCompanyNumberBE(identifier) {
  const companyNumberSchema = z.string().regex(/^[0-1]{1}[0-9]{9}$/);

  const feedback = companyNumberSchema.safeParse(identifier.replace(/\./g, ""));
  if (!feedback.success) {
    return {
      success: false,
      message:
        "Numéro d'entreprise invalide: doit commencer par 0 ou 1 et être composé d'un total de 9 chiffres",
    };
  }

  const companyNumber = feedback.data;
  const companyNumberCheckDigit = parseInt(companyNumber.substring(8, 10));
  const companyNumberBase = parseInt(companyNumber.substring(0, 8));
  const companyNumberCheck = 97 - (companyNumberBase % 97);
  if (companyNumberCheckDigit !== companyNumberCheck) {
    return {
      success: false,
      message: `Check digit incorrect: reçu ${companyNumberCheckDigit}, attendu ${companyNumberCheck}`,
    };
  }

  return {
    success: true,
    data: {
      identifier: identifier,
      identifierType: "COMPANY_NUMBER",
      countryOrRegion: "BE",
    },
  };
}

function validateVatNumberBE(identifier) {
  const vatNumberSchema = z.string().regex(/^BE[0-9]{10}$/);

  const rationalizedIdentifier = identifier.replace(/\.| /g, "");
  const feedback = vatNumberSchema.safeParse(rationalizedIdentifier);
  if (!feedback.success) {
    const length =
      rationalizedIdentifier.length > 2 ? rationalizedIdentifier.length - 2 : 0;
    return {
      success: false,
      message: `Numéro de TVA invalide. Assurez-vous que le numéro commence par "BE" et comporte 10 chiffres. Longueur actuelle: ${length} chiffre${
        length > 1 ? "s" : ""
      }.`,
    };
  }

  const vatNumber = feedback.data.substring(2, 12);
  const feedbackCompanyNumber = validateCompanyNumberBE(vatNumber);

  if (!feedbackCompanyNumber.success) {
    return {
      success: false,
      message: feedbackCompanyNumber.message,
    };
  }

  return {
    success: true,
    data: {
      identifier: identifier,
      identifierType: "VAT_NUMBER",
      countryOrRegion: "BE",
    },
  };
}

function validateSIRET(identifier) {
  const siretSchema = z.string().regex(/^[0-9]{14}$/);

  const feedback = siretSchema.safeParse(identifier.replace(/\.| /g, ""));
  if (!feedback.success) {
    return {
      success: false,
      message: "SIRET invalide",
    };
  }

  const siren = identifier.substring(0, 9);
  const sirenFeedback = validateSIREN(siren);
  if (!sirenFeedback.success) {
    return {
      success: false,
      message: `Le SIREN (${siren}) du SIRET n'est pas valide. ${sirenFeedback.message}`,
    };
  }

  /* The postal service in France has a different rule */
  if (identifier === LAPOSTE_SIRET_HQ) {
    return {
      success: true,
      data: {
        identifier: identifier,
        identifierType: "SIRET",
        countryOrRegion: "FR",
      },
    };
  } else if (identifier.startsWith(LAPOSTE_SIREN)) {
    const siret = feedback.data;
    const siretSum = luhn(siret.toString(), true);
    if (siretSum % 5 !== 0) {
      return {
        success: false,
        message: `Ce SIRET n'est pas valide pour La Poste`,
      };
    }
  }

  return {
    success: true,
    data: {
      identifier: identifier,
      identifierType: "SIRET",
      countryOrRegion: "FR",
    },
  };
}

const LAPOSTE_SIREN = "356000000";
const LAPOSTE_SIRET_HQ = "35600000000048";

function validateSIREN(identifier) {
  const sirenSchema = z.string().regex(/^[0-9]{9}$/);

  const feedback = sirenSchema.safeParse(identifier.replace(/\.| /g, ""));
  if (!feedback.success) {
    return {
      success: false,
      message: "SIREN invalide (9 chiffres attendus)",
    };
  }

  /* The postal service in France has a different rule */
  if (identifier === LAPOSTE_SIREN) {
    return {
      success: true,
      data: {
        identifier: identifier,
        identifierType: "SIREN",
        countryOrRegion: "FR",
      },
    };
  }

  const siren = feedback.data;
  const sirenCheckDigit = parseInt(siren.substring(8, 9));
  const sirenBase = parseInt(siren.substring(0, 8));
  const sirenCheck = luhn(sirenBase.toString(), false);
  if (sirenCheckDigit !== sirenCheck) {
    return {
      success: false,
      message: `Check digit incorrect: reçu ${sirenCheckDigit}, attendu ${sirenCheck}`,
    };
  }

  return {
    success: true,
    data: {
      identifier: identifier,
      identifierType: "SIREN",
      countryOrRegion: "FR",
    },
  };
}

export function vatCheckDigitFR(siren) {
  return (12 + 3 * (parseInt(siren, 10) % 97)) % 97;
}
function validateVatNumberFR(identifier) {
  const vatNumberSchema = z.string().regex(/^FR[0-9]{11}$/);

  const rationalizedIdentifier = identifier.replace(/\.| /g, "");
  const feedback = vatNumberSchema.safeParse(rationalizedIdentifier);
  if (!feedback.success) {
    const length =
      rationalizedIdentifier.length > 2 ? rationalizedIdentifier.length - 2 : 0;
    return {
      success: false,
      message: `Numéro de TVA invalide. Assurez-vous que le numéro commence par "FR" et comporte 11 chiffres. Longueur actuelle: ${length} chiffre${
        length > 1 ? "s" : ""
      }.`,
    };
  }

  const vatNumber = feedback.data;
  const siren = vatNumber.substring(4, 13);
  const sirenFeedback = validateSIREN(siren);
  if (!sirenFeedback.success) {
    return {
      success: false,
      message: `Le SIREN (${siren}) du numéro de TVA n'est pas valide. ${sirenFeedback.message}`,
    };
  }

  const checkDigit = parseInt(vatNumber.substring(2, 4));
  const computedCheckDigit = vatCheckDigitFR(siren);
  if (checkDigit !== computedCheckDigit) {
    return {
      success: false,
      message: `Check digit incorrect: reçu ${checkDigit}, attendu ${computedCheckDigit}`,
    };
  }

  return {
    success: true,
    data: {
      identifier: identifier,
      identifierType: "VAT_NUMBER",
      countryOrRegion: "FR",
    },
  };
}

function luhn(number, returnSum) {
  let len = number.length;
  let shouldDouble = true;
  let sum = 0;

  for (let i = len - 1; i >= 0; i--) {
    let d = parseInt(number.charAt(i));
    if (shouldDouble) {
      d *= 2;
    }
    if (d > 9) {
      d -= 9;
    }
    shouldDouble = !shouldDouble;
    sum += d;
  }

  if (returnSum) {
    return sum;
  }

  return (10 - (sum % 10)) % 10;
}
