import { useLogout } from "./useLogout";
import { useOryLogout } from "./useOryLogout";

export function useFullLogout(
  onSuccess,
  disableToast = false,
  onSettled = () => {}
) {
  const logout = useOryLogout(
    ["ory-session"],
    () => {
      onSuccess && onSuccess();
    },
    disableToast,
    onSettled
  );
  const lidapLogout = useLogout(
    undefined,
    { retry: false },
    () => {
      logout.mutate();
    },
    disableToast
  );

  return {
    ...lidapLogout,
    isLoading: lidapLogout.isLoading || logout.isLoading,
    isError: lidapLogout.isError || logout.isError,
    error: lidapLogout.error || logout.error,
    isSucces: lidapLogout.isSuccess && logout.isSuccess,
  };
}
