import React, { useEffect, useState } from "react";
import ButtonTailwind from "../button/buttonTailwind";
import { useUser } from "@/contexts/UserContext";
import { useOryLogin, useOryLoginFlow } from "@/hooks/useOryLogin";
import { Password } from "@/components/elements/User/Password";
import Modal from "@/components/elements/Modal/Modal";

export default function OryConfirmAuthModal({
  onSuccessfulAuth,
  setIsOryConfirmAuthModalDisplayed,
}) {
  const userContext = useUser();
  const email = userContext.state.user.email;
  const [flow, setFlow] = useState();
  const emptySimpleField = { success: false, data: "", isDirty: false };
  const [password, setPassword] = useState(emptySimpleField);

  const loginFlow = useOryLoginFlow((data) => {
    setFlow(data);
  });

  const login = useOryLogin(["ory-session"], undefined, () => {
    onSuccessfulAuth();
  });

  useEffect(() => {
    if (!loginFlow.isSuccess && !loginFlow.isLoading && !loginFlow.isError) {
      loginFlow.mutate();
    }
  }, [loginFlow]);

  const logInData = {
    loginFlow: flow?.loginFlow,
    csrf_token: flow?.csrf_token,
    email: email,
    password: password.data,
  };

  const isValid = (() => {
    if (password.success && email) {
      return true;
    } else {
      return false;
    }
  })();

  function handleSubmit(event) {
    event.preventDefault();
    if (!isValid) {
      return;
    }
    login.mutate(logInData);
  }

  return (
    <Modal>
      <div className="flex flex-col gap-4">
        <h1 className="text-3xl">Veuillez confirmer votre identité</h1>
        <p>
          Pour des raisons de sécurité, vous devez confirmer votre identité. Si
          vous avez oublié votre données d&apos;authentification, veuillez
          utiliser le flux de <a href="/recovery">récupération de compte</a>.
        </p>
      </div>
      <div className="flex gap-4">
        <Password
          name="password"
          label="Mot de passe"
          id="passIn"
          placeholder="Mot de passe"
          autoComplete="current-password"
          required={true}
          type="password"
          initialPassword={password.data}
          icon={false}
          onChange={setPassword}
          displayError={login.isError || isValid}
          bypassRules={true}
        />
        <ButtonTailwind
          variant="outlined"
          onClick={() => setIsOryConfirmAuthModalDisplayed(false)}
        >
          Retour
        </ButtonTailwind>
        <ButtonTailwind variant="filled" onClick={handleSubmit}>
          Confirmer l&apos;authentification
        </ButtonTailwind>
      </div>
    </Modal>
  );
}
