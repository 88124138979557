import { useMutation, useQueryClient } from "@tanstack/react-query";
import Lidapi from "@/utils/lidapi";
import { stringify } from "@/utils/stringify";

export function usePatchInvoiceStatus(
  queryKeyToInvalidate,
  multipleQueryKeysToInvalidate,
  onSuccessCallback
) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async ({ invoiceStatusRequest, queryParam }) => {
      const queryString = queryParam ? `?${stringify(queryParam)}` : "";

      return await api.updateInvoiceStatus(
        invoiceStatusRequest.enterpriseId,
        invoiceStatusRequest.invoiceNumber,
        queryString
      );
    },
    onSuccess: () => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (
        multipleQueryKeysToInvalidate &&
        multipleQueryKeysToInvalidate.length > 0
      ) {
        multipleQueryKeysToInvalidate.forEach((queryKey) => {
          queryClient.invalidateQueries({
            queryKey: [...queryKey],
          });
        });
      }
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
  });

  return action;
}
