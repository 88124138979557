import { useUser } from "@/contexts/UserContext";
import { useCreateUpdateClient } from "@/hooks/useCreateUpdateClient";
import { CRUDL } from "@/utils/crudl";
import { useNavigate, useParams } from "react-router-dom";
import ClientData from "@/feature/clients/manageClient/ClientData";
import { useClients } from "@/hooks/useClients";
import LoadingBlock from "@/components/elements/LoadingBlock";
import CouldNotLoad from "@/components/elements/CouldNotLoad";
import { notify } from "@/utils/notify";
import Client360 from "@/feature/clients/client360/Client360";
import { useDefaultClient } from "@/hooks/forms/defaults/useDefaultClient";
import CenteredLoadingBlock from "@/components/elements/CenteredLoadingBlock";

export function ManageClient({ context, onSuccess, onCancel }) {
  const userContext = useUser();
  const navigate = useNavigate();
  const { defaultNation, isDefaultClientLoading } = useDefaultClient();

  /* MUTATE */
  const manageClient = useCreateUpdateClient(
    ["clients"],
    onSuccess
      ? onSuccess
      : (data) => {
          notify({
            isSuccess: true,
            message: `Client ${
              context === CRUDL.CREATE ? "créé" : "mis à jour"
            } avec succès`,
          });
          if (context !== CRUDL.UPDATE) {
            data?.payload?.clientId ?? data?.payload?.id
              ? navigate(`/clients/${data.payload.clientId ?? data.payload.id}`)
              : navigate("/home");
          }
        }
  );
  const enterpriseId = userContext.state.user.enterpriseId;

  /* READ - UPDATE - DELETE */
  const { clientId } = useParams();
  const client = useClients({
    clientId,
    enterpriseId,
    queryParam: {
      addresses: true,
      identifiers: true,
      emails: true,
      phones: true,
      paymentInformation: true,
    },
    reactQueryConfig: {
      enabled: !!clientId && !!enterpriseId,
    },
  });

  if (context === CRUDL.CREATE) {
    return isDefaultClientLoading ? (
      <CenteredLoadingBlock />
    ) : (
      <ClientData
        key={context}
        onSubmit={(data) => manageClient.mutate(data)}
        isLoading={manageClient.isLoading || isDefaultClientLoading}
        isError={manageClient.isError}
        context={context}
        data={{
          enterpriseId,
          country: defaultNation,
        }}
        onCancel={onCancel ? onCancel : () => navigate(-1)}
      />
    );
  }

  if (client.isClientsLoading) return <LoadingBlock />;
  if (client.isClientsError)
    return (
      <CouldNotLoad
        message="Impossible de charger les données clients"
        error={client.clientsError}
      />
    );

  if (context === CRUDL.READ) {
    return (
      <ClientData
        key={context + clientId}
        enterpriseId={enterpriseId}
        onSubmit={() => navigate(`/clients/${clientId}/edit`)}
        isLoading={client.isClientsLoading}
        isError={client.isClientsError}
        context={context}
        data={client.clientsData?.payload}
        onCancel={onCancel ? onCancel : () => navigate("/clients")}
      />
    );
  }

  if (context === CRUDL.UPDATE) {
    return (
      <Client360
        key={context + clientId}
        enterpriseId={enterpriseId}
        onSubmit={(data) => manageClient.mutate(data)}
        isLoading={manageClient.isLoading}
        isError={manageClient.isError}
        context={context}
        data={client.clientsData?.payload}
        onCancel={
          onCancel
            ? () => {
                onCancel();
                client.refetchClients();
              }
            : () => {
                client.refetchClients();
                navigate(-1);
              }
        }
      />
    );
  }
}
