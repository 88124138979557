import React, { useState } from "react";
import { Typography } from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import LoadingBlock from "@/components/elements/LoadingBlock";
import CouldNotLoad from "@/components/elements/CouldNotLoad";
import InputTailwind from "@/components/token/Input/inputTailwind";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import { notify } from "@/utils/notify";
import { manageLidapResponse } from "@/utils/lidapi";

function SearchableListWithMutation({
  fetchUrl,
  listName,
  payloadArrayName,
  filterField,
  onClick,
  content,
  queryKey,
  queryKeyToInvalidate,
}) {
  const [filter, setFilter] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const queryClient = useQueryClient();

  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const response = await fetch(`${fetchUrl}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      return manageLidapResponse(response, "Could not load whitelist");
    },
  });

  const action = useMutation({
    mutationFn: (item) => onClick(item),
    onSuccess: () => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      notify({
        isSuccess: true,
        message: `Opération effectuée avec succès`,
      });
    },
  });

  const coreDisplay = (content, numberOfResults) => {
    return (
      <div className="flex flex-col items-start overflow-hidden px-6 py-12 gap-8 w-full h-full ring-1 ring-inset ring-gray-300 rounded-xl shadow-sm">
        <div className="flex w-full flex-col gap-4">
          <p className="text-4xl font-bold">
            {listName}
            {" ("}
            {numberOfResults || "..."}
            {")"}
          </p>
          <InputTailwind
            label="Adresse email"
            type="text"
            placeholder="Filtrer par nom"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          ></InputTailwind>
        </div>
        <div className="h-full w-full overflow-y-auto">
          <table style={{ color: "var(--shades-900)", width: "100%" }}>
            <tbody>{content}</tbody>
          </table>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return coreDisplay(<LoadingBlock message={<td>Loading...</td>} tag="tr" />);
  }

  if (isError) {
    return coreDisplay(
      <CouldNotLoad
        message={<td>Could not load: + {error.message}</td>}
        tag="tr"
        error={error}
      />
    );
  }

  const items = data.payload[payloadArrayName];
  const filteredItems = items.filter((item) =>
    item[filterField]?.toLowerCase().includes(filter.toLowerCase())
  );

  return coreDisplay(
    filteredItems.map((item) => (
      <tr
        key={item.id}
        className="flex justify-between w-full px-4 py-4 items-center border-b border-gray-300"
      >
        <td>{item[filterField]}</td>
        <td>
          <ButtonTailwind
            onClick={() => {
              setSelectedItem(item);
              action.mutate(item);
            }}
            loading={action.isLoading && selectedItem?.id === item.id}
            disabled={action.isLoading && selectedItem?.id !== item.id}
          >
            {" "}
            {content}{" "}
          </ButtonTailwind>
        </td>
      </tr>
    )),
    items?.length || 0
  );
}

export default SearchableListWithMutation;
