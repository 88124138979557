export default class FetchError extends Error {
  constructor(
    message,
    statusCode,
    code,
    traceId,
    url,
    disableToast = false,
    request = null
  ) {
    super(message);
    this.name = "FetchError";
    this.statusCode = statusCode;
    this.code = code;
    this.traceId = traceId;
    this.url = url;
    this.disableToast = disableToast;
    this.request = request;
  }
}
