import React from "react";
import { Datepicker } from "flowbite-react";
import { datePickerTheme } from "@/data/themes.js";
import {
  convertStringToDate,
  transformDate,
} from "@/utils/date/dateTransformers";
import { LidapInfo } from "@/components/token/tooltip/LidapInfo";

function LidapDatePicker({
  dateLabel,
  minStartDate,
  maxEndDate,
  startDate,
  endDate,
  setDate,
  isStartDate,
  tooltipText,
}) {
  return (
    <div className="w-full">
      <div className="flex items-center">
        {/* gap-2 */}
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {dateLabel}
        </label>
        {tooltipText ? <LidapInfo toolTipText={tooltipText} /> : null}
      </div>
      <Datepicker
        placeholder="Sélectionner la date"
        dateformat="dd/MM/yyyy"
        selected={convertStringToDate(isStartDate ? startDate : endDate)}
        onSelectedDateChanged={(date) => setDate(transformDate(date))}
        startDate={convertStringToDate(startDate)}
        language="fr"
        weekStart={1}
        minDate={convertStringToDate(minStartDate)}
        maxDate={convertStringToDate(maxEndDate)}
        theme={datePickerTheme}
        className="dark:bg-black focus:ring-gray-700"
        defaultDate={convertStringToDate(isStartDate ? startDate : endDate)}
        labelTodayButton="Aujourd'hui"
        labelClearButton="Annuler"
      />
    </div>
  );
}

export function MyDateRangePickerV2({
  minStartDate,
  startDate,
  setStartDate,
  startDateLabel,
  isStartDateShown = true,
  tooltipStartDate = "",
  maxEndDate,
  endDate,
  setEndDate,
  endDateLabel,
  isEndDateShown = true,
  tooltipEndDate = "",
}) {
  return (
    <div className="flex items-center w-full gap-4 max-w-full">
      {isStartDateShown ? (
        <div className="w-full max-w-md">
          <LidapDatePicker
            minStartDate={minStartDate}
            maxEndDate={endDate}
            setDate={setStartDate}
            dateLabel={startDateLabel}
            startDate={startDate}
            endDate={endDate}
            isStartDate={true}
            tooltipText={tooltipStartDate}
          />
        </div>
      ) : null}

      {isEndDateShown ? (
        <div className="w-full max-w-md">
          <LidapDatePicker
            minStartDate={startDate}
            maxEndDate={maxEndDate}
            setDate={setEndDate}
            dateLabel={endDateLabel}
            startDate={startDate}
            endDate={endDate}
            isStartDate={false}
            tooltipText={tooltipEndDate}
          />
        </div>
      ) : null}
    </div>
  );
}
