import { ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";
import { LidapToolTip } from "../tooltip/LidapToolTip";

export function ArrowDownOnSquareIconWithLoading({
  loading,
  handleAction,
  value,
  className = "h-4 w-4 text-gray-400 stroke-2 cursor-pointer",
  spinnerClassName = "h-3 w-3 border-2 border-t-2 border-t-neutral-600 border-neutral-200 rounded-full animate-spin",
  toolTipText = "Sauver",
  disabled = false,
}) {
  return loading ? (
    <span className={spinnerClassName}></span>
  ) : (
    <div className="relative group">
      <ArrowDownOnSquareIcon
        className={className}
        onClick={() => {
          if (disabled) return;
          handleAction(value);
        }}
        disabled={disabled}
      />
      <LidapToolTip toolTipText={toolTipText} />
    </div>
  );
}
