import React from "react";
import ClientOverview from "./ClientOverviewV3";
import { useNavigate } from "react-router-dom";
import { CRUDL } from "@/utils/crudl";

export default function ClientPanel() {
  const navigate = useNavigate();

  function handleClientAction(action, data) {
    switch (action) {
      case CRUDL.CREATE:
        navigate("/clients/create");
        break;
      case CRUDL.UPDATE:
        navigate(`/clients/${data.id}`);
        break;
      case CRUDL.DELETE:
        navigate(`/clients/${data.id}/delete`);
        break;
      case CRUDL.READ:
        navigate(`/clients/${data.id}`);
        break;
      default:
        break;
    }
  }

  return (
    <div className="flex flex-row justify-start w-full h-full max-h-screen overflow-auto">
      {/* <Menu origin="Client"></Menu> */}

      <div className="flex flex-col justify-center items-center w-full">
        <ClientOverview clientAction={handleClientAction} />
      </div>
    </div>
  );
}
