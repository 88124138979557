import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputTailwind from "../../token/Input/inputTailwind";
import ButtonTailwind from "../../../components/token/button/buttonTailwind";
import styles from "./ButtonsList.module.css";

export default function ButtonsList(props) {
  const [variants, setVariants] = useState(Array(6).fill("outlined2"));

  const [vatInputValue, setVatInputValue] = useState(
    props.isVATFromButton
      ? (props.serviceVAT * 100).toFixed(2)
      : props.serviceVAT * 100
  );

  function changeVATValue(e, index) {
    props.setServiceVAT(e.target.value / 100);

    if (index === 0) {
      setVariants(Array(6).fill("outlined2")); // Reset all variants to 'outlined2' when the input is clicked
    } else {
      const newVariants = Array(6).fill("outlined2"); // Reset all variants to 'outlined2'
      newVariants[index] =
        variants[index] === "filled" ? "outlined2" : "filled"; // Toggle the variant of the clicked button
      setVariants(newVariants);
    }
  }

  // useEffect(() => {
  const newVariants = Array(6).fill("outlined2"); // Reset all variants to 'outlined2'
  switch (props.serviceVAT) {
    case 0:
      break;
    case 0.06:
      newVariants[2] = "filled";
      break;
    case 0.12:
      newVariants[3] = "filled";
      break;
    case 0.21:
      newVariants[4] = "filled";
      break;
    default:
      newVariants[0] = "filled";
      break;
  }
  // setVariants(newVariants);  // Update the variants state with the new array
  // }, [props.serviceVAT]);  // Depend on props.serviceVAT to re-run this effect when it changes

  const vatValues = [
    { region: "BE", value: ["0", "6", "12", "21"] },
    { region: "FR", value: ["0", "2.1", "5.5", "10", "20"] },
  ];

  const actualVatValues =
    vatValues?.find((vat) => vat.region == props.vatRegion)?.value ||
    vatValues?.find((vat) => vat.region == "BE")?.value;

  return (
    <Box className={styles.wrapVatButtons}>
      <InputTailwind
        label="TVA"
        id="TVA Value"
        type="number"
        placeholder="Autre"
        value={
          props.isVATFromButton
            ? (props.serviceVAT * 100).toFixed(2)
            : vatInputValue
        }
        onClick={
          ((e) => changeVATValue(e, 0),
          props?.setIsVATFromButton && props?.setIsVATFromButton(false))
        }
        onChange={(e) => {
          changeVATValue(e, 0), setVatInputValue(e.target.value);
        }}
        error={props.error}
      ></InputTailwind>

      {/* {["0", "6", "12", "21"].map((value, index) => ( */}
      {actualVatValues.map((value, index) => (
        <ButtonTailwind
          key={value}
          variant={
            Number(value) / 100 === props.serviceVAT
              ? "filled"
              : variants[index + 1]
          } // Offset by 1 to account for the input
          value={value}
          onClick={(e) => changeVATValue(e, index + 1)} // Offset by 1 to account for the input
        >
          {value}%
        </ButtonTailwind>
      ))}
    </Box>
  );
}
