import { DocumentAdditionalDataSubType } from "@/feature/document/DocumentAdditionalDataSubType";
import { DocumentAdditionalDataType } from "@/feature/document/DocumentAdditionalDataType";
import { extractDocumentAdditionalData } from "@/feature/document/helpers/extractDocumentAdditionalData";

export function DocumentNumberTitle({
  invoiceNumber,
  documentUserTitle,
  documentAdditionalData,
}) {
  const documentTitle =
    documentUserTitle ||
    extractDocumentAdditionalData({
      additionalDataArray: documentAdditionalData,
      additionalDataType: DocumentAdditionalDataType.DOCUMENT_TITLE,
      additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
    });

  return (
    <div className="flex flex-col items-left">
      {documentTitle ? (
        <div className="text-sm font-bold truncate">{documentTitle}</div>
      ) : null}
      {invoiceNumber ? (
        <div className="text-sm text-gray-500">{invoiceNumber}</div>
      ) : null}
    </div>
  );
}
