import SkeletonElement from "@/components/token/loading/SkeletonElement";
import { countriesName } from "@/data/countryCodeCorrespondance";
import {
  useCompanyIdentifiers,
  useCompanyNameAndAddress,
} from "./useCompanyPreview";

export function CompanyPreview({
  country,
  addresses,
  additionalData,
  identifiers,
  type = "ISSUER",
}) {
  const { address, name } = useCompanyNameAndAddress({
    additionalData,
    addresses,
    type,
  });

  const { enterpriseNumber, enterpriseNumberLabel, vatNumber } =
    useCompanyIdentifiers({
      additionalData,
      country,
      type,
      identifiers,
    });

  if (!address) {
    return (
      <div className="space-y-2 mt-1">
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
      </div>
    );
  }

  return (
    <>
      {
        <div key={address.id} className="flex flex-col">
          <span>{name}</span>

          <span>
            {address?.streetName} {address?.streetNumber}
          </span>

          <span>
            {address?.postalCode}
            {address?.postalCode && address?.city ? "," : ""}
            {address?.city}
          </span>

          <span>{countriesName[address?.country]}</span>
          {enterpriseNumber ? (
            <span>
              {enterpriseNumberLabel} {enterpriseNumber}
            </span>
          ) : null}
          {vatNumber ? (
            <span>
              {"N° TVA :"} {vatNumber}
            </span>
          ) : null}
        </div>
      }
    </>
  );
}
