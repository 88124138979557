import { useSearchReferential } from "@/hooks/useReferential";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import SearchInput from "@/components/token/searchInput/searchInput";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { trimAllDots, trimAllSpaces } from "@/utils/string";
import { identifierLabels } from "@/components/elements/stakeholder/identifier/identifierLabel";

export function EnterpriseReferentialSearch({
  onSelect = () => {},
  country,
  onWaive = () => {},
}) {
  const [inputValue, setInputValue] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [debouncedValue] = useDebounce(inputValue, 300);
  const searchValue = (() => {
    if (!debouncedValue) return debouncedValue;

    const rationalizedValue = trimAllSpaces(trimAllDots(debouncedValue));

    if (!country) return rationalizedValue;

    if (country === "FR") {
      /* VAT number */
      if (rationalizedValue.slice(0, 2) === "FR") {
        return rationalizedValue.slice(4);
      }

      /* SIREN */
      if (rationalizedValue.length === 9) {
        return rationalizedValue;
      }

      /* SIRET */
      if (rationalizedValue.length === 14) {
        return rationalizedValue.slice(0, 9);
      }
    }

    if (country === "BE") {
      /* VAT number */
      if (rationalizedValue.slice(0, 2) === "BE") {
        return rationalizedValue.slice(4);
      }

      /* Enterprise number */
      if (rationalizedValue.length === 10) {
        return rationalizedValue;
      }
    }
  })();

  const placeholder = (() => {
    if (!country) return "Numéro d'entreprise";

    if (country === "FR") {
      return "SIREN, SIRET ou numéro de TVA";
    }

    if (country === "BE") {
      return "Numéro d'entreprise ou de TVA";
    }
  })();

  const queryParam = country ? { country } : null;
  const referential = useSearchReferential({
    identifier: searchValue,
    queryParam: queryParam,
    reactQueryConfig: {
      enabled: !!searchValue,
      keepPreviousData: true,
    },
    disableToast: false,
  });
  const data = referential.data;
  const candidates = data?.payload ? data.payload.referentials : [];

  return (
    <div className="relative w-full max-w-md mx-auto">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row items-center gap-2">
            <label className={`block text-sm font-medium leading-6`}>
              Recherche intelligente
            </label>
            {referential.isFetching ? (
              <span className="spinner"></span>
            ) : (
              <span></span>
            )}
          </div>
          <button className={`  cursor-pointer`} onClick={onWaive}>
            <span className="block text-sm leading-6 hover:underline text-neutral-500">
              Encodage manuel
            </span>
          </button>
        </div>

        <div className="relative mt-1 rounded-md">
          <SearchInput
            type="text"
            label="Numéro d'entreprise"
            placeholder={placeholder}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
      </div>

      {debouncedValue && data && !selectedItem && (
        <ul className=" w-full bg-white border border-gray-300 rounded-lg mt-1 max-h-60 overflow-auto z-10 shadow-sm">
          {candidates.map((item) => (
            <li
              key={item.country + item.identifierType + item.identifier}
              className="py-2 px-4 bg-neutral-50 hover:bg-neutral-100 cursor-pointer flex flex-row w-full space-between justify-between items-center"
              onClick={() => {
                onSelect(item);
                setSelectedItem(item);
                setInputValue("");
              }}
            >
              <div>
                <div className="font-medium">{item.referential.name}</div>

                <div className="flex flex-row gap-2 items-center">
                  <div className="text-sm text-neutral-800">
                    {identifierLabels[item.country + item.identifierType]}:{" "}
                    <span className="font-medium">{item.identifier}</span>
                  </div>
                  <div className="text-xs text-gray-500">
                    {item.country} -{" "}
                    {item.referential.addresses.find((e) => e.isMain)?.city}
                  </div>
                </div>
              </div>

              <div>
                <ChevronRightIcon className="h-6 w-6 text-gray-500" />
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
