import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { LidapToolTip } from "./LidapToolTip";
import { useState } from "react";

export function LidapInfo({
  toolTipText,
  infoCircleClassName = "h-3 w-3 text-gray-400 stroke-2",
  tooltipBoxAdditionalClassName = "",
}) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative w-6 h-6 flex justify-center items-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Wrap icon in a span to control hover state */}
      <span className="inline-flex cursor-pointer">
        <InformationCircleIcon className={infoCircleClassName} />
      </span>

      <LidapToolTip
        toolTipText={toolTipText}
        tooltipBoxAdditionalClassName={tooltipBoxAdditionalClassName}
        isGrouped={false}
        isVisible={isHovered}
      />
    </div>
  );
}
