import { unitPriceReference } from "@/components/elements/Article/Units";
import { useUser } from "@/contexts/UserContext";
import { CRUDL } from "@/utils/crudl";
import { notify } from "@/utils/notify";
import { useMemo, useState } from "react";

export function useFormArticles({ onSubmit, context, data }) {
  /* Context */
  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;
  const country = userContext.state.user.country;

  /* Default values */
  const defaultValues = useMemo(() => {
    if (context === CRUDL.UPDATE) {
      return {
        title: data.title,
        description: data.description,
        serviceQuantity: data.quantity.amount,
        quantityUnit: unitPriceReference.find(
          (unit) => unit.unitValue === data.quantity.unit
        ).label,
        servicePrice: data.unitPrice.amount,
        serviceVAT: data.vatRate.taxRate,
        currency: data.unitPrice.currency,
      };
    } else {
      return {
        title: "",
        description: "",
        serviceQuantity: 1,
        quantityUnit: "Forfait",
        servicePrice: "",
        serviceVAT: 0,
        currency: "EUR",
      };
    }
  }, [
    context,
    data?.description,
    data?.quantity.amount,
    data?.quantity.unit,
    data?.title,
    data?.unitPrice.amount,
    data?.unitPrice.currency,
    data?.vatRate.taxRate,
  ]);

  /* Hooks */
  const units = useMemo(() => {
    return unitPriceReference.map((unitPrice) => unitPrice.label);
  }, []);

  /* State */
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const [serviceQuantity, setServiceQuantity] = useState({
    success: null,
    data: 1,
    isDirty: false,
  });
  const [quantityUnit, setQuantityUnit] = useState("");
  const [servicePrice, setServicePrice] = useState({
    success: null,
    data: 1,
    isDirty: false,
  });
  const [serviceVAT, setServiceVAT] = useState({
    success: null,
    data: 0,
    isDirty: false,
  });
  const [currency, setCurrency] = useState("");

  /* Derived */
  const isQuantityError =
    serviceQuantity <= 0 ? "La quantité doit être supérieure à 0" : "";
  const isPriceError =
    servicePrice < 0 ? "Le prix doit être supérieur ou égal à 0" : "";
  const VATError =
    serviceVAT < 0 ? "Le taux de TVA doit être supérieur ou égal à 0" : "";

  /* Hooks */
  const isFormValid = useMemo(() => {
    return (
      title.length > 0 &&
      !titleError &&
      description.length > 0 &&
      !descriptionError &&
      serviceQuantity.data > 0 &&
      servicePrice.data >= 0 &&
      serviceVAT.data >= 0
    );
  }, [
    title,
    description,
    serviceQuantity,
    servicePrice,
    serviceVAT,
    titleError,
    descriptionError,
  ]);

  const isFormDirty = useMemo(() => {
    return (
      title !== defaultValues.title ||
      description !== defaultValues.description ||
      serviceQuantity !== defaultValues.serviceQuantity ||
      quantityUnit !== defaultValues.quantityUnit ||
      servicePrice !== defaultValues.servicePrice ||
      serviceVAT !== defaultValues.serviceVAT ||
      currency !== defaultValues.currency
    );
  }, [
    title,
    description,
    serviceQuantity,
    quantityUnit,
    servicePrice,
    serviceVAT,
    currency,
    defaultValues,
  ]);

  /* Handlers */
  function createArticle() {
    if (!isFormValid) {
      notify({
        isSuccess: false,
        message: "Le formulaire contient des erreurs",
      });
      return;
    }
    const qtyUnit = quantityUnit || defaultValues.quantityUnit;
    const articlePayload = {
      title: title || defaultValues.title,
      description: description || defaultValues.description,
      quantity: {
        amount: serviceQuantity.data || defaultValues.serviceQuantity,
        unit: unitPriceReference.find((unit) => unit.label === qtyUnit)
          .unitValue,
      },
      unitPrice: {
        amount: servicePrice.data || defaultValues.servicePrice,
        currency: currency || defaultValues.currency,
      },
      vatRate: {
        taxRate: serviceVAT.data || defaultValues.serviceVAT,
        countryOrRegion: country || defaultValues.countryOrRegion,
      },
    };

    context === CRUDL.UPDATE
      ? onSubmit({
          enterpriseId: enterpriseId,
          article: articlePayload,
          id: data.id,
        })
      : onSubmit({ enterpriseId: enterpriseId, article: articlePayload });
  }

  return {
    title,
    setTitle,
    description,
    setDescription,
    serviceQuantity,
    setServiceQuantity,
    quantityUnit,
    setQuantityUnit,
    isQuantityError,
    servicePrice,
    setServicePrice,
    isPriceError,
    serviceVAT,
    setServiceVAT,
    VATError,
    currency,
    setCurrency,
    units,
    createArticle,
    defaultValues,
    isFormValid,
    isFormDirty,
    titleError,
    setTitleError,
    descriptionError,
    setDescriptionError,
  };
}
