import { useQuery } from "@tanstack/react-query";
import { ory } from "./useOryLogin";
import { manageAxiosError } from "@/utils/manageAxiosError";

export function useOrySession(reactQueryConfig) {
  const location = window.location;
  const isLoginPage = location.pathname === "/login";

  const query = useQuery({
    queryKey: ["ory-session"],
    queryFn: async () => {
      try {
        const response = await ory.toSession();
        return response;
      } catch (error) {
        const { code, message } = (() => {
          if (error.response) {
            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              return { message: "Session expirée", code: "AUTHEXTA01" };
            } else if (error.response.status === 429) {
              return {
                message: "Impossible de vérifier la session",
                code: "AUTHEXTA02",
              };
            }
          }

          return {
            message: "Impossible de récupérer la session",
            code: "AUTHEXTA03",
          };
        })();

        const disableToast = isLoginPage;

        manageAxiosError(error, message, code, "ory-session", disableToast);
      }
    },
    ...reactQueryConfig,
  });

  return query;
}
