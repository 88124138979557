import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "@/assets/css/mailVerif.css";
import { useLocation } from "react-router-dom";
import LoadingBlock from "@/components/elements/LoadingBlock";
import { useUser } from "@/contexts/UserContext";
import { notify } from "@/utils/notify";
import {
  useCreateOryEmailVerificationFlow,
  useGetOryEmailVerificationFlow,
} from "@/hooks/useOryEmailVerification";
import CouldNotLoad from "@/components/elements/CouldNotLoad";
import LidapLogo from "@/components/token/logo-lidap-horizontal";
import { useOnboarding } from "@/contexts/OnboardingContext";

export default function OryEmailVerification() {
  const [flow, setFlow] = useState();
  const location = useLocation();
  const userContext = useUser();
  const onboarding = useOnboarding();
  const email = location.state?.email || userContext.state.user?.email;
  const isEmailVerified = userContext.state.user?.isEmailVerified;
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const flowId = searchParams.get("flow") || location.state?.flowId;
  const verifyMail = onboarding.verifyMail;

  const createVerificationFlow = useCreateOryEmailVerificationFlow((data) => {
    if (flowId) return;

    setFlow(data);
    searchParams.set("flow", data.verificationFlow.id);
    setSearchParams(searchParams);
    if (!verifyMail.isLoading) {
      verifyMail.mutate({
        flowId: data.verificationFlow.id,
        email: email,
        csrf_token: data.csrf_token,
      });
    }
  });

  const getVerificationFlow = useGetOryEmailVerificationFlow(
    { id: flowId },
    {
      enabled: !!flowId && !createVerificationFlow.isSuccess,
    }
  );

  const csrf_token = flow?.csrf_token || getVerificationFlow.data?.csrf_token;

  const flowExpiresAt = (() => {
    if (flow) return flow?.verificationFlow?.expires_at;
    if (getVerificationFlow.data)
      return getVerificationFlow.data?.data?.expires_at;
    return "";
  })();

  const navigate = useNavigate();

  const [userMailAdress] = useState(email);

  function handleChange(event) {
    const inputValue = event.target.value;

    if (inputValue.length <= 6) {
      // Limite à 6 caractères
      searchParams.set("code", inputValue);
      setSearchParams(searchParams);
      if (verifyMail.isError) verifyMail.reset();
    }
  }

  useEffect(() => {
    if (flowId || isEmailVerified) {
      return;
    }

    if (!createVerificationFlow.isLoading) createVerificationFlow.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createVerificationFlow]);

  useEffect(() => {
    if (isEmailVerified) {
      notify({
        isSuccess: true,
        message: "Votre email a déjà été vérifié",
      });
      navigate("/home");
    }
  }, [isEmailVerified, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (verifyMail.isLoading) return;

    if (code?.length !== 6) {
      notify({
        isSuccess: false,
        message: "Veuillez entrer un code de 6 chiffres",
      });
      return;
    }
    verifyMail.mutate({
      flowId: flowId,
      code: code,
      csrf_token: csrf_token,
    });
  };

  useEffect(() => {
    if (
      code &&
      code.length === 6 &&
      !verifyMail.isSuccess &&
      !verifyMail.isLoading &&
      !verifyMail.isError
    ) {
      verifyMail.mutate({
        flowId: flowId,
        code: code,
        csrf_token: csrf_token,
      });
    }
  }, [code, flowId, csrf_token, verifyMail]);

  if (!flowId || getVerificationFlow.isFetching)
    return <LoadingBlock message="Chargement" />;

  if (getVerificationFlow.isError) {
    return (
      <CouldNotLoad
        message="Impossible de récupérer le flux de vérification"
        error={getVerificationFlow.error}
      />
    );
  }

  return (
    <div className="flex flex-col w-screen h-screen bg-neutral-100 items-center justify-center">
      <div className="bg-white p-12 shadow-sm rounded-lg border border-neutral-200 flex flex-col items-center justify-center gap-8">
        <div className="w-32">
          <LidapLogo
            alt="logo de Lidap"
            id="menu-logo-lidap"
            className="text-gray-900"
            maxWidth="6rem"
            height="100%"
            style={{ cursor: "pointer" }}
          />
        </div>

        <div className="flex flex-col gap-4 justify-center items-center">
          <div id="mail-code-info-box">
            <div id="getStartedSignUp">Vérifiez votre Email</div>
            {userMailAdress ? (
              <>
                <span className="text-neutral-400">
                  Nous vous avons envoyé un code à
                </span>
                <output className="text-neutral-400">{userMailAdress}</output>
              </>
            ) : null}
          </div>
          <form
            className="flex flex-col items-center justify-center gap-4"
            onSubmit={handleSubmit}
          >
            <div className="border border-gray-200 rounded-lg overflow-hidden shadow-sm">
              <input
                type="number"
                placeholder="1 2 3 4 5 6"
                value={code}
                onChange={handleChange}
                id="mail-code-input"
                maxLength="6"
              ></input>
            </div>
            {/* <input
              type="submit"
              className="bg-neutral-900 text-white shadow-sm hover:bg-neutral-800 dark:hover:bg-neutral-200 px-4 py-2 text-sm cursor-pointer rounded-md text-center justify-center focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 whitespace-nowrap flex items-center gap-x-2 w-max"
              onClick={async (e) => {
                e.preventDefault();
                verifyMail.mutate({
                  flowId: flowId,
                  code: code,
                  csrf_token: csrf_token,
                });
              }}
              disabled={code?.length !== 6}
            ></input> */}
          </form>
        </div>
        <div className="flex flex-col items-center justify-center gap-2">
          <div className="flex flex-col justify-center items-center text-center">
            {flowExpiresAt && (
              <span className="text-neutral-500" id="code-expiry">
                Le code expire à{" "}
                {new Date(flowExpiresAt).toLocaleTimeString("fr-FR", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                {". "}
                &nbsp;
              </span>
            )}
            <div className="flex justify-center items-center text-center">
              {/* <span id="mail-code-resend-text">Vous pouvez&nbsp;</span> */}
              {createVerificationFlow.isLoading ? (
                <span className="text-neutral-500" id="code-expiry">
                  Renvoi en cours...
                </span>
              ) : verifyMail.isLoading ? (
                <span className="text-neutral-500" id="code-expiry">
                  Vérification en cours...
                </span>
              ) : (
                <button
                  className="border-none bg-transparent text-base text-center text-neutral-500 underline"
                  disabled={
                    verifyMail.isLoading ||
                    (code?.length === 6 && !verifyMail.isError)
                  }
                  onClick={async (e) => {
                    e.preventDefault();
                    // if (flowExpiresAt && new Date(flowExpiresAt) > new Date()) {
                    //   notify({
                    //     isSuccess: false,
                    //     message: "Un code a déjà été envoyé, veuillez patienter",
                    //   });
                    //   return;
                    // }
                    verifyMail.mutate({
                      flowId: flowId,
                      email: email,
                      csrf_token: csrf_token,
                    });
                  }}
                >
                  Renvoyer le code
                </button>
              )}
            </div>
          </div>
          {/* <ButtonTailwind onClick={() => logout.mutate()} variant="outlined2">
              Revenir au login
            </ButtonTailwind> */}
        </div>
      </div>
    </div>
  );
}
