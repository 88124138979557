import InputPriceSelector from "@/components/token/Input/inputPriceSelector/InputPriceSelector";
import ButtonsList from "@/components/token/ButtonsList/ButtonsList";
import React from "react";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import { CRUDL } from "@/utils/crudl";
import { useFormArticles } from "@/hooks/forms/useFormArticles";
import ServiceTitle from "@/components/elements/Document/Service/ServiceTitle";
import ServiceDescription from "@/components/elements/Document/Service/ServiceDescription";
import RequireSubscriptionButton from "@/components/token/button/RequireSubscriptionButton";

export default function ArticleCreate({
  onCancel,
  onSubmit,
  context,
  isLoading,
  isError,
  data,
}) {
  const articleForm = useFormArticles({
    onCancel,
    onSubmit,
    context,
    isLoading,
    isError,
    data,
  });

  const servicePrice = articleForm.servicePrice.isDirty
    ? articleForm.servicePrice.data
    : articleForm.defaultValues.servicePrice;
  const serviceQuantity = articleForm.serviceQuantity.isDirty
    ? articleForm.serviceQuantity.data
    : articleForm.defaultValues.serviceQuantity;
  const serviceVAT = articleForm.serviceVAT.isDirty
    ? articleForm.serviceVAT.data
    : articleForm.defaultValues.serviceVAT;

  return (
    <div className="flex flex-row mx-auto">
      <div className="flex flex-col w-[512px] max-w-[768px] p-8 bg-white rounded-lg mt-12 mb-24 h-max gap-6">
        <div className="">
          <p className="tw-h2">
            {context === CRUDL.UPDATE
              ? "Modifier un article"
              : "Créer un Article"}
          </p>
        </div>
        <div className="flex flex-col overflow-y-auto">
          <form
            className="flex flex-col gap-2"
            onSubmit={(e) => {
              e.preventDefault();
              articleForm.createArticle();
            }}
          >
            {/* <InputTailwind
              value={articleForm.title || articleForm.defaultValues.title}
              onChange={(e) => articleForm.setTitle(e.target.value)}
              label="Titre"
              placeholder="Titre du service"
              type="text"
              required={true}
              maxLength={1000}
              minLength={1}
            /> */}
            <ServiceTitle
              titleError={articleForm.titleError}
              setServiceTitle={articleForm.setTitle}
              serviceTitle={
                articleForm.title || articleForm.defaultValues.title
              }
              setTitleError={articleForm.setTitleError}
              errorOnFocus={true}
            />

            {/* <TextArea
              label="Description"
              placeholder="Description du service"
              value={
                articleForm.description || articleForm.defaultValues.description
              }
              onChange={(e) => articleForm.setDescription(e.target.value)}
            /> */}
            <ServiceDescription
              label="Description"
              serviceDescription={
                articleForm.description || articleForm.defaultValues.description
              }
              setDescription={articleForm.setDescription}
              setDescriptionError={articleForm.setDescriptionError}
              setServiceDescription={articleForm.setDescription}
              descriptionError={articleForm.descriptionError}
              errorOnFocus={true}
            />
            <div className="flex flex-col w-full gap-4">
              <InputPriceSelector
                label="Quantité"
                placeholder="1"
                isRequired={true}
                name="price"
                type="number"
                id="QuantityInput"
                numberValue={serviceQuantity}
                onChange={(q) => {
                  articleForm.setServiceQuantity((prev) => ({
                    ...prev,
                    data: q,
                    isDirty: true,
                  }));
                }}
                selectValue={
                  articleForm.quantityUnit ||
                  articleForm.defaultValues.quantityUnit
                }
                onSelect={(e) => articleForm.setQuantityUnit(e.target.value)}
                selectLabel="Unité"
                options={articleForm.units}
                error={articleForm.isQuantityError}
              />

              <InputPriceSelector
                label="Prix unitaire"
                placeholder="0.00"
                isRequired={true}
                name="price"
                type="number"
                numberValue={servicePrice}
                onChange={(p) =>
                  articleForm.setServicePrice((prev) => ({
                    ...prev,
                    data: p,
                    isDirty: true,
                  }))
                }
                selectValue={
                  articleForm.currency || articleForm.defaultValues.currency
                }
                onSelect={(e) => articleForm.setCurrency(e.target.value)}
                selectLabel="Currency"
                error={articleForm.isPriceError}
                options={["EUR", "USD", "CHF", "CAD"]}
              />
            </div>
            <ButtonsList
              setServiceVAT={(vat) =>
                articleForm.setServiceVAT((prev) => ({
                  ...prev,
                  data: vat,
                  isDirty: true,
                }))
              }
              serviceVAT={serviceVAT}
              error={articleForm.VATError}
              inputDefaultValue={"0"}
            ></ButtonsList>

            <div className="flex flex-row justify-between gap-2 mt-8">
              <ButtonTailwind
                type="button"
                style="w-full"
                variant="outlined2"
                onClick={onCancel}
                disabled={
                  isLoading ||
                  (context === CRUDL.UPDATE && !articleForm.isFormDirty)
                }
              >
                {"Retour"}
              </ButtonTailwind>
              <RequireSubscriptionButton
                type="submit"
                style="w-full"
                loading={isLoading}
                disabled={
                  isLoading ||
                  (context === CRUDL.UPDATE && !articleForm.isFormDirty)
                }
              >
                {context === CRUDL.UPDATE
                  ? "Modifier l'article"
                  : "Enregistrer le service"}
              </RequireSubscriptionButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
