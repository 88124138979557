import {
  OrySettingsErrors,
  useCreateOrySettingsFlow,
  useOrySettings,
} from "@/hooks/useOrySettings";
import { notify } from "@/utils/notify";
import { useCallback, useEffect, useState } from "react";

export default function useFormOryChangePassword({ onSuccess }) {
  /* State */
  const emptyPassword = {
    success: false,
    data: "",
    isDirty: false,
  };
  const [newPassword, setNewPassword] = useState(emptyPassword);
  const [flow, setFlow] = useState();
  const [isOryConfirmAuthModalDisplayed, setIsOryConfirmAuthModalDisplayed] =
    useState(false);

  /* Hooks */
  const createSettingsFlow = useCreateOrySettingsFlow((data) => {
    setFlow(data);
  });

  const updateSettingsFlow = useOrySettings(
    ["ory-settings-flow"],
    () => {
      notify({
        isSuccess: true,
        message: "Profil sécurité mis à jour avec succès",
      });
      setNewPassword(emptyPassword);
      if (onSuccess) onSuccess();
    },
    (error) => {
      if (
        error?.code === OrySettingsErrors.ORY_SETTINGS_CONFIRMATION_REQUIRED
      ) {
        setIsOryConfirmAuthModalDisplayed(true);
      } else {
        setNewPassword(emptyPassword);
      }
    }
  );

  /* Use effects */
  useEffect(() => {
    if (flow) {
      return;
    }

    if (!createSettingsFlow.isLoading) createSettingsFlow.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSettingsFlow]);

  /* Deduced values */
  // const csrf_token = flow?.csrf_token;
  // const flowId = flow?.settingsFlow?.id;
  const passwordError = (() => {
    if (createSettingsFlow.isError) {
      return "Impossible d'initialiser le flux de mise à jour du mot de passe";
    }

    /* If the password modification was rejected and the user has not yet provided another input, we display the error*/
    if (updateSettingsFlow.isError && !newPassword.isDirty) {
      if (updateSettingsFlow.error?.message) {
        return updateSettingsFlow.error.message;
      }
    }

    return null;
  })();

  /* Handlers */
  const handlePasswordChange = useCallback(() => {
    if (!newPassword.data || updateSettingsFlow.isLoading || !flow) return;

    updateSettingsFlow.mutate({
      flowId: flow.settingsFlow?.id,
      method: "password",
      password: newPassword.data,
      csrf_token: flow.csrf_token,
    });
  }, [newPassword.data, updateSettingsFlow, flow]);

  return {
    handlePasswordChange,
    newPassword,
    setNewPassword,
    isOryConfirmAuthModalDisplayed,
    setIsOryConfirmAuthModalDisplayed,
    isLoading: updateSettingsFlow.isLoading,
    passwordError,
    // Other necessary data for the components...
  };
}
