export const SettingsType = {
  USER: "user",
  ENTERPRISE: "enterprise",
  DOCUMENT: "document",
};

export const DocumentSettingsKey = {
  DOCUMENT_BODY_COLOR: "DOCUMENT_BODY_COLOR",
  DOCUMENT_CONDITIONS: "DOCUMENT_CONDITIONS",
  DOCUMENT_TITLE_COLOR: "DOCUMENT_TITLE_COLOR",
  VAT_DISCLAIMER: "VAT_DISCLAIMER",
  DOCUMENT_CREATION_CONFIRM_MODAL: "DOCUMENT_CREATION_CONFIRM_MODAL",
  DELAY_QUOTE_DAYS: "DELAY_QUOTE_DAYS",
  EMAIL_ON_DOCUMENTS: "EMAIL_ON_DOCUMENTS",
  PHONE_ON_DOCUMENTS: "PHONE_ON_DOCUMENTS",
};

export const DefaultSettings = {
  document: {
    DOCUMENT_BODY_COLOR: {
      settingsValue: "#f5f5f5",
    },
    DOCUMENT_CONDITIONS: {
      settingsValue: "",
    },
    DOCUMENT_TITLE_COLOR: {
      settingsValue: "#000000",
    },
    VAT_DISCLAIMER: {
      settingsValue: "",
    },
    DELAY_QUOTE_DAYS: {
      settingsValue: 30,
    },
  },
  user: {},
  enterprise: {},
};

export function extractSetting({ settingsData, settingsType, settingsKey }) {
  if (!settingsData) {
    return "";
  }

  if (!settingsKey) return settingsData[settingsType];
  if (!settingsData || !settingsData[settingsType])
    return DefaultSettings[settingsType][settingsKey]?.settingsValue || "";

  return (
    settingsData[settingsType][settingsKey]?.settingsValue ||
    DefaultSettings[settingsType][settingsKey]?.settingsValue ||
    ""
  );
}
