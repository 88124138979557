import { LidapToolTip } from "@/components/token/tooltip/LidapToolTip";
import { useState } from "react";

export function LidapIcon({
  Icon,
  loading,
  handleAction,
  value,
  className = "h-4 w-4 text-gray-400 stroke-2 cursor-pointer",
  spinnerClassName = "h-3 w-3 border-2 border-t-2 border-t-neutral-600 border-neutral-200 rounded-full animate-spin",
  toolTipText = "Sauver",
  disabled = false,
}) {
  const [isHovered, setIsHovered] = useState(false);

  return loading ? (
    <span className={spinnerClassName}></span>
  ) : (
    <div className="relative group">
      <Icon
        className={className}
        onClick={() => {
          if (disabled) return;
          handleAction(value);
        }}
        aria-disabled={disabled}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      <LidapToolTip
        toolTipText={toolTipText}
        isGrouped={false}
        isVisible={isHovered}
      />
    </div>
  );
}
