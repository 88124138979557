import { useMutation, useQueryClient } from "@tanstack/react-query";
import Lidapi from "@/utils/lidapi";

export function useCreateUpdateClient(queryKeyToInvalidate, onSuccessCallback) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async ({ body: data, params: { enterpriseId, clientId } }) =>
      clientId
        ? await api.updateClient({
            body: data,
            params: { enterpriseId, clientId },
          })
        : await api.newClient({ body: data, params: { enterpriseId } }),
    onSuccess: (data) => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    },
  });

  return action;
}
