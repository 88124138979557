import { useQuery } from "@tanstack/react-query";
import { stringify } from "@/utils/stringify";
import { manageLidapResponse } from "@/utils/lidapi";

export function getPhones({ stakeholderId, queryParam }) {
  const stakeholderParams = stakeholderId ? `/${stakeholderId}` : "";
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  return {
    queryKey: ["phones", { stakeholderId }, queryParam],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/stakeholders${stakeholderParams}/phones/${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return manageLidapResponse(response, "Could not load phones");
    },
  };
}

export function usePhones({ stakeholderId, queryParam, reactQueryOptions }) {
  const { queryKey, queryFn } = getPhones({ stakeholderId, queryParam });

  return useQuery({
    queryKey: queryKey,
    queryFn: queryFn,
    ...reactQueryOptions,
  });
}
