import CenteredLoadingBlock from "@/components/elements/CenteredLoadingBlock";
import { useOnboarding } from "@/contexts/OnboardingContext";
import { Navigate } from "react-router-dom";

export default function Switch() {
  const onboarding = useOnboarding();

  if (onboarding.isLoading) {
    return <CenteredLoadingBlock />;
  }

  if (onboarding.target) {
    return <Navigate to={onboarding.target} replace={true} />;
  }

  return <Navigate to="/home" replace={true} />;
}
