import { useMutation, useQueryClient } from "@tanstack/react-query";
import Lidapi from "@/utils/lidapi";

export function useCreateInvoice(
  queryKeyToInvalidate,
  multipleQueryKeysToInvalidate,
  onSuccessCallback
) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async (invoiceRequest) => {
      return await api.createInvoice(
        invoiceRequest.data,
        invoiceRequest.enterpriseId
      );
    },
    onSuccess: (data) => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (
        multipleQueryKeysToInvalidate &&
        multipleQueryKeysToInvalidate.length > 0
      ) {
        multipleQueryKeysToInvalidate.forEach((queryKey) => {
          queryClient.invalidateQueries({
            queryKey: [...queryKey],
          });
        });
      }
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    },
  });

  return action;
}
