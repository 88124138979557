export function VatButtonsListSkeleton() {
  return (
    <div className="animate-pulse">
      {/* <div className="h-9 bg-neutral-200 dark:bg-neutral-800 rounded w-full mb-2"></div> */}
      <div className="flex flex-row justify-between w-full">
        <label
          htmlFor={"TVA"}
          className={`block text-sm font-medium leading-6 text-gray-900`}
        >
          {"TVA"}
        </label>
      </div>

      <div className="flex gap-2">
        <div className="h-9 w-16 bg-neutral-200 dark:bg-neutral-800 rounded"></div>
        <div className="h-9 w-16 bg-neutral-200 dark:bg-neutral-800 rounded"></div>
        <div className="h-9 w-16 bg-neutral-200 dark:bg-neutral-800 rounded"></div>
        <div className="h-9 w-16 bg-neutral-200 dark:bg-neutral-800 rounded"></div>
      </div>
    </div>
  );
}
