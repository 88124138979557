import React from "react";
import { CRUDL } from "@/utils/crudl";
import ArticleCreate from "@/feature/articles/manageArticle/ArticleCreate";
import { useArticles } from "@/hooks/useArticles";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "@/contexts/UserContext";
import { useCreateUpdateArticle } from "@/hooks/useCreateUpdateDeleteArticles";
import LoadingBlock from "@/components/elements/LoadingBlock";
import CouldNotLoad from "@/components/elements/CouldNotLoad";

export default function ManageArticle({ context }) {
  /* Context */
  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;

  /* Hooks */
  const navigate = useNavigate();

  /* Mutation */
  const action = useCreateUpdateArticle(["articles"], undefined, () =>
    navigate(-1)
  );

  /* Params */
  const { articleId } = useParams();
  const article = useArticles({
    enterpriseId,
    articleId,
    reactQueryConfig: { enabled: !!enterpriseId && !!articleId },
  });

  /* Render */
  if (context !== CRUDL.CREATE && article.isLoading) return <LoadingBlock />;
  if (article.isError)
    return (
      <CouldNotLoad
        message="Impossible de récupérer l'article."
        error={article.error}
      />
    );

  if (context === CRUDL.CREATE) {
    return (
      <ArticleCreate
        onSubmit={action.mutate}
        context={context}
        onCancel={() => navigate(-1)}
        isLoading={action.isLoading}
      />
    );
  } else if (context === CRUDL.UPDATE) {
    return (
      <ArticleCreate
        isLoading={article.isLoading || action.isLoading}
        isError={article.isError}
        data={article.data?.payload?.article}
        context={context}
        onSubmit={action.mutate}
        onCancel={() => navigate(-1)}
      />
    );
  }
}
