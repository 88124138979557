import InputTailwind from "@/components/token/Input/inputTailwind";
import { validateIdentifier } from "@/components/elements/stakeholder/identifier/identifierValidator";
import { identifierLabels } from "@/components/elements/stakeholder/identifier/identifierLabel";
import { identifierFormatRules } from "@/components/elements/stakeholder/identifier/identifierFormatter";
import { useEffect } from "react";
import { CRUDL } from "@/utils/crudl";

export function Identifier({
  initialIdentifier,
  identifierType,
  countryOrRegion,
  disabled,
  onChange,
  isLoading,
  required,
  name = "identifier",
  label,
  displayError = true,
  forceValidate,
  forceFormatting = false,
  context,
  placeholder,
}) {
  const identifier = {
    identifier: initialIdentifier,
    identifierType,
    countryOrRegion,
  };
  const feedback = validateIdentifier(
    initialIdentifier,
    identifierType,
    countryOrRegion,
    required
  );
  const identifierLabel =
    identifierLabels[countryOrRegion + identifierType] || "Identifiant";
  const formatter = (() => {
    const cdt = identifierFormatRules[countryOrRegion + identifierType];
    if (!cdt) {
      return (identifier) => identifier;
    }
    return cdt;
  })();

  useEffect(() => {
    if (forceValidate && (!feedback.success || context === CRUDL.CREATE)) {
      onChange({
        data: identifier,
        success: feedback.success,
        isDirty: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceValidate, feedback.success, onChange]);

  useEffect(() => {
    if (!initialIdentifier || forceFormatting) return;
    onChange({
      success: feedback.success,
      data: identifier,
      isDirty: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryOrRegion, forceFormatting]);

  const handleOnBlur = () => {
    if (!initialIdentifier) return;
    const fmt = formatter(initialIdentifier.trim());
    if (initialIdentifier !== fmt) {
      const newIdentifier = {
        identifier: fmt,
        identifierType,
        countryOrRegion,
      };
      const feedback = validateIdentifier(
        newIdentifier.identifier,
        newIdentifier.identifierType,
        newIdentifier.countryOrRegion,
        required
      );

      onChange({
        success: feedback.success,
        data: newIdentifier,
        isDirty: true,
      });
    }
  };

  const handleIdentifierChange = (e) => {
    e.preventDefault();
    const newIdentifier = {
      identifier: e.target.value,
      identifierType,
      countryOrRegion,
    };
    const feedback = validateIdentifier(
      newIdentifier.identifier,
      newIdentifier.identifierType,
      newIdentifier.countryOrRegion,
      required
    );
    onChange({
      success: feedback.success,
      data: newIdentifier,
      isDirty: true,
    });
  };

  return (
    <InputTailwind
      label={label ? label : identifierLabel}
      type="text"
      placeholder={placeholder || "Identifiant d'enterprise"}
      value={
        forceFormatting
          ? formatter(identifier.identifier)
          : identifier.identifier
      }
      onChange={(e) => handleIdentifierChange(e)}
      loading={isLoading}
      disabled={disabled}
      error={feedback.success || !displayError ? null : feedback.message}
      isRequired={required}
      name={name}
      onBlur={handleOnBlur}
    ></InputTailwind>
  );
}
