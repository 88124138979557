import Whitelist from "@/feature/admin/whitelist/whitelist";
import ProtectedRoute from "@/utils/ProtectedRouteV2";
import RequireEnterpriseRoute from "@/utils/RequireEnterpriseRoute";

export const whitelistRoutes = [
  {
    path: "whitelist",
    element: (
      <ProtectedRoute requireWhitelist={true} hideMenu={false}>
        <RequireEnterpriseRoute>
          <Whitelist />
        </RequireEnterpriseRoute>
      </ProtectedRoute>
    ),
  },
];
