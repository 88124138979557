import React, { createContext, useContext } from "react";
import { useUser } from "./UserContext";
import { useCurrentInvoiceNumbers } from "@/hooks/useInvoices";
import { useEnterprises } from "@/hooks/useEnterprises";
import { useOryEmailVerification } from "@/hooks/useOryEmailVerification";
import { notify } from "@/utils/notify";
import { useOryAuth } from "./OryAuthContext";

const OnboardingContext = createContext();

export function useOnboarding() {
  return useContext(OnboardingContext);
}

export const OnboardingProvider = ({ children }) => {
  const userContext = useUser();
  const auth = useOryAuth();
  const isEmailVerified = auth.session
    ? auth.session.data.identity.verifiable_addresses.some(
        (a) => a.verified === true
      )
    : null;
  const isOnWhitelist = userContext.state.user?.isOnWhitelist;
  const {
    emailVerificationFlowId,
    setEmailVerificationFlowId,
    isSignup,
    setIsSignup,
  } = auth;

  const flowIdParam = emailVerificationFlowId
    ? `?flow=${emailVerificationFlowId}`
    : "";

  const verifyMail = useOryEmailVerification(
    ["ory-session"],
    [["enterprises"]],
    (data) => {
      if (data && data.resent) {
        notify({
          isSuccess: true,
          message: "Un nouveau code a été envoyé",
        });
        verifyMail.reset();
        setEmailVerificationFlowId("");
        return;
      }
    }
  );

  const enterprise = useEnterprises({
    enterpriseId: undefined,
    queryParam: {
      emails: true,
      addresses: true,
    },
    reactQueryOptions: {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled:
        ((!!isEmailVerified && !!isOnWhitelist) || verifyMail.isSuccess) &&
        !!auth.session,
      retry: (failureCount, error) => {
        error.statusCode !== 404 ? true : false;
      },
    },
  });

  const enterpriseId = enterprise?.data?.payload?.enterprises?.[0]?.id;
  const currentInvoiceNumber = useCurrentInvoiceNumbers(
    {
      enterpriseId,
    },
    {
      enabled: !!enterpriseId && !!auth.session,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  const value = (() => {
    if (!isEmailVerified) {
      return {
        enterprise: enterprise,
        currentInvoiceNumber: currentInvoiceNumber,
        target:
          isSignup && !emailVerificationFlowId
            ? null
            : `/mail/verification${flowIdParam}`,
        isLoading:
          userContext.state.isLoading || (isSignup && !emailVerificationFlowId),
        isError: false,
      };
    }

    if (
      enterprise.isLoading ||
      enterprise.isInitialLoading ||
      enterprise.isError ||
      currentInvoiceNumber.isInitialLoading ||
      currentInvoiceNumber.isError
    ) {
      return {
        enterprise,
        currentInvoiceNumber,
        target: null,
        isLoading:
          enterprise.isLoading ||
          enterprise.isInitialLoading ||
          currentInvoiceNumber.isInitialLoading,
        isError: enterprise.isError || currentInvoiceNumber.isError,
      };
    }

    if (
      enterprise.data &&
      enterprise.data.payload &&
      enterprise.data.payload.enterprises.length === 0
    ) {
      return {
        enterprise,
        currentInvoiceNumber,
        target: "/one-last-step/enterprise",
        isLoading: false,
        isError: false,
      };
    }

    if (
      enterprise.data &&
      enterprise.data.payload &&
      enterprise.data.payload.enterprises.length > 0 &&
      currentInvoiceNumber.data &&
      currentInvoiceNumber.data.payload &&
      currentInvoiceNumber.data.payload.currentInvoiceNumbers.length === 0
    ) {
      return {
        enterprise,
        currentInvoiceNumber,
        target: "/one-last-step/numbering",
        isLoading: false,
        isError: false,
      };
    }
  })();

  return (
    <OnboardingContext.Provider
      value={{
        ...value,
        verifyMail,
        setEmailVerificationFlowId,
        setIsSignup,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
