import React from "react";
import { Datepicker } from "flowbite-react";
import { datePickerTheme } from "@/data/themes.js";
import {
  convertStringToDate,
  transformDate,
} from "@/utils/date/dateTransformers";

const MyDateRangePicker = ({
  issuingDate,
  setIssuingDate,
  labelIssuingDate,
  dueDate,
  setDueDate,
  labeLDueDate,
}) => {
  return (
    <div className="flex items-center w-full gap-4 max-w-full">
      <div className="w-full">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {labelIssuingDate || "Date d'émission"}
        </label>
        <Datepicker
          placeholder="Sélectionner la date de début"
          dateformat="dd/MM/yyyy"
          selected={convertStringToDate(issuingDate)}
          onSelectedDateChanged={(date) => setIssuingDate(transformDate(date))}
          selectsStart
          startDate={convertStringToDate(issuingDate)}
          endDate={convertStringToDate(dueDate)}
          language="fr"
          weekStart={1}
          maxDate={convertStringToDate(dueDate)}
          theme={datePickerTheme}
          className="dark:bg-black focus:ring-gray-700"
          defaultDate={convertStringToDate(issuingDate)}
          labelTodayButton="Aujourd'hui"
          labelClearButton="Annuler"
        />
      </div>

      <div className="w-full">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {labeLDueDate || "Date d'échéance"}
        </label>
        <Datepicker
          placeholder="Sélectionner la date de fin"
          dateformat="dd/MM/yyyy"
          selected={convertStringToDate(dueDate)}
          onSelectedDateChanged={(date) => setDueDate(transformDate(date))}
          startDate={convertStringToDate(issuingDate)}
          endDate={convertStringToDate(dueDate)}
          language="fr"
          weekStart={1}
          minDate={convertStringToDate(issuingDate)}
          theme={datePickerTheme}
          className="dark:bg-black focus:ring-gray-700"
          defaultDate={convertStringToDate(dueDate)}
          labelTodayButton="Aujourd'hui"
          labelClearButton="Annuler"
        />
      </div>
    </div>
  );
};

export default MyDateRangePicker;
