import React from "react";
import {
  UserCircleIcon,
  DocumentTextIcon,
  CreditCardIcon,
  BuildingLibraryIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./settingsMenu.css";
import { useQueryClient } from "@tanstack/react-query";

export default function SettingsMenu({ children }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  // Accessing the passed state from the location object
  const from = location?.state?.from;
  const origin = from?.pathname ? from.pathname : "/home";

  return (
    <section className="settings">
      <div
        className="absolute top-12 right-12 z-[2000] cursor-pointer h-12 w-12 flex justify-center items-center"
        onClick={() => {
          queryClient.invalidateQueries(["users", "info"]);
          navigate(origin);
        }}
      >
        <XMarkIcon className="h-6 w-6 text-gray-900" />
      </div>
      <div className="flex flex-col h-screen bg-neutral-100 border-r border-gray-300 w-64 select-none">
        <div className="px-4 py-8 flex flex-col gap-4">
          <div>
            <p className="text-xl font-semibold text-black">Paramètres</p>

            <p className="text-xs font-regular text-neutral-500">
              Gérez tous vos paramètres
            </p>
          </div>

          <div className="flex flex-col gap-1">
            <NavLink
              to="/settings/profile"
              state={{ from: { pathname: origin } }}
              className={({ isActive }) =>
                isActive
                  ? "flex items-center gap-2 py-2 px-2 w-44 rounded-md select-none text-neutral-900 bg-white ring-gray-300 shadow-sm ring-1 ring-inset text-sm cursor-pointer"
                  : "flex items-center gap-2 py-2 px-2 w-44 rounded-md select-none text-neutral-500 hover:bg-neutral-200 dark:hover:bg-neutral-800 text-sm cursor-pointer"
              }
            >
              <UserCircleIcon className="h-5 w-5" />
              Profil
            </NavLink>
            <NavLink
              to="/settings/company"
              state={{ from: { pathname: origin } }}
              className={({ isActive }) =>
                isActive
                  ? "flex items-center gap-2 py-2 px-2 w-44 rounded-md select-none text-neutral-900 bg-white ring-gray-300 shadow-sm ring-1 ring-inset text-sm ursor-pointer"
                  : "flex items-center gap-2 py-2 px-2 w-44 rounded-md select-none text-neutral-500 hover:bg-neutral-200 dark:hover:bg-neutral-800 text-sm cursor-pointer"
              }
            >
              <BuildingLibraryIcon className="h-5 w-5" />
              Société
            </NavLink>

            <NavLink
              to="/settings/invoicing"
              state={{ from: { pathname: origin } }}
              className={({ isActive }) =>
                isActive
                  ? "flex items-center gap-2 py-2 px-2 w-44 rounded-md select-none text-neutral-900 bg-white ring-gray-300 shadow-sm ring-1 ring-inset text-sm cursor-pointer"
                  : "flex items-center gap-2 py-2 px-2 w-44 rounded-md select-none text-neutral-500 hover:bg-neutral-200 dark:hover:bg-neutral-800 text-sm cursor-pointer"
              }
            >
              <DocumentTextIcon className="h-5 w-5" />
              Facturation
            </NavLink>
            {process.env.STRIPE_ENABLED === "true" && (
              <NavLink
                to="/settings/subscriptions"
                state={{ from: { pathname: origin } }}
                className={({ isActive }) =>
                  isActive
                    ? "flex items-center gap-2 py-2 px-2 w-44 rounded-md select-none text-neutral-900 bg-white ring-gray-300 shadow-sm ring-1 ring-inset text-sm cursor-pointer"
                    : "flex items-center gap-2 py-2 px-2 w-44 rounded-md select-none text-neutral-500 hover:bg-neutral-200 dark:hover:bg-neutral-800 text-sm cursor-pointer"
                }
              >
                <CreditCardIcon className="h-5 w-5" />
                Abonnement Lidap
              </NavLink>
            )}
          </div>
        </div>
      </div>

      <div className="max-h-screen overflow-auto w-full bg-neutral-100">
        {children}
      </div>
    </section>
  );
}
