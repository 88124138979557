import React from "react";
import InvoiceIcon from "@/assets/Images/InvoiceIcon.svg";
import CreditIcon from "@/assets/Images/CreditIcon.svg";
import EstimateIcon from "@/assets/Images/EstimateIcon.svg";
import { useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { invoiceTabs } from "../invoices/invoiceTabs";

export default function DocumentsForms(props) {
  const documentType = (name, onClick, icon) => (
    <button
      className="group max-w-sm w-full rounded border bg-white flex items-center gap-4 cursor-pointer overflow-hidden border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 dark:hover:border-neutral-300 shadow-sm"
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onClick();
        }
      }}
    >
      <div className="image-border bg-neutral-300 flex items-center gap-2.5 border-r border-neutral-300 group-hover:border-neutral-400">
        <img src={icon} alt="Contract Icon" />
      </div>
      <span className="text-black">{name}</span>
    </button>
  );
  const navigate = useNavigate();

  return (
    <div className="flex flex-col bg-neutral-100 items-center justify-center gap-3 w-full">
      <div
        className="absolute top-12 right-12 z-[2000] cursor-pointer h-12 w-12 flex justify-center items-center"
        onClick={() => navigate("/invoices")}
      >
        <XMarkIcon className="h-6 w-6 text-gray-900" />
      </div>
      {documentType(
        "Devis",
        () => props.setTab(invoiceTabs.QUOTE),
        EstimateIcon
      )}
      {documentType(
        "Facture",
        () => props.setTab(invoiceTabs.INVOICE),
        InvoiceIcon
      )}

      {documentType(
        "Note de crédit",
        () => props.setTab(invoiceTabs.CREDIT_NOTE),
        CreditIcon
      )}
    </div>
  );
}
