export function DocumentChoiceType({ icon, name, onClick, alt }) {
  return (
    <button
      className="group max-w-sm w-full rounded border bg-white flex items-center gap-4 cursor-pointer overflow-hidden border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 dark:hover:border-neutral-300 shadow-sm"
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onClick();
        }
      }}
    >
      <div className="image-border bg-neutral-300 flex items-center gap-2.5 border-r border-neutral-300 group-hover:border-neutral-400">
        <img src={icon} alt={alt} />
      </div>
      <span className="text-black">{name}</span>
    </button>
  );
}
