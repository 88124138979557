import InputTailwind from "@/components/token/Input/inputTailwind";
import SelectNation from "@/components/token/selectNation/selectNation";
import { getParamByISO } from "iso-country-currency";
import { useEffect } from "react";

export function Country({
  initialCountry,
  disabled,
  onChange,
  isLoading,
  required,
  name = "country",
  info,
  forceValidate,
  countries,
  readOnly,
  // displayError = true
}) {
  const country = initialCountry;

  useEffect(() => {
    if (forceValidate && !(!required || initialCountry)) {
      onChange({
        success: !required || initialCountry,
        isDirty: true,
      });
    }
  }, [forceValidate, onChange, initialCountry, required]);

  const handleCountryChange = (c) => {
    onChange({
      success: !required || c,
      data: c,
      isDirty: true,
    });
  };

  return disabled || readOnly ? (
    <InputTailwind
      label="Pays"
      type="text"
      placeholder="Votre pays"
      value={country ? getParamByISO(country, "countryName") : ""}
      loading={isLoading}
      disabled={disabled}
      error={required && !country ? "Ce champ est requis" : null}
      isRequired={required}
      name={name}
      maxLength={56}
      info={info}
      readOnly={readOnly}
    ></InputTailwind>
  ) : (
    <SelectNation
      selectedNation={country}
      onSelect={handleCountryChange}
      disabled={disabled}
      countries={countries}
    />
  );
}
