import { useUser } from "@/contexts/UserContext";
import { useCreateNumerotationDocument } from "../useCreateNumerotationDocument";
import { useState } from "react";
import { notify } from "@/utils/notify";
import { useLastDocuments } from "../useInvoices";
import { CRUDL } from "@/utils/crudl";

export default function useFormNumbering({
  onSubmit,
  currentNumbers,
  isLoading,
  context = CRUDL.CREATE,
  baseEnterpriseId,
}) {
  /* Hooks */
  const userContext = useUser();
  const enterpriseId = baseEnterpriseId || userContext.state.user.enterpriseId;

  /* Default */
  const emptySimpleField = { success: null, data: "", isDirty: false };
  const findDocumentNumber = (candidates, documentType) => {
    if (candidates === undefined) return 0;

    const dn = candidates.find((d) => d.documentType === documentType);
    if (!dn) return 0;

    const type_year_nb = dn.invoiceNumber.split("-");
    const currentYear = new Date().getFullYear();
    if (parseInt(type_year_nb[1], 10) !== currentYear) return 0;

    return parseInt(dn.invoiceNumber.split("-")[2], 10);
  };

  const currentDocumentsNumber =
    currentNumbers.data?.payload.currentInvoiceNumbers;
  const _quote = findDocumentNumber(currentDocumentsNumber, "QUOTE").toString();
  const _invoice = findDocumentNumber(
    currentDocumentsNumber,
    "INVOICE"
  ).toString();
  const _creditNote = findDocumentNumber(
    currentDocumentsNumber,
    "CREDIT_NOTE"
  ).toString();

  /* Query */
  const lastDocuments = useLastDocuments(
    { enterpriseId },
    { enabled: !!enterpriseId }
  );
  const lastDocumentsData = lastDocuments.data?.payload || [];
  const lastQuoteNumber = findDocumentNumber(lastDocumentsData, "QUOTE");
  const lastInvoiceNumber = findDocumentNumber(lastDocumentsData, "INVOICE");
  const lastCreditNoteNumber = findDocumentNumber(
    lastDocumentsData,
    "CREDIT_NOTE"
  );

  /* Mutation */
  const createNumbering = useCreateNumerotationDocument(
    ["invoices", "current-invoice-numbers", { enterpriseId }],
    () => {
      if (onSubmit) {
        onSubmit();
      }
    }
  );

  /* State */
  const [quoteNumbering, setQuoteNumbering] = useState({
    ...emptySimpleField,
    data: _quote,
  });

  const [invoiceNumbering, setInvoiceNumbering] = useState({
    ...emptySimpleField,
    data: _invoice,
  });

  const [creditNoteNumbering, setCreditNoteNumbering] = useState({
    ...emptySimpleField,
    data: _creditNote,
  });

  const [isInvalidFormSubmitted, setIsInvalidFormSubmitted] = useState(false);
  const displayError = isInvalidFormSubmitted;
  const isFormDirty = (() => {
    return (
      quoteNumbering.isDirty ||
      invoiceNumbering.isDirty ||
      creditNoteNumbering.isDirty
    );
  })();

  const isValid = (() => {
    const successOrNull = (field) =>
      field.success === true || field.success === null;
    return (
      successOrNull(quoteNumbering) &&
      successOrNull(invoiceNumbering) &&
      successOrNull(creditNoteNumbering)
    );
  })();

  function handleSubmit() {
    if (!isFormDirty && context === CRUDL.UPDATE) {
      notify({
        isSuccess: false,
        message: "Aucune modification n'a été apportée",
      });
      return;
    }

    if (!isValid) {
      setIsInvalidFormSubmitted(true);
      return;
    }

    const documents = [];
    if (quoteNumbering.isDirty || context === CRUDL.CREATE) {
      documents.push({
        documentType: "QUOTE",
        documentNumber: parseInt(quoteNumbering.data, 10) || _quote,
      });
    }

    if (invoiceNumbering.isDirty || context === CRUDL.CREATE) {
      documents.push({
        documentType: "INVOICE",
        documentNumber: parseInt(invoiceNumbering.data, 10) || _invoice,
      });
    }

    if (creditNoteNumbering.isDirty || context === CRUDL.CREATE) {
      documents.push({
        documentType: "CREDIT_NOTE",
        documentNumber: parseInt(creditNoteNumbering.data, 10) || _creditNote,
      });
    }

    const documentNumbering = {
      documents: [...documents],
    };

    createNumbering.mutate({
      enterpriseId,
      documentData: documentNumbering,
    });
  }

  return {
    quoteNumbering,
    setQuoteNumbering,
    invoiceNumbering,
    setInvoiceNumbering,
    creditNoteNumbering,
    setCreditNoteNumbering,
    displayError,
    isFormDirty,
    isValid,
    handleSubmit,
    isUpdateLoading: createNumbering.isLoading,
    isDataLoading: isLoading,
    initialValues: {
      quote: _quote,
      invoice: _invoice,
      creditNote: _creditNote,
    },
    minValues: {
      quote: lastQuoteNumber,
      invoice: lastInvoiceNumber,
      creditNote: lastCreditNoteNumber,
    },
  };
}
