import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonTailwind from "@/components/token/button/buttonTailwind";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <>
      <div className="w-screen h-screen flex flex-col justify-center items-center gap-4 justify-center items-center">
        <div className="flex flex-col gap-2 justify-center items-center">
          <p className="text-6xl font-bold text-neutral-300">404</p>
          <p className="text-base text-neutral-300">
            Désolé, il n&apos;y a rien ici...
          </p>
        </div>

        <ButtonTailwind variant="outlined2" onClick={() => navigate(-1)}>
          Retour
        </ButtonTailwind>
      </div>
    </>
  );
}
