import ButtonTailwind from "@/components/token/button/buttonTailwind";
import { useNavigate } from "react-router-dom";

export function ButtonErrorFallbackRouter({ resetErrorBoundary }) {
  const navigate = useNavigate();
  return (
    <ButtonTailwind
      variant="outlined2"
      onClick={() => {
        navigate("/home?referrer=err");
        resetErrorBoundary();
      }}
    >
      {"Retour à l'accueil"}
    </ButtonTailwind>
  );
}

export function ButtonErrorFallbackGlobal({ resetErrorBoundary }) {
  return (
    <ButtonTailwind
      variant="outlined2"
      onClick={() => {
        resetErrorBoundary();
        window.history.pushState({}, "", "/login");
        window.location.reload();
      }}
    >
      {"Retour à l'écran de connexion"}
    </ButtonTailwind>
  );
}
