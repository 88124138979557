export function mergeDocumentAddresses(addresses1, addresses2) {
  const from2to1 = addresses1.map((address1) => {
    const address2 = addresses2.find(
      (a) => a.addressType === address1.addressType
    );
    if (!address2) return address1;
    return {
      addressType: address1.addressType,
      addressId: address2.addressId,
      address: {
        ...address1.address,
        ...address2.address,
      },
    };
  });

  const from1to2 = addresses2
    .filter(
      (address2) =>
        !addresses1.find((a) => a.addressType === address2.addressType)
    )
    .map((address2) => {
      return {
        addressType: address2.addressType,
        addressId: address2.addressId,
        address: address2.address,
      };
    });

  return [...from2to1, ...from1to2];
}
