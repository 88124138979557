export function serviceValidator(service, externalsError = []) {
  const errors = [];
  if (!service.title) {
    errors.push({ field: "title", error: "Le titre du service est requis" });
  }

  if (!service.unitPrice.amount && service.unitPrice.amount !== 0) {
    errors.push({
      field: "unitPrice.amount",
      error: "Le prix du service est requis",
    });
  }

  if (!service.unitPrice.currency) {
    errors.push({
      field: "unitPrice.currency",
      error: "La devise du service est requise",
    });
  }

  if (!service.quantity.amount) {
    errors.push({ field: "quantity.amount", error: "La quantité est requise" });
  } else if (isNaN(service.quantity.amount)) {
    errors.push({
      field: "quantity.amount",
      error: "La quantité doit être un nombre",
    });
  } else if (service.quantity.amount <= 0) {
    errors.push({
      field: "quantity.amount",
      error: "La quantité doit être supérieure à 0",
    });
  }

  if (!service.quantity.unit) {
    errors.push({ field: "quantity.unit", error: "L'unité est requise" });
  }

  if (externalsError.length) {
    errors.push(...externalsError);
  }

  return {
    isValid: errors.length === 0,
    errors,
  };
}
