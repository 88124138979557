import { ArrowDownOnSquareIconWithLoading } from "@/components/token/loading/ArrowDownOnSquareIconWithLoading";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentDuplicateIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { LidapIcon } from "@/components/token/loading/LidapIcon";
import { serviceValidator } from "./helpers/serviceValidator";
import { notify } from "@/utils/notify";

export function ServiceRibbon({
  index,
  service,
  loading,
  setDisplaySaveModal,
  removeService,
  duplicateService,
  validateService,
  isServiceExpanded,
  toggleService,
}) {
  const buttonClassName = isServiceExpanded
    ? "flex justify-between items-center w-full bg-gray-50 border-b border-gray-300 py-3 px-4 max-w-full"
    : "flex justify-between items-center w-full rounded-md border border-gray-300 bg-gray-50 py-3 px-4 max-w-full";

  return (
    <button className={buttonClassName}>
      <div className="flex justify-between items-center gap-2 w-full max-w-72">
        {/* <DragIndicatorIcon sx={{ width: "16px", color: "darkGrey" }} /> */}
        <span className="text-sm text-current text-black text-left truncate max-w-full">
          {service.title || "Titre"}
        </span>
      </div>
      <div className="flex justify-between items-center gap-3 max-w-full">
        <ArrowDownOnSquareIconWithLoading
          handleAction={() => {
            const { isValid, errors } = serviceValidator(service);
            const errorMessages = errors.map((error) => error.error);
            if (!isValid) {
              notify({
                isSuccess: false,
                message: "Veuillez corriger les erreurs :",
                list: errorMessages,
              });
              return;
            }
            setDisplaySaveModal(true);
          }}
          value={service.title}
          loading={loading}
          className="h-4 w-4 text-gray-400 stroke-2"
        />
        <LidapIcon
          Icon={TrashIcon}
          loading={false}
          handleAction={() => removeService(index)}
          value={index}
          toolTipText="Supprimer"
          className="h-4 w-4 text-gray-400 stroke-2"
        />
        <LidapIcon
          Icon={DocumentDuplicateIcon}
          loading={false}
          handleAction={() => duplicateService(service)}
          value={index}
          toolTipText="Dupliquer"
          className="h-4 w-4 text-gray-400 stroke-2"
        />
        {isServiceExpanded ? (
          <LidapIcon
            Icon={ChevronUpIcon}
            loading={false}
            handleAction={validateService}
            value={index}
            toolTipText="Réduire"
            className="h-4 w-4 text-gray-400 stroke-2"
          />
        ) : (
          <LidapIcon
            Icon={ChevronDownIcon}
            loading={false}
            handleAction={toggleService}
            value={index}
            toolTipText="Afficher"
            className="h-4 w-4 text-gray-400 stroke-2"
          />
        )}
      </div>
    </button>
  );
}
