import InputTailwind from "@/components/token/Input/inputTailwind";
import { useEffect } from "react";

export function DocumentNumberingInput({
  initialValue,
  disabled,
  onChange,
  isLoading,
  required,
  minLength = 1,
  maxLength = 4,
  name = "documentNumbering",
  label,
  displayError = true,
  placeholder = "Numérotation",
  forceValidate,
  minValue = 0,
}) {
  const numbering = initialValue;
  /* It should be a number between 0 and 9999 */

  const error = (numbering) => {
    if (required && !numbering) {
      return {
        success: false,
        message: "Ce champ est requis",
      };
    } else if (numbering && numbering.length < minLength) {
      return {
        success: false,
        message: "Le numéro doit contenir au moins 1 caractère",
      };
    } else if (numbering && numbering.length > maxLength) {
      return {
        success: false,
        message: "Le numéro doit contenir au maximum 4 caractères",
      };
    } else if (isNaN(numbering)) {
      return {
        success: false,
        message: "Le numéro doit être un nombre",
      };
    } else if (numbering < 0 || numbering > 9999) {
      return {
        success: false,
        message: "Le numéro doit être entre 0 et 9999",
      };
    } else if (numbering < minValue) {
      return {
        success: false,
        message: `Vous avez déjà créé ${minValue} document(s) de ce type cette année`,
      };
    } else {
      return {
        success: true,
        message: null,
      };
    }
  };

  useEffect(() => {
    if (forceValidate && !error(initialValue).success) {
      onChange({
        success: !error(initialValue).success,
        isDirty: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceValidate, onChange, initialValue]);

  const handleNumberChange = (e) => {
    const value = e.target.value;
    onChange({
      success: error(value).success,
      data: value,
      isDirty: true,
    });
  };

  return (
    <InputTailwind
      label={label ? label : "Numéro de document"}
      type="text"
      placeholder={placeholder}
      value={numbering}
      onChange={(e) => handleNumberChange(e)}
      loading={isLoading}
      disabled={disabled}
      error={displayError ? error(numbering).message : null}
      isRequired={required}
      name={name}
      maxLength={4}
      minLength={1}
    ></InputTailwind>
  );
}
