import { useMutation, useQueryClient } from "@tanstack/react-query";
import Lidapi from "@/utils/lidapi";

export function useUpdateUser(queryKeyToInvalidate, onSuccessCallback) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async (data) => await api.updateUser(data),
    onSuccess: async () => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }

      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
  });

  return action;
}

export function useUpdateUserInfo(
  queryKeyToInvalidate,
  onSuccessCallback,
  disableToast = false,
  reactQueryConfig = {}
) {
  const queryClient = useQueryClient();
  const api = new Lidapi(disableToast);

  const action = useMutation({
    mutationFn: async (forceSearch) => await api.getUserInfo(forceSearch),
    onSuccess: async (response) => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }

      if (onSuccessCallback) {
        onSuccessCallback(response);
      }
    },
    ...reactQueryConfig,
  });

  return action;
}
