import SkeletonElement from "@/components/token/loading/SkeletonElement";
import { countriesName } from "@/data/countryCodeCorrespondance";

export function ClientPreview({
  country,
  addresses,
  additionalData,
  identifiers,
}) {
  if (
    !addresses?.filter((address) => address.addressType === "RECEIVER_BILLING")
  ) {
    return (
      <div className="space-y-2 mt-1">
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
      </div>
    );
  }

  const receiverName =
    additionalData?.find((data) => data?.additionalDataType === "RECEIVER_NAME")
      ?.additionalData || "";

  const receiverVAT = identifiers?.find(
    (id) => id.identifierType == "VAT"
  )?.identifier;

  const { enterpriseNumber, enterpriseNumberLabel } = (() => {
    const identifierType = country === "FR" ? "SIREN" : "COMPANY_NUMBER";
    const enterpriseNumber = identifiers?.find(
      (id) => id.identifierType === identifierType
    );

    const enterpriseNumberLabel =
      enterpriseNumber?.identifierType === "SIREN" ? "SIREN" : "N°";

    return {
      enterpriseNumber: enterpriseNumber?.identifier,
      enterpriseNumberLabel,
    };
  })();

  return (
    <>
      {addresses
        .filter((address) => address.addressType === "RECEIVER_BILLING")
        ?.map((addressItem, index) => (
          <div key={index} className="flex flex-col">
            <span>{receiverName}</span>

            <span>
              {addressItem?.address?.streetName}{" "}
              {addressItem?.address?.streetNumber}
            </span>

            <span>
              {addressItem?.address?.postalCode}
              {addressItem?.address?.postalCode && addressItem?.address?.city
                ? ","
                : ""}
              {addressItem?.address?.city}
            </span>

            <span>{countriesName[addressItem?.address?.country]}</span>
            {enterpriseNumber ? (
              <span>
                {enterpriseNumberLabel} {enterpriseNumber}
              </span>
            ) : null}
            {receiverVAT ? <span>N° TVA : {receiverVAT}</span> : null}
          </div>
        ))}
    </>
  );
}
