import { DocumentAdditionalDataSubType } from "@/feature/document/DocumentAdditionalDataSubType";
import { DocumentAdditionalDataType } from "@/feature/document/DocumentAdditionalDataType";
import { DocumentAddressType } from "@/feature/document/DocumentAddressType";
import {
  DocumentSettingsKey,
  extractSetting,
  SettingsType,
} from "@/feature/Settings/helpers/extractSetting";
import { buildClientDataForDocument } from "./buildClientDataForDocument";
import { extractPaymentInformationValue } from "../../NewPaymentForm/extractPaymentInformationValue";
import { DocumentStatus } from "@/components/token/documents/DocumentStatus";
import { LidapDocumentType } from "@/components/token/documents/DocumentType";
import { CRUDL } from "@/utils/crudl";

export function documentInitial({
  context = CRUDL.CREATE,
  enterprise,
  documentType,
  documentSettings,
  enterprisePhones,
  //   companyLogo, //TODO! to handle in the future
  selectedClient,
  paymentInformation,
  draftDocument,
  preload,
  linkedDocument,
}) {
  const { issuingDate, dueDate } = documentDateBuilder({
    documentType,
    documentSettings,
  });

  const issuerAddress = enterprise.addresses.find((address) => address.isMain);
  const issuerVatNumber = enterprise.identifiers.find(
    (identifier) => identifier.identifierType === "VAT"
  );
  const issuerCompanyNumber = enterprise.identifiers.find(
    (identifier) =>
      identifier.identifierType ===
      (enterprise.country === "FR" ? "SIREN" : "COMPANY_NUMBER")
  );

  const emailOnDocuments = extractSetting({
    settingsData: documentSettings,
    settingsType: SettingsType.DOCUMENT,
    settingsKey: DocumentSettingsKey.EMAIL_ON_DOCUMENTS,
  });
  const issuerEmail =
    enterprise.emails.find((email) => email.id === emailOnDocuments) ||
    enterprise.emails.find((email) => email.isMain);
  const phoneOnDocuments = extractSetting({
    settingsData: documentSettings,
    settingsType: SettingsType.DOCUMENT,
    settingsKey: DocumentSettingsKey.PHONE_ON_DOCUMENTS,
  });
  const issuerPhone =
    enterprisePhones.find((phone) => phone.id === phoneOnDocuments) ||
    enterprisePhones.find((phone) => phone.isMain);

  /* Build the document */
  const addresses = [
    {
      addressType: DocumentAddressType.ISSUER_BILLING,
      addressId: issuerAddress.id,
      address: {
        country: issuerAddress.country,
        region: issuerAddress.region,
        streetName: issuerAddress.streetName,
        streetNumber: issuerAddress.streetNumber,
        postalCode: issuerAddress.postalCode,
        city: issuerAddress.city,
        boxNumber: issuerAddress.boxNumber,
      },
    },
  ];

  /* These additional data are invariant (new document or preload) */
  const additionalData = [
    {
      additionalData: enterprise.name,
      additionalDataType: DocumentAdditionalDataType.ISSUER_NAME,
      additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
    },
    {
      additionalData: issuerCompanyNumber.identifier,
      additionalDataType: DocumentAdditionalDataType.ISSUER_COMPANY_NUMBER,
      additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
    },
  ];

  if (issuerVatNumber?.identifier) {
    additionalData.push({
      additionalData: issuerVatNumber.identifier,
      additionalDataType: DocumentAdditionalDataType.ISSUER_VAT_NUMBER,
      additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
    });
  }

  if (draftDocument) {
    const additionalDataFromDraft = buildAdditionalDataFromDraft({
      draftDocument,
      paymentInformation,
      documentSettings,
      issuerEmail,
      issuerPhone,
    });
    additionalData.push(...additionalDataFromDraft);
  } else if (preload) {
    const additionalDataFromPreload = buildAdditionalDataFromPreload({
      preload,
      paymentInformation,
      documentType,
      documentSettings,
      issuerEmail,
      issuerPhone,
    });
    additionalData.push(...additionalDataFromPreload);
  } else {
    const additionalDataForNewDocument = buildAdditionalDataFromSettings({
      documentType,
      documentSettings,
      issuerEmail,
      issuerPhone,
      paymentInformation,
    });

    additionalData.push(...additionalDataForNewDocument);
  }

  if (selectedClient) {
    const {
      addresses: receiverAddresses,
      additionalData: receiverAdditionalData,
    } = buildClientDataForDocument(selectedClient);

    addresses.push(...receiverAddresses);
    additionalData.push(...receiverAdditionalData);
  }

  const services = [];
  if (preload && preload.documentType !== LidapDocumentType.CREDIT_NOTE) {
    const preloadedServices = buildServicesFromExistingDocument({
      existingDocument: preload,
    });
    services.push(...preloadedServices);
  } else if (linkedDocument) {
    const preloadedServices = buildServicesFromExistingDocument({
      existingDocument: linkedDocument,
    });
    services.push(...preloadedServices);
  } else if (draftDocument && draftDocument.services?.length > 0) {
    const preloadedServices = buildServicesFromExistingDocument({
      existingDocument: draftDocument,
    });
    services.push(...preloadedServices);
  }

  const invoiceNumber =
    context === CRUDL.CREATE ? null : draftDocument?.invoiceNumber;
  return {
    clientId: selectedClient?.id,
    enterpriseId: enterprise.id,
    enterprise: enterprise,
    invoiceNumber: invoiceNumber,
    issuingDate: issuingDate,
    dueDate: dueDate,
    purchaseOrder: null,
    services: services,
    addresses: addresses,
    additionalData: additionalData,
    comment: null,
    invoiceStatus: DocumentStatus.DRAFT,
    documentType: documentType || preload?.documentType,
    receiptDate: null,
    isEmailOnDocument: !!issuerEmail,
    isPhoneOnDocument: !!issuerPhone,
    selectedInvoice: linkedDocument || null,
    errors: [],
  };
}

function extractCoreSettings({ documentSettings, documentType }) {
  const documentBodyColor = extractSetting({
    settingsData: documentSettings,
    settingsType: SettingsType.DOCUMENT,
    settingsKey: DocumentSettingsKey.DOCUMENT_BODY_COLOR,
  });
  const documentTitleColor = extractSetting({
    settingsData: documentSettings,
    settingsType: SettingsType.DOCUMENT,
    settingsKey: DocumentSettingsKey.DOCUMENT_TITLE_COLOR,
  });
  const documentConditions = extractSetting({
    settingsData: documentSettings,
    settingsType: SettingsType.DOCUMENT,
    settingsKey: DocumentSettingsKey.DOCUMENT_CONDITIONS,
  });
  const vatDisclaimer = extractSetting({
    settingsData: documentSettings,
    settingsType: SettingsType.DOCUMENT,
    settingsKey: DocumentSettingsKey.VAT_DISCLAIMER,
  });

  const documentConditionsType =
    documentType === LidapDocumentType.CREDIT_NOTE
      ? DocumentAdditionalDataType.CREDIT_NOTE_CONDITIONS
      : DocumentAdditionalDataType.INVOICE_CONDITIONS;

  return {
    documentBodyColor,
    documentTitleColor,
    documentConditions,
    vatDisclaimer,
    documentConditionsType,
  };
}

function buildAdditionalDataFromSettings({
  documentType,
  documentSettings,
  issuerEmail,
  issuerPhone,
  paymentInformation,
  excludeDataType = [],
}) {
  const {
    documentBodyColor,
    documentTitleColor,
    documentConditions,
    vatDisclaimer,
    documentConditionsType,
  } = extractCoreSettings({ documentSettings, documentType });

  const additionalData = [];
  if (
    !excludeDataType.includes(DocumentAdditionalDataType.DOCUMENT_BODY_COLOR)
  ) {
    additionalData.push({
      additionalData: documentBodyColor,
      additionalDataType: DocumentAdditionalDataType.DOCUMENT_BODY_COLOR,
      additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
    });
  }

  if (
    !excludeDataType.includes(DocumentAdditionalDataType.DOCUMENT_TITLE_COLOR)
  ) {
    additionalData.push({
      additionalData: documentTitleColor,
      additionalDataType: DocumentAdditionalDataType.DOCUMENT_TITLE_COLOR,
      additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
    });
  }

  if (!excludeDataType.includes(DocumentAdditionalDataType.VAT_DISCLAIMER)) {
    additionalData.push({
      additionalData: vatDisclaimer,
      additionalDataType: DocumentAdditionalDataType.VAT_DISCLAIMER,
      additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
    });
  }

  if (!excludeDataType.includes(documentConditionsType)) {
    additionalData.push({
      additionalData: documentConditions,
      additionalDataType: documentConditionsType,
      additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
    });
  }

  if (
    issuerEmail?.email &&
    !excludeDataType.includes(DocumentAdditionalDataType.ISSUER_EMAIL)
  ) {
    additionalData.push({
      additionalData: issuerEmail.email,
      additionalDataType: DocumentAdditionalDataType.ISSUER_EMAIL,
      additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
    });
  }

  if (
    issuerPhone?.phoneNumber &&
    !excludeDataType.includes(DocumentAdditionalDataType.ISSUER_PHONE)
  ) {
    additionalData.push({
      additionalData: `+${issuerPhone.geoCode}${issuerPhone.phoneNumber}`,
      additionalDataType: DocumentAdditionalDataType.ISSUER_PHONE,
      additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
    });
  }

  if (
    paymentInformation &&
    documentType !== LidapDocumentType.CREDIT_NOTE &&
    !excludeDataType.includes(DocumentAdditionalDataType.PAYMENT_METHOD)
  ) {
    const { stakeholderPaymentInformation } = paymentInformation;
    for (const i in stakeholderPaymentInformation) {
      const p = stakeholderPaymentInformation[i];
      const { paymentMethod, value } = extractPaymentInformationValue({
        paymentInformationDetails: p.paymentInformationDetails,
        title: p.title,
      });

      additionalData.push({
        additionalData: value,
        additionalDataType: DocumentAdditionalDataType.PAYMENT_METHOD,
        additionalDataSubType: paymentMethod,
        externalId: p.id,
      });
    }
  }

  return additionalData;
}

function buildAdditionalDataFromPreload({
  preload,
  paymentInformation,
  documentType,
  documentSettings,
  issuerEmail,
  issuerPhone,
}) {
  if (!preload) return [];

  /* The document type is in priority the one selected by the user. If none is selected, then it falls back to the preloaded document */
  const effectiveDocumentType = documentType || preload.documentType;

  const additionalDataFromPreload = [];
  const additionalDataTypesToCopy = [
    // DocumentAdditionalDataType.ISSUER_PHONE,
    // DocumentAdditionalDataType.ISSUER_EMAIL,
    // DocumentAdditionalDataType.DOCUMENT_BODY_COLOR,
    // DocumentAdditionalDataType.DOCUMENT_TITLE_COLOR,
    // DocumentAdditionalDataType.VAT_DISCLAIMER,
  ];

  if (effectiveDocumentType === LidapDocumentType.CREDIT_NOTE) {
    const creditNoteSpecific = [
      // DocumentAdditionalDataType.CREDIT_NOTE_PAYMENT_APPLICATION_METHOD,
      // DocumentAdditionalDataType.CREDIT_NOTE_REASON,
      // DocumentAdditionalDataType.CREDIT_NOTE_CONDITIONS,
    ];
    additionalDataTypesToCopy.push(...creditNoteSpecific);
  } else if (effectiveDocumentType === LidapDocumentType.INVOICE) {
    const invoiceSpecific = [
      // DocumentAdditionalDataType.INVOICE_CONDITIONS,
      // DocumentAdditionalDataType.INVOICE_PAYMENT_CONDITIONS,
    ];
    additionalDataTypesToCopy.push(...invoiceSpecific);
  }

  const additionalDataFromSettings = buildAdditionalDataFromSettings({
    documentType: effectiveDocumentType,
    documentSettings,
    issuerEmail,
    issuerPhone,
    paymentInformation,
    excludeDataType: additionalDataTypesToCopy,
  });

  additionalDataFromPreload.push(...additionalDataFromSettings);

  if (preload.additionalData) {
    for (const i in preload.additionalData) {
      const p = preload.additionalData[i];
      if (additionalDataTypesToCopy.includes(p.additionalDataType)) {
        additionalDataFromPreload.push({
          additionalData: p.additionalData,
          additionalDataType: p.additionalDataType,
          additionalDataSubType: p.additionalDataSubType,
          externalId: p.externalId,
        });
      }
    }
  } else {
    return additionalDataFromPreload;
  }

  /* We copy the payment information from the duplicated document, but only if it still exists */
  // const preloadPaymentInformation = preload.additionalData.filter(
  //   (a) => a.additionalDataType === DocumentAdditionalDataType.PAYMENT_METHOD
  // );
  // if (preloadPaymentInformation && paymentInformation) {
  //   const { stakeholderPaymentInformation } = paymentInformation;
  //   for (const i in preloadPaymentInformation) {
  //     const p = preloadPaymentInformation[i];
  //     /* Check if it still exists */
  //     const existingPaymentInformation = stakeholderPaymentInformation.find(
  //       (s) => s.id === p.externalId
  //     );
  //     if (existingPaymentInformation) {
  //       const { paymentMethod, value } = extractPaymentInformationValue({
  //         paymentInformationDetails:
  //           existingPaymentInformation.paymentInformationDetails,
  //         title: existingPaymentInformation.title,
  //       });

  //       additionalDataFromPreload.push({
  //         additionalData: value,
  //         additionalDataType: DocumentAdditionalDataType.PAYMENT_METHOD,
  //         additionalDataSubType: paymentMethod,
  //         externalId: existingPaymentInformation.id,
  //       });
  //     }
  //   }
  // }

  return additionalDataFromPreload;
}

function buildAdditionalDataFromDraft({
  draftDocument,
  paymentInformation,
  documentSettings,
  issuerEmail,
  issuerPhone,
}) {
  if (!draftDocument) return [];

  /* The document type is in priority the one selected by the user. If none is selected, then it falls back to the draft document */
  const effectiveDocumentType = draftDocument.documentType;

  const additionalDataFromDraft = [];
  const additionalDataTypesToCopy = [
    DocumentAdditionalDataType.DOCUMENT_TITLE,
    // DocumentAdditionalDataType.ISSUER_PHONE,
    // DocumentAdditionalDataType.ISSUER_EMAIL,
    // DocumentAdditionalDataType.DOCUMENT_BODY_COLOR,
    // DocumentAdditionalDataType.DOCUMENT_TITLE_COLOR,
    // DocumentAdditionalDataType.VAT_DISCLAIMER,
  ];

  if (effectiveDocumentType === LidapDocumentType.CREDIT_NOTE) {
    const creditNoteSpecific = [
      // DocumentAdditionalDataType.CREDIT_NOTE_PAYMENT_APPLICATION_METHOD,
      // DocumentAdditionalDataType.CREDIT_NOTE_REASON,
      // DocumentAdditionalDataType.CREDIT_NOTE_CONDITIONS,
    ];
    additionalDataTypesToCopy.push(...creditNoteSpecific);
  } else if (effectiveDocumentType === LidapDocumentType.INVOICE) {
    const invoiceSpecific = [
      // DocumentAdditionalDataType.INVOICE_CONDITIONS,
      // DocumentAdditionalDataType.INVOICE_PAYMENT_CONDITIONS,
    ];
    additionalDataTypesToCopy.push(...invoiceSpecific);
  }

  const additionalDataFromSettings = buildAdditionalDataFromSettings({
    documentType: effectiveDocumentType,
    documentSettings,
    issuerEmail,
    issuerPhone,
    paymentInformation,
    excludeDataType: additionalDataTypesToCopy,
  });

  additionalDataFromDraft.push(...additionalDataFromSettings);

  if (draftDocument.additionalData) {
    for (const i in draftDocument.additionalData) {
      const p = draftDocument.additionalData[i];
      if (additionalDataTypesToCopy.includes(p.additionalDataType)) {
        additionalDataFromDraft.push({
          additionalData: p.additionalData,
          additionalDataType: p.additionalDataType,
          additionalDataSubType: p.additionalDataSubType,
          externalId: p.externalId,
        });
      }
    }
  } else {
    return additionalDataFromDraft;
  }

  /* We copy the payment information from the draft document, but only if it still exists */
  // const draftDocumentPaymentInformation = draftDocument.additionalData.filter(
  //   (a) => a.additionalDataType === DocumentAdditionalDataType.PAYMENT_METHOD
  // );
  // if (draftDocumentPaymentInformation && paymentInformation) {
  //   const { stakeholderPaymentInformation } = paymentInformation;
  //   for (const i in draftDocumentPaymentInformation) {
  //     const p = draftDocumentPaymentInformation[i];
  //     /* Check if it still exists */
  //     const existingPaymentInformation = stakeholderPaymentInformation.find(
  //       (s) => s.id === p.externalId
  //     );
  //     if (existingPaymentInformation) {
  //       const { paymentMethod, value } = extractPaymentInformationValue({
  //         paymentInformationDetails:
  //           existingPaymentInformation.paymentInformationDetails,
  //         title: existingPaymentInformation.title,
  //       });

  //       additionalDataFromDraft.push({
  //         additionalData: value,
  //         additionalDataType: DocumentAdditionalDataType.PAYMENT_METHOD,
  //         additionalDataSubType: paymentMethod,
  //         externalId: existingPaymentInformation.id,
  //       });
  //     }
  //   }
  // }

  return additionalDataFromDraft;
}

export function buildServicesFromExistingDocument({ existingDocument }) {
  const preloadedServices = existingDocument.services.map((s) => {
    const i = existingDocument.services.indexOf(s);
    return {
      id: i,
      title: s.title,
      description: s.description,
      quantity: {
        amount: s.quantity.amount,
        unit: s.quantity.unit,
      },
      unitPrice: {
        amount: s.unitPrice.amount,
        currency: s.unitPrice.currency,
      },
      vatRate: {
        taxRate: s.vatRate.taxRate,
        countryOrRegion: s.vatRate.countryOrRegion,
      },
      isDisplayed: false,
    };
  });

  return preloadedServices;
}

export function documentDateBuilder({ documentType, documentSettings }) {
  const today = new Date();

  const issuingDate = today.toISOString();
  const dueDate =
    documentType === LidapDocumentType.QUOTE
      ? dateWithDefaultDelay({ documentSettings }).toISOString()
      : null;

  return {
    issuingDate,
    dueDate,
  };
}

export function dateWithDefaultDelay({ documentSettings }) {
  const delay = extractSetting({
    settingsData: documentSettings,
    settingsType: SettingsType.DOCUMENT,
    settingsKey: DocumentSettingsKey.DELAY_QUOTE_DAYS,
  });
  return new Date(new Date().setDate(new Date().getDate() + delay));
}
