import { useQuery } from "@tanstack/react-query";
import { stringify } from "@/utils/stringify";
import { manageLidapResponse } from "@/utils/lidapi";

export function useAddresses({ stakeholderId, queryParam, reactQueryOptions }) {
  const stakeholderParams = stakeholderId ? `/${stakeholderId}` : "";
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["addresses", { stakeholderId }, queryParam],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/stakeholders${stakeholderParams}/addresses/${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return manageLidapResponse(response, "Could not load addresses");
    },
    ...reactQueryOptions,
  });

  return {
    isLoading: isLoading,
    isError: isError,
    data: data,
    error: error,
  };
}
