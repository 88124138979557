import React from "react";
import LoadingBlock from "./LoadingBlock";

function CenteredLoadingBlock({ message }) {
  return (
    <div className="flex w-full h-screen justify-center items-center bg-neutral-100">
      <LoadingBlock message={message} />
    </div>
  );
}

export default CenteredLoadingBlock;
