import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { withFaroRouterInstrumentation } from "@grafana/faro-react";
import { initializeFaro } from "@/utils/faro/faro";
import { routes } from "@/Routes/routes";
import { useMemo } from "react";
import { useIsAdmin } from "./contexts/UserContext";
import { adminRoutes } from "./Routes/admin/adminRoutes";

export const faro = initializeFaro();

export function AppRouter() {
  const isAdmin = useIsAdmin();

  const browserRouter = useMemo(() => {
    const allRoutes = isAdmin ? [...routes, ...adminRoutes] : routes;

    const reactBrowserRouter = createBrowserRouter(allRoutes);
    const browserRouter = withFaroRouterInstrumentation(reactBrowserRouter);
    return browserRouter;
  }, [isAdmin]);

  return <RouterProvider router={browserRouter}></RouterProvider>;
}
