import { DocumentAdditionalDataSubType } from "@/feature/document/DocumentAdditionalDataSubType";
import { DocumentAdditionalDataType } from "@/feature/document/DocumentAdditionalDataType";
import { DocumentAddressType } from "@/feature/document/DocumentAddressType";

export function buildClientDataForDocument(client) {
  const address = client.addresses.find((address) => address.isMain);
  const vatNumber = client.identifiers.find(
    (identifier) => identifier.identifierType === "VAT"
  );
  const companyNumber = client.identifiers.find(
    (identifier) =>
      identifier.identifierType ===
      (client.country === "FR" ? "SIREN" : "COMPANY_NUMBER")
  );

  const addresses = [
    {
      addressType: DocumentAddressType.RECEIVER_BILLING,
      addressId: address.id,
      address: {
        country: address.country,
        region: address.region,
        streetName: address.streetName,
        streetNumber: address.streetNumber,
        postalCode: address.postalCode,
        city: address.city,
        boxNumber: address.boxNumber,
      },
    },
  ];

  const additionalData = [
    {
      additionalData: client.name,
      additionalDataType: DocumentAdditionalDataType.RECEIVER_NAME,
      additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
    },
  ];

  if (vatNumber) {
    additionalData.push({
      additionalData: vatNumber.identifier,
      additionalDataType: DocumentAdditionalDataType.RECEIVER_VAT_NUMBER,
      additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
    });
  }

  if (companyNumber) {
    additionalData.push({
      additionalData: companyNumber.identifier,
      additionalDataType: DocumentAdditionalDataType.RECEIVER_COMPANY_NUMBER,
      additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
    });
  }

  return {
    id: client.id,
    addresses,
    additionalData,
  };
}
