import Lidapi from "@/utils/lidapi";
import { stringify } from "@/utils/stringify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export function getExternalRevenue({ enterpriseId, queryParam, disableToast }) {
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";
  const api = new Lidapi(disableToast);

  return {
    queryKey: [
      "analytics",
      "external",
      "revenue",
      { enterpriseId },
      queryParam,
    ],
    queryFn: () => api.getExternalRevenue(enterpriseId, queryString),
  };
}

export function useExternalRevenue({
  enterpriseId,
  queryParam,
  reactQueryConfig,
  disableToast,
}) {
  const queryClient = useQueryClient();
  const { queryFn, queryKey } = getExternalRevenue({
    enterpriseId,
    queryParam,
    disableToast,
  });

  const query = useQuery(queryKey, {
    queryFn: queryFn,
    initialData: () => {
      const dataFromCache = queryClient.getQueryData(queryKey);
      return dataFromCache;
    },
    ...reactQueryConfig,
  });

  return query;
}

export function useManageExternalRevenue(
  queryKeyToInvalidate,
  multipleQueryKeysToInvalidate,
  onSuccessCallback
) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async ({ enterpriseId, revenue, queryParam }) => {
      const queryString = queryParam ? `?${stringify(queryParam)}` : "";

      return await api.manageExternalRevenue({
        enterpriseId,
        revenue,
        queryString,
      });
    },
    onSuccess: () => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (
        multipleQueryKeysToInvalidate &&
        multipleQueryKeysToInvalidate.length > 0
      ) {
        multipleQueryKeysToInvalidate.forEach((queryKey) => {
          queryClient.invalidateQueries({
            queryKey: [...queryKey],
          });
        });
      }
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
  });

  return action;
}
