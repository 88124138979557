import React from "react";
import {
  ButtonErrorFallbackGlobal,
  ButtonErrorFallbackRouter,
} from "./ErrorFallbackActions";

function ErrorFallbackComponent({ error, resetErrorBoundary, context }) {
  return (
    <div
      role="alert"
      className="w-screen h-screen flex flex-col justify-center items-center gap-4 justify-center items-center"
    >
      <div className="flex flex-col gap-2 justify-center items-center">
        <div className="flex flex-col gap-1 justify-center items-center">
          <p className="text-base text-neutral-300">
            Une erreur est survenue...
          </p>
          <p className="mt-1 text-base text-neutral-300">
            Si le problème persiste, veuillez contacter{" "}
            <a style={{ color: "#E16D64" }} href={`mailto:support@lidap.io`}>
              support@lidap.io
            </a>
          </p>
        </div>
      </div>

      {context === "router" && (
        <ButtonErrorFallbackRouter resetErrorBoundary={resetErrorBoundary} />
      )}
      {context === "global" && (
        <ButtonErrorFallbackGlobal resetErrorBoundary={resetErrorBoundary} />
      )}
    </div>
  );
}

export default ErrorFallbackComponent;
