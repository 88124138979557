import { DocumentAdditionalDataSubType } from "../document/DocumentAdditionalDataSubType";
import { DocumentAdditionalDataType } from "../document/DocumentAdditionalDataType";
import { extractDocumentAdditionalData } from "../document/helpers/extractDocumentAdditionalData";

export function displayInvoiceChoice(invoice) {
  const invoiceNumber = invoice.invoiceNumber;
  const documentUserTitle = extractDocumentAdditionalData({
    additionalDataArray: invoice.additionalData,
    additionalDataType: DocumentAdditionalDataType.DOCUMENT_TITLE,
    additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
  });

  if (!documentUserTitle) {
    return invoiceNumber;
  }

  return `${invoiceNumber} - ${documentUserTitle}`;
}
