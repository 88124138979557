import { useOryAuth } from "@/contexts/OryAuthContext";
import { Navigate } from "react-router-dom";

export default function RedirectWhenLoggedInRoute({ children }) {
  const auth = useOryAuth();

  if (auth.session) {
    return <Navigate to={"/switch"} replace={true} />;
  }

  return <div className="flex flex-row w-screen">{children}</div>;
}
