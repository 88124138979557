import ButtonTailwind from "@/components/token/button/buttonTailwind";
import ClientInformation from "@/feature/clients/client360/ClientInformation";
import { useNavigate } from "react-router-dom";

export default function Client360({
  context,
  onCancel,
  enterpriseId,
  data,
  isError,
  isLoading,
  onSubmit,
}) {
  /* Hooks */
  const navigate = useNavigate();

  /* Render */
  return (
    <div className="flex flex-row justify-center w-full h-screen overflow-scroll">
      <div className="flex flex-col w-full max-w-[768px] p-8 bg-white rounded-lg mt-12 mb-24 h-max">
        <div className="flex flex-row w-full justify-between items-center mb-12">
          <p className="text-xl font-bold">{data.name}</p>
          <ButtonTailwind
            onClick={() => {
              navigate(`/documents/create?clientId=${data.id}`);
            }}
          >
            Nouveau document
          </ButtonTailwind>
        </div>

        <ClientInformation
          key={context + enterpriseId}
          enterpriseId={enterpriseId}
          onSubmit={onSubmit}
          isLoading={isLoading}
          isError={isError}
          context={context}
          data={data}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
}
