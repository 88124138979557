import { React, useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  Cog6ToothIcon,
  ArrowRightStartOnRectangleIcon,
} from "@heroicons/react/24/solid";

export default function PopUpMenu(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const [darkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <div
      className="bg-white flex flex-col ring-1 ring-inset ring-gray-300 rounded-lg py-1"
      id="user-pop-up-box"
      style={{ display: `${props.isCardDisplayed ? "flex" : "none"}` }}
      ref={props.PopUpMenuRef}
    >
      <div>
        {/* <div
          className="popup-menu-element hover:bg-gray-100 dark:hover:bg-neutral-900 text-gray-500 mx-1 rounded-md"
          onClick={() => setDarkMode(!darkMode)}
        >
          <MoonIcon className="h-4 w-4" />
          <p className="text-sm">Mode sombre</p>
        </div> */}

        {/* <div className="popup-menu-element hover:bg-gray-100 dark:hover:bg-neutral-900 text-gray-500 mx-1 rounded-md">
          <LifebuoyIcon className="h-4 w-4" />
          <p className="text-sm">Aide</p>
        </div> */}

        <NavLink
          to={{
            pathname: "/settings/profile",
          }}
          state={{ from: location }}
          className="popup-menu-element hover:bg-gray-100 dark:hover:bg-neutral-900 text-gray-500 mx-1 rounded-md"
        >
          <Cog6ToothIcon className="h-4 w-4" />
          <p className="text-sm">Paramètres</p>
        </NavLink>
      </div>

      <div
        className="popup-menu-element hover:bg-gray-100 dark:hover:bg-neutral-900 text-gray-500 mx-1 rounded-md w-56"
        onClick={() => {
          navigate("/logout");
        }}
      >
        <ArrowRightStartOnRectangleIcon className="h-4 w-4" />
        <p className="text-sm">Se déconnecter</p>
      </div>
    </div>
  );
}
