import { DateDefaults } from "./DateDefaults";

export const DateFormats = {
  MMDDYYYY: "MM/dd/yyyy",
  DDMMYYYY: "dd/MM/yyyy",
  YYYYMMDD: "yyyy-MM-dd",
};

export function transformDate(date, format = DateFormats.MMDDYYYY) {
  const dateObj = new Date(date);
  const day = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const month = dateObj.getDate().toString().padStart(2, "0");
  const year = dateObj.getFullYear();
  if (format === DateFormats.DDMMYYYY) return `${day}/${month}/${year}`;
  if (format === DateFormats.YYYYMMDD) return `${year}-${month}-${day}`;
  return `${month}/${day}/${year}`;
}

export function convertStringToDate(dateString) {
  if (!dateString) return null;
  const [day, month, year] = dateString.split("/");
  return new Date(year, month - 1, day);
}

function timeZoneOffsetInMinutes(ianaTimeZone) {
  const now = new Date();
  now.setSeconds(0, 0);

  // Format current time in `ianaTimeZone` as `M/DD/YYYY, HH:MM:SS`:
  const tzDateString = now.toLocaleString("en-US", {
    timeZone: ianaTimeZone,
    hourCycle: "h23",
  });

  // Parse formatted date string:
  const match = /(\d+)\/(\d+)\/(\d+), (\d+):(\d+)/.exec(tzDateString);
  // eslint-disable-next-line no-unused-vars
  const [_, month, day, year, hour, min] = match.map(Number);

  // Change date string's time zone to UTC and get timestamp:
  const tzTime = Date.UTC(year, month - 1, day, hour, min);

  // Return the offset between UTC and target time zone:
  return Math.floor((tzTime - now.getTime()) / (1000 * 60));
}

function convertTimezoneOffsetToUTC(offset) {
  const hours = Math.floor(offset / 60);
  const remainingMinutes = offset % 60;

  const sign = offset > 0 ? "+" : "-";

  return `${sign}${String(hours).padStart(2, "0")}:${String(
    remainingMinutes
  ).padStart(2, "0")}`;
}

function timeZoneOffsetUTC(ianaTimeZone) {
  const offset = timeZoneOffsetInMinutes(ianaTimeZone);
  return convertTimezoneOffsetToUTC(offset);
}

/**
 * This function converts a string date to a JavaScript Date object
 * If the string date is today, it returns the current date and time
 * Otherwise, it returns the date at midnight in the user's timezone
 * @param {*} dateString
 * @param {*} userTimezone
 * @param {*} userLocale
 * @returns
 */
export function formatDateToISO(
  dateString,
  userTimezone = DateDefaults.TIMEZONE,
  userLocale = DateDefaults.LOCALE
) {
  const [day, month, year] = dateString.split("/").map(Number);

  const now = new Date().toISOString(userLocale, { timeZone: userTimezone });
  const today = new Date(now);

  const date = new Date(Date.UTC(year, month - 1, day));

  const isToday =
    today.getUTCFullYear() === date.getUTCFullYear() &&
    today.getUTCMonth() === date.getUTCMonth() &&
    today.getUTCDate() === date.getUTCDate();

  if (isToday) {
    return new Date(
      new Date().toISOString(userLocale, { timeZone: userTimezone })
    ).toISOString();
  }

  const utcOffset = timeZoneOffsetUTC(userTimezone);
  const dateAtMidnightAsISOString = `${year}-${month
    .toString()
    .padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}T00:00:00.000${utcOffset}`;
  const dateAtMidnight = new Date(dateAtMidnightAsISOString);

  return dateAtMidnight.toISOString();
}
