import { postalCodePattern } from "@/components/elements/stakeholder/address/postalCodePattern";

export function postalCodeValidator(country, postalCode, required = true) {
  if (required && !postalCode) {
    return {
      success: false,
      message: "Ce champ est requis",
      errorField: "postalCode",
    };
  }

  if (country && postalCode && postalCodePattern[country]) {
    const re = new RegExp(postalCodePattern[country]);
    if (!re.test(postalCode)) {
      return {
        success: false,
        message: `Code postal invalide pour le pays sélectionné`,
        errorField: "postalCode",
      };
    }
  }

  return {
    success: true,
    data: {
      postalCode: postalCode,
      country: country,
    },
  };
}
