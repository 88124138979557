import { useState } from "react";
import { ArrowDownOnSquareIconWithLoading } from "@/components/token/loading/ArrowDownOnSquareIconWithLoading";

export default function TextArea({
  label = "Zone de texte",
  placeholder = "Texte libre",
  value = "",
  onChange,
  name,
  maxLength,
  required,
  error,
  errorOnFocus,
  id,
  onFocus,
  onBlur,
  save,
  onSave,
  loading,
}) {
  const [focused, setFocused] = useState(false);
  const handleOnFocus = () => {
    if (onFocus) onFocus();
    setFocused(true);
  };
  const handleOnBlur = () => {
    if (onBlur) onBlur();
    setFocused(false);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <label
          htmlFor="comment"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
        {save ? (
          <ArrowDownOnSquareIconWithLoading
            handleAction={onSave}
            value={value}
            loading={loading}
          />
        ) : null}
      </div>
      <div className="mt-1">
        <textarea
          rows={4}
          name={name}
          id="comment"
          className="block w-full input-design rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 dark:placeholder-[#444] focus:ring-2 focus:ring-inset focus:ring-black dark:focus:ring-white sm:text-sm sm:leading-6"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          required={required}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />
        {error && (!focused || errorOnFocus) && error !== true && (
          <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
            {error}
          </p>
        )}
      </div>
    </div>
  );
}
