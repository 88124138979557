import Lidapi from "@/utils/lidapi";
import { stringify } from "@/utils/stringify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export function getSettings({
  settingsType,
  parentId,
  queryParam,
  disableToast,
}) {
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";
  const api = new Lidapi(disableToast);

  return {
    queryKey: ["settings", { settingsType, parentId }, queryParam],
    queryFn: () => api.getAllSettings(settingsType, parentId, queryString),
  };
}

export function useGetSettings({
  settingsType,
  parentId,
  queryParam,
  reactQueryConfig,
  disableToast,
}) {
  const queryClient = useQueryClient();
  const { queryFn, queryKey } = getSettings({
    settingsType,
    parentId,
    queryParam,
    disableToast,
  });

  const query = useQuery(queryKey, {
    queryFn: queryFn,
    initialData: () => {
      const dataFromCache = queryClient.getQueryData(queryKey);
      return dataFromCache;
    },
    ...reactQueryConfig,
  });

  return query;
}

export function useManageSettings(
  queryKeyToInvalidate,
  multipleQueryKeysToInvalidate,
  onSuccessCallback
) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async ({
      settingsType,
      parentId,
      settingsKey,
      settingsValue,
      queryParam,
    }) => {
      const queryString = queryParam ? `?${stringify(queryParam)}` : "";

      return await api.manageSettings({
        settingsType,
        parentId,
        settingsKey,
        settingsValue,
        queryString,
      });
    },
    onSuccess: () => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (
        multipleQueryKeysToInvalidate &&
        multipleQueryKeysToInvalidate.length > 0
      ) {
        multipleQueryKeysToInvalidate.forEach((queryKey) => {
          queryClient.invalidateQueries({
            queryKey: [...queryKey],
          });
        });
      }
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
  });

  return action;
}

export function useManageMultipleSettings(
  queryKeyToInvalidate,
  multipleQueryKeysToInvalidate,
  onSuccessCallback
) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async ({ settingsType, parentId, settings, queryParam }) => {
      const queryString = queryParam ? `?${stringify(queryParam)}` : "";

      return await api.manageMultipleSettings({
        settingsType,
        parentId,
        settings,
        queryString,
      });
    },
    onSuccess: () => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (
        multipleQueryKeysToInvalidate &&
        multipleQueryKeysToInvalidate.length > 0
      ) {
        multipleQueryKeysToInvalidate.forEach((queryKey) => {
          queryClient.invalidateQueries({
            queryKey: [...queryKey],
          });
        });
      }
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
  });

  return action;
}
