export function extractDocumentAdditionalData({
  additionalDataArray,
  additionalDataType,
}) {
  return additionalDataArray.find(
    (a) => a.additionalDataType === additionalDataType
  )?.additionalData;
}

export function extractDocumentAdditionalDataWithError({
  additionalDataArray,
  additionalDataType,
}) {
  const a = additionalDataArray.find(
    (a) => a.additionalDataType === additionalDataType
  );

  return {
    value: a?.additionalData,
    error: a?.error,
  };
}
