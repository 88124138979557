import FetchError from "./FetchError";

export function getOryGlobalError(error) {
  const errorResponse = error.response;
  const data = errorResponse?.data;
  const globalError = data?.error;

  return globalError;
}

export function getFirstOryError(error) {
  const errorResponse = error.response;
  const data = errorResponse?.data;

  /* Method-level error */
  const firstErrorMethod = data?.ui?.messages?.find(
    (message) => message.type === "error"
  );

  /* Field-level error */
  const firstErrorField = data?.ui?.nodes
    .flatMap((node) => node.messages || [])
    .find((message) => message.type === "error");

  return firstErrorMethod || firstErrorField;
}

export function getOryResponseStatus(error) {
  const status = error.response?.status;
  if (!status) {
    return 500;
  }

  return status;
}

export function getAllOryErrors(error) {
  const errorResponse = error.response;
  const data = errorResponse?.data;
  // data.ui.nodes.messages
  const methodErrors = data?.ui?.messages?.map(
    (message) => message.type === "error"
  );

  const fieldErrors = data.ui.nodes
    .flatMap((node) => node.messages || [])
    .map((message) => message.type === "error");

  return {
    methodErrors: methodErrors,
    fieldErrors: fieldErrors,
  };
}

export function throwGenericOryError(
  error,
  oryFlowName,
  message,
  disableToast = false
) {
  const globalError = getOryGlobalError(error);
  if (globalError) {
    throw new FetchError(
      `${globalError.message}: ${globalError.reason}`,
      globalError.code || 500,
      "AUTHEXTG01",
      undefined,
      oryFlowName,
      disableToast
    );
  }

  const firstError = getFirstOryError(error);
  const status = getOryResponseStatus(error);

  if (firstError) {
    throw new FetchError(
      firstError.text,
      status,
      "AUTHEXTG02",
      undefined,
      oryFlowName,
      disableToast
    );
  }

  throw new FetchError(
    message,
    status,
    "AUTHEXTG02",
    undefined,
    oryFlowName,
    disableToast
  );
}
