import React, { useState, useCallback, useMemo } from "react";
import "../../assets/css/ParametresInvoice.css";
import { DocumentUpdater } from "../NumerotationQuantity/DocumentUpdater";
import { useCurrentInvoiceNumbers } from "@/hooks/useInvoices";
import { useCreateUpdatePhone } from "@/hooks/useCreateUpdatePhone";
import { useCreateUpdateEmail } from "@/hooks/useCreateUpdateEmail";
import ColorPicker from "@/components/token/ColorPicker/ColorPicker";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import ToggleInput from "@/components/token/Input/toggleInput";
import { notify } from "@/utils/notify";
import { useManageSettings } from "@/hooks/useSettings";
import { DocumentActions } from "@/components/elements/Document/DocumentForm";
import { DocumentAdditionalDataType } from "../document/DocumentAdditionalDataType";
import { DocumentAdditionalDataSubType } from "../document/DocumentAdditionalDataSubType";
import { extractDocumentAdditionalData } from "../document/helpers/extractDocumentAdditionalData";
import { LidapDocumentType } from "@/components/token/documents/DocumentType";
import { validateEmail } from "@/components/elements/stakeholder/contact/emailValidator";
import { PhonePlaceholders } from "@/components/elements/stakeholder/contact/phonePlaceholders";

export default function DocumentParametersV2({
  enterprise,
  setIsParametersOpen,
  documentData,
  setDocumentData,
  phoneData = [],
  emailData = [],
}) {
  const enterpriseId = enterprise.id;
  const savedEmail = emailData.find((e) => e.isMain);
  const savedEmailAddress = savedEmail?.email || "";
  const savedPhone = phoneData.find((p) => p.isMain);
  const savedPhoneNumber = savedPhone
    ? `+${savedPhone.geoCode}${savedPhone.phoneNumber}`
    : "";
  const currentNumbers = useCurrentInvoiceNumbers({
    enterpriseId,
  });
  const [colorTypeBeingSaved, setColorTypeBeingSaved] = useState(null);

  const manageSettings = useManageSettings(["settings"], undefined, () => {
    notify({
      isSuccess: true,
      message: "Paramètres de facturation mis à jour avec succès",
    });
    setColorTypeBeingSaved(null);
  });

  const handlePhone = useCreateUpdatePhone(["phones"], undefined, () =>
    notify({
      isSuccess: true,
      message: "Numéro de téléphone mis à jour avec succès",
    })
  );

  const handleEmail = useCreateUpdateEmail(["enterprises"], undefined, () =>
    notify({ isSuccess: true, message: "Email mis à jour avec succès" })
  );

  const handleColorChange = (color, type) => {
    setDocumentData({
      type: DocumentActions.SET_UNIQUE_ADDITIONAL_DATA,
      payload: {
        additionalDataType: type,
        additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
        additionalData: color,
      },
    });
  };

  const {
    issuerEmail,
    issuerPhone,
    rationalizedPhoneNumber,
    documentTitleColor,
    documentBodyColor,
  } = useMemo(() => {
    const issuerEmail = extractDocumentAdditionalData({
      additionalDataArray: documentData.additionalData,
      additionalDataType: DocumentAdditionalDataType.ISSUER_EMAIL,
    });
    const issuerPhone = extractDocumentAdditionalData({
      additionalDataArray: documentData.additionalData,
      additionalDataType: DocumentAdditionalDataType.ISSUER_PHONE,
    });
    const rationalizedPhoneNumber = issuerPhone
      ? issuerPhone.replace(/\s|\+|-|\./g, "")
      : "";
    const documentTitleColor = extractDocumentAdditionalData({
      additionalDataArray: documentData.additionalData,
      additionalDataType: DocumentAdditionalDataType.DOCUMENT_TITLE_COLOR,
    });
    const documentBodyColor = extractDocumentAdditionalData({
      additionalDataArray: documentData.additionalData,
      additionalDataType: DocumentAdditionalDataType.DOCUMENT_BODY_COLOR,
    });

    return {
      issuerEmail,
      issuerPhone,
      rationalizedPhoneNumber,
      documentTitleColor,
      documentBodyColor,
    };
  }, [documentData.additionalData]);

  const documentType = documentData.documentType;

  const saveColor = useCallback(
    (color, type) => {
      setColorTypeBeingSaved(type);
      manageSettings.mutate({
        settingsType: "document",
        parentId: enterpriseId,
        settingsKey: type,
        settingsValue: color,
      });
    },
    [enterpriseId, manageSettings]
  );

  function toggleContact({ type, value }) {
    setDocumentData({
      type: DocumentActions.TOGGLE_CONTACT,
      payload: {
        contactType: type,
        contactValue: value,
      },
    });
  }

  function updateContactOnDocument({ type, value }) {
    setDocumentData({
      type: DocumentActions.SET_UNIQUE_ADDITIONAL_DATA,
      payload: {
        additionalDataType: type,
        additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
        additionalData: value,
      },
    });
  }

  const contactUpdate = useCallback(() => {
    if (issuerEmail && issuerEmail !== savedEmailAddress) {
      const newEmail = {
        isMain: true,
        isBillingEmail: false,
        email: issuerEmail,
      };
      handleEmail.mutate({
        stakeholderId: enterpriseId,
        email: newEmail,
        id: savedEmail?.id,
      });
    }

    if (issuerPhone && issuerPhone !== savedPhoneNumber) {
      const newPhone = {
        isMain: true,
        isBillingPhone: true,
        phoneNumber: parseInt(rationalizedPhoneNumber.slice(2)),
        geoCode: rationalizedPhoneNumber.substring(0, 2),
      };
      handlePhone.mutate({
        stakeholderId: enterpriseId,
        phone: newPhone,
        id: savedPhone?.id,
      });
    }
  }, [
    issuerEmail,
    savedEmailAddress,
    issuerPhone,
    savedPhoneNumber,
    handleEmail,
    enterpriseId,
    savedEmail?.id,
    rationalizedPhoneNumber,
    handlePhone,
    savedPhone?.id,
  ]);

  const emailFeedback = useMemo(() => {
    const feedback = validateEmail(issuerEmail, documentData.isEmailOnDocument);
    return feedback;
  }, [issuerEmail, documentData.isEmailOnDocument]);

  return (
    <div className="mx-auto w-[512px] flex flex-col justify-start items-left h-screen">
      <div className="w-[512px] p-8">
        <ButtonTailwind
          onClick={() => setIsParametersOpen(false)}
          variant={"text"}
        >
          ← Retour
        </ButtonTailwind>
      </div>

      <div className="flex flex-col gap-4 px-8 pb-8">
        <p className="text-black font-bold text-xl">
          Informations de facturation
        </p>
        <div className="flex flex-col gap-2">
          <ToggleInput
            label="Email"
            type="email"
            placeholder={"account.pro@lidap.be"}
            value={
              documentData.isEmailOnDocument ? issuerEmail : savedEmailAddress
            }
            onChange={(e) => {
              e.preventDefault();
              if (!documentData.isEmailOnDocument) {
                toggleContact({
                  type: DocumentAdditionalDataType.ISSUER_EMAIL,
                  value: e.target.value,
                });
              }

              updateContactOnDocument({
                type: DocumentAdditionalDataType.ISSUER_EMAIL,
                value: e.target.value,
              });
            }}
            isRequired={documentData.isEmailOnDocument}
            icon={false}
            toggle={documentData.isEmailOnDocument}
            onToggle={() => {
              if (!savedEmailAddress && !issuerEmail) {
                notify({
                  isError: true,
                  message: "Veuillez ajouter une adresse email",
                });
                return;
              }

              toggleContact({
                type: DocumentAdditionalDataType.ISSUER_EMAIL,
                value: issuerEmail || savedEmailAddress,
              });
            }}
            error={emailFeedback.message}
          ></ToggleInput>

          <ToggleInput
            label="Téléphone"
            type="tel"
            placeholder={
              PhonePlaceholders[enterprise.country] ||
              "Votre numéro de téléphone"
            }
            value={
              documentData.isPhoneOnDocument ? issuerPhone : savedPhoneNumber
            }
            onChange={(e) => {
              e.preventDefault();
              if (!documentData.isPhoneOnDocument) {
                toggleContact({
                  type: DocumentAdditionalDataType.ISSUER_PHONE,
                  value: e.target.value,
                });
              }

              updateContactOnDocument({
                type: DocumentAdditionalDataType.ISSUER_PHONE,
                value: e.target.value,
              });
            }} //TODO
            isRequired={documentData.isPhoneOnDocument}
            icon={false}
            toggle={documentData.isPhoneOnDocument}
            onToggle={() => {
              if (!savedPhoneNumber && !issuerPhone) {
                notify({
                  isError: true,
                  message: "Veuillez ajouter un numéro de téléphone",
                });
                return;
              }

              toggleContact({
                type: DocumentAdditionalDataType.ISSUER_PHONE,
                value: issuerPhone || savedPhoneNumber,
              });
            }}
          ></ToggleInput>

          <div className="mt-2">
            <ButtonTailwind
              onClick={contactUpdate}
              loading={handleEmail.isLoading || handlePhone.isLoading}
              disabled={
                !(
                  (issuerEmail !== savedEmailAddress && !!issuerEmail) ||
                  (`+${rationalizedPhoneNumber}` !== savedPhoneNumber &&
                    !!rationalizedPhoneNumber)
                ) ||
                emailFeedback.success === false ||
                (!rationalizedPhoneNumber && documentData.isPhoneOnDocument) ||
                (!issuerEmail && documentData.isEmailOnDocument)
              }
            >
              {"Mise à jour"}
            </ButtonTailwind>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 p-8 pt-0">
        <p className="text-black text-xl font-bold">{"Numérotation"}</p>
        <div className="flex flex-col gap-2">
          {currentNumbers.isLoading ? null : (
            <DocumentUpdater
              currentNumbers={currentNumbers}
              isError={currentNumbers.isError}
              isLoading={currentNumbers.isLoading}
              estimateDisabled={documentType !== LidapDocumentType.QUOTE}
              invoiceDisabled={documentType !== LidapDocumentType.INVOICE}
              creditNoteDisabled={
                documentType !== LidapDocumentType.CREDIT_NOTE
              }
            />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-4 p-8 pt-0">
        <p className="text-black font-bold text-xl">{"Couleurs"}</p>
        <div className="flex flex-col gap-2">
          <ColorPicker
            color={documentBodyColor}
            setColor={(color) =>
              handleColorChange(
                color,
                DocumentAdditionalDataType.DOCUMENT_BODY_COLOR
              )
            }
            label={"Couleur du corps"}
            key={"bodyColor"}
            loading={
              manageSettings.isLoading &&
              colorTypeBeingSaved ===
                DocumentAdditionalDataType.DOCUMENT_BODY_COLOR
            }
            onSave={(color) =>
              saveColor(color, DocumentAdditionalDataType.DOCUMENT_BODY_COLOR)
            }
          />
          <ColorPicker
            color={documentTitleColor}
            setColor={(color) =>
              handleColorChange(
                color,
                DocumentAdditionalDataType.DOCUMENT_TITLE_COLOR
              )
            }
            label={"Couleur du titre"}
            key={"titleColor"}
            loading={
              manageSettings.isLoading &&
              colorTypeBeingSaved ===
                DocumentAdditionalDataType.DOCUMENT_TITLE_COLOR
            }
            onSave={(color) =>
              saveColor(color, DocumentAdditionalDataType.DOCUMENT_TITLE_COLOR)
            }
          />
        </div>
      </div>
    </div>
  );
}
