import React, { useCallback, useState } from "react";

import Box from "@mui/material/Box";
import styles from "./ServiceForm.module.css";
import InputPriceSelector from "../../../components/token/Input/inputPriceSelector/InputPriceSelector";
import {
  ServiceUnitFixedPrice,
  ServiceUnits,
  unitPriceReference,
} from "@/components/elements/Article/Units";
import ServiceTitle from "../Document/Service/ServiceTitle";
import ServiceDescription from "../Document/Service/ServiceDescription";
import { useCreateUpdateArticle } from "@/hooks/useCreateUpdateDeleteArticles";
import { notify } from "@/utils/notify";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import Modal from "@/components/elements/Modal/Modal";
import Select from "@/components/token/select/select";
import { CRUDL } from "@/utils/crudl";
import { VatButtonsList } from "@/components/token/ButtonsList/VatButtonsList";
import { VatButtonsListSkeleton } from "@/components/token/ButtonsList/VatButtonListSkeleton";
import { serviceValidator } from "./helpers/serviceValidator";
import { ServiceRibbon } from "./ServiceRibbon";

export default function ServiceFormV2({
  enterprise,
  client,
  service,
  duplicateService,
  removeService,
  onSubmit,
  setService,
  isLoading,
}) {
  const [displaySaveModal, setDisplaySaveModal] = useState(false);
  const [updateCreateChoice, setUpdateCreateChoice] = useState({
    name: "Nouveau service",
    action: CRUDL.CREATE,
  });
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const enterpriseId = enterprise.id;
  const isDisplayed = service.isDisplayed;
  const index = service.id;
  const errors = service.errors || [];
  const templateId = service.templateId;

  const createUpdateArticle = useCreateUpdateArticle(
    ["articles"],
    null,
    (data) => {
      notify({
        isSuccess: true,
        message:
          updateCreateChoice.action === CRUDL.CREATE
            ? "Article créé avec succès"
            : "Article mis à jour avec succès",
      }),
        setDisplaySaveModal(false);
      if (updateCreateChoice.action === CRUDL.CREATE) {
        setService({
          ...service,
          templateId: data.payload.id,
        });
        setUpdateCreateChoice({
          name: "Mettre à jour ce service",
          action: CRUDL.UPDATE,
        });
      }
    }
  );

  const validateAndSetService = useCallback(
    (service) => {
      const { isValid, errors } = serviceValidator(service);
      setService({
        ...service,
        errors: errors,
        isValid,
      });
    },
    [setService]
  );

  function saveService(action) {
    const newService = {
      title: service.title,
      description: service.description,
      quantity: {
        amount: service.quantity.amount,
        unit: service.quantity.unit || ServiceUnitFixedPrice.unitValue,
      },
      unitPrice: {
        amount: service.unitPrice.amount,
        currency: service.unitPrice.currency,
      },
      vatRate: {
        taxRate: service.vatRate.taxRate,
        countryOrRegion: service.vatRate.countryOrRegion,
      },
    };

    const payload = (() => {
      if (action === CRUDL.CREATE) {
        return {
          enterpriseId: enterpriseId,
          article: newService,
        };
      }

      if (action === CRUDL.UPDATE) {
        return {
          enterpriseId: enterpriseId,
          article: newService,
          id: templateId,
        };
      }
    })();

    createUpdateArticle.mutate({
      ...payload,
    });
  }

  const toggleService = useCallback(() => {
    validateAndSetService({
      ...service,
      isDisplayed: !service.isDisplayed,
    });
  }, [service, validateAndSetService]);

  function validateService() {
    // If valid, change the display mode
    const { isValid, errors } = serviceValidator(service);

    if (isValid) {
      toggleService();
      return;
    }

    if (!isValid) {
      const errorMessages = errors.map((error) => error.error);
      notify({
        isSuccess: false,
        message: "Veuillez corriger les erreurs :",
        list: errorMessages,
      });
    }

    setService({
      ...service,
      isDisplayed: true,
      errors: errors,
    });
  }

  return (
    <>
      {displaySaveModal && (
        <Modal>
          {templateId ? (
            <>
              <h1 className="text-3xl">Sauvegarder ce service ?</h1>
              <Select
                data={[
                  { name: "Mettre à jour ce service", action: CRUDL.UPDATE },
                  { name: "Nouveau service", action: CRUDL.CREATE },
                ]}
                selectedItem={updateCreateChoice}
                setSelectedItem={setUpdateCreateChoice}
                label="Action"
              ></Select>
              <div className="flex justify-end gap-4">
                <ButtonTailwind
                  variant="outlined"
                  onClick={() => setDisplaySaveModal(false)}
                  disabled={createUpdateArticle.isLoading}
                >
                  Annuler
                </ButtonTailwind>
                <ButtonTailwind
                  variant={"filled"}
                  onClick={() => saveService(updateCreateChoice.action)}
                  loading={createUpdateArticle.isLoading}
                >
                  Enregistrer
                </ButtonTailwind>
              </div>
            </>
          ) : (
            <>
              <h1 className="text-3xl">
                Enregistrer ce service dans <br />
                votre bibliothèque ?
              </h1>
              <p>
                L&apos;enregistrement d&apos;un service permet de
                l&apos;exploiter de
                <br />
                nouveau à l&apos;avenir pour gagner du temps. Vous pourrez le
                <br /> modifier plus tard si besoin.
              </p>

              <div className="flex justify-end gap-4">
                <ButtonTailwind
                  variant="outlined"
                  onClick={() => setDisplaySaveModal(false)}
                  disabled={createUpdateArticle.isLoading}
                >
                  Annuler
                </ButtonTailwind>
                <ButtonTailwind
                  variant={"filled"}
                  onClick={() => saveService(updateCreateChoice.action)}
                  loading={createUpdateArticle.isLoading}
                >
                  Enregistrer
                </ButtonTailwind>
              </div>
            </>
          )}
        </Modal>
      )}
      {isDisplayed ? (
        <div className="flex flex-col w-full h-max border border-gray-300 rounded-md overflow-hidden">
          <ServiceRibbon
            key={`service-ribbon-expanded:${service.id}`}
            duplicateService={duplicateService}
            removeService={removeService}
            index={index}
            service={service}
            setDisplaySaveModal={setDisplaySaveModal}
            loading={createUpdateArticle.isLoading}
            validateService={validateService}
            isServiceExpanded={isDisplayed}
          />
          <form
            onSubmit={onSubmit}
            className="flex flex-col w-full gap-8 px-4 py-4"
          >
            <Box className={"flex flex-col gap-4"}>
              <ServiceTitle
                titleError={
                  titleError ||
                  errors.find((error) => error.field === "title")?.error
                }
                setServiceTitle={(title) =>
                  validateAndSetService({
                    ...service,
                    title: title,
                    field: "title",
                  })
                }
                serviceTitle={service.title}
                setTitleError={setTitleError}
                errorOnFocus={true}
              />
              <ServiceDescription
                descriptionError={
                  descriptionError ||
                  errors.find((error) => error.field === "description")?.error
                }
                serviceDescription={service.description}
                setDescriptionError={setDescriptionError}
                setServiceDescription={(description) =>
                  validateAndSetService({
                    ...service,
                    description: description,
                    field: "description",
                  })
                }
                name="description"
                label="Description (optionnel)"
                placeholder="Indiquez une description..."
                errorOnFocus={true}
              />
              <div className={styles.QuantityBox}>
                <InputPriceSelector
                  label="Quantité"
                  placeholder="1"
                  isRequired={true}
                  name="price"
                  type="number"
                  id="QuantityInput"
                  numberValue={service.quantity.amount}
                  onChange={(amount) =>
                    validateAndSetService({
                      ...service,
                      quantity: {
                        ...service.quantity,
                        amount,
                      },
                      field: "quantity.amount",
                    })
                  }
                  selectValue={
                    unitPriceReference.find(
                      (unitPrice) =>
                        unitPrice.unitValue === service.quantity.unit
                    )?.label || "Forfait"
                  }
                  onSelect={(e) => {
                    e.preventDefault();
                    const unit =
                      unitPriceReference.find(
                        (unitPrice) => unitPrice.label === e.target.value
                      )?.unitValue || ServiceUnitFixedPrice.unitValue;
                    validateAndSetService({
                      ...service,
                      quantity: {
                        ...service.quantity,
                        unit,
                      },
                      field: "quantity.unit",
                    });
                  }}
                  selectLabel="Unité"
                  options={ServiceUnits}
                  error={
                    errors.find(
                      (error) =>
                        error.field === "quantity.amount" ||
                        error.field === "quantity.unit"
                    )?.error
                  }
                />
                <InputPriceSelector
                  label="Prix unitaire"
                  placeholder="0.00"
                  isRequired={true}
                  name="price"
                  type="number"
                  numberValue={service.unitPrice.amount}
                  onChange={(amount) => {
                    validateAndSetService({
                      ...service,
                      unitPrice: {
                        ...service.unitPrice,
                        amount,
                      },
                      field: "unitPrice.amount",
                    });
                  }}
                  selectValue={service.unitPrice.currency}
                  onSelect={(e) => {
                    e.preventDefault();
                    const currency = e.target.value;
                    validateAndSetService({
                      ...service,
                      unitPrice: {
                        ...service.unitPrice,
                        currency,
                      },
                      field: "unitPrice.currency",
                    });
                  }}
                  selectLabel="Currency"
                  error={
                    errors.find(
                      (error) =>
                        error.field === "unitPrice.amount" ||
                        error.field === "unitPrice.currency"
                    )?.error
                  }
                  options={["EUR", "USD", "CHF", "CAD"]}
                />
              </div>
              {isLoading ? (
                <VatButtonsListSkeleton />
              ) : (
                <VatButtonsList
                  sellerCountry={enterprise.country}
                  buyerCountry={client.country}
                  buyerType={client.type}
                  vatRate={service.vatRate.taxRate}
                  setVatRate={({ taxRate, countryOrRegion }) =>
                    validateAndSetService({
                      ...service,
                      vatRate: {
                        taxRate: taxRate,
                        countryOrRegion: countryOrRegion,
                      },
                      field: "vatRate",
                    })
                  }
                />
              )}
            </Box>
          </form>
        </div>
      ) : (
        <ServiceRibbon
          key={`service-ribbon-collapsed:${service.id}`}
          duplicateService={duplicateService}
          removeService={removeService}
          index={index}
          service={service}
          setDisplaySaveModal={setDisplaySaveModal}
          loading={createUpdateArticle.isLoading}
          validateService={validateService}
          isServiceExpanded={isDisplayed}
          toggleService={toggleService}
        />
      )}
    </>
  );
}
