import React, { useEffect, useMemo } from "react";
import useFormOryChangePassword from "@/hooks/forms/useFormOryChangePassword";
import { useNavigate, useSearchParams } from "react-router-dom";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import { Password } from "@/components/elements/User/Password";
import OryConfirmAuthModal from "./OryConfirmAuthModal";
import { useUpdateUserInfo } from "@/hooks/useUpdateUser";
import { useUser } from "@/contexts/UserContext";

export default function OryChangePassword() {
  const navigate = useNavigate();
  const userContext = useUser();
  const [searchParams] = useSearchParams();
  const isFromRecovery = searchParams.get("referrer") === "recovery";
  const updateUserInfo = useUpdateUserInfo(["users/info"], undefined, false, {
    retry: false,
  });

  const {
    isOryConfirmAuthModalDisplayed,
    setIsOryConfirmAuthModalDisplayed,
    handlePasswordChange,
    newPassword,
    setNewPassword,
    isLoading,
    passwordError,
  } = useFormOryChangePassword({
    onSuccess: () => {
      if (isFromRecovery) updateUserInfo.mutate();
      navigate("/home?referrer=password_change_success");
    },
  });

  useEffect(() => {
    if (
      isFromRecovery &&
      !userContext.user?.userId &&
      !updateUserInfo.isLoading &&
      !updateUserInfo.isError &&
      !updateUserInfo.isSuccess
    ) {
      updateUserInfo.mutate();
    }
  }, [isFromRecovery, updateUserInfo, userContext.user?.userId]);

  const MemoizedPassword = useMemo(
    () => (
      <Password
        initialPassword={newPassword.data}
        onChange={setNewPassword}
        isLoading={isLoading}
        required={true}
        name={"Nouveau mot de passe"}
        label={"Nouveau mot de passe"}
        id={"new-password"}
        displayError={
          newPassword.isDirty || (!newPassword.isDirty && passwordError)
        }
        bypassRules={false}
        placeholder={"•••••••••••"}
        disabled={isLoading}
        externalError={passwordError}
        dataFormType="password,new"
      />
    ),
    // Dependencies that could cause the component to re-render
    [
      newPassword.data,
      newPassword.isDirty,
      setNewPassword,
      isLoading,
      passwordError,
    ]
  );

  return (
    <>
      {isOryConfirmAuthModalDisplayed && (
        <OryConfirmAuthModal
          setIsOryConfirmAuthModalDisplayed={setIsOryConfirmAuthModalDisplayed}
          onSuccessfulAuth={() => {
            handlePasswordChange();
          }}
        />
      )}
      <form
        className="flex flex-col gap-4 w-full "
        data-form-type="change_password"
        onSubmit={(e) => {
          e.preventDefault();
          handlePasswordChange();
        }}
      >
        <div className="settings-align-inputs">{MemoizedPassword}</div>

        <div>
          <ButtonTailwind
            loading={isLoading}
            disabled={
              !newPassword.data ||
              newPassword.success === false ||
              newPassword.isDirty === false
            }
            type={"submit"}
          >
            Modifier mon mot de passe
          </ButtonTailwind>
        </div>
      </form>
    </>
  );
}
