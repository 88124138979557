import React, { useState } from "react";
import { LidapToolTip } from "../tooltip/LidapToolTip";

export default function EllipsisMenu({ onClick, children, tooltipText }) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="relative">
      <div
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <button
          className="flex items-center gap-2 p-2 rounded-r-md hover:bg-gray-100 dark:hover:bg-neutral-900"
          onClick={onClick}
          style={{ minHeight: "40px" }}
        >
          •••
        </button>
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
          {children}
        </div>
        {tooltipText && (
          <LidapToolTip
            toolTipText={tooltipText}
            isGrouped={false}
            isVisible={isHovered}
          />
        )}
      </div>
    </div>
  );
}
