import { useUser } from "@/contexts/UserContext";
import React, { useMemo, useRef } from "react";
import { getRecentClients, useRecentClients } from "@/hooks/useClients";
import { ClientStart } from "@/feature/clients/createClient/ClientStart";
import { CRUDL } from "@/utils/crudl";
import LidapTable from "@/components/token/table/LidapTable";
import { useLidapTableBuild, useLidapTableInit } from "@/hooks/useLidapTable";
import { useQueryClient } from "@tanstack/react-query";
import RequireSubscriptionButton from "@/components/token/button/RequireSubscriptionButton";

export default function ClientOverview({ clientAction }) {
  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;

  const editCellsRefs = useRef({});

  const queryClient = useQueryClient();
  const defaultListParam = {
    pageNumber: 0,
    numberOfRecords: 1,
  };
  const { queryFn: clientQueryFn, queryKey: clientQueryKey } = getRecentClients(
    { enterpriseId, queryParam: defaultListParam }
  );

  const lidapTableInit = useLidapTableInit(
    {
      chainingActions: [
        {
          name: "Modifier",
          render: ({ row }) => (
            <button
              onClick={(e) => {
                e.stopPropagation();
                clientAction(CRUDL.UPDATE, row.original);
              }}
              className="text-black flex items-center gap-4 hover:bg-gray-100 dark:hover:bg-neutral-900 p-2 px-4 rounded-md"
            >
              Modifier
            </button>
          ),
        },
      ],
    },
    editCellsRefs
  );

  const recentClients = useRecentClients({
    enterpriseId,
    queryParam: {
      pageNumber: lidapTableInit.page - 1,
      numberOfRecords: lidapTableInit.numberOfRecords,
    },
    reactQueryConfig: {
      keepPreviousData: true,
    },
  });

  const mapClientType = (clientType) => {
    switch (clientType) {
      case "COMPANY":
        return "Entreprise";
      case "PRIVATE":
        return "Particulier";
      default:
        return "Inconnu";
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name", // accessor is now accessorKey
        header: "Client",
        cell: ({ cell }) => cell.getValue(),
        className: {
          cell: "truncate py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
          header:
            "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",
        },
        onClick: (cell) => {
          clientAction(CRUDL.UPDATE, cell.row.original);
        },
      },
      {
        accessorKey: "clientType", // accessor is now accessorKey
        header: "Type",
        cell: ({ cell }) => mapClientType(cell.getValue()),
        className: {
          cell: "truncate py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
          header:
            "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",
        },
        onClick: (cell) => {
          clientAction(CRUDL.UPDATE, cell.row.original);
        },
      },
      {
        accessorFn: (row) => row.emails?.find((e) => e.isMain)?.email,
        header: "Email du contact",
        className: {
          cell: "truncate px-3 py-4 text-sm text-gray-500",
          header: "px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
        },
        onClick: (cell) => {
          clientAction(CRUDL.UPDATE, cell.row.original);
        },
      },
      {
        id: "edit",
        header: () => {
          return <span className="sr-only">Edit</span>;
        },
        cell: ({ cell }) => {
          if (!editCellsRefs.current[cell.row.id]) {
            editCellsRefs.current[cell.row.id] = React.createRef();
          }
          return (
            <div>
              •••
              <lidapTableInit.Chaining
                row={cell.row}
                selectedRowId={lidapTableInit.selectedRowId}
              />
            </div>
          );
        },
        className: {
          cell: "relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 text-gray-500 hover:text-indigo-900 cursor-pointer whitespace-nowrap px-3 py-4 text-sm text-gray-500",
          header: "relative py-3.5 pl-3 pr-4 sm:pr-6",
        },
        onClick: (cell) => lidapTableInit.handleEditClick(cell.row.id),
      },
    ],
    [clientAction, lidapTableInit]
  );

  const lidapTableBuild = useLidapTableBuild({
    query: recentClients,
    lidapTableInit,
    columns,
  });

  if (
    recentClients.isSuccess &&
    recentClients.data.payload.results.length === 0 &&
    lidapTableBuild.totalNumberOfResults === 0
  ) {
    return (
      <ClientStart
        onClick={() => {
          clientAction(CRUDL.CREATE);
        }}
      />
    );
  }

  return (
    <LidapTable
      lidapTableInit={lidapTableInit}
      lidapTableBuild={lidapTableBuild}
      additionalFilters={[]}
      actionButtons={[
        {
          name: "Nouveau client",
          render: (
            <RequireSubscriptionButton
              onClick={() => {
                clientAction(CRUDL.CREATE);
              }}
              onMouseEnter={() => {
                queryClient.prefetchQuery(clientQueryKey, clientQueryFn, {
                  staleTime: 1000 * 60 * 5,
                });
              }}
            >
              Nouveau client
            </RequireSubscriptionButton>
          ),
        },
      ]}
      additionalActionsOnPaginate={() => {}}
      trClass="hover:bg-gray-100 dark:hover:bg-neutral-900 cursor-pointer"
    />
  );
}
