import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ory } from "@/hooks/useOryLogin";
import { throwGenericOryError } from "@/utils/ory.frontend";

export const handleOryLogout = async ({ logoutFlow, logout_token }) => {
  const { logout_token: token } = await (async () => {
    if (!logoutFlow || !logout_token) {
      return await getLogoutFlow();
    }
    return { flow: logoutFlow, token: logout_token };
  })();

  return ory.updateLogoutFlow(
    {
      token: token,
    },

    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

async function getLogoutFlow() {
  const { data: logoutFlow } = await ory.createBrowserLogoutFlow({
    headers: {
      "Content-Type": "application/json",
    },
  });

  return { logoutFlow, logout_token: logoutFlow.logout_token };
}

export function useOryLogoutFlow(onSuccess) {
  const action = useMutation({
    mutationFn: async () => await getLogoutFlow(),
    onSuccess: async (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    retry: false,
  });

  return action;
}

export function useOryLogout(
  queryKeyToInvalidate,
  onSuccess,
  disableToast,
  onSettled = () => {}
) {
  const queryClient = useQueryClient();

  const action = useMutation({
    mutationFn: async (data) => {
      try {
        await handleOryLogout(data ? data : { flow: null, logout_token: null });
      } catch (error) {
        throwGenericOryError(error, "ory-logout", undefined, disableToast);
      }
    },
    onSuccess: async () => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      // navigate("/login");
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: async () => {
      // navigate("/login");
    },
    onSettled: onSettled,
    retry: false,
  });

  return action;
}
