import { parsePhoneNumber } from "libphonenumber-js";

export function validateParsePhone(phoneNumber, required = true) {
  if (required && !phoneNumber) {
    return {
      success: false,
      message: "Ce champ est requis",
      errorField: "phoneNumber",
    };
  }

  if (phoneNumber) {
    try {
      const parsedNumber = parsePhoneNumber(phoneNumber);

      const newPhone = {
        phoneNumber: parsedNumber.nationalNumber,
        country: parsedNumber.country,
        geoCode: `${parsedNumber.countryCallingCode}`,
      };

      return {
        success: parsedNumber.isValid(),
        message: "Numéro de téléphone invalide",
        data: newPhone,
      };
    } catch (error) {
      return {
        success: false,
        message: "Numéro de téléphone invalide",
        errorField: "phoneNumber",
      };
    }
  }

  return {
    success: true,
    data: {
      phoneNumber,
    },
  };
}
