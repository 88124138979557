// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner-xl {
  border: 2px solid transparent; /* Makes the spinner circular */
  border-top: 2px solid #737373; /* Color of the spinner */
  border-radius: 50%;
  width: 2rem; /* Size of the spinner */
  height: 2rem;
  animation: spinner 0.6s linear infinite;
}
`, "",{"version":3,"sources":["webpack://./src/components/elements/Pagination/Pagination.css"],"names":[],"mappings":"AAAA;EACE;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,6BAA6B,EAAE,+BAA+B;EAC9D,6BAA6B,EAAE,yBAAyB;EACxD,kBAAkB;EAClB,WAAW,EAAE,wBAAwB;EACrC,YAAY;EACZ,uCAAuC;AACzC","sourcesContent":["@keyframes spinner {\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.spinner-xl {\n  border: 2px solid transparent; /* Makes the spinner circular */\n  border-top: 2px solid #737373; /* Color of the spinner */\n  border-radius: 50%;\n  width: 2rem; /* Size of the spinner */\n  height: 2rem;\n  animation: spinner 0.6s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
