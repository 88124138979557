import React from "react";
import { ChartBarIcon } from "@heroicons/react/24/outline";
import { PresentationChartLineIcon } from "@heroicons/react/24/outline";
import { ChartDisplayType } from "@/utils/charts/ChartDisplayType";

export default function ToggleChartType({ setChartType, chartType }) {
  return (
    <div className="flex gap-1 bg-neutral-100 rounded-lg p-1 select-none text-sm">
      <div
        className={`flex items-center justify-center p-2 px-4 gap-2 rounded cursor-pointer ${
          chartType === ChartDisplayType.LINE
            ? "bg-white text-neutral-600 shadow-sm"
            : "text-neutral-500 hover:bg-neutral-50"
        }`}
        onClick={() => setChartType(ChartDisplayType.LINE)}
      >
        <PresentationChartLineIcon
          className={`h-4 w-4 ${
            chartType ? "text-neutral-600" : "text-neutral-500"
          }`}
        />
        Ligne
      </div>
      <div
        className={`flex items-center justify-center p-2 px-4 gap-2 rounded cursor-pointer ${
          chartType === ChartDisplayType.BAR
            ? "bg-white text-neutral-600 shadow-sm"
            : "text-neutral-500 hover:bg-neutral-50"
        }`}
        onClick={() => setChartType(ChartDisplayType.BAR)}
      >
        <ChartBarIcon className="h-4 w-4 text-gray-500" />
        Barres
      </div>
    </div>
  );
}
