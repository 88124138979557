import { DocumentActions } from "@/components/elements/Document/DocumentForm";
import { serviceValidator } from "@/components/elements/ServiceForm/helpers/serviceValidator";
import { notify } from "@/utils/notify";
import { useCallback, useMemo, useState } from "react";
import { DocumentAdditionalDataType } from "./DocumentAdditionalDataType";
import {
  extractDocumentAdditionalData,
  extractDocumentAdditionalDataWithError,
} from "./helpers/extractDocumentAdditionalData";
import { transformDate } from "@/utils/date/dateTransformers";
import { useManageSettings } from "@/hooks/useSettings";
import { SettingsType } from "../Settings/helpers/extractSetting";
import { dateWithDefaultDelay } from "@/components/elements/Document/helpers/documentInitial";
import { DocumentTypeLabel } from "@/components/token/documents/DocumentType";
import { unitPriceReference } from "@/components/elements/Article/Units";
import { formatNumberForDocument } from "@/utils/numbers/useFormatNumber";
import {
  useCompanyIdentifiers,
  useCompanyNameAndAddress,
} from "../invoicePreview/useCompanyPreview";

export function useCommonDocumentForm({
  documentData,
  setDocumentData,
  documentSettings,
  enterprise,
  lastDocumentOfSameType,
}) {
  const [serviceIndex, setServiceIndex] = useState(0);
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);
  const [displayLeavingPopUp, setDisplayLeavingPopUp] = useState(false);
  const [settingsBeingSaved, setSettingsBeingSaved] = useState(null);

  const handleServiceSelection = useCallback(
    (service) => {
      const s = {
        ...service,
        isDisplayed: false,
        templateId: service.id,
        id: serviceIndex,
      };

      setServiceIndex((prev) => prev + 1);
      setDocumentData({
        type: DocumentActions.SET_SERVICE,
        payload: s,
      });
    },
    [setDocumentData, serviceIndex]
  );

  const handleServiceAddition = useCallback(() => {
    const s = {
      id: serviceIndex,
      isDisplayed: true,
    };

    setServiceIndex((prev) => prev + 1);
    setDocumentData({
      type: DocumentActions.ADD_SERVICE,
      payload: s,
    });
  }, [setDocumentData, serviceIndex]);

  const handleServiceRemoval = useCallback(
    (serviceId) => {
      setDocumentData({
        type: DocumentActions.REMOVE_SERVICE,
        payload: serviceId,
      });
    },
    [setDocumentData]
  );

  const validateServices = useCallback(
    ({ documentData, onSuccess }) => {
      const services = documentData.services;
      if (services.length === 0) {
        notify({
          isSuccess: false,
          message: "Vous devez ajouter au moins un service pour continuer",
        });
        return;
      }

      const servicesFeedbacks = services.map((service) => {
        const errors = service.errors;
        const feedback = serviceValidator(service, errors);

        return {
          id: service.id,
          isValid: feedback.isValid,
          errors: feedback.errors,
        };
      });

      const allServicesValid = servicesFeedbacks.every(
        (service) => service.isValid
      );

      setDocumentData({
        type: DocumentActions.VALIDATE_SERVICES,
        payload: servicesFeedbacks,
      });

      if (!allServicesValid) {
        notify({
          isSuccess: false,
          message: "Veuillez corriger les erreurs présentes dans les services",
        });
        return;
      }

      if (onSuccess) {
        onSuccess();
      }
    },
    [setDocumentData]
  );

  const conditionsType = useMemo(
    () =>
      documentData.documentType === DocumentType.CREDIT_NOTE
        ? DocumentAdditionalDataType.CREDIT_NOTE_CONDITIONS
        : DocumentAdditionalDataType.INVOICE_CONDITIONS,
    [documentData.documentType]
  );

  const documentConditions = useMemo(
    () =>
      extractDocumentAdditionalDataWithError({
        additionalDataArray: documentData.additionalData,
        additionalDataType: conditionsType,
      }),
    [documentData.additionalData, conditionsType]
  );

  const documentUserTitle = useMemo(
    () =>
      extractDocumentAdditionalDataWithError({
        additionalDataArray: documentData.additionalData,
        additionalDataType: DocumentAdditionalDataType.DOCUMENT_TITLE,
      }),
    [documentData.additionalData]
  );

  /* Derived state */
  const issuingDateAsMMDDYYYY = useMemo(
    () =>
      transformDate(
        documentData.issuingDate
          ? new Date(documentData.issuingDate)
          : new Date()
      ),
    [documentData.issuingDate]
  );

  const dueDateAsMMDDYYYY = useMemo(
    () =>
      transformDate(
        documentData.dueDate
          ? new Date(documentData.dueDate)
          : dateWithDefaultDelay({ documentSettings })
      ),
    [documentData.dueDate, documentSettings]
  );

  const { issuingDateMin, issuingDateMinAsMMDDYYYY } = useMemo(() => {
    const issuingDateMin = (() => {
      if (lastDocumentOfSameType) {
        return new Date(lastDocumentOfSameType.issuingDate);
      }

      return null;
    })();

    return {
      issuingDateMin,
      issuingDateMinAsMMDDYYYY: issuingDateMin
        ? transformDate(issuingDateMin)
        : null,
    };
  }, [lastDocumentOfSameType]);

  const vatDisclaimer = useMemo(
    () =>
      extractDocumentAdditionalData({
        additionalDataArray: documentData.additionalData,
        additionalDataType: DocumentAdditionalDataType.VAT_DISCLAIMER,
      }),
    [documentData.additionalData]
  );

  const enterpriseId = enterprise.id;

  const accountsToCredit = useMemo(
    () =>
      documentData.additionalData.filter(
        (a) =>
          a.additionalDataType === DocumentAdditionalDataType.PAYMENT_METHOD
      ) || [],
    [documentData.additionalData]
  );

  /* Mutations */
  const manageSettings = useManageSettings(["settings"], undefined, () => {
    notify({
      isSuccess: true,
      message: "Paramètres de facturation mis à jour avec succès",
    });
  });

  /* Handlers */
  function handleSettings({ settingsKey, settingsValue, error }) {
    if (error) {
      notify({
        isSuccess: false,
        message: error,
      });
      return;
    }

    setSettingsBeingSaved(settingsKey);
    manageSettings.mutate({
      settingsType: SettingsType.DOCUMENT,
      parentId: enterpriseId,
      settingsKey: settingsKey,
      settingsValue: settingsValue || "",
    });
  }

  return {
    handleServiceSelection,
    handleServiceAddition,
    handleServiceRemoval,
    isCreationModalOpen,
    setIsCreationModalOpen,
    displayLeavingPopUp,
    setDisplayLeavingPopUp,
    validateServices,
    documentConditions,
    conditionsType,
    documentUserTitle,
    issuingDateMin,
    issuingDateMinAsMMDDYYYY,
    issuingDateAsMMDDYYYY,
    dueDateAsMMDDYYYY,
    vatDisclaimer,
    accountsToCredit,
    handleSettings,
    settingsBeingSaved,
    manageSettings,
  };
}

export function useDocumentPreview({ documentData }) {
  const enterpriseId = documentData.enterpriseId;
  const documentNumber = documentData.invoiceNumber;

  const tvaVentilation = [];

  const documentType = documentData.documentType;
  const services = documentData.services;

  const accountsToCredit = useMemo(
    () =>
      documentData.additionalData.filter(
        (a) =>
          a.additionalDataType === DocumentAdditionalDataType.PAYMENT_METHOD
      ) || [],
    [documentData.additionalData]
  );

  const {
    formattedSubtotalHT,
    formattedTotalTVA,
    formattedTotalTTC,
    currency,
  } = useMemo(() => {
    const subtotalHT = services?.reduce(
      (acc, service) =>
        acc +
        parseFloat(service?.unitPrice?.amount) *
          parseFloat(service?.quantity?.amount),
      0
    );
    const totalTVA = services.reduce(
      (acc, service) =>
        acc +
        parseFloat(service?.unitPrice?.amount) *
          parseFloat(service?.quantity?.amount) *
          parseFloat(service?.vatRate?.taxRate),
      0
    );
    const totalTTC = (parseFloat(subtotalHT) + parseFloat(totalTVA)).toFixed(2);
    const formattedSubtotalHT = formatNumberForDocument(subtotalHT);
    const formattedTotalTVA = formatNumberForDocument(totalTVA);
    const formattedTotalTTC = formatNumberForDocument(totalTTC);
    const currency = services[0]?.unitPrice?.currency;

    return {
      subtotalHT,
      formattedSubtotalHT,
      totalTVA,
      formattedTotalTVA,
      totalTTC,
      formattedTotalTTC,
      currency,
    };
  }, [services]);

  const documentTitle = useMemo(
    () => DocumentTypeLabel[documentType],
    [documentType]
  );

  const getUnitLabel = useCallback((unit, quantity) => {
    const unitInfo = unitPriceReference.find(
      (unitPrice) => unitPrice.unitValue === unit
    );
    return quantity > 1 && unitInfo?.pluralLabel
      ? unitInfo.pluralLabel
      : unitInfo?.label;
  }, []);

  /* Derived state */
  const {
    additionalData,
    documentBodyColor,
    documentTitleColor,
    issuerEmail,
    issuerPhone,
    addresses,
    vatDisclaimer,
    documentConditions,
    creditNoteReason,
    creditNotePaymentApplicationMethod,
  } = useMemo(() => {
    const additionalData = documentData.additionalData;
    const documentBodyColor = extractDocumentAdditionalData({
      additionalDataArray: additionalData,
      additionalDataType: DocumentAdditionalDataType.DOCUMENT_BODY_COLOR,
    });
    const documentTitleColor = extractDocumentAdditionalData({
      additionalDataArray: additionalData,
      additionalDataType: DocumentAdditionalDataType.DOCUMENT_TITLE_COLOR,
    });
    const issuerEmail = extractDocumentAdditionalData({
      additionalDataArray: additionalData,
      additionalDataType: DocumentAdditionalDataType.ISSUER_EMAIL,
    });
    const issuerPhone = extractDocumentAdditionalData({
      additionalDataArray: additionalData,
      additionalDataType: DocumentAdditionalDataType.ISSUER_PHONE,
    });
    const vatDisclaimer = extractDocumentAdditionalData({
      additionalDataArray: additionalData,
      additionalDataType: DocumentAdditionalDataType.VAT_DISCLAIMER,
    });
    const creditNoteReason = extractDocumentAdditionalData({
      additionalDataArray: additionalData,
      additionalDataType: DocumentAdditionalDataType.CREDIT_NOTE_REASON,
    });
    const creditNotePaymentApplicationMethod = extractDocumentAdditionalData({
      additionalDataArray: additionalData,
      additionalDataType:
        DocumentAdditionalDataType.CREDIT_NOTE_PAYMENT_APPLICATION_METHOD,
    });
    const conditionsType =
      documentData.documentType === DocumentType.CREDIT_NOTE
        ? DocumentAdditionalDataType.CREDIT_NOTE_CONDITIONS
        : DocumentAdditionalDataType.INVOICE_CONDITIONS;
    const documentConditions = extractDocumentAdditionalData({
      additionalDataArray: additionalData,
      additionalDataType: conditionsType,
    });

    const addresses = documentData.addresses;

    return {
      additionalData,
      documentBodyColor,
      documentTitleColor,
      issuerEmail,
      issuerPhone,
      addresses,
      creditNoteReason,
      creditNotePaymentApplicationMethod,
      vatDisclaimer,
      documentConditions,
    };
  }, [
    documentData.additionalData,
    documentData.addresses,
    documentData.documentType,
  ]);

  const issuingDate = documentData.issuingDate;
  const issuingDateAsDDMMYYYY = issuingDate
    ? transformDate(new Date(issuingDate), "DDMMYYYY")
    : null;

  const dueDate = documentData.dueDate;
  const dueDateAsDDMMYYYY = dueDate
    ? transformDate(new Date(dueDate), "DDMMYYYY")
    : null;

  const refundedInvoice = useMemo(() => {
    const refundedInvoice = documentData.selectedInvoice
      ? documentData.selectedInvoice
      : documentData.linkedInvoices?.find((i) => i.linkType === "REFUND")
          ?.linkedInvoice; //TODO! export as const

    const issuingDateAsDDMMYYYY = refundedInvoice?.issuingDate
      ? transformDate(new Date(refundedInvoice.issuingDate), "DDMMYYYY")
      : null;

    const dueDateAsDDMMYYYY = refundedInvoice?.dueDate
      ? transformDate(new Date(refundedInvoice.dueDate), "DDMMYYYY")
      : null;

    return {
      ...refundedInvoice,
      issuingDateAsDDMMYYYY,
      dueDateAsDDMMYYYY,
    };
  }, [documentData.linkedInvoices, documentData.selectedInvoice]);

  const { name: issuerName, address: issuerAddress } = useCompanyNameAndAddress(
    {
      additionalData,
      addresses,
      type: "ISSUER",
    }
  );
  const {
    vatNumber: issuerVatNumber,
    enterpriseNumber: issuerEnterpriseNumber,
    enterpriseNumberLabel: issuerEnterpriseNumberLabel,
  } = useCompanyIdentifiers({
    additionalData,
    country: issuerAddress?.country,
    type: "ISSUER",
  });
  const { name: receiverName, address: receiverAddress } =
    useCompanyNameAndAddress({
      additionalData,
      addresses,
      type: "RECEIVER",
    });
  const {
    vatNumber: receiverVatNumber,
    enterpriseNumber: receiverEnterpriseNumber,
    enterpriseNumberLabel: receiverEnterpriseNumberLabel,
  } = useCompanyIdentifiers({
    additionalData,
    country: receiverAddress?.country,
    type: "RECEIVER",
  });

  return {
    enterpriseId,
    issuingDateAsDDMMYYYY,
    dueDateAsDDMMYYYY,
    documentType,
    documentNumber,
    additionalData,
    documentTitle,
    documentBodyColor,
    documentTitleColor,
    issuerEmail,
    issuerPhone,
    addresses,
    vatDisclaimer,
    documentConditions,
    creditNoteReason,
    creditNotePaymentApplicationMethod,
    accountsToCredit,
    formattedSubtotalHT,
    formattedTotalTVA,
    formattedTotalTTC,
    currency,
    tvaVentilation,
    getUnitLabel,
    services,
    refundedInvoice,
    issuerName,
    issuerAddress,
    issuerVatNumber,
    issuerEnterpriseNumber,
    issuerEnterpriseNumberLabel,
    receiverName,
    receiverAddress,
    receiverVatNumber,
    receiverEnterpriseNumber,
    receiverEnterpriseNumberLabel,
  };
}
