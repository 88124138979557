import { Email } from "@/components/elements/stakeholder/contact/Email";
import InputTailwind from "@/components/token/Input/inputTailwind";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import LidapLogo from "@/components/token/logo-lidap-horizontal";
import { useFullLogout } from "@/hooks/useFullLogout";
import {
  OryRecoveryErrors,
  useCreateOryRecoveryFlow,
  useGetOryRecoveryFlow,
  useOryRecovery,
} from "@/hooks/useOryRecovery";
import { notify } from "@/utils/notify";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export default function AccountRecovery() {
  /* States */
  const emptySimpleField = { success: false, data: "", isDirty: false };
  const [mail, setMail] = useState(emptySimpleField);
  const [flow, setFlow] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const flowId = searchParams.get("flow") || flow?.recoveryFlow?.id;
  const emailSent = searchParams.get("emailSent") === "true";
  const email = searchParams.get("email") || mail.data;
  const [code, setCode] = useState({
    data: "",
    isDirty: false,
  });
  const navigate = useNavigate();

  /* Mutations */
  const oryRecoveryEmail = useOryRecovery(["ory-recovery"], [], () => {
    searchParams.set("emailSent", "true");
    searchParams.set("email", mail.data || email);
    setSearchParams(searchParams);
    notify({
      isSuccess: true,
      message: `Email de réinitialisation ${emailSent ? "renvoyé" : "envoyé"}`,
    });
  });

  const oryRecoveryCode = useOryRecovery(
    ["ory-recovery"],
    [["ory-session"]],
    () => {
      notify({
        isSuccess: true,
        message: "Code correct",
      });
      navigate("/settings/profile?referrer=recovery");
    }
  );

  const createRecoveryFlow = useCreateOryRecoveryFlow((data) => {
    if (flowId && flowExpiresAt && flowExpiresAt >= new Date().toISOString())
      return;

    setFlow(data);
    searchParams.set("flow", data.recoveryFlow.id);
    setSearchParams(searchParams);
  });

  /* Queries */
  const getRecoveryFlow = useGetOryRecoveryFlow(
    { id: flowId },
    {
      enabled: !!flowId && !createRecoveryFlow.isSuccess,
    }
  );

  /* Hooks */
  // useEffect(() => {
  //   if (oryRecoveryCode.isSuccess) {
  //     // navigate("/security/change-password?referrer=recovery");
  //     /* Fixes Ory's 422 error */
  //     navigate("/settings/profile?referrer=recovery");
  //   }
  // }, [oryRecoveryCode.isSuccess, navigate]);

  useEffect(() => {
    if (flowId) {
      return;
    }

    createRecoveryFlow.mutate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useFullLogout(() => {
    navigate("/login");
  }, true);

  /* Handlers */
  function handleSubmitEmail(event) {
    event.preventDefault();
    if (!mail.success && !email) {
      return;
    }

    if (flowExpiresAt < new Date().toISOString()) {
      createRecoveryFlow.mutate();
      return;
    }

    oryRecoveryEmail.mutate({
      flowId: flowId,
      csrf_token: csrf_token,
      email: mail.data || email,
    });
  }

  function handleSubmitCode(event) {
    event.preventDefault();
    if (
      (!oryRecoveryEmail.isIdle && !oryRecoveryEmail.isSuccess) ||
      !code.data ||
      code.data.length < 6
    ) {
      return;
    }

    if (flowExpiresAt < new Date().toISOString()) {
      createRecoveryFlow.mutate();
      return;
    }

    oryRecoveryCode.mutate({
      flowId: flowId,
      csrf_token: csrf_token,
      code: code.data,
    });
  }

  /* Deduced values */
  const csrf_token = flow?.csrf_token || getRecoveryFlow.data?.csrf_token;
  const flowExpiresAt = (() => {
    if (flow) return flow?.recoveryFlow?.expires_at;
    if (getRecoveryFlow.data) return getRecoveryFlow.data?.data?.expires_at;
    return "";
  })();
  const errorInRecovery = (() => {
    if (!oryRecoveryCode.isError || !oryRecoveryCode.error) return "";
    if (code.isDirty) return ""; // avoid showing the error if the user has not interacted with the field

    if (
      !oryRecoveryCode.error.code ===
      OryRecoveryErrors.ORY_INVALID_RECOVERY_CODE
    )
      return "";

    return oryRecoveryCode.error?.message;
  })();

  /* Render */

  return (
    <div className="flex flex-row w-screen">
      <div className="flex flex-col w-full h-screen">
        <div className="flex">
          <div className="wrap-menu-logo">
            <Link to="/" style={{ cursor: "auto" }} className="wrap-logo-lidap">
              <LidapLogo
                alt="logo de Lidap"
                className="menu-logo-lidap"
                color="var(--shades-900)"
                maxWidth="6rem"
                height="100%"
                style={{ cursor: "pointer" }}
              />
            </Link>
          </div>
        </div>
        <div className="flex justify-center items-center flex-col m-auto w-96 gap-8 h-full pb-16">
          {emailSent ? (
            <div id="codeSent">
              <div id="message">Code de récupération envoyé</div>
              <p className="text-gray-700 mb-4">
                Un e-mail est en route vers {email} avec les instructions pour
                réinitialiser votre mot de passe.
              </p>

              <p className="text-gray-600 mb-4">
                Si vous ne recevez pas d&apos;email, vérifiez que l&apos;adresse
                email que vous avez saisie est correcte, vérifiez vos spams ou
                contactez{" "}
                <a
                  style={{ color: "#E16D64" }}
                  href={`mailto:support@lidap.io?subject=Lidap - Erreur de récupération du compte&body=Bonjour, %0D%0A%0D%0AJe rencontre actuellement un problème avec Lidap, ne pouvant pas récupérer mon compte. %0D%0A%0D%0AMerci de votre aide. %0D%0A%0D%0ACordialement, %0D%0A%0D%0A${email}]`}
                >
                  support@lidap.io
                </a>{" "}
                si le problème persiste.
              </p>
            </div>
          ) : (
            <div id="message">Mot de passe oublié ?</div>
          )}
          <form
            id="InputContainer"
            onSubmit={emailSent ? handleSubmitCode : handleSubmitEmail}
          >
            <div className="wrap-inputs">
              {emailSent ? (
                <InputTailwind
                  label="Code"
                  placeholder="Ex: 658952"
                  type="text"
                  onChange={(e) =>
                    setCode((prev) => {
                      return { ...prev, data: e.target.value, isDirty: true };
                    })
                  }
                  maxLength={6}
                  isRequired={true}
                  id="code"
                  value={code.data}
                  error={errorInRecovery}
                  name="code"
                />
              ) : (
                <Email
                  name="email"
                  label="Email"
                  onChange={setMail}
                  required={true}
                  initialEmail={mail.data}
                  id="email"
                  displayError={oryRecoveryEmail.isError}
                />
              )}
            </div>

            <div className="flex flex-col w-full items-stretch gap-2 justify-center items-center">
              {emailSent ? (
                <ButtonTailwind
                  variant="filled"
                  width="100%"
                  type="submit"
                  loading={oryRecoveryCode.isLoading}
                  disabled={!code.data || code.data.length < 6}
                >
                  {" "}
                  Vérifier le code
                </ButtonTailwind>
              ) : null}
              <ButtonTailwind
                variant={emailSent ? "outlined" : "filled"}
                width="100%"
                type={emailSent ? "button" : "submit"}
                loading={oryRecoveryEmail.isLoading}
                onClick={handleSubmitEmail}
              >
                {emailSent ? "Renvoyer" : "Envoyer"} l&apos;email de
                réinitialisation
              </ButtonTailwind>

              <div className="flex items-center justify-center" id="back">
                <Link
                  onClick={() => logout.mutate()}
                  to="/login"
                  id="backToLogin"
                  className="text-neutral-700 hover:text-black text-sm"
                >
                  Retour à la connexion
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="LateralBlock"></div>
    </div>
  );
}
