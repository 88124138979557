import React, { useState } from "react";
import { validatePassword } from "@/components/elements/User/passwordValidator";
import {
  EyeIcon,
  EyeSlashIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import "./Password.css";

export function Password({
  initialPassword,
  disabled,
  onChange,
  autoComplete,
  KeyDownManager,
  isLoading,
  required,
  name = "password",
  label = "Mot de passe",
  id,
  displayError = true,
  bypassRules = false,
  externalError = null,
  erroneousPassword = null,
}) {
  const password = initialPassword;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const feedback = validatePassword(initialPassword, required, bypassRules);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    const feedback = validatePassword(value, required, bypassRules);
    onChange({
      success:
        feedback.success && (!externalError || erroneousPassword !== value),
      data: value,
      isDirty: true,
    });
  };

  const error = (() => {
    if (isFocused) return "";
    if (externalError) {
      return externalError;
    }

    return feedback.success || !displayError ? null : feedback.message;
  })();

  const displayEyeIcon = () => {
    return isPasswordVisible ? (
      <EyeSlashIcon
        className="h-6 w-6 text-gray-500 cursor-pointer"
        onClick={() => setIsPasswordVisible(false)}
      />
    ) : (
      <EyeIcon
        className="h-6 w-6 text-gray-500 cursor-pointer"
        onClick={() => setIsPasswordVisible(true)}
      />
    );
  };

  return (
    <div className="flex flex-col w-full">
      <label
        htmlFor={name}
        className={`block text-sm font-medium leading-6 ${
          isLoading ? "text-gray-300" : "text-gray-900"
        }`}
      >
        {label}
      </label>
      <div
        // tabIndex={0} // Rend cette div focusable
        className={`block w-full rounded-md ring-1 shadow-sm mt-1 overflow-hidden
            ${isFocused ? "ring-black dark:ring-white ring-2" : "ring-gray-300"}
            ${
              disabled
                ? "bg-gray-100 border-gray-200 text-gray-400 cursor-not-allowed"
                : "bg-white"
            }
            ${
              feedback.success || !displayError
                ? ""
                : "border-red-300 text-red-900 focus:ring-red-500"
            } relative w-full ${isLoading ? "bg-gray-200" : ""} flex`}
      >
        <div className="w-full">
          <input //TODO!: fix it --> should be an InputTailwind
            type={isPasswordVisible ? "text" : "password"}
            name={name}
            id={id}
            value={password}
            className="bg-transparent border-0 h-max focus:border-0 ring-0 focus:ring-0 w-full py-1.5 sm:text-sm sm:leading-6 placeholder-gray-400 dark:placeholder-[#444]"
            placeholder="Mot de passe"
            onChange={handlePasswordChange}
            onKeyDown={KeyDownManager}
            disabled={disabled}
            required={required}
            autoComplete={autoComplete}
            onFocus={() => setIsFocused(true)} // Définir le focus à true
            onBlur={() => setIsFocused(false)} // Définir le focus à false
          />
          {(feedback.success && !externalError) ||
          !displayError ||
          !error ? null : (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>

        <div className="w-max px-2 flex justify-center items-center select-none">
          {displayEyeIcon()}
        </div>
      </div>
      {(feedback.success && !externalError) ||
      !displayError ||
      !error ? null : (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      )}
    </div>
  );
}
