import React from "react";
import { useUser } from "@/contexts/UserContext";
import BackgroundImage from "@/assets/Images/image-femme-login.jpg";
import StepForm from "@/components/elements/StepForm";
import EnterpriseForm from "@/feature/EnterpriseForm/EnterpriseForm";
import NumerotationQuantity from "@/feature/NumerotationQuantity/NumerotationQuantity";
// import { useParams } from "react-router-dom";
import { useFormCreateEnterprise } from "@/hooks/forms/useFormCreateEnterprise";
import useFormNumbering from "@/hooks/forms/useFormNumbering";
import { CRUDL } from "@/utils/crudl";
import { useOnboarding } from "@/contexts/OnboardingContext";
import { useQueryClient } from "@tanstack/react-query";

const SIGNUP_STEPS = {
  enterprise: {
    index: 0,
    name: "enterprise",
  },
  numbering: {
    index: 1,
    name: "numbering",
  },
};

export default function OneLastStep({ stepName }) {
  /* Navigation and location */
  // const { stepName } = useParams();
  const step = SIGNUP_STEPS[stepName]?.index || 0;

  /* Context */
  const userContext = useUser();
  const onboarding = useOnboarding();
  const enterprise = onboarding.enterprise;
  const currentInvoiceNumber = onboarding.currentInvoiceNumber;

  /* Queries */

  // useEffect(() => {
  //   if (enterprise.isSuccess) {
  //     const enterpriseId =
  //       enterprise.data?.payload?.enterprises?.find(
  //         (enterprise) => enterprise.id === userContext.state.user.enterpriseId
  //       )?.id || enterprise.data?.payload?.enterprises?.[0]?.id;

  //     if (enterpriseId) {
  //       navigate(`/one-last-step/${SIGNUP_STEPS.numbering.name}`);
  //     }
  //   }
  // }, [
  //   enterprise.data?.payload?.enterprises,
  //   enterprise.isSuccess,
  //   navigate,
  //   userContext.state.user.enterpriseId,
  // ]);

  // useEffect(() => {
  //   if (userContext.state.isError) {
  //     notify({
  //       isSuccess: false,
  //       message:
  //         "Une erreur s'est produite lors de la récupération de vos informations",
  //     });
  //     return;
  //   }

  //   if (!userContext.state.isLoading && enterprise.isSuccess) {
  //     const enterpriseId =
  //       enterprise.data?.payload?.enterprises?.find(
  //         (enterprise) => enterprise.id === userContext.state.user.enterpriseId
  //       )?.id || enterprise.data?.payload?.enterprises?.[0]?.id;

  //     if (enterpriseId) {
  //       navigate(`/one-last-step/${SIGNUP_STEPS.numbering.name}`);
  //     }
  //   }
  // }, [
  //   enterprise.data?.payload?.enterprises,
  //   enterprise.isSuccess,
  //   navigate,
  //   userContext.state.isError,
  //   userContext.state.isLoading,
  //   userContext.state.user.enterpriseId,
  // ]);

  const enterpriseId =
    enterprise.data?.payload?.enterprises?.find(
      (enterprise) => enterprise.id === userContext.state.user.enterpriseId
    )?.id || enterprise.data?.payload?.enterprises?.[0]?.id;

  /* Forms */
  const enterpriseForm = useFormCreateEnterprise({
    onSuccessfulCreation: () => {
      // enterprise.refetch();
    },
  });
  const queryClient = useQueryClient();
  const numberingForm = useFormNumbering({
    onSubmit: () => {
      /* This is required to ensure the latest info from the user is refreshed after the onboarding has ended */
      queryClient.invalidateQueries({
        queryKey: ["users", "info"],
      });
    },
    currentNumbers: currentInvoiceNumber,
    isLoading: currentInvoiceNumber.isLoading,
    context: CRUDL.CREATE,
    baseEnterpriseId: enterpriseId,
  });

  /* Mutations */

  const submitFunctions = [
    enterpriseForm.handleSubmit,
    numberingForm.handleSubmit,
  ];

  // useEffect(() => {
  //   if (
  //     currentInvoiceNumber.data?.payload?.currentInvoiceNumbers?.length >= 3
  //   ) {
  //     navigate("/home?referrer=signup-complete");
  //   }
  // }, [currentInvoiceNumber.data?.payload?.currentInvoiceNumbers, navigate]);

  /* Render */

  return (
    <div className="flex justify-between w-screen h-screen">
      <StepForm
        key={step}
        numberOfSteps={2}
        currentStep={step}
        TextSubmit="Valider mes paramètres"
        onSubmit={submitFunctions[step]}
        disableSubmit={
          enterprise.isInitialLoading ||
          currentInvoiceNumber.isInitialLoading ||
          (step === 0 &&
            (enterpriseId ||
              enterpriseForm.createEnterprise.isSuccess ||
              !enterpriseForm.isFormValid))
        }
        loadingSubmit={
          numberingForm.isUpdateLoading ||
          enterpriseForm.createEnterprise.isLoading
        }
      >
        {step === 0 && <EnterpriseForm enterpriseForm={enterpriseForm} />}
        {/* {step === 1 && <NumerotationType />} */}
        {step === 1 && <NumerotationQuantity numberingForm={numberingForm} />}
      </StepForm>

      <div
        className="flex w-full h-screen bg-cover border-16 border-white rounded-bl-8xl "
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      ></div>
    </div>
  );
}
