export function trimSpaces(input) {
  if (!input) return "";
  const trimmedString = input.trim().replace(/\s+/g, " ");
  return trimmedString;
}

export function trimAllSpaces(input) {
  if (!input) return "";
  const trimmedString = input.trim().replace(/\s+/g, "");
  return trimmedString;
}

export function trimAllDots(input) {
  if (!input) return "";
  const trimmedString = input.trim().replace(/\./g, "");
  return trimmedString;
}
