import React from "react";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import styles from "./ImageImport.module.css";
import Base64Image from "./Base64Image";
import { getBase64 } from "@/utils/getBase64";

// HOW TO IMPORT :
//  <ImageImport setRawLogo={setRawLogo} setUsableLogo={setUsableLogo}/>

export default function ImageImport(props) {
  const inputLogo = props.logo;

  const convertFormat = (format) => {
    if (format === "image/png") {
      return "png";
    }
    if (format === "image/jpg" || format === "image/jpeg") {
      return "jpg";
    }
    if (format === "image/svg+xml") {
      return "svg+xml";
    }
    return "";
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    props.handleChange({
      data: {
        imageContent: await getBase64(file),
        imageFormat: convertFormat(file?.type),
        purpose: inputLogo.data.purpose,
        id: inputLogo.data.id,
        file: file,
      },
      isDirty: true,
      success: true,
    });
  };

  return (
    <div>
      <div className="flex flex-row gap-4">
        <label
          className="flex items-center justify-center w-16 h-16 bg-neutral-200 cursor-pointer border border-neutral-300 dark:border-neutral-700 rounded-md shadow-sm overflow-hidden"
          htmlFor="inputFile"
        >
          {inputLogo?.data?.imageContent ? (
            <Base64Image
              base64={inputLogo.data.imageContent}
              format={inputLogo.data.imageFormat}
              alt={props.alt}
              className={styles.selectedLogo}
            />
          ) : (
            <AddPhotoAlternateOutlinedIcon sx={{}} />
          )}
        </label>

        <div className={styles.changeFileInfos}>
          {/* <label
            htmlFor="inputFile"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Modifier
          </label> */}

          <input
            type="file"
            id="inputFile"
            accept=".png,.jpg,.jpeg,.svg"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />

          <p className="text-sm text-neutral-500">
            Vérifiez que l&apos;image soit bien au format<br></br> PNG, JPG ou
            SVG et qu&apos;elle ne dépasse<br></br> pas 5 Mo
          </p>
        </div>
      </div>
    </div>
  );
}
