export const DocumentTypeLabel = {
  QUOTE: "Devis",
  INVOICE: "Facture",
  CREDIT_NOTE: "Note de crédit",
};

export const LidapDocumentType = {
  QUOTE: "QUOTE",
  INVOICE: "INVOICE",
  CREDIT_NOTE: "CREDIT_NOTE",
};

export const DocumentCreationLabel = {
  QUOTE: "Créer un devis",
  INVOICE: "Créer une facture",
  CREDIT_NOTE: "Créer une note de crédit",
};

export default function DocumentTypeStatus(props) {
  return <div>{DocumentTypeLabel[props.status]}</div>;
}
