import React from "react";

function InputPriceSelector({
  type,
  name,
  id,
  label,
  placeholder,
  numberValue,
  onChange,
  isRequired,
  selectLabel,
  selectValue,
  onSelect,
  options,
  error,
  errorOnFocus = false,
  onFocus,
  onBlur,
}) {
  const [focused, setFocused] = React.useState(false);
  const handleOnFocus = () => {
    if (onFocus) onFocus();
    setFocused(true);
  };
  const handleOnBlur = () => {
    if (onBlur) onBlur();
    setFocused(false);
  };

  return (
    <div>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          type={type}
          name={name}
          id={id}
          required={isRequired}
          className={`${
            (!focused || errorOnFocus) && error
              ? "border-red-300 text-red-900 placeholder-red-300 ring-red-500 focus:ring-red-500 "
              : "ring-gray-300 placeholder-gray-400 focus:ring-black dark:focus:ring-white "
          }block w-full rounded-md border-0 py-1.5 pl-3 pr-20 text-gray-900 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white`}
          placeholder={placeholder}
          value={numberValue !== null ? numberValue : ""}
          onChange={(e) =>
            onChange(e.target.value === "" ? "" : Number(e.target.value))
          }
          aria-invalid={!!error}
          aria-describedby={error ? `${id}-error` : undefined}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor="currency" className="sr-only">
            {selectLabel}
          </label>
          <select
            id="currency"
            name="currency"
            value={selectValue}
            onChange={onSelect}
            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-black dark:focus:ring-white sm:text-sm"
          >
            {options?.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      {(!focused || errorOnFocus) && error && error !== true && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      )}
    </div>
  );
}

export default InputPriceSelector;
