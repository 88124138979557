import React from "react";
import "@/assets/css/Presentation.css";
import RequireSubscriptionButton from "@/components/token/button/RequireSubscriptionButton";

export default function Presentation(props) {
  return (
    <main className="w-full h-full bg-neutral-100">
      <div id="wrap-feature-welcome-screen">
        <div id="feature-welcome-screen">
          <div id="welcome-screen-content">
            <div id="welcome-screen-content-text">
              <p className="text-black text-2xl font-semibold">{props.title}</p>
              <p className="text-neutral-500">{props.text}</p>
            </div>

            <RequireSubscriptionButton
              onClick={() => {
                props.onClick ? props.onClick() : null;
              }}
            >
              {props.buttonContent}
            </RequireSubscriptionButton>
          </div>

          <div
            className="max-h-48 flex justify-center"
            id="welcome-screen-illustration"
          >
            <img src={props.illustration} />
          </div>
        </div>
      </div>
    </main>
  );
}
