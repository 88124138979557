import { useState } from "react";
import Ribbon from "./Ribbon";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useEnterpriseId } from "@/contexts/UserContext";
import { getCurrentSubscription } from "@/hooks/stripe/useLidapStripe";

export default function SubscriptionStateRibbon({
  isShown = true,
  trialDays,
  isTrialing,
  isActive,
  isLoading,
  isPaymentMethodSet,
  cancelAtPeriodEnd,
}) {
  const [showRibbon, setShowRibbon] = useState(
    isShown &&
      ((isTrialing &&
        trialDays < (process.env.SHOW_RIBBON_THRESHOLD_DAYS || 7) &&
        !isPaymentMethodSet) ||
        !isActive ||
        cancelAtPeriodEnd)
  );

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const enterpriseId = useEnterpriseId();
  const { queryFn, queryKey } = getCurrentSubscription({ enterpriseId });

  const handleClose = () => {
    setShowRibbon(false);
  };

  if (!showRibbon || !isShown) return null;

  const message = (() => {
    if (!isActive)
      return "Votre essai gratuit est terminé. Abonnez-vous pour continuer à utiliser nos services.";

    if (cancelAtPeriodEnd)
      // return "Votre abonnement se terminera à la fin de la période. Réactivez-le pour continuer à utiliser notre service.";
      return "Votre essai gratuit a débuté, profitez de -50% dès aujourd’hui";

    if (trialDays < 0)
      return "Votre essai gratuit est terminé. Abonnez-vous pour continuer à utiliser nos services.";
    if (trialDays === 0)
      return "Votre essai gratuit se termine aujourd'hui. Abonnez-vous pour continuer à utiliser notre service.";

    return `Votre essai gratuit se termine dans ${trialDays} jours. Abonnez-vous pour continuer à utiliser notre service.`;
  })();

  return (
    <>
      {isLoading ? null : (
        <Ribbon
          message={message}
          onClose={handleClose}
          onClick={() => {
            navigate("/settings/subscriptions");
          }}
          onMouseEnter={() => {
            queryClient.prefetchQuery(queryKey, queryFn, {
              staleTime: 1000 * 60 * 5,
            });
          }}
        />
      )}
    </>
  );
}
