import React from "react";
import SearchableListWithMutation from "./SearchableList";
import { manageLidapResponse } from "@/utils/lidapi";

export default function Whitelist() {
  return (
    <div className="flex w-full">
      {/* <Menu origin="Whitelist" /> */}
      <div className="flex flex-row h-screen w-full items-center justify-center">
        <div className="flex h-full w-full p-16 gap-6">
          <SearchableListWithMutation
            content={"Ajouter"}
            fetchUrl={`${process.env.BASE_API_URL}/admin/not-whitelisted`}
            listName={"Demandes"}
            onClick={addToWhitelist}
            filterField={"email"}
            payloadArrayName={"notWhitelisted"}
            queryKey={["whitelist", "not-whitelisted"]}
            queryKeyToInvalidate={["whitelist"]}
          />
          <SearchableListWithMutation
            content={"Supprimer"}
            fetchUrl={`${process.env.BASE_API_URL}/admin/whitelist`}
            listName={"Whitelist"}
            onClick={removeFromWhitelist}
            filterField={"email"}
            payloadArrayName={"whitelist"}
            queryKey={["whitelist", "whitelisted"]}
            queryKeyToInvalidate={["whitelist"]}
          />
        </div>
      </div>
    </div>
  );
}

async function addToWhitelist(item) {
  const email = item.email;
  const response = await fetch(`${process.env.BASE_API_URL}/admin/whitelist`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ email }),
  });

  return manageLidapResponse(response, "Could not add to whitelist");
}

async function removeFromWhitelist(item) {
  const id = item.id;
  const response = await fetch(
    `${process.env.BASE_API_URL}/admin/whitelist/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error(`Remove from whitelist failed: ${response.status}`);
  }

  if (response.status > 299 || response.status < 200) {
    throw new Error(`Remove from whitelist failed: ${response.status}`);
  }

  return response.json();
}

// Error handling component
// const ErrorNotification = ({ error }) => {
//   const [show, setShow] = useState(true);

//   if (!error || !show) {
//     return null;
//   }

//   return (
//     <div className="error-notification">
//       {error}
//       <button onClick={() => setShow(false)}>Dismiss</button>
//     </div>
//   );
// };
