import { useUser } from "@/contexts/UserContext";
import { useRecentClients } from "@/hooks/useClients";

export function useDefaultClient() {
  const userContext = useUser();
  const lastClientCreated = useRecentClients({
    enterpriseId: userContext?.state?.user?.enterpriseId,
    queryParam: {
      pageNumber: 0,
      numberOfRecords: 1,
    },
    reactQueryConfig: {
      refetchOnWindowFocus: false,
    },
  });
  const lastClient = (() => {
    const results = lastClientCreated?.data?.payload?.results;
    if (results && results.length > 0) {
      return results[0];
    }
  })();
  const lastCountry = lastClient?.addresses?.find((a) => a.isMain)?.country;
  const defaultNation =
    lastCountry || userContext?.state?.user?.country || "BE";

  const defaultClientType = lastClient?.clientType || "COMPANY";

  return {
    defaultNation,
    defaultClientType,
    isDefaultClientLoading: lastClientCreated.isLoading,
  };
}
