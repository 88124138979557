// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rnt2Q4R9GeZUzF52RxV5 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.yY1pU7PLcwvEtXMvl0gB {
  color: var(--neutral-400);
  border: 1px solid var(--border);
}

.dxug35jHaJXcRawdwtYi {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/elements/ImageImport/ImageImport.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV","sourcesContent":[".selected-logo {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.fileInput {\n  color: var(--neutral-400);\n  border: 1px solid var(--border);\n}\n\n.changeFileInfos {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected-logo": `rnt2Q4R9GeZUzF52RxV5`,
	"fileInput": `yY1pU7PLcwvEtXMvl0gB`,
	"changeFileInfos": `dxug35jHaJXcRawdwtYi`
};
export default ___CSS_LOADER_EXPORT___;
