import React from "react";
import PropTypes from "prop-types";
import "./buttonTailwind.css";

const ButtonTailwind = ({
  onClick,
  children,
  icon,
  iconEnd,
  variant = "filled",
  size = "medium",
  value,
  style,
  loading = false,
  disabled = false,
  type = "button",
  onMouseEnter,
}) => {
  const buttonStyles = {
    filled:
      "bg-neutral-900 text-white shadow-sm hover:bg-neutral-800 dark:hover:bg-neutral-200",
    outlined:
      "border border-neutral-500 text-neutral-900 shadow-sm hover:bg-neutral-100 dark:hover:bg-neutral-900 input-design",

    outlined2:
      "border border-gray-300 text-black hover:bg-gray-100 dark:hover:bg-neutral-900 shadow-sm input-design",
    text: "text-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-900",
    disabled: "bg-neutral-300 opacity-50 cursor-not-allowed",
  };

  const sizeStyles = {
    small: "px-2.5 py-1.5 text-xs",
    medium: "px-4 py-2 text-sm",
    large: "px-6 py-3 text-lg",
  };

  const getClassNames = () => {
    let classNames = `${style} ${sizeStyles[size]} rounded-md text-center justify-center focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 whitespace-nowrap flex items-center gap-x-2`;

    if (loading || disabled) {
      classNames += ` ${buttonStyles.disabled}`;
    } else {
      classNames += ` ${buttonStyles[variant]}`;
    }

    return classNames;
  };

  return (
    <button
      type={type}
      className={getClassNames()}
      onClick={!disabled ? onClick : null}
      value={value}
      disabled={loading || disabled}
      onMouseEnter={onMouseEnter}
    >
      {loading ? (
        <p className="flex items-center text-neutral-500">
          <span className="spinner mr-2 "></span>
          Chargement...
        </p>
      ) : (
        <>
          {icon && <span className="flex-shrink-0">{icon}</span>}
          {children}
          {iconEnd && <span className="flex-shrink-0">{iconEnd}</span>}
        </>
      )}
    </button>
  );
};

ButtonTailwind.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  icon: PropTypes.node,
  iconEnd: PropTypes.node,
  variant: PropTypes.oneOf([
    "filled",
    "outlined",
    "outlined2",
    "text",
    "disabled",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool, // Add PropTypes for disabled
  type: PropTypes.string,
};

export default ButtonTailwind;
