import React from "react";
import SelectMenu from "@/components/token/select/select";
import { vatDisclaimers } from "@/data/vatDisclaimers";
import { DocumentActions } from "@/components/elements/Document/DocumentForm";
import { formatDateToISO } from "@/utils/date/dateTransformers";
import { DateDefaults } from "@/utils/date/DateDefaults";
import { DocumentAdditionalDataType } from "../document/DocumentAdditionalDataType";
import { DocumentAdditionalDataSubType } from "../document/DocumentAdditionalDataSubType";
import ValidatedTextArea from "@/components/token/textareas/ValidatedTextArea";
import { DocumentSettingsKey } from "../Settings/helpers/extractSetting";
import AccountsToCreditV2 from "@/components/elements/CompteACrediter/AccountsToCreditV2";
import { useCommonDocumentForm } from "../document/useDocumentForm";
import { MyDateRangePickerV2 } from "../createInvoice/MyDateRangePickerV2";
import ValidatedInputTailwind from "@/components/token/Input/ValidatedInputTailwind";
import { extractSimpleFieldError } from "../document/helpers/extractDocumentErrors";

export function LastStepInvoiceFormV2({
  enterprise,
  documentData,
  setDocumentData,
  paymentInformation,
  lastDocumentOfSameType,
  documentSettings,
}) {
  const {
    documentConditions,
    conditionsType,
    documentUserTitle,
    accountsToCredit,
    vatDisclaimer,
    handleSettings,
    manageSettings,
    settingsBeingSaved,
    issuingDateMinAsMMDDYYYY,
    issuingDateAsMMDDYYYY,
    dueDateAsMMDDYYYY,
  } = useCommonDocumentForm({
    documentData,
    setDocumentData,
    enterprise,
    lastDocumentOfSameType,
    documentSettings,
  });

  /* Render */
  return (
    <div className="flex flex-col gap-8">
      <div className="w-full flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <MyDateRangePickerV2
            startDateLabel={"Date d'émission"}
            startDate={issuingDateAsMMDDYYYY}
            setStartDate={(date) =>
              setDocumentData({
                type: DocumentActions.SET_SIMPLE_FIELD,
                field: "issuingDate",
                payload: formatDateToISO(
                  date,
                  DateDefaults.TIMEZONE,
                  DateDefaults.LOCALE
                ),
              })
            }
            minStartDate={issuingDateMinAsMMDDYYYY}
            tooltipStartDate="La date d'émission ne peut pas être antérieure à la date d'émission du dernier document de ce type"
            isStartDateShown={true}
            endDateLabel={"Date d'échéance"}
            endDate={dueDateAsMMDDYYYY}
            setEndDate={(date) =>
              setDocumentData({
                type: DocumentActions.SET_SIMPLE_FIELD,
                field: "dueDate",
                payload: formatDateToISO(
                  date,
                  DateDefaults.TIMEZONE,
                  DateDefaults.LOCALE
                ),
              })
            }
            isEndDateShown={true}
          />

          <ValidatedInputTailwind
            label="Intitulé du document"
            type="text"
            placeholder="Nommez votre document pour faciliter son identification"
            inputText={documentUserTitle.value}
            setInputValue={(value, error) => {
              const payload = {
                additionalDataType: DocumentAdditionalDataType.DOCUMENT_TITLE,
                additionalDataSubType:
                  DocumentAdditionalDataSubType.NOT_APPLICABLE,
                additionalData: value,
              };
              if (error) payload.error = error;

              setDocumentData({
                type: DocumentActions.SET_UNIQUE_ADDITIONAL_DATA,
                payload,
              });
            }}
            inputError={documentUserTitle.error}
            maxLength={60}
          />

          <ValidatedInputTailwind
            label="Numéro de bon de commande (optionnel)"
            id="purchaseOrderIn"
            type="text"
            placeholder="Indiquez un numéro de bon de commande"
            inputText={documentData.purchaseOrder}
            setInputValue={(value, error) => {
              const payload = {
                value,
              };
              if (error) payload.error = error;

              setDocumentData({
                type: DocumentActions.SET_SIMPLE_FIELD_WITH_ERROR,
                payload,
                field: "purchaseOrder",
              });
            }}
            inputError={extractSimpleFieldError({
              documentData,
              field: "purchaseOrder",
            })}
            maxLength={60}
          />
        </div>
      </div>
      <AccountsToCreditV2
        accounts={paymentInformation}
        accountsToCredit={accountsToCredit}
        toggleAccount={(account) => {
          setDocumentData({
            type: DocumentActions.TOGGLE_PAYMENT,
            payload: account,
          });
        }}
      />
      <SelectMenu
        selectedItem={vatDisclaimer}
        setSelectedItem={(v) =>
          setDocumentData({
            type: DocumentActions.SET_UNIQUE_ADDITIONAL_DATA,
            payload: {
              additionalDataType: DocumentAdditionalDataType.VAT_DISCLAIMER,
              additionalDataSubType:
                DocumentAdditionalDataSubType.NOT_APPLICABLE,
              additionalData: v,
            },
          })
        }
        data={vatDisclaimers(enterprise.country)}
        label={"Mention de TVA"}
        placeholder={"Mention de TVA"}
        onSave={(value) => {
          handleSettings({
            settingsKey: DocumentSettingsKey.VAT_DISCLAIMER,
            settingsValue: value,
          });
        }}
        loading={
          manageSettings.isLoading &&
          settingsBeingSaved === DocumentSettingsKey.VAT_DISCLAIMER
        }
      />
      <ValidatedTextArea
        label="Conditions de paiement et mentions légales (optionnel)"
        placeholder="Numéro de TVA intracommunaire, mode de paiement, délai de paiement, frais applicables en cas de retard de paiement, etc..."
        value={documentConditions.value}
        setInputValue={(value, error) => {
          const payload = {
            additionalDataType: conditionsType,
            additionalDataSubType: DocumentAdditionalDataSubType.NOT_APPLICABLE,
            additionalData: value,
          };
          if (error) payload.error = error;

          setDocumentData({
            type: DocumentActions.SET_UNIQUE_ADDITIONAL_DATA,
            payload,
          });
        }}
        maxLength={500}
        save={true}
        onSave={(value) => {
          handleSettings({
            settingsKey: DocumentSettingsKey.DOCUMENT_CONDITIONS,
            settingsValue: value,
            error: documentConditions.error,
          });
        }}
        loading={
          manageSettings.isLoading &&
          settingsBeingSaved === DocumentSettingsKey.DOCUMENT_CONDITIONS
        }
        error={documentConditions.error}
      />
    </div>
  );
}
