import { Name } from "@/components/elements/User/Name";
import { Identifier } from "@/components/elements/stakeholder/identifier/Identifier";
import { CRUDL } from "@/utils/crudl";

export function ClientCompany({
  clientForm,
  isLoading,
  context,
  forceValidate,
}) {
  const nation = clientForm.selectedNation || clientForm.defaultNation;
  const companyIdentifier =
    clientForm.companyIdentifier?.data &&
    (clientForm.companyIdentifier?.isDirty ||
      nation !== clientForm.defaultCompanyIdentifier?.countryOrRegion)
      ? clientForm.companyIdentifier?.data
      : clientForm.defaultCompanyIdentifier;
  const vatNumber =
    clientForm.vatNumber?.data && clientForm.vatNumber?.isDirty
      ? clientForm.vatNumber?.data
      : clientForm.defaultVatNumber;

  return (
    <>
      <Name
        name="clientName"
        setName={clientForm.setClientName}
        label="Nom du client"
        placeholder="Acme Inc."
        id="clientName"
        initialName={clientForm.clientName.data || clientForm.defaultClientName}
        disabled={
          isLoading || context === CRUDL.READ || context === CRUDL.DELETE
        }
        onChange={(c) => {
          clientForm.setClientName((prev) => ({
            ...prev,
            ...c,
          }));
        }}
        required={true}
        displayError={clientForm.clientName.isDirty || clientForm.displayError}
        forceValidate={
          !clientForm.clientName.isDirty &&
          (context === CRUDL.UPDATE || forceValidate)
        }
        context={context}
        minLength={1}
      />

      {nation === "BE" || nation === "FR" ? (
        <Identifier
          identifierType={companyIdentifier.identifierType}
          countryOrRegion={
            clientForm.companyIdentifier?.isDirty
              ? companyIdentifier.countryOrRegion
              : nation
          }
          initialIdentifier={companyIdentifier.identifier}
          disabled={
            isLoading || context === CRUDL.READ || context === CRUDL.DELETE
          }
          onChange={(i) =>
            clientForm.setCompanyIdentifier((prev) => {
              return {
                ...prev,
                ...i,
              };
            })
          }
          isLoading={clientForm.isLastClientLoading}
          name={"companyNumber"}
          required={true}
          displayError={
            clientForm.companyIdentifier.isDirty || clientForm.displayError
          }
          placeholder={
            context === CRUDL.CREATE || context === CRUDL.DELETE
              ? null
              : "Numéro d'entreprise"
          }
          key={`${companyIdentifier?.identifierType}-${companyIdentifier?.countryOrRegion}`}
          forceValidate={
            !clientForm.companyIdentifier.isDirty &&
            (context === CRUDL.UPDATE || forceValidate)
          }
          forceFormatting={!clientForm.companyIdentifier.isDirty}
          context={context}
        />
      ) : null}

      <Identifier
        identifierType={vatNumber?.identifierType}
        countryOrRegion={nation}
        initialIdentifier={vatNumber?.identifier}
        disabled={
          isLoading || context === CRUDL.READ || context === CRUDL.DELETE
        }
        onChange={(i) =>
          clientForm.setVatNumber((prev) => {
            return {
              ...prev,
              ...i,
            };
          })
        }
        isLoading={clientForm.isLastClientLoading}
        name={"vatNumber"}
        required={false}
        displayError={clientForm.vatNumber.isDirty || clientForm.displayError}
        placeholder={
          context === CRUDL.CREATE || context === CRUDL.DELETE
            ? null
            : "Numéro de TVA"
        }
        key={`${vatNumber?.identifierType}-${vatNumber?.countryOrRegion}`}
        forceValidate={
          !clientForm.vatNumber.isDirty &&
          (context === CRUDL.UPDATE || forceValidate)
        }
        forceFormatting={!clientForm.vatNumber.isDirty}
        context={context}
      />
    </>
  );
}
