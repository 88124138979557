import { z } from "zod";

export function validateEmail(email, required = true) {
  const emailSchema = z.string().email();
  if (required && !email) {
    return {
      success: false,
      message: "Ce champ est requis",
      errorField: "email",
    };
  }

  if (email && !emailSchema.safeParse(email).success) {
    return {
      success: false,
      message: "Adresse e-mail invalide",
      errorField: "email",
    };
  }

  return {
    success: true,
    data: {
      email: email,
    },
  };
}
