import React from "react";

export default function SectionToggle({
  sections,
  activeSection,
  setActiveSection,
}) {
  return (
    <div className="flex flex-row justify-between gap-3 w-full border-b border-neutral-300">
      <div className="flex">
        {sections?.map((section) => (
          <button
            key={section.id}
            tabIndex={0}
            className={`text-sm text-neutral-900 py-[10px] border-b-2 ${
              section.id === activeSection
                ? "border-neutral-900"
                : "border-transparent"
            } mr-6`}
            onClick={() => {
              setActiveSection(section.id);
            }}
          >
            {section.name}
          </button>
        ))}
      </div>
    </div>
  );
}
