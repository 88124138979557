export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (!file) resolve();

    reader.readAsArrayBuffer(file);

    reader.onload = () => {
      const base64 = _arrayBufferToBase64(reader.result);
      resolve(base64);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};

function _arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
