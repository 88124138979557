// import ReactFlagsSelect from "react-flags-select";
// import styles from "./select.module.css";
// import React, { useState, useEffect } from "react";

// export default function SelectNation(props) {
//   const [selected, setSelected] = useState(props?.selectedNation);

//   useEffect(() => {
//     setSelected(props?.selectedNation);
//   }, [props?.selectedNation]);

//   const onSelect = (value) => {
//     setSelected(value);
//     props.setSelectedNation ? props.setSelectedNation(value) : null;
//     props.onSelect ? props.onSelect(value) : null;
//   };
//   const countries = props.countries;

//   return (
//     <div className="flex flex-col w-full">
//       <label className="block text-sm font-medium leading-6 text-gray-900">
//         Pays
//       </label>
//       <div className="mt-1 ">
//         <ReactFlagsSelect
//           className={styles.select}
//           searchable={true}
//           defaultCountry={selected ? selected : "BE"}
//           selected={selected}
//           onSelect={onSelect}
//           disabled={props?.disabled}
//           countries={countries ? countries : undefined}
//           // placeholder={"BE"}
//         />
//       </div>
//     </div>
//   );
// }
import React, { useState, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";
import "./selectCustom.css"; // Import your custom CSS file

export default function SelectNation(props) {
  const [selected, setSelected] = useState(props?.selectedNation);

  useEffect(() => {
    setSelected(props?.selectedNation);
  }, [props?.selectedNation]);

  const onSelect = (value) => {
    setSelected(value);
    props.setSelectedNation ? props.setSelectedNation(value) : null;
    props.onSelect ? props.onSelect(value) : null;
  };
  const countries = props.countries;

  return (
    <div className="flex flex-col w-full">
      <label className="block text-sm font-medium leading-6 text-gray-900">
        Pays
      </label>
      <div className="mt-1">
        <ReactFlagsSelect
          className="selectCustom"
          searchable={true}
          defaultCountry={selected ? selected : "BE"}
          selected={selected}
          onSelect={onSelect}
          disabled={props?.disabled}
          countries={countries ? countries : undefined}
          searchPlaceholder="Rechercher un pays" // Example of custom placeholder
        />
      </div>
    </div>
  );
}
