import React, { useState } from "react";
import ArticleOverview from "@/Pages/app/ArticleOverview";
import { useNavigate } from "react-router-dom";
import { CRUDL } from "@/utils/crudl";
import { useUser } from "@/contexts/UserContext";
import { useDeleteArticle } from "@/hooks/useDeleteArticle";
import { notify } from "@/utils/notify";

export default function ArticlePanel() {
  const navigate = useNavigate();
  const [deleteArticle, setDeleteArticle] = useState(null);
  const userContext = useUser();
  const user = userContext.state.user;
  const delItem = useDeleteArticle(["articles"], undefined, () => {
    notify({
      isSuccess: true,
      message: "L'article a bien été supprimé",
    });
    setDeleteArticle(null);
  });
  const handleDelete = () => {
    delItem.mutate({
      enterpriseId: user.enterpriseId,
      id: deleteArticle.id,
    });
  };

  function handleClientAction(action, data) {
    switch (action) {
      case CRUDL.CREATE:
        navigate("/articles/create");
        break;
      case CRUDL.UPDATE:
        navigate(`/articles/${data.id}`);
        break;
      case CRUDL.DELETE:
        setDeleteArticle(data);
        break;
      case CRUDL.READ:
        navigate(`/articles/${data.id}`);
        break;
      default:
        break;
    }
  }

  return (
    <ArticleOverview
      articleAction={handleClientAction}
      handleDelete={handleDelete}
      setDeleteArticle={setDeleteArticle}
      deleteArticle={deleteArticle}
    />
  );
}
