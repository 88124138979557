import React, { useMemo, useState } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { ButtonCopyToClipboard } from "../token/button/buttonCopyToClipboard";
import { useUser } from "@/contexts/UserContext";

function CouldNotLoad({ message, error }) {
  const [showMore, setShowMore] = useState(false);
  const userContext = useUser();
  const user = userContext.state.user;

  const messageFromHttp = useMemo(() => {
    switch (error?.statusCode) {
      case 400:
        return "La requête est invalide";
      case 401:
        return "L'authentification a échoué";
      case 403:
        return "Vous n'êtes pas autorisé à effectuer cette action";
      case 404:
        return "La ressource n'existe pas";
      case 500:
        return "Une erreur interne est survenue";
      default:
        return "";
    }
  }, [error?.statusCode]);

  return (
    <div className="flex flex-col w-full h-full items-center justify-center">
      <div className="rounded-md bg-red-50 p-4 w-max ">
        <div className="flex flex-col items-center gap-2">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800 text-center">
              {message ? message : "Error when loading page"}
              {messageFromHttp &&
                ` - ${messageFromHttp} [${error?.statusCode}]`}
            </h3>

            {!showMore && error?.traceId && (
              <button
                className="mt-1 text-sm text-gray-500"
                onClick={() => setShowMore(true)}
              >
                Plus d&apos;informations
              </button>
            )}
            {showMore && error?.traceId && (
              <>
                <p className="mt-1 text-sm text-gray-500">
                  Si le problème persiste, veuillez renseigner
                  l&apos;identifiant suivant au support de Lidap{" "}
                  <a
                    style={{ color: "#E16D64" }}
                    href={`mailto:support@lidap.io?subject=Lidap - Erreur [${error?.statusCode}] ${error?.traceId}&body=Bonjour, %0D%0A%0D%0AJe rencontre actuellement un problème avec Lidap. %0D%0A%0D%0AJe vous transmets l'identifiant de l'erreur : ${error?.traceId} %0D%0A%0D%0AMerci de votre aide. %0D%0A%0D%0ACordialement, %0D%0A%0D%0A${user?.firstName} ${user?.lastName}%0D%0A%0D%0A[id: ${user?.userId}]`}
                  >
                    support@lidap.io
                  </a>
                  :
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  <ButtonCopyToClipboard textToCopy={error?.traceId} />{" "}
                  <b>{error?.traceId}</b>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CouldNotLoad;
