import Presentation from "@/Pages/Presentation";
import AddClientIllustration from "@/assets/Images/AddClientIllustration.svg";

export function ClientStart({ onClick }) {
  return (
    <div className="flex flex-row w-full h-full overflow-y-scroll">
      <Presentation
        title="Enregistrez vos premiers clients"
        text="Centralisez tous vos clients et générez des documents en quelques clics à partir de leurs informations."
        illustration={AddClientIllustration}
        buttonContent="Nouveau client"
        onClick={onClick}
      />
    </div>
  );
}
