// import React from "react";

// const status = {
//   PAID: "Payé",
//   CREATED: "À payer",
//   TO_VALIDATE: "À valider",
//   DRAFT: "Brouillon",
//   CANCELLED: "Annulé",
// };

// const style = {
//   PAID: "text-green-700 bg-green-50 ring-green-600/20 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset w-max",
//   CREATED:
//     "text-yellow-600 bg-yellow-50 ring-yellow-500/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset w-max",
//   TO_VALIDATE:
//     "text-orange-600 bg-orange-50 ring-orange-500/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset w-max",
//   DRAFT:
//     "text-gray-600 bg-gray-50 ring-gray-500/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset w-max",
//   CANCELLED:
//     "text-red-700 bg-red-50 ring-red-600/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset w-max",
// };

// export default function InvoiceStatus(props) {
//   return <div className={style[props.status]}>{status[props.status]}</div>;
// }

import React from "react";

// Functions to return status and style based on documentType and status
const getStatus = (status, documentType) => {
  const baseStatus = {
    PAID: "Payé",
    CREATED: "À payer",
    TO_VALIDATE: "À valider",
    DRAFT: "Brouillon",
    CANCELLED: "Annulé",
  };

  // Customize or override the status text based on documentType if necessary
  if (documentType === "QUOTE") {
    return {
      ...baseStatus,
      PAID: "Validé",
      CREATED: "À confirmer", // Example modification for quotes
    };
  }

  return baseStatus;
};

const getStyle = (status, documentType) => {
  const baseStyle = {
    PAID: "text-green-700 bg-green-50 ring-green-600/20 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset w-max",
    CREATED:
      "text-yellow-600 bg-yellow-50 ring-yellow-500/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset w-max",
    TO_VALIDATE:
      "text-orange-600 bg-orange-50 ring-orange-500/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset w-max",
    DRAFT:
      "text-gray-600 bg-gray-50 ring-gray-500/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset w-max",
    CANCELLED:
      "text-red-700 bg-red-50 ring-red-600/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset w-max",
  };

  // // Customize or override the styles based on documentType if necessary
  // if (documentType === "QUOTE") {
  //   return {
  //     ...baseStyle,
  //     CREATED:
  //       "text-blue-600 bg-blue-50 ring-blue-500/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset w-max",
  //   };
  // }

  return baseStyle;
};

export default function InvoiceStatus({ status, documentType }) {
  // Retrieve the correct status and style based on documentType and status
  const currentStatus = getStatus(status, documentType)[status];
  const currentStyle = getStyle(status, documentType)[status];

  return <div className={currentStyle}>{currentStatus}</div>;
}
