import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ory } from "./useOryLogin";
import FetchError from "@/utils/FetchError";
import { manageAxiosError } from "@/utils/manageAxiosError";
import {
  getFirstOryError,
  getOryGlobalError,
  getOryResponseStatus,
} from "@/utils/ory.frontend";
import { faro } from "@/AppRouter";

export const OrySettingsErrors = {
  ORY_SETTINGS_FLOW: "AUTHEXT002",
  ORY_SETTINGS_UPDATE: "AUTHEXT006",
  ORY_SETTINGS_SESSION_REFRESH_REQUIRED: "AUTHEXTRFR",
  ORY_SETTINGS_GLOBAL_ERROR: "AUTHEXT007",
  ORY_SETTINGS_FIRST_ERROR: "AUTHEXT005",
  ORY_SETTINGS_UNKNOWN: "AUTHEXT008",
};

async function getSettingsFlow() {
  const { data: settingsFlow } = await ory.createBrowserSettingsFlow(
    {
      returnTo: "",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  // Submit the login form
  const attributes = settingsFlow.ui.nodes.find(
    (node) => node.group === "default"
  )?.attributes;
  const csrf_token = attributes.name === "csrf_token" ? attributes.value : null;
  return { settingsFlow, csrf_token };
}

export function useCreateOrySettingsFlow(onSuccess) {
  const action = useMutation({
    mutationFn: async () => await getSettingsFlow(),
    onSuccess: async (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
  });

  return action;
}

export function useGetOrySettingsFlow(queryParam, reactQueryOptions) {
  const { isLoading, isError, data, error, isFetching } = useQuery({
    queryKey: ["ory-settings-flow", queryParam],
    queryFn: async () => {
      try {
        const response = await ory.getSettingsFlow(
          {
            id: queryParam.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const attributes = response?.data?.ui?.nodes?.find(
          (node) => node.group === "default"
        )?.attributes;
        const csrf_token =
          attributes?.name === "csrf_token" ? attributes?.value : null;
        return { response, csrf_token };
      } catch (error) {
        if (process.env.ENV === "development") {
          console.error(error);
        }

        manageAxiosError(
          error,
          "Impossible de récupérer le flux de mise à jour des settings",
          OrySettingsErrors.ORY_SETTINGS_FLOW,
          "ory-settings-flow"
        );
      }
    },
    ...reactQueryOptions,
  });

  return {
    isLoading: isLoading,
    isError: isError,
    data: data,
    error: error,
    isFetching: isFetching,
  };
}

async function handleOrySettings({ flowId, method, password, csrf_token }) {
  const payload = {
    method: method,
    password: password,
    csrf_token: csrf_token,
  };

  try {
    const { data } = await ory.updateSettingsFlow(
      {
        flow: flowId,
        updateSettingsFlowBody: {
          ...payload,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error) {
    if (process.env.ENV === "development") {
      console.error(error);
    }
    const status = getOryResponseStatus(error);

    const globalError = getOryGlobalError(error);
    if (globalError) {
      const code = (() => {
        if (globalError.id === "session_refresh_required") {
          return OrySettingsErrors.ORY_SETTINGS_SESSION_REFRESH_REQUIRED;
        }
        return OrySettingsErrors.ORY_SETTINGS_GLOBAL_ERROR;
      })();
      const fetchError = new FetchError(
        `${globalError.message}: ${globalError.reason}`,
        status,
        code,
        undefined,
        "ory-settings-flow",
        true
      );

      faro.api.pushError(fetchError, {
        type: "ory",
      });

      throw fetchError;
    }

    const firstError = getFirstOryError(error);

    if (firstError) {
      const fetchError = new FetchError(
        firstError.text,
        status,
        OrySettingsErrors.ORY_SETTINGS_FIRST_ERROR,
        undefined,
        "ory-settings-flow",
        true
      );

      faro.api.pushError(fetchError, {
        type: "ory",
      });

      throw fetchError;
    }

    const fetchError = new FetchError(
      "Impossible de mettre à jour les settings",
      status,
      OrySettingsErrors.ORY_SETTINGS_UNKNOWN,
      undefined,
      "ory-settings-flow"
    );

    faro.api.pushError(fetchError, {
      type: "ory",
    });

    throw fetchError;
  }
}

export function useOrySettings(queryKeyToInvalidate, onSuccess, onError) {
  const queryClient = useQueryClient();

  const action = useMutation({
    mutationFn: async ({ flowId, method, password, csrf_token }) => {
      await handleOrySettings({ flowId, method, password, csrf_token });
    },
    onSuccess: (data) => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });

  return action;
}
