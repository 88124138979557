import { ShellLayout } from "@/utils/ShellLayout";
import ClientPanel from "@/Pages/app/ClientPanel";
import { CRUDL } from "@/utils/crudl";
import { ManageClient } from "@/feature/clients/manageClient/ManageClient";
import OryLogin from "@/Pages/OryLogIn";
import OryLogOut from "@/Pages/OryLogOut";
import ProtectedRoute from "@/utils/ProtectedRouteV2";
import NotFound from "@/Pages/NotFound";
import { CreateInvoice } from "@/Pages/app/CreateInvoice";
import DownloadInvoicePDF from "@/Pages/app/DownloadInvoicePDF";
import { InvoiceOverview } from "@/Pages/app/InvoiceOverviewV4";
import RedirectWhenLoggedInRoute from "@/utils/RedirectWhenLoggedInRoute";
import Sign from "@/Pages/Sign";
import AccountRecovery from "@/Pages/AccountRecovery";
import ArticlePanel from "@/Pages/app/ArticlePanel";
import ManageArticle from "@/feature/articles/manageArticle/ManageArticle";
import Profile from "@/components/elements/Profile";
import { SettingsLayout } from "@/feature/Settings/SettingsLayout";
import CompanySettings from "@/feature/Settings/Company/CompanySettings";
import Facturation from "@/components/elements/Facturation/Facturation";
import Subscription from "@/components/elements/Subscriptions/Subscription";
import Forbidden from "@/Pages/Forbidden";
import OryEmailVerification from "@/feature/mailInput/OryEmailVerification";
import OryChangePasswordRecovery from "@/Pages/OryChangePasswordRecovery";
import OneLastStep from "@/Pages/OneLastStep";
import Switch from "@/feature/Switch";
import Home from "@/Pages/app/Home";
import { Navigate } from "react-router-dom";
import { ManageDocument } from "@/Pages/app/ManageDocument";
import DownloadDocumentPDFV2 from "@/Pages/app/DownloadDocumentPDFV2";
import OrySignupForm from "@/components/elements/OrySignupForm";

export const routes = [
  {
    path: "/",
    element: <ShellLayout />,
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute showRibbon={true}>
            <Navigate to="/home" />
          </ProtectedRoute>
        ),
      },
      {
        path: "home",
        element: (
          <ProtectedRoute showRibbon={true}>
            <Home />
          </ProtectedRoute>
        ),
      },
      {
        path: "switch",
        element: (
          <ProtectedRoute showRibbon={false}>
            <Switch />
          </ProtectedRoute>
        ),
      },
      {
        path: "clients",
        element: <ShellLayout />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute showRibbon={true}>
                <ClientPanel />
              </ProtectedRoute>
            ),
          },
          {
            path: ":clientId",
            element: (
              <ProtectedRoute showRibbon={false}>
                <ManageClient context={CRUDL.UPDATE} />
              </ProtectedRoute>
            ),
          },
          {
            path: "create",
            element: (
              <ProtectedRoute
                requireSubscription={true}
                fallback="/clients"
                showRibbon={false}
              >
                <ManageClient context={CRUDL.CREATE} />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "invoices",
        element: <ShellLayout />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute showRibbon={true}>
                <InvoiceOverview />
              </ProtectedRoute>
            ),
          },
          {
            path: ":invoiceNumber",
            element: <ShellLayout />,
            children: [
              {
                path: "download",
                element: (
                  <ProtectedRoute showRibbon={false}>
                    <DownloadInvoicePDF />
                  </ProtectedRoute>
                ),
              },
              {
                path: "edit",
                element: (
                  <ProtectedRoute
                    hideMenu={true}
                    requireSubscription={true}
                    showRibbon={false}
                    fallback="/invoices"
                  >
                    <CreateInvoice context={CRUDL.UPDATE} />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: "create",
            element: (
              <ProtectedRoute
                hideMenu={true}
                requireSubscription={true}
                showRibbon={false}
                fallback="/invoices"
              >
                <CreateInvoice />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "documents",
        element: <ShellLayout />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute showRibbon={true}>
                <InvoiceOverview />
              </ProtectedRoute>
            ),
          },
          {
            path: "create",
            element: (
              <ProtectedRoute
                hideMenu={true}
                requireSubscription={true}
                showRibbon={false}
                fallback="/documents"
              >
                <ManageDocument context={CRUDL.CREATE} />
              </ProtectedRoute>
            ),
          },
          {
            path: ":documentNumber",
            children: [
              {
                path: "download",
                element: (
                  <ProtectedRoute
                    hideMenu={false}
                    requireSubscription={false}
                    showRibbon={false}
                    fallback="/documents"
                  >
                    <DownloadDocumentPDFV2 />
                  </ProtectedRoute>
                ),
              },
              {
                path: "edit",
                element: (
                  <ProtectedRoute
                    hideMenu={true}
                    requireSubscription={true}
                    showRibbon={false}
                    fallback="/documents"
                  >
                    <ManageDocument context={CRUDL.UPDATE} />
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "articles",
        element: <ShellLayout />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute showRibbon={true}>
                <ArticlePanel />
              </ProtectedRoute>
            ),
          },
          {
            path: "create",
            element: (
              <ProtectedRoute
                requireWhitelist={true}
                requireSubscription={true}
                fallback="/articles"
                showRibbon={false}
              >
                <ManageArticle context={CRUDL.CREATE} />
              </ProtectedRoute>
            ),
          },
          {
            path: ":articleId",
            element: (
              <ProtectedRoute requireWhitelist={true} showRibbon={false}>
                <ManageArticle context={CRUDL.UPDATE} />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "mail",
        element: <ShellLayout />,
        children: [
          {
            path: "verification",
            element: (
              <ProtectedRoute
                hideMenu={true}
                isOnboarding={true}
                showRibbon={false}
              >
                <OryEmailVerification />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "settings",
        element: <SettingsLayout />,
        children: [
          { index: true, element: <Profile /> },
          { path: "profile", element: <Profile /> },
          { path: "company", element: <CompanySettings /> },
          { path: "invoicing", element: <Facturation /> },
          {
            path: "subscriptions",
            element: process.env.STRIPE_ENABLED === "true" && <Subscription />,
          },
        ],
      },
      {
        path: "security",
        element: <ShellLayout />,
        children: [
          {
            path: "change-password",
            element: (
              <ProtectedRoute hideMenu={true}>
                <OryChangePasswordRecovery />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "one-last-step",
        element: <ShellLayout />,
        children: [
          {
            path: "enterprise",
            element: (
              <ProtectedRoute
                requireWhitelist={true}
                hideMenu={true}
                isOnboarding={true}
                showRibbon={false}
              >
                <OneLastStep stepName={"enterprise"} />
              </ProtectedRoute>
            ),
          },
          {
            path: "numbering",
            element: (
              <ProtectedRoute
                requireWhitelist={true}
                hideMenu={true}
                isOnboarding={true}
                showRibbon={false}
              >
                <OneLastStep stepName={"numbering"} />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: (
      <RedirectWhenLoggedInRoute>
        <OryLogin />
      </RedirectWhenLoggedInRoute>
    ),
  },
  {
    path: "/signup",
    element: (
      <RedirectWhenLoggedInRoute>
        {/* <Sign /> */}
        <OrySignupForm></OrySignupForm>
      </RedirectWhenLoggedInRoute>
    ),
  },
  {
    path: "/recovery",
    element: (
      <RedirectWhenLoggedInRoute>
        <AccountRecovery />
      </RedirectWhenLoggedInRoute>
    ),
  },
  { path: "/logout", element: <OryLogOut /> },
  { path: "/forbidden", element: <Forbidden /> },
  { path: "*", element: <NotFound /> },
];
