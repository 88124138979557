import CenteredLoadingBlock from "@/components/elements/CenteredLoadingBlock";
import CouldNotLoad from "@/components/elements/CouldNotLoad";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import { SubscriptionAdminView } from "@/components/token/subscription/SubscriptionAdminView";
import { useSubscriptionList } from "@/hooks/stripe/useLidapStripe";
import { useNavigate, useParams } from "react-router-dom";

export function Subscription360() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const subscriptionList = useSubscriptionList({
    queryParam: {
      userId,
    },
  });

  if (subscriptionList.isLoading) {
    return <CenteredLoadingBlock />;
  }

  if (subscriptionList.isError) {
    return (
      <CouldNotLoad
        error={subscriptionList.error}
        message={"Impossible de charger la souscription"}
      />
    );
  }

  const { results, totalCount } = subscriptionList.data.payload;
  const commonName = results[0]?.commonName;
  const userCreated = new Date(results[0]?.userCreated).toLocaleDateString();

  return (
    <div className="flex flex-row justify-center w-full h-screen overflow-scroll">
      <div className="flex flex-col w-full max-w-[768px] p-8 bg-white rounded-lg mt-12 mb-24 h-max">
        <div className="flex flex-row w-full justify-between items-top mb-12">
          <p className="text-xl font-bold">{commonName}</p>
          <div className="flex flex-col gap-2 items-right">
            <p className="text-sm text-neutral-500 text-right">
              Créé le {userCreated}
            </p>
            <p className="text-sm text-neutral-500 text-right">
              Souscription: {totalCount}
            </p>
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <div className={"w-full flex flex-col gap-2"}>
            {results.map((result) => {
              return (
                <SubscriptionAdminView key={result.id} subscription={result} />
              );
            })}
          </div>
        </div>
        <ButtonTailwind onClick={() => navigate(-1)}>{"Retour"}</ButtonTailwind>
      </div>
    </div>
  );
}
