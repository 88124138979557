import { faro } from "@/AppRouter";
import FetchError from "@/utils/FetchError";

export function manageAxiosError(
  error,
  message,
  code,
  url,
  disableToast = false
) {
  if (error.response) {
    const { status } = error.response;

    // Extract the error message from response if available
    const errorMessage =
      error.response.data?.message || error.response.data?.error?.message || "";

    const fetchError = new FetchError(
      `${message}: ${errorMessage}`, // Include the server-provided error message
      status, // Use the actual status code from the response
      code,
      undefined,
      url,
      disableToast
    );

    const type = (() => {
      if (status === 401) {
        return "authn";
      }
      if (status === 403) {
        return "authz";
      }
      return "external";
    })();

    faro.api.pushError(fetchError, {
      type: type,
    });

    throw fetchError;
  }

  if (error.request) {
    // The request was made but no response was received
    const fetchError = new FetchError(
      `${message}: aucune réponse reçue`,
      500,
      code,
      undefined,
      url,
      disableToast
    );

    faro.api.pushError(fetchError, {
      type: "external",
    });

    throw fetchError;
  }

  const fetchError = new FetchError(
    `${message}: erreur inconnue`,
    500,
    code,
    undefined,
    url,
    disableToast
  );

  faro.api.pushError(fetchError, {
    type: "external",
  });

  throw fetchError;
}
