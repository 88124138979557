import React from "react";
import PropTypes from "prop-types";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

const InputTailwind = ({
  type = "text",
  name = "example",
  label = "Example",
  id = "input",
  placeholder = "Example",
  value = "",
  onChange = () => {},
  onKeyDown,
  isRequired = false,
  disabled = false,
  error = "",
  autoComplete = "off",
  loading = false,
  minLength,
  maxLength,
  info,
  className = "w-full",
  readOnly,
  dataFormType,
  errorOnFocus = false,
  onBlur,
  onFocus,
  showButton = false,
  buttonText = "",
  onButtonClick = () => {},
  inputAdditionalClasses = "",
  autoFocus = false,
  inputRef = null,
}) => {
  const [focused, setFocused] = React.useState(false);
  const handleOnFocus = () => {
    if (onFocus) onFocus();
    setFocused(true);
  };
  const handleOnBlur = () => {
    if (onBlur) onBlur();
    setFocused(false);
  };

  // Skeleton Placeholder
  const SkeletonPlaceholder = () => (
    <div className="animate-pulse">
      <div className="h-9 bg-neutral-200 dark:bg-neutral-800 rounded w-full"></div>
    </div>
  );
  const SkeletonLabel = () => (
    <div className="animate-pulse">
      <div className="h-6 bg-neutral-200 dark:bg-neutral-800 rounded w-1/4"></div>
    </div>
  );

  return (
    <div className={`relative ${className}`}>
      {label !== null && (
        <div className="flex flex-row justify-between w-full">
          <label
            htmlFor={label}
            className={`block text-sm font-medium leading-6 ${
              loading ? "text-gray-300" : "text-gray-900"
            }`}
          >
            {loading ? <SkeletonLabel /> : label}
          </label>
          {showButton && (
            <button className="cursor-pointer" onClick={onButtonClick}>
              <span className="block text-sm font-medium leading-6 hover:underline text-neutral-500">
                {buttonText}
              </span>
            </button>
          )}
        </div>
      )}
      <div
        className={`relative mt-1 rounded-md ${loading ? "bg-gray-200" : ""}`}
      >
        {loading ? (
          <SkeletonPlaceholder />
        ) : (
          <input
            type={type}
            name={name}
            id={id}
            value={value || ""}
            className={`${
              error && (!focused || errorOnFocus)
                ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500"
                : "ring-gray-300 placeholder-gray-400 dark:placeholder-[#444] focus:ring-black dark:focus:ring-white text-black"
            } ${
              disabled
                ? "bg-gray-100 border-gray-200 text-gray-500 placeholder-gray-400 focus:ring-0 cursor-not-allowed"
                : "input-design"
            }
            block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${inputAdditionalClasses}`}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
            required={isRequired}
            disabled={disabled}
            autoComplete={autoComplete}
            aria-invalid={!!(error && (!focused || errorOnFocus))}
            aria-describedby={
              error && (!focused || errorOnFocus) ? `${id}-error` : undefined
            }
            minLength={minLength}
            maxLength={maxLength}
            readOnly={readOnly}
            data-form-type={dataFormType}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            autoFocus={autoFocus}
            ref={inputRef}
          />
        )}
        {error && (!focused || errorOnFocus) && !loading && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (!focused || errorOnFocus) && error !== true && !loading && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      )}
      {info &&
        info !== true &&
        !loading &&
        (typeof info === "string" ? (
          <p className="mt-2 text-xs text-gray-600" id={`${id}-info`}>
            {info}
          </p>
        ) : (
          info
        ))}
    </div>
  );
};

InputTailwind.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  autoComplete: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  dataFormType: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  readOnly: PropTypes.bool,
  errorOnFocus: PropTypes.bool,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default InputTailwind;
