export const DocumentStatus = {
  NEW: "NEW",
  DRAFT: "DRAFT",
  TO_VALIDATE: "TO_VALIDATE",
  CREATED: "CREATED",
  TO_SEND: "TO_SEND",
  SENT: "SENT",
  PAID: "PAID",
  REFUNDED: "REFUNDED",
  CANCELLED: "CANCELLED",
};
