import React from "react";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import Modal from "@/components/elements/Modal/Modal";

export default function DeletePaymentFormModal({
  deletePayment,
  paymentInfos,
  user,
  setPaymentToDelete,
  setDeleteScreen,
}) {
  return (
    // <div
    //   className="absolute top-0 left-0 z-50 bg-[rgba(0,0,0,0.25)] backdrop-blur-sm flex justify-center items-center w-screen h-screen"
    //   onMouseDown={() => setPaymentToModify(null)}
    // >
    //   <div
    //     className="bg-white px-8 py-12 flex flex-col items-start gap-8 rounded-md w-[32rem]"
    //     onMouseDown={(e) => e.stopPropagation()}
    //   >
    <Modal
      mouseDownScreen={() => {
        setPaymentToDelete(null);
        setDeleteScreen(false);
      }}
      mouseDownModal={(e) => e.stopPropagation()}
    >
      <h1 className="text-black font-bold text-[2rem]">
        Êtes-vous sûr de vouloir supprimer ce compte ?
      </h1>
      <div className="flex gap-4 w-full">
        <ButtonTailwind
          variant="outlined"
          style="flex-1"
          onClick={(e) => {
            e.stopPropagation();
            setPaymentToDelete(null);
            setDeleteScreen(false);
          }}
        >
          Non
        </ButtonTailwind>
        <ButtonTailwind
          variant="filled"
          style="flex-1"
          loading={deletePayment.isLoading}
          onClick={() =>
            deletePayment.mutate({
              stakeholderId: user.enterpriseId,
              id: paymentInfos.id,
            })
          }
        >
          Supprimer le compte
        </ButtonTailwind>
      </div>
    </Modal>
    //   </div>
    // </div>
  );
}
