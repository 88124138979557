import { useQuery } from "@tanstack/react-query";
import { stringify } from "@/utils/stringify";
import { manageLidapResponse } from "@/utils/lidapi";

export function usePaymentInformation({
  stakeholderId,
  queryParam,
  reactQueryConfig,
}) {
  const stakeholderParams = stakeholderId ? `/${stakeholderId}` : "";
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  const query = useQuery({
    queryKey: ["payment-information", { stakeholderId }, queryParam],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/stakeholders${stakeholderParams}/payment-information/${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return manageLidapResponse(
        response,
        "Could not load payment information"
      );
    },
    ...reactQueryConfig,
  });

  return query;
}
