import React from "react";
import { DocumentNumberingInput } from "./DocumentNumberingInput";

export default function NumerotationQuantity({ numberingForm }) {
  return (
    <div className="w-96 flex flex-col gap-12">
      <h1 className="text-black font-inter text-3xl font-bold leading-normal">
        Aidez nous à numéroter vos documents
      </h1>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <div className="flex gap-4">
            <DocumentNumberingInput
              disabled={numberingForm.isUpdateLoading}
              forceValidate={!numberingForm.isFormDirty}
              initialValue={numberingForm.quoteNumbering.data}
              isLoading={numberingForm.isDataLoading}
              label={"Combien de devis avez-vous créé cette année ?"}
              onChange={numberingForm.setQuoteNumbering}
              required={true}
              name="quoteNumbering"
              placeholder="0"
              minValue={numberingForm.minValues.quote}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-4">
            <DocumentNumberingInput
              disabled={numberingForm.isUpdateLoading}
              forceValidate={!numberingForm.isFormDirty}
              initialValue={numberingForm.invoiceNumbering.data}
              isLoading={numberingForm.isDataLoading}
              label={"Combien de factures avez-vous créé cette année ?"}
              onChange={numberingForm.setInvoiceNumbering}
              required={true}
              name="invoiceNumbering"
              placeholder="0"
              minValue={numberingForm.minValues.invoice}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-4">
            <DocumentNumberingInput
              disabled={numberingForm.isUpdateLoading}
              forceValidate={!numberingForm.isFormDirty}
              initialValue={numberingForm.creditNoteNumbering.data}
              isLoading={numberingForm.isDataLoading}
              label={"Combien de notes de crédit avez-vous créé cette année ?"}
              onChange={numberingForm.setCreditNoteNumbering}
              required={true}
              name="creditNoteNumbering"
              placeholder="0"
              minValue={numberingForm.minValues.creditNote}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
