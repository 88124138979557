import React, { useState } from "react";
import { useUser } from "@/contexts/UserContext";
import { useInvoices } from "@/hooks/useInvoices";
import { useClients } from "@/hooks/useClients";
import { usePatchInvoiceStatus } from "@/hooks/usePatchInvoiceStatus";
import CouldNotLoad from "@/components/elements/CouldNotLoad";
import { useParams } from "react-router-dom";
import "@/assets/css/Invoice.css";
import InvoicePreview from "@/feature/invoicePreview/InvoicePreview";
import InvoiceVersionPDF from "../../feature/createInvoice/InvoiceVersionPDF";
import { pdf } from "@react-pdf/renderer";
import {
  CheckCircleIcon,
  XCircleIcon,
  ArrowDownTrayIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import PaymentDateModal from "@/components/token/PaymentDateModal/PaymentDateModal";
import { notify } from "@/utils/notify";
import { useImages } from "@/hooks/useImages";
import CenteredLoadingBlock from "@/components/elements/CenteredLoadingBlock";
import { useEnterprises } from "@/hooks/useEnterprises";
import { Link } from "react-router-dom";

export default function DownloadInvoicePDF() {
  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;
  const enterprise = useEnterprises({
    // enterpriseId,
    queryParam: {
      emails: true,
      addresses: true,
    },
  });
  const patchInvoiceStatus = usePatchInvoiceStatus(
    ["invoices", { enterpriseId: enterpriseId }],
    undefined,
    () => {
      setDisplayPaymentDateModal(false);
      notify({
        isSuccess: true,
        message: "Statut modifié avec succès",
      });
    }
  );
  const { invoiceNumber } = useParams();
  const invoice = useInvoices({ enterpriseId, invoiceNumber, links: true });
  const invoiceContent = invoice.invoicesData?.payload?.invoice;
  const clientId = invoiceContent?.clientId;
  const clientFromInvoice = useClients({
    enterpriseId,
    clientId,
    queryParam: { addresses: true, emails: true, identifiers: true },
  });
  const companyLogo = useImages({
    enterpriseId,
    purpose: "company-logo",
    reactQueryOptions: {
      refetchOnWindowFocus: false,
    },
    errorOnMissing: false,
  });

  const [displayPaymentDateModal, setDisplayPaymentDateModal] = useState(false);

  function convertDateToDDMMYYYY(dateString) {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }

  if (invoice.isInvoicesLoading) {
    return <CenteredLoadingBlock message="Chargement de la facture..." />;
  }

  if (invoice.isInvoicesError || !invoiceContent) {
    return (
      <CouldNotLoad
        message="Impossible de charger la facture"
        error={invoice.invoicesError}
      />
    );
  }

  if (clientFromInvoice.isClientsLoading) {
    return <CenteredLoadingBlock message="Chargement du client..." />;
  }

  if (clientFromInvoice.isClientsError) {
    return (
      <CouldNotLoad
        message="Impossible de charger le client"
        error={clientFromInvoice.clientsError}
      />
    );
  }

  if (enterprise.isLoading) {
    return <CenteredLoadingBlock message="Chargement de l'entreprise..." />;
  }

  if (enterprise.isError) {
    return (
      <CouldNotLoad
        message="Impossible de charger l'entreprise"
        error={enterprise.enterprisesError}
      />
    );
  }

  const documentData = (() => {
    const logo = (() => {
      if (companyLogo?.data?.payload?.imageContent) {
        return {
          imageContent: companyLogo?.data.payload.imageContent,
          imageFormat: companyLogo?.data.payload.imageFormat,
        };
      }
    })();

    const doc = {
      Logo: logo,
      SIREN:
        enterprise?.data?.payload?.enterprises[0]?.identifiers?.find(
          (id) => id.identifierType === "SIREN"
        )?.identifier || undefined,
      enterpriseNumber: {
        value:
          enterprise?.data?.payload?.enterprises[0]?.identifiers?.find(
            (id) => id.identifierType === "SIREN"
          )?.identifier ||
          enterprise?.data?.payload?.enterprises[0]?.identifiers?.find(
            (id) => id.identifierType === "COMPANY_NUMBER"
          )?.identifier ||
          undefined,
        type: enterprise?.data?.payload?.enterprises[0]?.identifiers?.find(
          (id) => id.identifierType === "SIREN"
        )?.identifier
          ? "SIREN"
          : "OTHER",
        vat:
          enterprise?.data?.payload?.enterprises[0]?.identifiers?.find(
            (id) => id.identifierType === "VAT"
          )?.identifier || undefined,
      },
      clientId: invoiceContent.clientId || undefined,
      invoiceNumber: invoiceContent.invoiceNumber,
      issuingDate: convertDateToDDMMYYYY(invoiceContent.issuingDate),
      dueDate: convertDateToDDMMYYYY(invoiceContent.dueDate),
      purchaseOrder: invoiceContent.purchaseOrder,
      services: invoiceContent.services.map((service) => ({
        title: service.title,
        description: service.description,
        quantity: {
          amount: service.quantity.amount,
          unit: service.quantity.unit,
        },
        unitPrice: {
          amount: service.unitPrice.amount,
          currency: service.unitPrice.currency,
        },
        vatRate: {
          countryOrRegion: userContext.state.user.country || "BE",
          taxRate: service.vatRate.taxRate,
        },
      })),
      addresses: [
        {
          addressId: invoiceContent.addresses.find(
            (address) => address.addressType === "RECEIVER_BILLING"
          ).addressId,
          address: invoiceContent.addresses.find(
            (address) => address.addressType === "RECEIVER_BILLING"
          ).address,
          addressType: "RECEIVER_BILLING",
        },
        {
          addressId: invoiceContent.addresses.find(
            (address) => address.addressType === "ISSUER_BILLING"
          ).addressId,
          address: invoiceContent.addresses.find(
            (address) => address.addressType === "ISSUER_BILLING"
          ).address,
          addressType: "ISSUER_BILLING",
        },
      ],
      additionalData: invoiceContent.additionalData,
      total: {
        amount: invoiceContent.total.amount,
        currency: invoiceContent.total.currency,
      },
      totalWithTax: {
        amount: invoiceContent.totalWithTax.amount,
        currency: invoiceContent.totalWithTax.currency,
      },
      totalRounded: {
        amount: invoiceContent.totalRounded.amount,
        currency: invoiceContent.totalRounded.currency,
      },
      documentType: invoiceContent.documentType,
      linkedInvoice: invoiceContent.linkedInvoice,
      invoiceStatus: invoiceContent.invoiceStatus,
      selectedClient: clientFromInvoice.clientsData.payload,
    };

    return { data: doc };
  })();

  const downloadInvoice = async () => {
    const doc = <InvoiceVersionPDF documentData={documentData.data} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${invoiceNumber}`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const invoiceStatus = invoiceContent?.invoiceStatus;
  const documentType = invoiceContent?.documentType;

  function changeInvoiceStatus(invoiceNumber, status, date) {
    const invoiceStatusRequest = {
      enterpriseId: enterpriseId,
      invoiceNumber: invoiceNumber,
    };
    const params = {
      invoiceStatus: status,
      ...(date ? { receiptDate: date } : {}),
    };
    patchInvoiceStatus.mutate({ invoiceStatusRequest, queryParam: params });
  }

  return (
    <>
      {displayPaymentDateModal && (
        <PaymentDateModal
          changeInvoiceStatus={changeInvoiceStatus}
          setDisplayPaymentDateModal={setDisplayPaymentDateModal}
          invoiceNumber={invoiceNumber}
          isLoading={patchInvoiceStatus.isLoading}
        />
      )}
      <div className="flex flex-row w-full max-h-screen overflow-y-scroll">
        <div className="w-full min-h-screen mx-auto flex justify-center items-center space-y-4 bg-[#F5F5F5] py-16 gap-8 h-max">
          <div className="h-max flex gap-8">
            <InvoicePreview
              documentData={documentData.data}
              preview={"DOWNLOAD"}
            />

            <div className="flex flex-col py-24 gap-2">
              <div
                className={
                  "flex flex-col p-1 text-left bg-white rounded-md shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none"
                }
              >
                {documentType !== "DRAFT" && (
                  <button
                    className="text-black text-sm flex py-2 px-4 gap-4 hover:bg-neutral-100 rounded-md"
                    onClick={downloadInvoice}
                  >
                    <ArrowDownTrayIcon
                      className="h-4 w-4 text-neutral-600"
                      aria-hidden="true"
                    />
                    Télécharger
                  </button>
                )}
                {invoiceStatus === "PAID" && (
                  <button
                    className="text-black text-sm flex py-2 px-4 gap-4 hover:bg-neutral-100 rounded-md"
                    onClick={() =>
                      changeInvoiceStatus(invoiceNumber, "CREATED")
                    }
                  >
                    <XCircleIcon
                      className="h-4 w-4 text-neutral-600"
                      aria-hidden="true"
                    />
                    Facture non payée
                  </button>
                )}
                {invoiceStatus !== "PAID" &&
                  invoiceStatus === "CREATED" &&
                  !invoiceNumber.includes("D") && (
                    <button
                      className="text-black text-sm flex py-2 px-4 gap-4 hover:bg-neutral-100 rounded-md"
                      onClick={() => setDisplayPaymentDateModal(true)}
                    >
                      <CheckCircleIcon
                        className="h-4 w-4 text-neutral-600"
                        aria-hidden="true"
                      />
                      Facture payée
                    </button>
                  )}
                {documentType !== "DRAFT" && (
                  <Link
                    to={`/invoices/create?preload=${invoiceNumber}`}
                    className="text-black text-sm flex py-2 px-4 gap-4 hover:bg-neutral-100 rounded-md"
                  >
                    <DocumentDuplicateIcon
                      className="h-4 w-4 text-neutral-600"
                      aria-hidden="true"
                    />
                    Dupliquer
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
