export function updateDocumentErrors({ documentData, error, field }) {
  const errors = documentData.errors || [];
  const existingErrorOnField = errors.find((e) => e.field === field);

  if (!error && !existingErrorOnField) return errors;
  if (!error && existingErrorOnField) {
    return errors.filter((e) => e.field !== field);
  }

  const newError = {
    field,
    error,
  };
  const updatedErrors = existingErrorOnField
    ? errors.map((e) =>
        e.field === field
          ? {
              ...e,
              ...newError,
            }
          : e
      )
    : [...errors, newError];

  return updatedErrors;
}
