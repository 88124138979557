import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

export default function searchInput({
  setIsSearchOpen,
  onChange,
  placeholder = "Rechercher...",
  value = "",
}) {
  return (
    <div
      onClick={() => {
        if (setIsSearchOpen) setIsSearchOpen(true);
      }}
    >
      <div className="relative rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          type="search"
          name="search"
          id="search"
          className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 dark:placeholder-[#444] focus:ring-2 focus:ring-inset focus:ring-black dark:focus:ring-white sm:text-sm sm:leading-6 input-design"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
}
