import TextArea from "@/components/token/textareas/textareas";
import { useInputValidation, useTextUtf8 } from "@/hooks/validation/useRegexp";

export default function ValidatedTextArea({
  inputText,
  inputError,
  setInputValue,
  setErrorValue,
  name = "freetext",
  label = "Texte libre",
  placeholder = "Indiquez un texte...",
  errorOnFocus = true,
  ...props
}) {
  const regexp = useTextUtf8();
  const handleChange = useInputValidation({
    setInputValue,
    setErrorValue,
  });

  return (
    <TextArea
      name={name}
      label={label}
      placeholder={placeholder}
      value={inputText}
      onChange={(e) => handleChange(e, regexp)}
      error={inputError}
      errorOnFocus={errorOnFocus}
      {...props}
    />
  );
}
