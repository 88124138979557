// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TtBKF6gsQRk_gYHkYPnC {
  width: 100%;
}
.lvElnXV3u5y50LwXqarp {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 24rem;
  margin: auto;
}

.NtemF_wnrV9GLfyWkzvC {
  text-align: center;
  margin-bottom: 0.5rem;
}

.NtemF_wnrV9GLfyWkzvC h4 {
  margin: 0;
}

.NtemF_wnrV9GLfyWkzvC p {
  margin: 0;
}

.qZ8ZcQeHGq4QVK6vz7LQ {
  display: flex;
  gap: 0.75rem;
}

.TJT7J9df08p5gE6dmCDQ {
  flex: 2;
}

.TMGVJblNqRdkqJLcpPnM {
  flex: 1;
}

.wJ242vxqH0Fb9wA0JTjU {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.GBnPY_CRQFZ95X7elpRo {
  display: flex;
  gap: 0.75rem;
}

#wXh24Lbb7w3yuAL9Cf1r {
  text-align: center;
  width: var(--mainElemWidth);
  font-size: 24px;
  font-family: Inter-SemiBold;
}
`, "",{"version":3,"sources":["webpack://./src/feature/EnterpriseForm/EnterpriseForm.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,2BAA2B;EAC3B,eAAe;EACf,2BAA2B;AAC7B","sourcesContent":[".enterpriseFormWrap {\n  width: 100%;\n}\n.enterpriseForm {\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n  width: 24rem;\n  margin: auto;\n}\n\n.enterpriseFormHeader {\n  text-align: center;\n  margin-bottom: 0.5rem;\n}\n\n.enterpriseFormHeader h4 {\n  margin: 0;\n}\n\n.enterpriseFormHeader p {\n  margin: 0;\n}\n\n.enterpriseSelects {\n  display: flex;\n  gap: 0.75rem;\n}\n\n.nationSelectBox {\n  flex: 2;\n}\n\n.deviseBox {\n  flex: 1;\n}\n\n.enterpriseFormInputs {\n  display: flex;\n  flex-direction: column;\n  gap: 0.75rem;\n}\n\n.postalCityBox {\n  display: flex;\n  gap: 0.75rem;\n}\n\n#getStartedSignUp {\n  text-align: center;\n  width: var(--mainElemWidth);\n  font-size: 24px;\n  font-family: Inter-SemiBold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"enterpriseFormWrap": `TtBKF6gsQRk_gYHkYPnC`,
	"enterpriseForm": `lvElnXV3u5y50LwXqarp`,
	"enterpriseFormHeader": `NtemF_wnrV9GLfyWkzvC`,
	"enterpriseSelects": `qZ8ZcQeHGq4QVK6vz7LQ`,
	"nationSelectBox": `TJT7J9df08p5gE6dmCDQ`,
	"deviseBox": `TMGVJblNqRdkqJLcpPnM`,
	"enterpriseFormInputs": `wJ242vxqH0Fb9wA0JTjU`,
	"postalCityBox": `GBnPY_CRQFZ95X7elpRo`,
	"getStartedSignUp": `wXh24Lbb7w3yuAL9Cf1r`
};
export default ___CSS_LOADER_EXPORT___;
