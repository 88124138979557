import { useInputValidation, useTextUtf8 } from "@/hooks/validation/useRegexp";
import InputTailwind from "@/components/token/Input/inputTailwind";

export default function ValidatedInputTailwind({
  inputText,
  inputError,
  setInputValue,
  setErrorValue,
  name = "input",
  label = "Entrée",
  placeholder = "Champ",
  errorOnFocus = true,
  ...props
}) {
  const regexp = useTextUtf8();
  const handleChange = useInputValidation({
    setInputValue,
    setErrorValue,
  });

  return (
    <InputTailwind
      name={name}
      label={label}
      placeholder={placeholder}
      value={inputText}
      onChange={(e) => handleChange(e, regexp)}
      error={inputError}
      errorOnFocus={errorOnFocus}
      {...props}
    />
  );
}
