import SectionToggle from "@/components/token/sectionToggle/sectionToggle";
import React from "react";
import Dashboard from "./Dashboard";
import Analytics from "./Analytics";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import DataCompletionModal from "@/feature/DataCompletionModal/DataCompletionModal";
import { useSearchParams } from "react-router-dom";
import { useAnalyticsData } from "@/hooks/analytics/useAnalyticsData";

const sections = {
  DASHBOARD: "dashboard",
  ANALYTICS: "analytics",
};

export default function Home() {
  const [searchParams, setSearchParams] = useSearchParams();
  const section = searchParams.get("s") || sections.DASHBOARD;
  const displayDataCompletionModal = searchParams.get("dcm") === "true";

  const analytics = useAnalyticsData();

  return (
    <>
      {displayDataCompletionModal ? (
        <DataCompletionModal
          setDisplayDataCompletionModal={() => {
            searchParams.delete("dcm");
            setSearchParams(searchParams);
          }}
          analytics={analytics}
        />
      ) : null}
      <div className="flex flex-col w-full bg-neutral-100 max-w-screen-2xl h-screen mx-auto">
        <div className="flex flex-col w-full gap-4 p-16 pb-0">
          <div className="flex justify-between w-full">
            <p className="text-black text-2xl font-semibold w-max">Revenus</p>
            <ButtonTailwind
              onClick={() => {
                searchParams.set("dcm", "true");
                setSearchParams(searchParams);
              }}
            >
              Compléter mes données
            </ButtonTailwind>
          </div>
          <SectionToggle
            sections={[
              { name: "Tableau de bord", id: sections.DASHBOARD },
              { name: "Analyses", id: sections.ANALYTICS },
            ]}
            activeSection={section}
            setActiveSection={(s) => {
              searchParams.set("s", s);
              setSearchParams(searchParams);
            }}
          />
        </div>

        {section === sections.DASHBOARD && !analytics.isLoading ? (
          //  ||  activeSection === "Déclaration"
          <Dashboard analytics={analytics} />
        ) : null}
        {section === sections.ANALYTICS && !analytics.isLoading ? (
          <Analytics analytics={analytics} />
        ) : null}
      </div>
    </>
  );
}
