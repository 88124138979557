import { useFullLogout } from "@/hooks/useFullLogout";
import { useOrySession } from "@/hooks/useOrySession";
import { useQueryClient } from "@tanstack/react-query";
import React, { createContext, useContext, useState } from "react";

const OryAuthContext = createContext();

export function useOryAuth() {
  const context = useContext(OryAuthContext);
  if (!context) {
    throw new Error("useOryAuth must be used within a OryAuthProvider");
  }
  return context;
}

export const OryAuthProvider = ({ children }) => {
  /* This state is used to ensure we avoid race conditions on signup 
     where the user will be authenticated before the email verification flow id is set
  */
  const [isSignup, setIsSignup] = useState(false);
  const [emailVerificationFlowId, setEmailVerificationFlowId] = useState("");

  const queryClient = useQueryClient();
  const orySession = useOrySession({
    staleTime: 10 * 60 * 1000,
    cacheTime: Infinity,
  });

  const logout = useFullLogout(
    () => {
      logout.reset();
    },
    true,
    /* This ensures we do not keep queries from the previously logged in user in the cache */
    () => {
      queryClient.removeQueries({
        predicate: (query) => {
          return query.queryKey[0] !== "ory-session";
        },
      });
    }
  );

  return (
    <OryAuthContext.Provider
      value={{
        session: orySession.isError ? null : orySession.data,
        isLoading: orySession.isLoading,
        isError: orySession.isError,
        isSuccess: orySession.isSuccess,
        error: orySession.error,
        check: orySession.refetch,
        logout,
        emailVerificationFlowId,
        setEmailVerificationFlowId,
        isSignup,
        setIsSignup,
      }}
    >
      {children}
    </OryAuthContext.Provider>
  );
};
