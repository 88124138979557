export const countriesName = {
  AF: "افغانستان",
  AL: "Shqipëri",
  DZ: "الجزائر",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Հայաստան",
  AW: "Aruba",
  AU: "Australia",
  AT: "Österreich",
  AZ: "Azərbaycan",
  BS: "Bahamas",
  BH: "البحرين",
  BD: "বাংলাদেশ",
  BB: "Barbados",
  BY: "Беларусь",
  BE: "Belgique",
  BZ: "Belize",
  BJ: "Bénin",
  BM: "Bermuda",
  BT: "འབྲུག་ཡུལ།",
  BO: "Bolivia",
  BA: "Bosna i Hercegovina",
  BW: "Botswana",
  BR: "Brasil",
  IO: "British Indian Ocean Territory",
  BG: "България",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "កម្ពុជា",
  CM: "Cameroun",
  CA: "Canada",
  CV: "Cabo Verde",
  KY: "Cayman Islands",
  CF: "Centrafrique",
  TD: "تشاد",
  CL: "Chile",
  CN: "中国",
  CO: "Colombia",
  KM: "Komori",
  CG: "Congo",
  CD: "Congo",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "Côte d'Ivoire",
  HR: "Hrvatska",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Κύπρος",
  CZ: "Česko",
  DK: "Danmark",
  DJ: "جيبوتي",
  DM: "Dominica",
  DO: "República Dominicana",
  EC: "Ecuador",
  EG: "مصر",
  SV: "El Salvador",
  GQ: "Guinea Ecuatorial",
  ER: "ኤርትራ",
  EE: "Eesti",
  ET: "ኢትዮጵያ",
  FK: "Falkland Islands",
  FO: "Føroyar",
  FJ: "Fiji",
  FI: "Suomi",
  FR: "France",
  PF: "Polynésie française",
  GA: "Gabon",
  GM: "Gambia",
  GE: "საქართველო",
  DE: "Deutschland",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Ελλάδα",
  GL: "Kalaallit Nunaat",
  GD: "Grenada",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinée",
  GW: "Guiné-Bissau",
  HT: "Haïti",
  HN: "Honduras",
  HK: "香港",
  HU: "Magyarország",
  IS: "Ísland",
  IN: "भारत",
  ID: "Indonesia",
  IR: "ایران",
  IQ: "العراق",
  IE: "Éire",
  IM: "Isle of Man",
  IL: "ישראל",
  IT: "Italia",
  JM: "Jamaica",
  JP: "日本",
  JE: "Jersey",
  JO: "الأردن",
  KZ: "Қазақстан",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "조선민주주의인민공화국",
  KR: "대한민국",
  KW: "الكويت",
  KG: "Кыргызстан",
  LA: "ລາວ",
  LV: "Latvija",
  LB: "لبنان",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "ليبيا",
  LI: "Liechtenstein",
  LT: "Lietuva",
  LU: "Luxembourg",
  MO: "澳門",
  MK: "Македонија",
  MG: "Madagasikara",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "ދިވެހިރާއްޖެ",
  ML: "Mali",
  MT: "Malta",
  MH: "M̧ajeļ",
  MQ: "Martinique",
  MR: "موريتانيا",
  MU: "Maurice",
  MX: "México",
  FM: "Micronesia",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Монгол улс",
  ME: "Crna Gora",
  MS: "Montserrat",
  MA: "المغرب",
  MZ: "Moçambique",
  MM: "မြန်မာ",
  NA: "Namibia",
  NR: "Nauru",
  NP: "नेपाल",
  NL: "Nederland",
  NZ: "Aotearoa",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norge",
  OM: "عُمان",
  PK: "پاکستان",
  PW: "Palau",
  PS: "فلسطين",
  PA: "Panamá",
  PG: "Papua Niugini",
  PY: "Paraguay",
  PE: "Perú",
  PH: "Pilipinas",
  PN: "Pitcairn",
  PL: "Polska",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "قطر",
  RO: "România",
  RU: "Россия",
  RW: "Rwanda",
  KN: "Saint Kitts and Nevis",
  LC: "Saint Lucia",
  WS: "Samoa",
  SM: "San Marino",
  ST: "São Tomé e Príncipe",
  SA: "السعودية",
  SN: "Sénégal",
  RS: "Србија",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten",
  SK: "Slovensko",
  SI: "Slovenija",
  SB: "Solomon Islands",
  SO: "Soomaaliya",
  ZA: "South Africa",
  SS: "South Sudan",
  ES: "España",
  LK: "ශ්‍රී ලංකා",
  SD: "السودان",
  SR: "Suriname",
  SZ: "Eswatini",
  SE: "Sverige",
  CH: "Schweiz",
  SY: "سوريا",
  TW: "臺灣",
  TJ: "Тоҷикистон",
  TZ: "Tanzania",
  TH: "ไทย",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "تونس",
  TR: "Türkiye",
  TM: "Türkmenistan",
  TC: "Turks and Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Україна",
  AE: "الإمارات",
  GB: "United Kingdom",
  US: "United States",
  UY: "Uruguay",
  UZ: "Oʻzbekiston",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Việt Nam",
  VI: "Virgin Islands",
  YE: "اليَمَن",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};

export const countriesCurrency = {
  AED: "د.إ",
  AFN: "؋",
  ALL: "L",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "$",
  AUD: "$",
  AWG: "ƒ",
  AZN: "₼",
  BAM: "KM",
  BBD: "$",
  BDT: "৳",
  BGN: "лв",
  BHD: ".د.ب",
  BIF: "FBu",
  BMD: "$",
  BND: "$",
  BOB: "$b",
  BOV: "BOV",
  BRL: "R$",
  BSD: "$",
  BTC: "₿",
  BTN: "Nu.",
  BWP: "P",
  BYN: "Br",
  BYR: "Br",
  BZD: "BZ$",
  CAD: "$",
  CDF: "FC",
  CHE: "CHE",
  CHF: "CHF",
  CHW: "CHW",
  CLF: "CLF",
  CLP: "$",
  CNH: "¥",
  CNY: "¥",
  COP: "$",
  COU: "COU",
  CRC: "₡",
  CUC: "$",
  CUP: "₱",
  CVE: "$",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "kr",
  DOP: "RD$",
  DZD: "دج",
  EEK: "kr",
  EGP: "£",
  ERN: "Nfk",
  ETB: "Br",
  ETH: "Ξ",
  EUR: "€",
  FJD: "$",
  FKP: "£",
  GBP: "£",
  GEL: "₾",
  GGP: "£",
  GHC: "₵",
  GHS: "GH₵",
  GIP: "£",
  GMD: "D",
  GNF: "FG",
  GTQ: "Q",
  GYD: "$",
  HKD: "$",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  IMP: "£",
  INR: "₹",
  IQD: "ع.د",
  IRR: "﷼",
  ISK: "kr",
  JEP: "£",
  JMD: "J$",
  JOD: "JD",
  JPY: "¥",
  KES: "KSh",
  KGS: "лв",
  KHR: "៛",
  KMF: "CF",
  KPW: "₩",
  KRW: "₩",
  KWD: "KD",
  KYD: "$",
  KZT: "₸",
  LAK: "₭",
  LBP: "£",
  LKR: "₨",
  LRD: "$",
  LSL: "M",
  LTC: "Ł",
  LTL: "Lt",
  LVL: "Ls",
  LYD: "LD",
  MAD: "MAD",
  MDL: "lei",
  MGA: "Ar",
  MKD: "ден",
  MMK: "K",
  MNT: "₮",
  MOP: "MOP$",
  MRO: "UM",
  MRU: "UM",
  MUR: "₨",
  MVR: "Rf",
  MWK: "MK",
  MXN: "$",
  MXV: "MXV",
  MYR: "RM",
  MZN: "MT",
  NAD: "$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "₨",
  NZD: "$",
  OMR: "﷼",
  PAB: "B/.",
  PEN: "S/.",
  PGK: "K",
  PHP: "₱",
  PKR: "₨",
  PLN: "zł",
  PYG: "Gs",
  QAR: "﷼",
  RMB: "￥",
  RON: "lei",
  RSD: "Дин.",
  RUB: "₽",
  RWF: "R₣",
  SAR: "﷼",
  SBD: "$",
  SCR: "₨",
  SDG: "ج.س.",
  SEK: "kr",
  SGD: "S$",
  SHP: "£",
  SLL: "Le",
  SOS: "S",
  SRD: "$",
  SSP: "£",
  STD: "Db",
  STN: "Db",
  SVC: "$",
  SYP: "£",
  SZL: "E",
  THB: "฿",
  TJS: "SM",
  TMT: "T",
  TND: "د.ت",
  TOP: "T$",
  TRL: "₤",
  TRY: "₺",
  TTD: "TT$",
  TVD: "$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  USD: "$",
  UYI: "UYI",
  UYU: "$U",
  UYW: "UYW",
  UZS: "лв",
  VEF: "Bs",
  VES: "Bs.S",
  VND: "₫",
  VUV: "VT",
  WST: "WS$",
  XAF: "FCFA",
  XBT: "Ƀ",
  XCD: "$",
  XOF: "CFA",
  XPF: "₣",
  XSU: "Sucre",
  XUA: "XUA",
  YER: "﷼",
  ZAR: "R",
  ZMW: "ZK",
  ZWD: "Z$",
  ZWL: "$",
};
