import React from "react";
import ButtonTailwind from "../button/buttonTailwind";
import Modal from "@/components/elements/Modal/Modal";

export default function DraftCreationModal(props) {
  return (
    <Modal>
      <div className="flex flex-col gap-4">
        <h1 className="text-3xl">
          Enregistrer cette facture <br />
          comme brouillon ?
        </h1>
        <p>
          Vous pourrez la modifier plus tard si nécessaire. Si vous
          <br /> ignorez l&apos;enregistrement du brouillon, toutes les données
          <br />
          saisies seront perdues.
        </p>
      </div>
      <div className="flex gap-4">
        <ButtonTailwind
          variant="outlined"
          onClick={() => props.setIsDraftModalDisplayed(false)}
          disabled={props.isLoading}
        >
          Annuler
        </ButtonTailwind>
        <ButtonTailwind
          variant="filled"
          onClick={props.onClick}
          loading={props.isLoading}
        >
          Enregistrer comme brouillon
        </ButtonTailwind>
      </div>
    </Modal>
  );
}
