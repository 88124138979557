import React from "react";
import ButtonTailwind from "../button/buttonTailwind";
import Modal from "@/components/elements/Modal/Modal";

export default function CreateDocumentModal({
  createDocument,
  unDisplay,
  isLoading,
}) {
  return (
    <Modal>
      <h1 className="text-3xl">Créer ce document ?</h1>
      <p>
        Pour pouvoir modifier votre document, enregistrez-le en brouillon. Une
        fois créé, vous ne pourrez plus modifier ni supprimer ce document.
      </p>

      <div className="flex justify-end gap-4">
        <ButtonTailwind
          variant="outlined"
          onClick={unDisplay}
          disabled={isLoading}
        >
          Annuler
        </ButtonTailwind>
        <ButtonTailwind
          variant={"filled"}
          onClick={createDocument}
          loading={isLoading}
        >
          Créer le document
        </ButtonTailwind>
      </div>
    </Modal>
  );
}
