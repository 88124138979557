import React from "react";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import Modal from "@/components/elements/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { getCurrentSubscription } from "@/hooks/stripe/useLidapStripe";
import { useEnterpriseId } from "@/contexts/UserContext";
import { useQueryClient } from "@tanstack/react-query";

export default function RequireSubscriptionModal({ onWaive, onBack, message }) {
  const navigate = useNavigate();
  const onCancel = onBack ? onBack : () => navigate(-1);
  const onManage = onWaive
    ? onWaive
    : () => navigate("/settings/subscriptions");

  const queryClient = useQueryClient();
  const enterpriseId = useEnterpriseId();
  const { queryFn, queryKey } = getCurrentSubscription({ enterpriseId });

  return (
    <Modal>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <p className="text-3xl font-bold">
            {message || "Vous n'avez pas d'abonnement actif."}
          </p>
          <p className="text-neutral-500">
            Pour bénéficier de toutes les fonctionnalités de Lidap, veuillez
            activer votre abonnement
          </p>
        </div>

        <div className="flex gap-4">
          <ButtonTailwind variant="outlined" onClick={() => onCancel(null)}>
            Retour
          </ButtonTailwind>
          <ButtonTailwind
            onClick={onManage}
            onMouseEnter={() => {
              queryClient.prefetchQuery(queryKey, queryFn, {
                staleTime: 1000 * 60 * 5,
              });
            }}
          >
            Gérer mon abonnement
          </ButtonTailwind>
        </div>
      </div>
    </Modal>
  );
}
