import React from "react";
import {
  countriesName,
  countriesCurrency,
} from "@/data/countryCodeCorrespondance";
import "@/assets/css/InvoicePreview.css";
import { useNextInvoiceNumber } from "@/hooks/useInvoices";
import CouldNotLoad from "@/components/elements/CouldNotLoad";
import { useUser } from "@/contexts/UserContext";
import SkeletonElement from "@/components/token/loading/SkeletonElement";
import Base64Image from "@/components/elements/ImageImport/Base64Image";
import { ClientPreview } from "./ClientPreview";

export default function InvoicePreview(props) {
  const userContext = useUser();
  const documentTitleColor = props.documentTitleColor;
  const documentBodyColor = props.documentBodyColor;

  const enterpriseId = userContext.state.user.enterpriseId;

  const tvaVentilation = [];

  const infos = props?.documentData;

  const documentType = infos?.documentType || null;
  // ? docTypeForRequest(infos.documentType)
  // : null;

  const nextInvoiceNumber = useNextInvoiceNumber({
    enterpriseId,
    queryParam: documentType ? { documentType } : null,
  });

  const formatNumber = (num) => {
    if (num === undefined || isNaN(num)) return num;
    const parsedNum = parseFloat(num);
    return parsedNum % 1 === 0 ? parsedNum.toFixed(0) : parsedNum.toFixed(2);
  };

  const subtotalHT = infos?.services?.reduce(
    (acc, service) =>
      acc +
      parseFloat(service?.unitPrice?.amount) *
        parseFloat(service?.quantity?.amount),
    0
  );

  const totalTVA = infos?.services?.reduce(
    (acc, service) =>
      acc +
      parseFloat(service?.unitPrice?.amount) *
        parseFloat(service?.quantity?.amount) *
        parseFloat(service?.vatRate?.taxRate),
    0
  );

  const totalTTC = (parseFloat(subtotalHT) + parseFloat(totalTVA)).toFixed(2);

  // Now format all the final outputs
  const formattedSubtotalHT = formatNumber(subtotalHT);
  const formattedTotalTVA = formatNumber(totalTVA);
  const formattedTotalTTC = formatNumber(totalTTC);

  const renderEnterpriseAddress = () => {
    if (
      !props?.documentData?.addresses?.filter(
        (address) => address.addressType === "ISSUER_BILLING"
      )
    ) {
      return (
        <div className="space-y-2 mt-1">
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
        </div>
      );
    }

    const senderName =
      props?.documentData?.additionalData?.find(
        (data) => data?.additionalDataType === "ISSUER_NAME"
      )?.additionalData || "";

    const senderVAT = props?.documentData?.enterpriseNumber?.vat;

    return (
      <>
        {props?.documentData?.addresses
          ?.filter((address) => address.addressType === "ISSUER_BILLING")
          .map((addressItem, index) => (
            <div key={index} className="flex flex-col">
              <span>{senderName}</span>

              <span>
                {addressItem?.address?.streetName}{" "}
                {addressItem?.address?.streetNumber}
              </span>

              <span>
                {addressItem?.address?.postalCode}, {addressItem?.address?.city}
              </span>

              <span>{countriesName[addressItem?.address?.country]}</span>
              <span>
                N° {infos?.enterpriseNumber?.type === "SIREN" && "SIREN"}:{" "}
                {infos?.enterpriseNumber?.value}{" "}
              </span>
              {senderVAT ? <span>N° TVA: {senderVAT}</span> : null}
            </div>
          ))}
      </>
    );
  };

  function docTitle() {
    if (infos?.documentType == "INVOICE") {
      return "Facture";
    } else if (infos?.documentType == "QUOTE") {
      return "Devis";
    } else if (infos?.documentType == "CREDIT_NOTE") {
      return "Note de crédit";
    }
  }

  function convertISOToDate(isoDateString) {
    // Parse the ISO string into a Date object
    const date = new Date(isoDateString);

    // Extract day, month, and year from the Date object
    const day = String(date.getDate()).padStart(2, "0"); // Adds leading 0 if day is less than 10
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading 0 if month is less than 10 (Note: Months are 0-indexed)
    const year = date.getFullYear();

    // Format the components into "DD/MM/YYYY"
    return `${day}/${month}/${year}`;
  }

  const unitPriceReference = [
    { unitValue: "FIXED_PRICE", label: "Forfait", pluralLabel: "Forfaits" },
    { unitValue: "DAY", label: "Jour", pluralLabel: "Jours" },
    { unitValue: "HOUR", label: "Heure", pluralLabel: "Heures" },
    { unitValue: "UNIT", label: "Unité", pluralLabel: "Unités" },
    { unitValue: "PERSON", label: "Personne", pluralLabel: "Personnes" },
    { unitValue: "WORD", label: "Mot", pluralLabel: "Mots" },
    { unitValue: "CHARACTER", label: "Caractère", pluralLabel: "Caractères" },
    { unitValue: "ARTICLE", label: "Article", pluralLabel: "Articles" },
    { unitValue: "PAGE", label: "Page", pluralLabel: "Pages" },
    { unitValue: "CLICK", label: "Clic", pluralLabel: "Clics" },
  ];

  const getUnitLabel = (unit, quantity) => {
    const unitInfo = unitPriceReference.find(
      (unitPrice) => unitPrice.unitValue === unit
    );
    return quantity > 1 && unitInfo?.pluralLabel
      ? unitInfo.pluralLabel
      : unitInfo?.label;
  };

  const PaymentMethodEquivalent = {
    IBAN: "IBAN",
    PAYMENT_LINK: "Lien de paiement",
    CHEQUE: "Chèque",
    CASH: "Liquide",
  };

  return (
    <div
      id="invoice-preview-main-box"
      className={`${infos?.className} bg-white`}
    >
      <section className="flex flex-col justify-between mt-16 mx-12">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col text-left w-max">
            <div className="invoice-user-infos-logo">
              {infos?.Logo && (
                // <img src={infos?.Logo} className="invoice-logo"></img>
                <Base64Image
                  base64={infos?.Logo.imageContent}
                  format={infos?.Logo.imageFormat}
                  alt={props.alt}
                  className={"w-24 h-12 object-contain"}
                />
              )}
            </div>
            <div className="mt-2">
              <p
                className="text-xl font-bold"
                style={{
                  color:
                    // props?.documentData?.invoiceStyle?.secondaryColor ||
                    // "#000000",
                    props?.documentData?.additionalData?.find(
                      (data) =>
                        data.additionalDataType === "DOCUMENT_TITLE_COLOR"
                    )?.additionalData ||
                    documentTitleColor ||
                    "#000000",
                }}
              >
                {" "}
                {docTitle()}
              </p>
              <div
                className="text-xl text-neutral-500 font-bold"
                style={{
                  color:
                    // props?.documentData?.invoiceStyle?.primaryColor ||
                    // "#737373",
                    props?.documentData?.additionalData?.find(
                      (data) =>
                        data.additionalDataType === "DOCUMENT_TITLE_COLOR"
                    )?.additionalData ||
                    documentTitleColor ||
                    "#000000",
                }}
              >
                {infos?.invoiceNumber ? (
                  infos?.invoiceNumber
                ) : nextInvoiceNumber.isNextInvoiceNumberLoading ? (
                  <div className="animate-pulse">
                    <div className="h-6 bg-neutral-200 dark:bg-neutral-800 rounded w-32"></div>
                  </div>
                ) : nextInvoiceNumber.isNextInvoiceNumberError ? (
                  <CouldNotLoad message="Erreur de chargement du numéro de facture" />
                ) : (
                  nextInvoiceNumber.nextInvoiceNumberData.payload
                    .nextInvoiceNumber
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col text-left">
            <div className="flex flex-row mb-3 gap-2 w-full">
              <div className="flex flex-col w-32 leading-3 text-sm">
                <p className="text-[8px] font-semibold text-black">
                  Date d&apos;émission
                </p>
                <span className="text-[8px] text-neutral-500">
                  {/* {infos?.issuingDate
                    ? dayjs(infos?.issuingDate).format("DD/MM/YYYY")
                    : dayjs().format("DD/MM/YYYY")} */}
                  {infos?.issuingDate}
                </span>
              </div>

              {infos?.dueDate && infos?.dueDate !== "NaN/NaN/NaN" ? (
                <div className="flex flex-col w-32 leading-3 text-sm">
                  <p className="text-[8px] font-semibold text-black">
                    {infos.documentType == "QUOTE"
                      ? "Date limite de validité"
                      : "Date d'échéance"}
                  </p>
                  <span className="text-[8px] text-neutral-500">
                    {/* {infos?.dueDate
                      ? dayjs(infos?.dueDate).format("DD/MM/YYYY")
                      : "-"} */}
                    {infos?.dueDate}
                  </span>
                </div>
              ) : null}
            </div>

            <div className="flex flex-row gap-2">
              <div className=" flex flex-col w-28 w-[128px]">
                <p className="text-[8px] font-semibold text-bold ">De</p>
                <div className="text-[8px] text-neutral-500">
                  {renderEnterpriseAddress()}
                </div>
                <div
                  className={`flex flex-col text-left text-[8px] text-neutral-500`}
                >
                  <span>
                    {
                      infos?.additionalData?.filter(
                        (data) => data?.additionalDataType === "ISSUER_EMAIL"
                      )[0]?.additionalData
                    }
                  </span>
                  <span>
                    {
                      infos?.additionalData?.filter(
                        (data) => data?.additionalDataType === "ISSUER_PHONE"
                      )[0]?.additionalData
                    }
                  </span>
                  <span>{props.userSIRET}</span>
                </div>
              </div>

              <div className=" flex flex-col w-28">
                <p className="text-[8px] font-semibold text-semiblack">À</p>
                <div className="text-[8px] text-neutral-500">
                  <ClientPreview
                    country={props?.documentData?.selectedClient?.country}
                    additionalData={props?.documentData?.additionalData}
                    addresses={props?.documentData?.addresses}
                    identifiers={
                      props?.documentData?.selectedClient?.identifiers
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {infos?.documentType === "CREDIT_NOTE" && (
          <div className="flex flex-col justify-between mt-4 items-start gap-3 w-full max-w-32">
            <span className="text-[8px] font-semibold">
              Note de crédit sur facture{" "}
              {infos?.selectedInvoice?.invoiceNumber
                ? infos?.selectedInvoice.invoiceNumber
                : ""}{" "}
              {infos?.selectedInvoice?.issuingDate ? "du" : null}{" "}
              {infos?.selectedInvoice?.issuingDate
                ? convertISOToDate(infos?.selectedInvoice?.issuingDate)
                : null}
            </span>
            <div className="text-[8px] font-semibold flex flex-col gap-1">
              <p className="text-left">
                Raison de l&apos;émission de cette note de crédit
              </p>
              <li className="text-black text-xs font-normal normal-case text-left">
                {
                  infos?.additionalData?.filter(
                    (data) => data.additionalDataType === "CREDIT_NOTE_REASON"
                  )[0]?.additionalData
                }
              </li>
            </div>
          </div>
        )}
      </section>

      <section id="invoice-services-section" className="mt-8">
        <div className="flex flex-row justify-between text-[8px] mx-12 text-black pb-4 mb-4 border-b border-gray-200">
          <div className="w-[190px] text-left">
            <span>Service(s)</span>
          </div>
          <div className="flex justify-evenly text-right">
            <div className="w-[47px]">Quantité</div>
            <div className="w-[47px]">Unité</div>
            <div className="w-[58px]">Coût unit.</div>
            <div className="w-[40px]">TVA</div>
            <div className="w-[58px]">Total HT</div>
            <div className="w-[58px]">Total TTC</div>
          </div>
        </div>
        {infos?.services?.map((service) => (
          <div
            className="flex flex-col gap-4 mt-2"
            key={"service" + service.id}
          >
            <div className="flex flex-row justify-between mx-12 text-[8px]">
              <div className="flex flex-col justify-start w-[190px]">
                <div className="justify-start text-left font-semibold break-words">
                  {service.title}
                </div>
                <div className="text-left font-normal mt-[2px] whitespace-pre-line break-words">
                  {service?.description}
                </div>
              </div>
              <div className="flex flex-row text-black text-right">
                <div className="w-[47px]">
                  {service?.quantity?.amount ? service?.quantity?.amount : 0}{" "}
                </div>
                <div className="w-[47px]">
                  {service?.quantity?.unit
                    ? getUnitLabel(
                        service?.quantity?.unit,
                        service?.quantity?.amount
                      )
                    : ""}
                </div>
                <div className="w-[58px]">
                  {service?.unitPrice?.amount ? service?.unitPrice?.amount : 0}{" "}
                  {countriesCurrency[service?.unitPrice?.currency]}
                </div>
                <div className="w-[40px]">
                  {service?.vatRate?.taxRate
                    ? formatNumber(service?.vatRate?.taxRate * 100)
                    : 0}
                  %
                </div>
                <div className="w-[58px]">
                  {service?.quantity?.amount && service?.unitPrice?.amount
                    ? formatNumber(
                        service?.quantity?.amount * service?.unitPrice?.amount
                      )
                    : 0}{" "}
                  {countriesCurrency[service?.unitPrice?.currency]}
                </div>
                <div className="w-[58px]">
                  {service?.quantity?.amount && service?.unitPrice?.amount
                    ? formatNumber(
                        service?.quantity?.amount * service?.unitPrice?.amount +
                          service?.quantity?.amount *
                            service?.unitPrice?.amount *
                            service?.vatRate?.taxRate
                      )
                    : 0}{" "}
                  {countriesCurrency[service?.unitPrice?.currency]}
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>

      {infos?.services &&
        infos.services.map((service) => {
          const vatRate = service?.vatRate?.taxRate || 0;
          if (vatRate > 0) {
            const existingVentilation = tvaVentilation.find(
              (vent) => vent.VAT === vatRate
            );
            const serviceTotal =
              service?.quantity?.amount * service?.unitPrice?.amount;
            const serviceTVA = serviceTotal * vatRate;

            if (existingVentilation) {
              existingVentilation.total += serviceTVA;
            } else {
              tvaVentilation.push({
                VAT: vatRate,
                total: serviceTVA,
              });
            }
          }
        })}

      <section className="flex flex-col gap-1 mx-12 border-t pt-4 border-gray-200 mt-4">
        <div className="flex flex-row justify-between text-[8px] text-neutral-500">
          <div>Sous-total (HT)</div>
          <div>
            {formattedSubtotalHT} {countriesCurrency[infos?.total?.currency]}
          </div>
        </div>
        {tvaVentilation.length > 0 && (
          <div>
            {tvaVentilation.map((vent, index) => (
              <div
                key={`vent-${index}`}
                className="flex flex-row justify-between text-[8px] text-neutral-500"
              >
                <div>TVA ({vent.VAT * 100}%)</div>
                <div>
                  {formatNumber(vent.total)}{" "}
                  {countriesCurrency[infos?.totalWithTax?.currency]}
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="flex flex-row justify-between text-[8px] text-neutral-500">
          <div>
            Total TVA
            {infos?.additionalData?.find(
              (type) => type?.additionalDataType == "VAT_DISCLAIMER"
            )?.additionalData ? (
              <span className="align-top">*</span>
            ) : (
              ""
            )}
          </div>

          <div>
            {formattedTotalTVA}{" "}
            {countriesCurrency[infos?.totalWithTax?.currency]}
          </div>
        </div>
      </section>

      <section
        className="flex flex-row justify-between text-[9px] px-4 py-2 mx-12 mt-4 rounded text-black"
        style={{
          backgroundColor:
            // props?.documentData?.invoiceStyle?.primaryColor || "#f5f5f5",
            props?.documentData?.additionalData?.find(
              (data) => data.additionalDataType === "DOCUMENT_BODY_COLOR"
            )?.additionalData ||
            documentBodyColor ||
            "#f5f5f5",
        }}
      >
        <div>Total (TTC)</div>
        <div>
          {formattedTotalTTC} {countriesCurrency[infos?.totalRounded?.currency]}
        </div>
      </section>

      <span className="flex flex-row justify-between text-[6px] px-4 mx-12 mt-2 rounded text-black">
        {
          infos?.additionalData?.find(
            (type) => type?.additionalDataType == "VAT_DISCLAIMER"
          )?.additionalData
        }
      </span>

      <section
        className="flex flex-col gap-1 text-left mx-12 mt-12 text-[8px] text-black"
        id="payment-section"
      >
        <p className="font-bold">Moyen de paiement</p>
        <div className="payment-infos">
          {infos?.additionalData
            ? infos?.additionalData
                ?.filter(
                  (account) =>
                    account?.additionalDataType == "PAYMENT_METHOD" &&
                    (account?.externalId || account?.id)
                )
                ?.map((account) => (
                  <div key={"account:" + account.title}>
                    <span style={{ fontWeight: "700" }}>
                      {/* {account.title &&
                        account.title != account.additionalData &&
                        account.title + ":"}{" "} */}
                      {PaymentMethodEquivalent[account.additionalDataSubType] ||
                        account.additionalDataSubType}
                      :{" "}
                    </span>
                    {account.additionalData}
                  </div>
                ))
            : null}
        </div>
      </section>

      {infos?.additionalData?.find(
        (condition) =>
          condition?.additionalDataType === "CREDIT_NOTE_CONDITIONS"
      )?.additionalData ||
      infos?.additionalData?.find(
        (condition) => condition?.additionalDataType === "INVOICE_CONDITIONS"
      )?.additionalData ? (
        <section
          className="flex flex-col gap-1 text-left mx-12 my-6 text-[8px] text-black"
          id="conditions-section"
        >
          <p className="font-bold">Conditions</p>
          <div className="w-full break-words">
            <span className="whitespace-pre-line">
              {infos?.additionalData?.find(
                (condition) =>
                  condition?.additionalDataType === "CREDIT_NOTE_CONDITIONS"
              )?.additionalData ||
                infos?.additionalData?.find(
                  (condition) =>
                    condition?.additionalDataType === "INVOICE_CONDITIONS"
                )?.additionalData}
            </span>
          </div>
        </section>
      ) : null}
      {infos?.additionalData?.find(
        (condition) =>
          condition?.additionalDataType ===
          "CREDIT_NOTE_PAYMENT_APPLICATION_METHOD"
      )?.additionalData ? (
        <section
          className="flex flex-col gap-1 text-left mx-12 my-8 text-[8px] text-black"
          id="conditions-section"
        >
          <p className="font-bold">Moyen d&apos;application du crédit</p>
          <div className="conditions-infos w-full break-words">
            {
              infos?.additionalData?.find(
                (condition) =>
                  condition?.additionalDataType ===
                  "CREDIT_NOTE_PAYMENT_APPLICATION_METHOD"
              )?.additionalData
            }
          </div>
        </section>
      ) : null}

      {infos?.purchaseOrder ? (
        <section className="flex flex-col gap-1 text-left mx-12 my-8 text-[8px] text-black">
          <p className="font-bold">Numéro de bon de commande</p>
          <div className="conditions-infos w-full break-words">
            <span>{infos?.purchaseOrder}</span>
          </div>
        </section>
      ) : null}

      {infos?.documentType === "CREDIT_NOTE" ||
      infos?.documentType === "QUOTE" ? (
        <section className="flex justify-end gap-1 text-left mx-12 my-8 text-[8px] text-black">
          <p className="font-bold">Signature</p>
        </section>
      ) : null}
    </div>
  );
}
