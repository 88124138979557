export default function Ribbon({ message, onClose, onClick, onMouseEnter }) {
  return (
    <div
      className="relative w-full text-black text-center cursor-pointer transition duration-200 p-3 m-auto"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      style={{
        background:
          "linear-gradient(to right, #DA7870 0%, #DB7B72 36%, #EEC186 100%)",
      }}
    >
      <span className="font-medium text-sm text-neutral-800">{message}</span>
      {/* <button
        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-xl font-bold"
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
      >
        &times;
      </button> */}
    </div>
  );
}
