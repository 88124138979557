// Functions to return status and style based on documentType and status
const getStatus = () => {
  const baseStatus = {
    REGISTERED: "Utilisateur enregistré",
    ENTERPRISE_CREATED: "Entreprise créée",
    SUBSCRIPTION_REQUESTED: "Demande d'abonnement",
    SUBSCRIPTION_CREATION_ERROR: "Erreur abonnement",
    SUBSCRIPTION_CREATED: "Abonnement créé",
  };

  return baseStatus;
};

const getStyle = ({ textSize = "text-xs" }) => {
  //"active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "paused" | "trialing" | "unpaid"
  const baseStyle = {
    SUBSCRIPTION_CREATED: `text-green-700 bg-green-50 ring-green-600/20 rounded-md py-1 px-2 ${textSize} font-medium ring-1 ring-inset w-max`,
    SUBSCRIPTION_REQUESTED: `text-yellow-600 bg-yellow-50 ring-yellow-500/10 rounded-md py-1 px-2 ${textSize} font-medium ring-1 ring-inset w-max`,
    ENTERPRISE_CREATED: `text-orange-600 bg-orange-50 ring-orange-500/10 rounded-md py-1 px-2 ${textSize} font-medium ring-1 ring-inset w-max`,
    REGISTERED: `text-orange-600 bg-orange-50 ring-orange-500/10 rounded-md py-1 px-2 ${textSize} font-medium ring-1 ring-inset w-max`,
    SUBSCRIPTION_CREATION_ERROR: `text-red-700 bg-red-50 ring-red-600/10 rounded-md py-1 px-2 ${textSize} font-medium ring-1 ring-inset w-max`,
  };

  return baseStyle;
};

export default function OnboardingStatus({ status, textSize }) {
  const currentStatus = getStatus()[status];
  const currentStyle = getStyle({ textSize })[status];

  return <div className={currentStyle}>{currentStatus}</div>;
}
