import InputTailwind from "@/components/token/Input/inputTailwind";
import { CRUDL } from "@/utils/crudl";
import { useEffect } from "react";

export function City({
  initialCity,
  disabled,
  onChange,
  isLoading,
  required,
  name = "postalCode",
  displayError = true,
  forceValidate,
  context,
}) {
  const city = initialCity;

  useEffect(() => {
    if (
      forceValidate &&
      (!(!required || initialCity) || context === CRUDL.CREATE)
    ) {
      onChange({
        success: !required || !!initialCity,
        isDirty: true,
      });
    }
  }, [forceValidate, onChange, required, initialCity, context]);

  const handleCityChange = (e) => {
    const value = e.target.value;
    onChange({
      success: !required || !!value,
      data: value,
      isDirty: true,
    });
  };

  return (
    <InputTailwind
      label="Ville"
      type="text"
      placeholder="Votre ville"
      value={city}
      onChange={(e) => handleCityChange(e)}
      loading={isLoading}
      disabled={disabled}
      error={required && !city && displayError ? "Ce champ est requis" : null}
      isRequired={required}
      name={name}
      maxLength={100}
    ></InputTailwind>
  );
}
