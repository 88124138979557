import { DocumentActions } from "@/components/elements/Document/DocumentForm";
import ServiceFormV2 from "@/components/elements/ServiceForm/ServiceFormV2";
import StepFormDocuments from "@/components/elements/StepFormDocuments";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import { DocumentStatus } from "@/components/token/documents/DocumentStatus";
import {
  DocumentCreationLabel,
  DocumentTypeLabel,
} from "@/components/token/documents/DocumentType";
import ValidatedTextArea from "@/components/token/textareas/ValidatedTextArea";
import { useCommonDocumentForm } from "@/feature/document/useDocumentForm";
import { useInvoices } from "@/hooks/useInvoices";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Box } from "@mui/material";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SearchSelect from "@/components/token/select/SearchSelect";
import { useClients, useGetOneClient } from "@/hooks/useClients";
import { DocumentAdditionalDataSubType } from "../document/DocumentAdditionalDataSubType";
import { DocumentAdditionalDataType } from "../document/DocumentAdditionalDataType";
import { notify } from "@/utils/notify";
import { LastStepCreditNoteFormV2 } from "./LastStepCreditNoteFormV2";
import { DocumentFlowModal } from "../document/DocumentFlowModal";
import { extractDocumentAdditionalDataWithError } from "../document/helpers/extractDocumentAdditionalData";
import { displayInvoiceChoice } from "./displayInvoiceChoice";

export function CreditNoteFormV2({
  enterprise,
  isDocumentCreationLoading,
  documentData,
  setDocumentData,
  invoiceNumber = null,
  setIsPageNew,
  articles = [],
  handleDocumentSubmit,
  isParametersOpen,
  setIsParametersOpen,
  creditNoteReason,
  lastDocumentOfSameType,
  documentSettings,
}) {
  /* Re-usable hook form */
  const {
    displayLeavingPopUp,
    isCreationModalOpen,
    handleServiceAddition,
    handleServiceRemoval,
    handleServiceSelection,
    setDisplayLeavingPopUp,
    setIsCreationModalOpen,
    validateServices,
  } = useCommonDocumentForm({
    documentData,
    setDocumentData,
    enterprise,
    documentSettings,
  });

  /* State */
  const [step, setStep] = useState(
    invoiceNumber && creditNoteReason?.value ? 1 : 0
  );
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] =
    useState(invoiceNumber);
  const [searchParams, setSearchParams] = useSearchParams();

  /* Derived state */
  const enterpriseId = enterprise.id;
  const documentType = documentData.documentType || DocumentType.CREDIT_NOTE;

  /* Queries */
  const invoices = useInvoices(
    { enterpriseId },
    {
      enabled: !!enterpriseId,
    }
  );

  const invoiceContent = invoices.invoicesData?.payload?.items || [];
  const filteredInvoices = invoiceContent
    .filter(
      (inv) =>
        inv.documentType == "INVOICE" &&
        inv.invoiceStatus != "DRAFT" &&
        inv.invoiceStatus != "TO_VALIDATE" &&
        inv.invoiceStatus != "CANCELLED"
    )
    .sort((a, b) => new Date(b.issuingDate) - new Date(a.issuingDate));

  const selectedInvoice = filteredInvoices.find(
    (i) => i.invoiceNumber === selectedInvoiceNumber
  );

  /* Get one client as mutation */
  const getOneClient = useGetOneClient(undefined, (data) => {
    setDocumentData({
      type: DocumentActions.SET_CLIENT,
      payload: data.payload,
    });
  });

  /* Clients list */
  const client = useClients({
    enterpriseId,
    clientId: selectedInvoice?.clientId,
    reactQueryConfig: {
      enabled: !!selectedInvoice,
    },
  });
  const selectedClient = client.clientsData?.payload;

  const isLoading = invoices.isInitialLoading || client.isInitialLoading;

  /* Handlers */
  const handleLinkedDocumentSelection = useCallback(
    (document) => {
      setSelectedInvoiceNumber(document.invoiceNumber);
      setDocumentData({
        type: DocumentActions.SET_LINKED_DOCUMENT,
        payload: document,
      });
      searchParams.set("linkedDocument", document.invoiceNumber);
      setSearchParams(searchParams);
      setIsPageNew(false);
      if (step === 0 && creditNoteReason?.value && !creditNoteReason.error) {
        setStep((prev) => prev + 1);
      }
      getOneClient.mutate({
        enterpriseId,
        clientId: document.clientId,
      });
    },
    [
      setDocumentData,
      searchParams,
      setSearchParams,
      step,
      setIsPageNew,
      enterpriseId,
      getOneClient,
      creditNoteReason,
    ]
  );

  /* Form navigation */
  function toStep1() {
    if (!selectedInvoiceNumber) {
      notify({
        isSuccess: false,
        message: "Vous devez sélectionner un numéro de facture pour continuer",
      });
      return;
    }

    if (!creditNoteReason?.value) {
      notify({
        isSuccess: false,
        message: "Vous devez fournir une raison d'émission pour continuer",
      });
      return;
    }

    if (creditNoteReason.error) {
      notify({
        isSuccess: false,
        message: creditNoteReason.error,
      });
      return;
    }

    setStep((prev) => prev + 1);
  }

  function toStep2() {
    validateServices({
      documentData,
      onSuccess: () => setStep((prev) => prev + 1),
    });
  }

  function handleSubmit() {
    const creditNotePaymentApplicationMethod =
      extractDocumentAdditionalDataWithError({
        additionalDataArray: documentData.additionalData,
        additionalDataType:
          DocumentAdditionalDataType.CREDIT_NOTE_PAYMENT_APPLICATION_METHOD,
      });
    if (creditNotePaymentApplicationMethod.error) {
      notify({
        isSuccess: false,
        message: creditNotePaymentApplicationMethod.error,
      });
      return;
    }

    if (!creditNotePaymentApplicationMethod.value) {
      notify({
        isSuccess: false,
        message: "Vous devez indiquer la façon dont le crédit sera appliqué",
      });
      return;
    }

    setIsCreationModalOpen(true);
  }

  const submitFunctions = [toStep1, toStep2, handleSubmit];

  /* Render */
  if (isParametersOpen) return null;

  return (
    <>
      <DocumentFlowModal
        displayLeavingPopUp={displayLeavingPopUp}
        handleDocumentSubmit={handleDocumentSubmit}
        isCreationModalOpen={isCreationModalOpen}
        isDocumentCreationLoading={isDocumentCreationLoading}
        setIsCreationModalOpen={setIsCreationModalOpen}
        setDocumentData={setDocumentData}
        setDisplayLeavingPopUp={setDisplayLeavingPopUp}
      />
      <StepFormDocuments
        docType={DocumentTypeLabel[documentType]}
        setStep={setStep}
        numberOfSteps={3}
        currentStep={step}
        TextSubmit={DocumentCreationLabel[documentType]}
        setStepBack={() => {
          if (step === 0) {
            setDisplayLeavingPopUp(true);
          } else {
            setStep(step - 1);
          }
        }}
        createDraft={() => handleDocumentSubmit(DocumentStatus.DRAFT)}
        setDisplayLeavingPopUp={setDisplayLeavingPopUp}
        draftDisplay={true}
        isDocumentCreationLoading={isDocumentCreationLoading}
        onSubmit={submitFunctions[step]}
        setParametersOpen={setIsParametersOpen}
      >
        <Box className="flex flex-col w-full gap-4">
          <Box className="flex flex-col w-full gap-2">
            {step === 0 ? (
              <Box className="flex flex-col gap-4 w-full">
                <SearchSelect
                  label="Facture originale concernée"
                  selectedValue={selectedInvoice}
                  onSelect={handleLinkedDocumentSelection}
                  options={invoices.isLoading ? null : filteredInvoices}
                  placeholder={`Rechercher votre facture (ex: F-${new Date().getFullYear()}-0005)...`}
                  accessorFn={displayInvoiceChoice}
                  loading={invoices.isLoading}
                />
                <ValidatedTextArea
                  label="Raison de l'émission d'une note de crédit"
                  placeholder="Description du motif (retour de marchandise, erreur de facturation, accord commercial, etc.)."
                  name={"creditNoteReason"}
                  value={creditNoteReason.value}
                  setInputValue={(value, error) => {
                    const payload = {
                      additionalDataType:
                        DocumentAdditionalDataType.CREDIT_NOTE_REASON,
                      additionalDataSubType:
                        DocumentAdditionalDataSubType.NOT_APPLICABLE,
                      additionalData: value,
                    };
                    if (error) payload.error = error;

                    setDocumentData({
                      type: DocumentActions.SET_UNIQUE_ADDITIONAL_DATA,
                      payload,
                    });
                  }}
                  required={true}
                  loading={false}
                  error={creditNoteReason.error}
                />
              </Box>
            ) : null}
            {step === 1 ? (
              <>
                <Box className="flex flex-col w-full gap-4">
                  <SearchSelect
                    label="Service"
                    selectedValue={"services"}
                    onSelect={handleServiceSelection}
                    displayCreationElement={handleServiceAddition}
                    creationElement="Ajouter un service"
                    options={articles}
                    placeholder="Sélectionnez un service"
                    accessor="title"
                  />

                  <Box className="flex flex-col w-full gap-4">
                    {documentData.services.map((service) => (
                      <div
                        key={`service-div:${service.id}`}
                        className="w-full min-h-max"
                      >
                        <ServiceFormV2
                          enterprise={enterprise}
                          client={selectedClient}
                          service={service}
                          setService={(s) => {
                            setDocumentData({
                              type: DocumentActions.UPDATE_SERVICE,
                              payload: s,
                            });
                          }}
                          key={`service:${service.id}`}
                          isLoading={isLoading}
                          removeService={handleServiceRemoval}
                          duplicateService={handleServiceSelection}
                        />
                      </div>
                    ))}

                    <ButtonTailwind
                      variant="text"
                      onClick={handleServiceAddition}
                    >
                      <PlusIcon className="h-6 w-6 text-neutral-700" />
                      Ajouter un service
                    </ButtonTailwind>
                  </Box>
                </Box>
              </>
            ) : null}
            {step === 2 ? (
              <LastStepCreditNoteFormV2
                enterprise={enterprise}
                documentData={documentData}
                setDocumentData={setDocumentData}
                linkedDocument={selectedInvoice}
                lastDocumentOfSameType={lastDocumentOfSameType}
                documentSettings={documentSettings}
              />
            ) : null}
          </Box>
        </Box>
      </StepFormDocuments>
    </>
  );
}
