import CenteredLoadingBlock from "@/components/elements/CenteredLoadingBlock";
import RequireSubscriptionModal from "@/components/token/modals/RequireSubscriptionModal";
import SubscriptionStateRibbon from "@/components/token/ribbon/SubscriptionStateRibbon";
import { useOnboarding } from "@/contexts/OnboardingContext";
import { useOryAuth } from "@/contexts/OryAuthContext";
import { useSubscription } from "@/contexts/UserContext";
import Menu from "@/feature/Menu/Menu";
import { useState } from "react";
import { Navigate, redirect } from "react-router-dom";

export default function ProtectedRoute({
  children,
  requireWhitelist = true,
  requireSubscription = false,
  fallback = "/home",
  showRibbon = false,
  hideMenu = false,
  isOnboarding = false,
}) {
  const auth = useOryAuth();
  const isOnWhitelist = true;
  const {
    trialDays,
    isTrialing,
    isActive,
    isLoading,
    cancelAtPeriodEnd,
    isPaymentMethodSet,
  } = useSubscription();
  const [isModalOpen, setIsModalOpen] = useState(
    requireSubscription && !isActive
  );
  const onboarding = useOnboarding();

  if (auth.isLoading || onboarding.isLoading) {
    return <CenteredLoadingBlock />;
  }

  if (!auth.session) {
    return <Navigate to="/login" replace={true} />;
  }

  if (onboarding.target && !isOnboarding) {
    return <Navigate to={onboarding.target} replace={true} />;
  } else if (isOnboarding) {
    /* Onboarding pages should only be accessible if there is still an outstanding 
       onboarding target
    */
    if (!onboarding.target) return <Navigate to="/home" replace={true} />;

    const currentLocation = window.location.pathname;
    /* No need to redirect if we are already on the right place*/
    if (!(onboarding.target || "").startsWith(currentLocation)) {
      return <Navigate to={onboarding.target} replace={true} />;
    }
  }

  return (
    <div className="flex flex-row w-screen max-h-screen overflow-y-auto bg-neutral-100">
      {hideMenu ? null : <Menu />}
      <div className="flex flex-col w-full max-h-screen overflow-y-auto">
        <div className="w-full">
          <SubscriptionStateRibbon
            isShown={showRibbon}
            trialDays={trialDays}
            isTrialing={isTrialing}
            isActive={isActive}
            isLoading={isLoading}
            isPaymentMethodSet={isPaymentMethodSet}
            cancelAtPeriodEnd={cancelAtPeriodEnd}
          />
        </div>
        {!requireWhitelist || isOnWhitelist ? children : null}
      </div>
      {requireSubscription && isModalOpen && (
        <RequireSubscriptionModal
          className="z-50"
          onBack={() => {
            setIsModalOpen(false);
            redirect(fallback);
          }}
        />
      )}
    </div>
  );
}
