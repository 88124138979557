import React, { useState } from "react";
import Toggle from "@/components/token/button/toggle";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import Select from "@/components/token/select/select";
import InputTailwind from "@/components/token/Input/inputTailwind";
import { useFormCreatePaymentInformationV2 } from "@/hooks/forms/useFormCreatePaymentInformationV2";
import ModifyPaymentFormV2 from "../ModifyPaymentForm/ModifyPaymentFormV2";
import { LidapIcon } from "@/components/token/loading/LidapIcon";
import { PencilIcon } from "@heroicons/react/24/outline";

export default function AccountsToCreditV2({
  accounts,
  accountsToCredit,
  toggleAccount,
}) {
  const [isAddPaymentDisplayed, setIsAddPaymentDisplayed] = useState(false);

  const {
    paymentType,
    selectedPayment,
    setSelectedPayment,
    accountWording,
    setAccountWording,
    secondInputValue,
    setSecondInputValue,
    paymentToModify,
    setPaymentToModify,
    isDuplicateTitle,
    handleSubmit,
    createPaymentInformation,
    AnnulerMoyenDePaiement,
    check,
  } = useFormCreatePaymentInformationV2({
    onCancel: () => setIsAddPaymentDisplayed(false),
    onSuccess: (response) => {
      setIsAddPaymentDisplayed(false);
      toggleAccount(response.payload.created);
    },
  });

  return (
    <div className="flex flex-col">
      {isAddPaymentDisplayed ? (
        <div className="z-50">
          <div
            className="bg-[rgba(0,0,0,0.5)] backdrop-blur-sm absolute top-0 left-0 w-screen h-screen"
            onClick={() => setIsAddPaymentDisplayed(false)}
          ></div>
          <div className="flex flex-col absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-12 bg-white rounded-2xl w-[28rem]">
            <h1 className="text-[2rem] mb-8 text-center">Ajouter un compte</h1>
            <div className="flex flex-col gap-4">
              <div>
                <Select
                  data={paymentType}
                  selectedItem={selectedPayment}
                  setSelectedItem={setSelectedPayment}
                  label="Moyen de paiement"
                />
              </div>
              <InputTailwind
                label="Intitulé"
                type="text"
                placeholder={selectedPayment.placeholderAccountWording}
                value={accountWording}
                onChange={(event) => setAccountWording(event.target.value)}
                isRequired={true}
                icon={false}
                error={
                  isDuplicateTitle(accountWording)
                    ? "Un moyen de paiement avec ce titre existe déjà"
                    : ""
                }
              />
              {selectedPayment.secondInput ? (
                <InputTailwind
                  label={selectedPayment.label}
                  type="text"
                  placeholder={selectedPayment.placeholder}
                  value={secondInputValue}
                  onChange={(event) => setSecondInputValue(event.target.value)}
                  isRequired={true}
                  icon={false}
                  error={
                    check.isValid || !secondInputValue ? "" : check.message
                  }
                />
              ) : null}
            </div>

            <div className="flex w-full gap-4 mt-8">
              {
                <>
                  <ButtonTailwind
                    onClick={() => {
                      AnnulerMoyenDePaiement();
                      setIsAddPaymentDisplayed(false);
                    }}
                    variant={"outlined2"}
                    style="w-full"
                    disabled={createPaymentInformation.isLoading}
                  >
                    Annuler
                  </ButtonTailwind>
                  <ButtonTailwind
                    onClick={handleSubmit}
                    loading={createPaymentInformation.isLoading}
                    style="w-full"
                  >
                    Enregistrer
                  </ButtonTailwind>
                </>
              }
            </div>
          </div>
        </div>
      ) : null}
      <div className="flex flex-col gap-3">
        <label className="text-black font-bold">{"Compte à créditer"}</label>

        {accounts.map((account) => {
          const isToggled = accountsToCredit.some(
            (acc) => acc.externalId === account.id
          );

          return (
            <div key={account.id} className="flex justify-between">
              <div className="flex gap-2 items-center">
                <label className="text-neutral-400 text-sm font-medium">
                  {account.title}
                </label>
                <LidapIcon
                  Icon={PencilIcon}
                  toolTipText="Modifier"
                  handleAction={setPaymentToModify}
                  loading={false}
                  value={account.id}
                />
                {paymentToModify == account.id ? (
                  <ModifyPaymentFormV2
                    accounts={accounts}
                    accountBeingModified={account}
                    setPaymentToModify={setPaymentToModify}
                    setAccountsToCredit={() => toggleAccount(account)}
                  />
                ) : null}
              </div>
              <Toggle
                onClick={() => toggleAccount(account)}
                isToggled={isToggled}
              />
            </div>
          );
        })}

        <ButtonTailwind
          variant="outlined2"
          width="100%"
          onClick={() => setIsAddPaymentDisplayed(true)}
        >
          Ajouter un compte
        </ButtonTailwind>
      </div>
    </div>
  );
}
