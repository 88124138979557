import { DocumentActions } from "@/components/elements/Document/DocumentForm";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import DocumentCreationModal from "@/components/token/documentCreationModal/DocumentCreationModal";
import { DocumentStatus } from "@/components/token/documents/DocumentStatus";
import { useNavigate } from "react-router-dom";

export function DocumentFlowModal({
  isCreationModalOpen,
  setIsCreationModalOpen,
  handleDocumentSubmit,
  isDocumentCreationLoading,
  displayLeavingPopUp,
  setDisplayLeavingPopUp,
  setDocumentData,
}) {
  const navigate = useNavigate();

  return (
    <>
      {isCreationModalOpen && (
        <DocumentCreationModal
          createDocument={() => handleDocumentSubmit(DocumentStatus.CREATED)}
          unDisplay={() => setIsCreationModalOpen(false)}
          isLoading={isDocumentCreationLoading}
        />
      )}

      {displayLeavingPopUp && (
        <div className="absolute top-0 left-0 h-screen w-screen flex justify-center items-center bg-[rgba(0,0,0,0.5)] backdrop-blur-sm z-50">
          <div className="rounded-md bg-white flex w-[32rem] py-12 px-8 flex-col items-start gap-8">
            <h1 className="text-black text-4xl font-semibold">
              Êtes-vous sûr de vouloir quitter ?
            </h1>
            <div className="flex gap-2">
              <ButtonTailwind
                variant="outlined2"
                width="100%"
                onClick={() => setDisplayLeavingPopUp(false)}
              >
                Annuler
              </ButtonTailwind>
              <ButtonTailwind
                variant="filled"
                width="100%"
                onClick={() => {
                  navigate("/documents");
                  setDocumentData({
                    type: DocumentActions.INITIALIZE,
                  });
                }}
              >
                Quitter
              </ButtonTailwind>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
