import InputTailwind from "@/components/token/Input/inputTailwind";
import { postalCodeValidator } from "./postalCodeValidator";
import { useEffect } from "react";
import { postalCodePlaceholders } from "./postalCodePlaceholders";
import { CRUDL } from "@/utils/crudl";

export function PostalCode({
  country,
  initialPostalCode,
  disabled,
  onChange,
  isLoading,
  required,
  name = "postalCode",
  displayError = true,
  forceValidate,
  context,
}) {
  const postalCode = initialPostalCode;
  const feedback = postalCodeValidator(country, postalCode?.trim(), required);
  const placeholderBasedOnCountry = postalCodePlaceholders[country] || "1000";

  useEffect(() => {
    if (forceValidate && (!feedback.success || context === CRUDL.CREATE)) {
      onChange({
        success: feedback.success,
        isDirty: true,
        data: postalCode,
      });
    }
  }, [forceValidate, onChange, feedback.success, context, postalCode]);

  useEffect(() => {
    if (!postalCode) return;

    const feedback = postalCodeValidator(country, postalCode?.trim(), required);
    onChange({
      success: feedback.success,
      data: postalCode,
      isDirty: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const handleOnBlur = () => {
    if (!postalCode) return;
    const trimmed = postalCode.trim();
    if (postalCode !== trimmed) {
      const feedback = postalCodeValidator(country, trimmed, required);
      onChange({
        success: feedback.success,
        data: trimmed,
        isDirty: true,
      });
    }
  };

  const handlePostalCodeChange = (e) => {
    const value = e.target.value;
    const feedback = postalCodeValidator(country, value, required);
    onChange({
      success: feedback.success,
      data: value,
      isDirty: true,
    });
  };

  return (
    <InputTailwind
      label="Code postal"
      type="text"
      placeholder={placeholderBasedOnCountry}
      value={postalCode}
      onChange={(e) => handlePostalCodeChange(e)}
      loading={isLoading}
      disabled={disabled}
      error={feedback.success || !displayError ? null : feedback.message}
      isRequired={required}
      name={name}
      maxLength={10}
      onBlur={handleOnBlur}
    ></InputTailwind>
  );
}
