import SettingsHeader from "@/components/token/settingsHeader/SettingsHeader";
import { useEnterpriseId } from "@/contexts/UserContext";
import {
  useCreateCheckoutSession,
  useCreateCustomerPortal,
  useCreateDefaultSubscription,
  useCurrentSubscription,
  useStripeConfiguration,
} from "@/hooks/stripe/useLidapStripe";
import SubscriptionCard from "@/Pages/stripe/SubscriptionCard";
import CustomerPortal from "@/Pages/stripe/CustomerPortal";
import CenteredLoadingBlock from "@/components/elements/CenteredLoadingBlock";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

export default function Subscription() {
  const queryClient = useQueryClient();

  const enterpriseId = useEnterpriseId();

  const currentSubscription = useCurrentSubscription({
    enterpriseId,
    reactQueryConfig: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
  });

  const subscription = currentSubscription.data?.payload;

  const configuration = useStripeConfiguration({
    queryParam: null,
    reactQueryConfig: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 1000 * 60 * 60,
    },
  });

  const pricesList = configuration.data
    ? configuration.data.payload.prices
    : [];
  const defaultPriceId = (() => {
    if (pricesList.length === 0) {
      return "";
    }
    if (pricesList.length === 1) {
      return pricesList[0].id;
    }
    return pricesList.find((price) => price.isDefault).id;
  })();

  const price = pricesList.find((price) => price.id === defaultPriceId);

  const displayedSubscription = (() => {
    if (configuration.isLoading || currentSubscription.isLoading) {
      return;
    }

    /* If we are here, it means the customer has no active subscription yet*/
    if (!subscription) {
      return {
        subscriptionType: price.name,
        status: "none",
        price: {
          id: price.id,
          currency: price.currency,
          amount: price.amount,
          isActive: price.isActive,
        },
      };
    }

    /* If the user is in free trial, we need to show the latest price retrieved from the configuration, 
    not the price that was applicable when they started the free triel */
    const amount =
      subscription.status === "trialing"
        ? price.amount
        : subscription.price.amount;

    return {
      subscriptionId: subscription.id,
      status: subscription.status,
      trialEnd: subscription.trialEnd,
      trialStart: subscription.trialStart,
      subscriptionType: subscription.subscriptionType,
      trialDays: subscription.trialDays,
      price: {
        id: subscription.price.id,
        currency: subscription.price.currency,
        amount: amount,
        isActive: subscription.price.isActive,
      },
      cancelAt: subscription.cancelAt,
      cancelAtPeriodEnd: subscription.cancelAtPeriodEnd,
      isPaymentMethodSet: subscription.isPaymentMethodSet,
    };
  })();

  /* Effects */
  /* We cannot be notified in React by Stripe that the subscription has changed. 
  Therefore, we need to invalidate the query to refetch the latest information. 
  */
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["users", "info"] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Mutation */
  const portal = useCreateCustomerPortal(
    ["stripe", "subscription"],
    [["users", "info"]],
    (data) => {
      const payload = data.payload;
      window.location.href = payload.portalUrl;
    }
  );

  /* Mutations and queries */
  const checkout = useCreateCheckoutSession(
    ["stripe", "subscription"],
    [["users", "info"]],
    (data) => {
      const payload = data.payload;
      window.location.href = payload.sessionUrl;
    }
  );

  const defaultSubscription = useCreateDefaultSubscription(
    ["stripe", "subscription"],
    [["users", "info"]]
  );
  /* Hooks */

  /* Effects */

  /* Handlers */
  const handleSubmitPortal = async (event, flow) => {
    event.preventDefault();

    const returnPath = "settings/subscriptions";

    portal.mutate({
      returnPath,
      enterpriseId,
      queryParam: {
        flow,
      },
    });
  };

  const handleSubmitCheckout = async (event) => {
    event.preventDefault();

    const priceId = price.id;
    const successPath = "payments/complete";
    const cancelPath = "settings/subscriptions";

    checkout.mutate({
      enterpriseId,
      priceId,
      successPath,
      cancelPath,
    });
  };

  const handleSubmitDefaultSubscription = async (event) => {
    event.preventDefault();

    defaultSubscription.mutate({
      enterpriseId,
    });
  };

  /* Derived */
  const isLoading = configuration.isLoading || currentSubscription.isLoading;
  const subscriptionName = displayedSubscription?.name;
  const priceAmount = displayedSubscription?.price?.amount / 100;
  const currency = "€"; //TODO: manage different currency
  const trialDays = displayedSubscription?.trialDays || 0;
  const status = displayedSubscription?.status;
  const cancelAt = displayedSubscription?.cancelAt;
  const isPaymentMethodSet = displayedSubscription?.isPaymentMethodSet;

  const { submitWording, submitFunction, extraWording } = (() => {
    const trialWording = (() => {
      if (!(status === "trialing")) return "";
      if (trialDays < 0) {
        return "Votre essai gratuit est terminé";
      }

      if (trialDays < 1) {
        return "Votre essai gratuit se termine aujourd'hui";
      }

      return `Votre essai gratuit se termine dans ${trialDays} ${
        trialDays <= 1 ? "jour" : "jours"
      }`;
    })();

    if (subscription && subscription.subscriptionId === "") {
      return {
        submitWording: "Je m'abonne",
        submitFunction: (event) =>
          handleSubmitPortal(event, "payment_method_update"),
        extraWording:
          "Un problème est survenu. Notre équipe support a été notifiée",
      };
    }

    if (status === "none") {
      return {
        submitWording: "Activer mon essai gratuit",
        submitFunction: handleSubmitDefaultSubscription,
      };
    } else if ((status === "active" || status === "trialing") && cancelAt) {
      const cancelWording = (() => {
        if (cancelAt) {
          return `Votre abonnement a été résilié et se termine le ${new Date(
            cancelAt * 1000
          ).toLocaleDateString()}`;
        }

        return null;
      })();

      return {
        submitWording: "Réactiver mon abonnement",
        submitFunction: handleSubmitPortal,
        extraWording: cancelWording,
      };
    } else if (!isPaymentMethodSet) {
      return {
        submitWording: "Je m'abonne",
        submitFunction: (event) =>
          handleSubmitPortal(event, "payment_method_update"),
        extraWording: trialWording,
      };
    } else if (status === "paused") {
      return {
        submitWording: "Je m'abonne",
        submitFunction: (event) =>
          handleSubmitPortal(event, "payment_method_update"),
        extraWording:
          "Votre essai gratuit est terminé. Un moyen de paiement est requis pour activer l'abonnement",
      };
    } else if (status === "active") {
      return {
        submitWording: "Gérer mon abonnement",
        submitFunction: handleSubmitPortal,
      };
    } else if (
      status === "incomplete" ||
      status === "past_due" ||
      status === "unpaid"
    ) {
      return {
        submitWording: "Compléter mon abonnement",
        submitFunction: handleSubmitPortal,
        extraWording: "Un paiement est en attente d'action",
      };
    } else if (status === "incomplete_expired") {
      return {
        submitWording: "Je m'abonne",
        submitFunction: handleSubmitCheckout,
        extraWording:
          "Votre précédent abonnement est inactif suite à un problème de paiement",
      };
    } else if (status === "canceled") {
      return {
        submitWording: "Je m'abonne",
        submitFunction: handleSubmitCheckout,
        extraWording: "Votre précédent abonnement est annulé",
      };
    }

    return {
      submitWording: "Gérer mon abonnement",
      submitFunction: handleSubmitPortal,
      extraWording: trialWording,
    };
  })();

  /* Render */
  return (
    <div className="flex flex-col gap-8 pt-12 max-w-[768px] mx-auto pb-32">
      <div>
        <SettingsHeader
          title="Informations d'abonnement"
          subTitle="Gérez votre souscription à Lidap"
        />
        <div className="w-full border-t border-grey-400"></div>
      </div>

      {isLoading ? (
        <CenteredLoadingBlock />
      ) : (
        <SubscriptionCard
          key={price.id}
          onClick={submitFunction}
          isLoading={
            status === "none"
              ? defaultSubscription.isLoading
              : checkout.isLoading
          }
          disabled={
            portal.isLoading ||
            defaultSubscription.isLoading ||
            defaultSubscription.isSuccess ||
            !subscription ||
            subscription?.subscriptionId === ""
          }
          subscriptionName={subscriptionName}
          subscriptionPrice={priceAmount}
          subscriptionCurrency={currency}
          submitWording={submitWording}
          extraWording={extraWording}
          subscriptionId={subscription?.subscriptionId}
        />
      )}

      {/* {subscription && subscription.status === "active" && ( */}
      {subscription && subscription.subscriptionId !== "" && (
        <CustomerPortal
          handleSubmitPortal={handleSubmitPortal}
          portal={portal}
        />
      )}
    </div>
  );
}
