import React from "react";
import loadingGif from "../../assets/Animation/Loading Animation.gif"; // Update the import path to where your GIF is located

function LoadingBlock({ message, tag }) {
  const Tag = tag || "span"; // Fallback to 'span' if no tag is provided

  return (
    <div className="flex items-center justify-center">
      <div className="w-16 opacity-25">
        <img src={loadingGif} alt="Loading..." />{" "}
      </div>
      {/* <Tag style={{ backgroundColor: "white" }}>
        {message || "Chargement..."}{" "}
      </Tag> */}
    </div>
  );
}

export default LoadingBlock;
