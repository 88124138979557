import ButtonTailwind from "@/components/token/button/buttonTailwind";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export default function CustomerPortal({ handleSubmitPortal, portal }) {
  return (
    <form onSubmit={handleSubmitPortal}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-[0.25rem]">
          <p className="font-bold">
            Afficher et gérer vos informations de facturation
          </p>
          <p>
            {
              "Affichez et modifiez vos informations de facturation, ainsi qu'annulez votre abonnement."
            }
          </p>
        </div>

        <ButtonTailwind
          iconEnd={<ArrowTopRightOnSquareIcon className="h-4 w-4 text-black" />}
          type="submit"
          style="w-max"
          variant="outlined2"
          loading={portal.isLoading}
        >
          Portail de facturation
        </ButtonTailwind>
      </div>
    </form>
  );
}
