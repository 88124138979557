import React from "react";

const EllipsisMenu = ({ onClick, children }) => (
  <div>
    <button
      className="flex items-center gap-2 p-2 rounded-r-md hover:bg-gray-100 dark:hover:bg-neutral-900"
      onClick={onClick}
    >
      •••
    </button>
    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
      {children}
    </div>
  </div>
);

export default EllipsisMenu;
