import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ory } from "./useOryLogin";
import FetchError from "@/utils/FetchError";
import { manageAxiosError } from "@/utils/manageAxiosError";

async function getVerificationFlow() {
  const { data: verificationFlow } = await ory.createBrowserVerificationFlow(
    {
      returnTo: "",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  // Submit the login form
  const attributes = verificationFlow.ui.nodes.find(
    (node) => node.group === "default"
  )?.attributes;
  const csrf_token = attributes.name === "csrf_token" ? attributes.value : null;
  return { verificationFlow, csrf_token };
}

export function useCreateOryEmailVerificationFlow(onSuccess) {
  const action = useMutation({
    mutationFn: async () => await getVerificationFlow(),
    onSuccess: async (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
  });

  return action;
}

export function useGetOryEmailVerificationFlow(queryParam, reactQueryOptions) {
  const { isLoading, isError, data, error, isFetching } = useQuery({
    queryKey: ["ory-verification-flow", queryParam],
    queryFn: async () => {
      try {
        const response = await ory.getVerificationFlow(
          {
            id: queryParam.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const attributes = response?.data?.ui?.nodes?.find(
          (node) => node.group === "default"
        )?.attributes;
        const csrf_token =
          attributes?.name === "csrf_token" ? attributes?.value : null;
        return { response, csrf_token };
      } catch (error) {
        if (process.env.ENV === "development") {
          console.error(error);
        }

        manageAxiosError(
          error,
          "Impossible de récupérer le flux de vérification de l'email",
          "AUTHEXT002",
          "ory-verification-flow"
        );
      }
    },
    ...reactQueryOptions,
  });

  return {
    isLoading: isLoading,
    isError: isError,
    data: data,
    error: error,
    isFetching: isFetching,
  };
}

async function handleOryEmailVerification({ flowId, code, email, csrf_token }) {
  const payload = (() => {
    if (email) return { method: "code", email: email, csrf_token: csrf_token };
    return { method: "code", code: code, csrf_token: csrf_token };
  })();

  const { data } = await ory.updateVerificationFlow(
    {
      flow: flowId,
      updateVerificationFlowBody: {
        ...payload,
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const errors = data.ui.messages?.find((message) => message.type === "error");
  if (errors) {
    throw new FetchError(
      errors.text,
      400,
      "AUTHEXT003",
      undefined,
      "ory-verification-flow"
    );
  }
  return data;
}

export function useOryEmailVerification(
  queryKeyToInvalidate,
  multipleQueryKeysToInvalidate,
  onSuccess
) {
  const queryClient = useQueryClient();

  const action = useMutation({
    mutationFn: async ({ flowId, code, email, csrf_token }) => {
      await handleOryEmailVerification({ flowId, code, email, csrf_token });
      /* If the email was provided in input, the action was a resend of the code, not a verification */
      return {
        resent: email ? true : false,
      };
    },
    onSuccess: (data) => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
          refetchType: "all",
        });
      }
      if (
        multipleQueryKeysToInvalidate &&
        multipleQueryKeysToInvalidate.length > 0
      ) {
        multipleQueryKeysToInvalidate.forEach((queryKey) => {
          queryClient.invalidateQueries({
            queryKey: [...queryKey],
          });
        });
      }
      if (onSuccess) {
        onSuccess(data);
      }
    },
  });

  return action;
}
