import { useSubscription } from "@/contexts/UserContext";
import ButtonTailwind from "./buttonTailwind";
import RequireSubscriptionModal from "../modals/RequireSubscriptionModal";
import { useState } from "react";

export default function RequireSubscriptionButton({
  onClick,
  onWaiveSubscriptionModal,
  children,
  ...props
}) {
  const { isActive } = useSubscription();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    if (!isActive) {
      setIsModalOpen(true);
    } else {
      onClick();
    }
  };

  return (
    <>
      {isModalOpen && (
        <RequireSubscriptionModal
          className="z-50"
          onWaive={onWaiveSubscriptionModal}
          onBack={() => setIsModalOpen(false)}
        />
      )}
      <ButtonTailwind onClick={handleButtonClick} {...props}>
        {children}
      </ButtonTailwind>
    </>
  );
}
