import React, { useState } from "react";
import SettingsHeader from "@/components/token/settingsHeader/SettingsHeader";
import InputTailwind from "@/components/token/Input/inputTailwind";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import "@/assets/css/Company.css";
import { useEnterprises } from "@/hooks/useEnterprises";
import { useUser } from "@/contexts/UserContext";
import { notify } from "@/utils/notify";
import { PostalCode } from "@/components/elements/stakeholder/address/PostalCode";
import { useCreateUpdatePhone } from "@/hooks/useCreateUpdatePhone";
import { useCreateUpdateIdentifier } from "@/hooks/useCreateUpdateIdentifier";
import { useCreateUpdateEmail } from "@/hooks/useCreateUpdateEmail";
import { useCreateUpdateAddress } from "@/hooks/useCreateUpdateAddress";
import { Identifier } from "@/components/elements/stakeholder/identifier/Identifier";
import { useIdentifiers } from "@/hooks/useIdentifiers";
import { useAddresses } from "@/hooks/useAddresses";
import { usePhones } from "@/hooks/usePhones";
import { useEmails } from "@/hooks/useEmails";
import { StreetName } from "@/components/elements/stakeholder/address/StreetName";
import { City } from "@/components/elements/stakeholder/address/City";
import { Country } from "@/components/elements/stakeholder/address/Country";
import { Email } from "@/components/elements/stakeholder/contact/Email";
import { Phone } from "@/components/elements/stakeholder/contact/Phone";
import { useEffect } from "react";
import { useCreateUpdateEnterprises } from "@/hooks/useCreateUpdateEnterprises";

export default function CompanySettings() {
  /* Context */
  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;

  /* Persisted data */
  const enterprise = useEnterprises({
    enterpriseId,
    reactQueryOptions: {
      refetchOnWindowFocus: false,
    },
  });
  const identifiers = useIdentifiers({
    stakeholderId: enterpriseId,
    reactQueryOptions: {
      refetchOnWindowFocus: false,
    },
  });
  const addresses = useAddresses({
    stakeholderId: enterpriseId,
    reactQueryOptions: {
      refetchOnWindowFocus: false,
    },
  });
  const phones = usePhones({
    stakeholderId: enterpriseId,
    reactQueryOptions: {
      refetchOnWindowFocus: false,
    },
  });
  const emails = useEmails({
    stakeholderId: enterpriseId,
    reactQueryOptions: {
      refetchOnWindowFocus: false,
    },
  });

  /* Mutation handlers */
  const handlePhone = useCreateUpdatePhone(["phones"], undefined, () =>
    setPhoneForUpdate({ ...phoneForUpdate, isDirty: false })
  );
  const handleIdentifier = useCreateUpdateIdentifier(
    ["identifiers"],
    undefined,
    () =>
      setCompanyNumberForUpdate({ ...companyNumberForUpdate, isDirty: false })
  );
  const handleVat = useCreateUpdateIdentifier(["identifiers"], undefined, () =>
    setVatNumberForUpdate({ ...vatNumberForUpdate, isDirty: false })
  );
  const handleEmail = useCreateUpdateEmail(["emails"], undefined, () =>
    setEmailForUpdate({ ...emailForUpdate, isDirty: false })
  );
  const handleAddress = useCreateUpdateAddress(["addresses"], undefined, () =>
    setAddressForUpdate({ ...addressForUpdate, isDirty: false })
  );
  const handleEnterprise = useCreateUpdateEnterprises(
    ["enterprises"],
    undefined,
    () => {
      setCompanyName({ ...companyName, isDirty: false });
      setCurrency({ ...currency, isDirty: false });
    }
  );

  /* State */
  /* Company main data */
  const [companyName, setCompanyName] = useState(null);
  useEffect(() => {
    if (enterprise.data && !companyName) {
      setCompanyName({
        data: enterprise.data.payload.name,
        success: false,
        isDirty: false,
      });
    }
  }, [enterprise.data, companyName]);

  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState(null);
  useEffect(() => {
    if (enterprise.data && !currency) {
      setCurrency({
        data: enterprise.data.payload.currency,
        success: false,
        isDirty: false,
      });
    }
  }, [enterprise.data, currency]);

  /* Identifier */
  const countryIdentifierType = (country) => {
    if (country === "BE") {
      return "COMPANY_NUMBER";
    } else if (country === "FR") {
      return "SIREN";
    } else {
      return "COMPANY_NUMBER";
    }
  };
  const [companyNumberForUpdate, setCompanyNumberForUpdate] = useState(null);
  useEffect(() => {
    if (identifiers.data && enterprise.data && !companyNumberForUpdate) {
      const retrievedIdentifiers =
        identifiers.data.payload.stakeholderIdentifiers;
      const cn = retrievedIdentifiers.find(
        (identifier) =>
          identifier.identifierType ===
            countryIdentifierType(enterprise.data.payload.country) &&
          identifier.countryOrRegion === enterprise.data.payload.country
      );
      setCompanyNumberForUpdate({
        data: cn,
        success: false,
        isDirty: false,
      });
    }
  }, [identifiers.data, companyNumberForUpdate, enterprise.data]);

  const [vatNumberForUpdate, setVatNumberForUpdate] = useState(null);
  useEffect(() => {
    if (identifiers.data && enterprise.data && !vatNumberForUpdate) {
      const retrievedIdentifiers =
        identifiers.data.payload.stakeholderIdentifiers;
      const vat = retrievedIdentifiers.find(
        (identifier) =>
          identifier.identifierType === "VAT" &&
          identifier.countryOrRegion === "EU"
      );
      setVatNumberForUpdate({
        data: vat,
        success: false,
        isDirty: false,
      });
    }
  }, [identifiers.data, vatNumberForUpdate, enterprise.data]);

  /* Address */
  const [addressForUpdate, setAddressForUpdate] = useState(null);
  useEffect(() => {
    if (addresses.data && !addressForUpdate) {
      const retrievedAddresses = addresses.data.payload.addresses;
      const ba = retrievedAddresses.find((address) => address.isMain);
      setAddressForUpdate({
        data: ba,
        success: false,
        isDirty: false,
      });
      setCountry(ba?.country || "BE");
    }
  }, [addresses.data, addressForUpdate]);

  /* Email */
  const [emailForUpdate, setEmailForUpdate] = useState(null);
  useEffect(() => {
    if (emails.data && !emailForUpdate) {
      const retrievedEmails = emails.data.payload.emails;
      const me = retrievedEmails.find((email) => email.isMain);
      setEmailForUpdate({
        data: me,
        success: false,
        isDirty: false,
      });
    }
  }, [emails.data, emailForUpdate]);

  /* Phone */
  const [phoneForUpdate, setPhoneForUpdate] = useState(null);

  useEffect(() => {
    if (phones.data && !phoneForUpdate) {
      const retrievedPhones = phones.data.payload.phones;
      const bp = retrievedPhones.find((phone) => phone.isBillingPhone);
      setPhoneForUpdate({
        data: bp,
        success: false,
        isDirty: false,
      });
    }
  }, [phones.data, phoneForUpdate]);

  /* Data manipulation logic */
  const enterpriseData = (() => {
    if (enterprise.isLoading || enterprise.isError) {
      return {
        companyName: "",
        country: userContext?.state?.user?.country || "BE",
      };
    }

    return {
      enterpriseId: enterprise.data.payload.id,
      companyName: companyName ? companyName : enterprise.data.payload.name,
      currency: currency ? currency : enterprise.data.payload.currency,
      country: country ? country : enterprise.data.payload.country,
    };
  })();

  if (enterprise.isError) {
    notify({
      isSuccess: false,
      message: "Impossible de charger les informations de l'entreprise",
      info: enterprise.error?.message,
    });
  }

  const companyNumber = (() => {
    if (identifiers.isLoading || identifiers.isError) {
      return "";
    }

    const retrievedIdentifiers =
      identifiers.data.payload.stakeholderIdentifiers;
    const cn = retrievedIdentifiers.find(
      (identifier) =>
        identifier.identifierType === "COMPANY_NUMBER" &&
        identifier.countryOrRegion === enterpriseData.country
    );

    if (!cn) {
      return {
        identifier: "",
        identifierType: "COMPANY_NUMBER",
        countryOrRegion: enterpriseData.country,
      };
    }

    return cn;
  })();

  const vatNumber = (() => {
    if (identifiers.isLoading || identifiers.isError) {
      return "";
    }

    const retrievedIdentifiers =
      identifiers.data.payload.stakeholderIdentifiers;
    const vat = retrievedIdentifiers.find(
      (identifier) =>
        identifier.identifierType === "VAT" &&
        identifier.countryOrRegion === "EU"
    );

    if (!vat) {
      return {
        identifier: "",
        identifierType: "VAT",
        countryOrRegion: "EU",
      };
    }

    return vat;
  })();

  const billingAddress = (() => {
    if (addresses.isLoading || addresses.isError) {
      return "";
    }

    const retrievedAddresses = addresses.data.payload.addresses;
    const ba = retrievedAddresses.find((address) => address.isMain);

    if (!ba) {
      return {
        streetName: "",
        country: enterpriseData.country,
        postalCode: "",
        city: "",
        isBillingAddress: true,
        isMain: true,
      };
    }

    return ba;
  })();

  const mainEmail = (() => {
    if (emails.isLoading || emails.isError) {
      return {
        id: "",
        email: "",
      };
    }
    const retrievedEmails = emails.data.payload.emails;
    const me = retrievedEmails.find((email) => email.isMain);

    if (!me) {
      return {
        id: "",
        email: "",
      };
    }

    return me;
  })();

  const billingPhone = (() => {
    if (phones.isLoading || phones.isError) {
      return {
        id: "",
        phoneNumber: "",
        geoCode: "+32",
      };
    }

    const retrievedPhones = phones.data.payload.phones;
    const bp = retrievedPhones.find((phone) => phone.isBillingPhone);

    if (!bp) {
      return {
        id: "",
        phoneNumber: "",
        geoCode: "+32",
      };
    }

    return bp;
  })();

  /* Update button logic */
  const updateCompanyData = () => {
    if (
      (companyName?.isDirty && companyName?.success) ||
      (currency?.isDirty && currency?.success)
    ) {
      const updatedEnterprise = {
        enterpriseType: enterprise.data.payload.enterpriseType,
        currency: currency?.success
          ? currency.data
          : enterprise.data.payload.currency,
        stakeholder: {
          name: companyName?.success
            ? companyName.data
            : enterprise.data.payload.name,
          stakeholderCategory: enterprise.data.payload.stakeholderCategory,
          stakeholderType: enterprise.data.payload.stakeholderType,
          person: {
            legalName: companyName?.data,
          },
        },
      };

      handleEnterprise.mutate({
        enterprise: updatedEnterprise,
        id: enterpriseId,
      });
    }

    if (companyNumberForUpdate?.isDirty && companyNumberForUpdate?.success) {
      handleIdentifier.mutate({
        stakeholderId: enterpriseId,
        identifier: companyNumberForUpdate.data,
        id: companyNumber.id,
      });
    }

    if (vatNumberForUpdate?.isDirty && vatNumberForUpdate?.success) {
      handleVat.mutate({
        stakeholderId: enterpriseId,
        identifier: {
          identifier: `${
            enterpriseData.country
          }${vatNumberForUpdate.data.identifier.replace(/\D/g, "")}`,
          countryOrRegion: "EU",
          identifierType: "VAT",
        },
        id: vatNumber.id,
      });
    }

    if (addressForUpdate?.isDirty && addressForUpdate?.success) {
      const newAddress = {
        isMain: true,
        isBillingAddress: true,
        country: addressForUpdate.data.country
          ? addressForUpdate.data.country
          : billingAddress.country,
        streetName: addressForUpdate.data.streetName
          ? addressForUpdate.data.streetName
          : billingAddress.streetName,
        postalCode: addressForUpdate.data.postalCode
          ? addressForUpdate.data.postalCode
          : billingAddress.postalCode,
        city: addressForUpdate.data.city
          ? addressForUpdate.data.city
          : billingAddress.city,
      };

      handleAddress.mutate({
        stakeholderId: enterpriseId,
        address: newAddress,
        id: billingAddress.id,
      });
    }

    if (emailForUpdate?.isDirty && emailForUpdate?.success) {
      const newEmail = {
        isMain: true,
        isBillingEmail: false,
        email: emailForUpdate.data.email,
      };

      handleEmail.mutate({
        stakeholderId: enterpriseId,
        email: newEmail,
        id: mainEmail.id,
      });
    }

    if (phoneForUpdate?.isDirty && phoneForUpdate?.success) {
      const newPhone = {
        isMain: true,
        isBillingPhone: true,
        phoneNumber: parseInt(
          phoneForUpdate.data.phoneNumber.replace(/\D/g, "")
        ),
        geoCode: phoneForUpdate.data.geoCode,
      };

      handlePhone.mutate({
        stakeholderId: enterpriseId,
        phone: newPhone,
        id: billingPhone.id,
      });
    }
  };

  /* State of mutations */
  const isUpdateOngoing =
    handleIdentifier.isLoading ||
    handleVat.isLoading ||
    handleAddress.isLoading ||
    handleEmail.isLoading ||
    handlePhone.isLoading ||
    handleEnterprise.isLoading;

  /* Error handling */
  if (handleEnterprise.isError) {
    notify({
      isSuccess: false,
      message:
        "Impossible de mettre à jour le nom et la monnaie de l'entreprise",
      info: handleEnterprise.error?.message,
    });

    handleEnterprise.reset();
  }

  if (handleIdentifier.isError) {
    notify({
      isSuccess: false,
      message: "Impossible de mettre à jour le numéro d'entreprise",
      // info: handleIdentifier.error?.message,
    });
  }

  if (handleVat.isError) {
    notify({
      isSuccess: false,
      message: "Impossible de mettre à jour le numéro de TVA",
      // info: handleVat.error?.message,
    });
  }

  if (handleAddress.isError) {
    notify({
      isSuccess: false,
      message: "Impossible de mettre à jour l'adresse",
      info: handleAddress.error?.message,
    });
  }

  if (handleEmail.isError) {
    notify({
      isSuccess: false,
      message: "Impossible de mettre à jour l'adresse e-mail",
      info: handleEmail.error?.message,
    });
  }

  if (handlePhone.isError) {
    notify({
      isSuccess: false,
      message: "Impossible de mettre à jour le numéro de téléphone",
      info: handlePhone.error?.message,
    });
  }

  /* Success handling */
  if (handleIdentifier.isSuccess) {
    notify({
      isSuccess: true,
      message: "Numéro d'entreprise mis à jour",
    });
    handleIdentifier.reset();
  }

  if (handleVat.isSuccess) {
    notify({
      isSuccess: true,
      message: "Numéro de TVA mis à jour",
    });
    handleVat.reset();
  }

  if (handleAddress.isSuccess) {
    notify({
      isSuccess: true,
      message: "Adresse mise à jour",
    });
    handleAddress.reset();
  }

  if (handleEmail.isSuccess) {
    notify({
      isSuccess: true,
      message: "Adresse e-mail mise à jour",
    });
    handleEmail.reset();
  }

  if (handlePhone.isSuccess) {
    notify({
      isSuccess: true,
      message: "Numéro de téléphone mis à jour",
    });
    handlePhone.reset();
  }

  if (handleEnterprise.isSuccess) {
    notify({
      isSuccess: true,
      message: "Information d'entreprise mise à jour",
    });
    handleEnterprise.reset();
  }

  const isDataLoading =
    enterprise.isLoading ||
    identifiers.isLoading ||
    addresses.isLoading ||
    phones.isLoading ||
    emails.isLoading;

  /* Return JSX */
  return (
    <div className="mt-12 mb-24 flex flex-col gap-8 w-full max-w-[768px] mx-auto p-8 bg-white rounded-lg h-max shadow-sm">
      <div>
        <SettingsHeader
          title="Informations de l'entreprise"
          subTitle="Gérez les informations affichées sur vos factures"
        />

        <div className="w-full border-t border-grey-400"></div>
      </div>

      <form id="society-form">
        <div id="society-first-inputs-panel">
          <InputTailwind
            label="Nom de la société"
            type="text"
            placeholder="Spacefox"
            value={companyName?.data}
            onChange={(e) =>
              setCompanyName((prev) => ({
                ...prev,
                data: e.target.value,
                success: true,
                isDirty: true,
              }))
            }
            loading={companyName ? false : isDataLoading}
            required={true}
          ></InputTailwind>
          <div className="aligned-inputs">
            <Email
              initialEmail={emailForUpdate?.data?.email}
              disabled={handleEmail.isLoading}
              onChange={(email) => {
                setEmailForUpdate((prev) => ({
                  ...prev,
                  data: { ...prev?.data, email: email.data },
                  success: email.success,
                  isDirty: email.isDirty,
                }));
              }}
              isLoading={isDataLoading || (emails.data && !emailForUpdate)}
              required={
                emails?.data?.payload?.emails?.length > 0 ? true : false
              }
              name="email"
            />
            <Phone
              initialPhone={phoneForUpdate?.data}
              disabled={handlePhone.isLoading}
              onChange={setPhoneForUpdate}
              isLoading={isDataLoading || (phones.data && !phoneForUpdate)}
              required={billingPhone.phoneNumber ? true : false}
              name="phone"
              contextCountry={enterpriseData.country}
            />
          </div>

          <Identifier
            identifierType={
              companyNumberForUpdate?.data?.identifierType || "COMPANY_NUMBER"
            }
            countryOrRegion={
              companyNumberForUpdate?.data?.countryOrRegion ||
              enterpriseData.country
            }
            initialIdentifier={companyNumberForUpdate?.data?.identifier}
            disabled={handleIdentifier.isLoading}
            onChange={setCompanyNumberForUpdate}
            isLoading={
              isDataLoading || (identifiers.data && !companyNumberForUpdate)
            }
            name={"companyNumber"}
            required={companyNumberForUpdate?.isDirty ? true : false}
            forceFormatting={true}
          />

          <Identifier
            identifierType={"VAT_NUMBER"}
            countryOrRegion={enterpriseData.country}
            initialIdentifier={vatNumberForUpdate?.data?.identifier || ""}
            disabled={handleIdentifier.isLoading}
            onChange={setVatNumberForUpdate}
            isLoading={
              isDataLoading || (identifiers.data && !vatNumberForUpdate)
            }
            name={"vatNumber"}
            required={vatNumberForUpdate?.isDirty ? true : false}
            forceFormatting={true}
            placeholder="Numéro de TVA"
          />
        </div>

        <div className="w-full border-t border-grey-400"></div>

        <div className="flex flex-col gap-3">
          <div className="settingsSelect">
            <Country
              initialCountry={country}
              disabled={true}
              onChange={(c) => {
                setCountry(c);
              }}
              isLoading={isDataLoading}
              name="billingCountry"
              info={
                <span className="mt-2 text-sm text-neutral-400">
                  Pour changer de pays, veuillez contacter le support à
                  l&apos;adresse{" "}
                  <a
                    style={{ color: "#E16D64" }}
                    href="mailto:support@lidap.io"
                  >
                    support@lidap.io
                  </a>
                </span>
              }
            />
          </div>
          {/* <Currency
            initialCurrency={currency?.data}
            disabled={false}
            onChange={(c) => {
              setCurrency(c.data);
            }}
            isLoading={enterprise.isLoading}
            name="currency"
            currencyList={currencies}
            required={true}
          /> */}

          <StreetName
            initialStreetName={addressForUpdate?.data?.streetName}
            disabled={handleAddress.isLoading}
            onChange={(sn) => {
              setAddressForUpdate((prev) => ({
                ...prev,
                data: { ...prev?.data, streetName: sn.data },
                success: sn.success,
                isDirty: sn.isDirty,
              }));
            }}
            isLoading={isDataLoading}
            name="billingStreetName"
            required={true}
          />
          <div className="aligned-inputs">
            <City
              initialCity={addressForUpdate?.data?.city}
              disabled={handleAddress.isLoading}
              onChange={(city) => {
                setAddressForUpdate((prev) => ({
                  ...prev,
                  data: { ...prev?.data, city: city.data },
                  success: city.success,
                  isDirty: city.isDirty,
                }));
              }}
              isLoading={isDataLoading}
              name="billingStreetName"
              required={true}
            />
            <PostalCode
              country={addressForUpdate?.data?.country}
              initialPostalCode={addressForUpdate?.data?.postalCode}
              disabled={handleAddress.isLoading}
              onChange={(pc) => {
                setAddressForUpdate((prev) => ({
                  ...prev,
                  data: { ...prev?.data, postalCode: pc.data },
                  success: pc.success,
                  isDirty: pc.isDirty && prev?.data?.postalCode !== pc.data,
                }));
              }}
              isLoading={isDataLoading}
              name="billingPostalCode"
              required={true}
            />
          </div>
        </div>

        <div className="w-min mt-1">
          <ButtonTailwind
            variant="outlined2"
            disabled={
              !(
                (companyName?.isDirty && companyName?.success) ||
                (currency?.isDirty && currency?.success) ||
                (companyNumberForUpdate?.isDirty &&
                  companyNumberForUpdate?.success) ||
                (addressForUpdate?.isDirty && addressForUpdate?.success) ||
                (emailForUpdate?.isDirty && emailForUpdate?.success) ||
                (phoneForUpdate?.isDirty && phoneForUpdate?.success) ||
                (vatNumberForUpdate?.isDirty &&
                  vatNumberForUpdate?.success &&
                  (vatNumberForUpdate?.data?.identifier || !vatNumber))
              )
            }
            onClick={updateCompanyData}
            loading={isUpdateOngoing}
          >
            Mettre à jour
          </ButtonTailwind>
        </div>
      </form>
    </div>
  );
}
