import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { stringify } from "@/utils/stringify";
import Lidapi, { manageLidapResponse } from "@/utils/lidapi";

export function getCheckoutSession({ sessionId, queryParam }) {
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  return {
    queryKey: ["stripe", "checkout", { sessionId }, queryParam],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/payments/stripe/checkout/${sessionId}${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return manageLidapResponse(response, "Could not load checkout session");
    },
  };
}

export function useCheckoutSession({
  sessionId,
  queryParam,
  reactQueryConfig,
}) {
  const queryClient = useQueryClient();
  const { queryFn, queryKey } = getCheckoutSession({ sessionId, queryParam });
  const query = useQuery(queryKey, {
    queryFn: queryFn,
    initialData: () => {
      const dataFromCache = queryClient.getQueryData(queryKey);
      return dataFromCache;
    },
    ...reactQueryConfig,
  });

  return query;
}

export function getStripeConfiguration({ queryParam }) {
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  return {
    queryKey: ["stripe", "config", queryParam],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/payments/stripe/config${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return manageLidapResponse(
        response,
        "Could not load Stripe configuration"
      );
    },
  };
}

export function useStripeConfiguration({ queryParam, reactQueryConfig }) {
  const queryClient = useQueryClient();
  const { queryFn, queryKey } = getStripeConfiguration({ queryParam });
  const query = useQuery(queryKey, {
    queryFn: queryFn,
    initialData: () => {
      const dataFromCache = queryClient.getQueryData(queryKey);
      return dataFromCache;
    },
    ...reactQueryConfig,
  });

  return query;
}

export function getCurrentSubscription({ enterpriseId, queryParam }) {
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  return {
    queryKey: ["stripe", "subscription", { enterpriseId }, queryParam],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/payments/stripe/${enterpriseId}/subscriptions${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return manageLidapResponse(
        response,
        "Could not load current subscription"
      );
    },
  };
}

export function useCurrentSubscription({
  enterpriseId,
  queryParam,
  reactQueryConfig,
}) {
  const { queryFn, queryKey } = getCurrentSubscription({
    enterpriseId,
    queryParam,
  });
  const query = useQuery(queryKey, {
    queryFn: queryFn,
    ...reactQueryConfig,
  });

  return query;
}

export function useCreateCheckoutSession(
  queryKeyToInvalidate,
  multipleQueryKeysToInvalidate,
  onSuccess,
  onError
) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async ({ enterpriseId, priceId, successPath, cancelPath }) =>
      await api.createCheckoutSession({
        enterpriseId,
        priceId,
        successPath,
        cancelPath,
      }),
    onSuccess: (data) => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (
        multipleQueryKeysToInvalidate &&
        multipleQueryKeysToInvalidate.length > 0
      ) {
        multipleQueryKeysToInvalidate.forEach((queryKey) => {
          queryClient.invalidateQueries({
            queryKey: [...queryKey],
          });
        });
      }
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    },
  });

  return action;
}

export function useCreateCustomerPortal(
  queryKeyToInvalidate,
  multipleQueryKeysToInvalidate,
  onSuccess,
  onError
) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async ({ returnPath, enterpriseId, queryParam }) => {
      const queryString = queryParam ? `?${stringify(queryParam)}` : "";
      return await api.createCustomerPortal({
        body: { returnPath },
        params: { enterpriseId },
        queryString,
      });
    },
    onSuccess: (data) => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (
        multipleQueryKeysToInvalidate &&
        multipleQueryKeysToInvalidate.length > 0
      ) {
        multipleQueryKeysToInvalidate.forEach((queryKey) => {
          queryClient.invalidateQueries({
            queryKey: [...queryKey],
          });
        });
      }
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    },
  });

  return action;
}

export function useCreateDefaultSubscription(
  queryKeyToInvalidate,
  multipleQueryKeysToInvalidate,
  onSuccess,
  onError
) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async ({ enterpriseId }) =>
      await api.createDefaultSubscription({ enterpriseId }),
    onSuccess: (data) => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (
        multipleQueryKeysToInvalidate &&
        multipleQueryKeysToInvalidate.length > 0
      ) {
        multipleQueryKeysToInvalidate.forEach((queryKey) => {
          queryClient.invalidateQueries({
            queryKey: [...queryKey],
          });
        });
      }
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    },
  });

  return action;
}

export function useCreateDefaultSubscriptionByAdmin(
  queryKeyToInvalidate,
  multipleQueryKeysToInvalidate,
  onSuccess,
  onError
) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async ({ enterpriseId }) =>
      await api.createDefaultSubscriptionByAdmin({ enterpriseId }),
    onSuccess: (data) => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (
        multipleQueryKeysToInvalidate &&
        multipleQueryKeysToInvalidate.length > 0
      ) {
        multipleQueryKeysToInvalidate.forEach((queryKey) => {
          queryClient.invalidateQueries({
            queryKey: [...queryKey],
          });
        });
      }
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    },
  });

  return action;
}

export function useResyncSubscription(
  queryKeyToInvalidate,
  multipleQueryKeysToInvalidate,
  onSuccess,
  onError
) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async ({ enterpriseId }) =>
      await api.resyncSubscription({ enterpriseId }),
    onSuccess: (data) => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (
        multipleQueryKeysToInvalidate &&
        multipleQueryKeysToInvalidate.length > 0
      ) {
        multipleQueryKeysToInvalidate.forEach((queryKey) => {
          queryClient.invalidateQueries({
            queryKey: [...queryKey],
          });
        });
      }
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    },
  });

  return action;
}

export function getSubscriptionList({ queryParam, disableToast }) {
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";
  const api = new Lidapi(disableToast);

  return {
    queryKey: ["admin", "payments", "stripe", "subscriptions", queryParam],
    queryFn: () => api.getSubscriptionList({ queryString }),
  };
}

export function useSubscriptionList({
  queryParam,
  reactQueryConfig,
  disableToast,
}) {
  const queryClient = useQueryClient();
  const { queryFn, queryKey } = getSubscriptionList({
    queryParam,
    disableToast,
  });

  const query = useQuery(queryKey, {
    queryFn: queryFn,
    initialData: () => {
      const dataFromCache = queryClient.getQueryData(queryKey);
      return dataFromCache;
    },
    ...reactQueryConfig,
  });

  return query;
}
