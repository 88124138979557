/* eslint-disable no-irregular-whitespace */

import { uniqueChars } from "@/utils/uniqueChars";

/* eslint-disable no-useless-escape */
export function useTextUtf8() {
  const regexp =
    /([\p{L}0-9\.\\\/+!?@_()=',{}\[\]<>\$€:&'‘’“”`´"%;:*~#°^•  –|‚„…†‡‰‹—˜™›¡¢£¤¥¦§¨©«¬­®¯±²³¶·¸¹»¼½¾¿×÷\n-]+)/gu;

  return regexp;
}

/**
 * This hook returns a function that allows validating against a given RegExp.
 * If the setErrorValue parameter is provided, it sets the error value.
 * Otherwise, the setInputValue is used as a single function with two parameters, the
 * value and the error.
 * @param {*} setInputValue
 * @param {*} setErrorValue
 * @returns
 */
export function useInputValidation({ setInputValue, setErrorValue }) {
  const handleChange = (e, regexp) => {
    const value = e.target.value;
    const invalid = uniqueChars(value?.replace(regexp, ""));
    const error = invalid
      ? `Caractères spéciaux non autorisés: ${invalid}`
      : "";

    if (setErrorValue) {
      setErrorValue(error);
      setInputValue(value);
      return;
    }

    setInputValue(value, error);
  };

  return handleChange;
}
