import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ory } from "./useOryLogin";
import FetchError from "@/utils/FetchError";
import { manageAxiosError } from "@/utils/manageAxiosError";
import {
  getFirstOryError,
  getOryGlobalError,
  getOryResponseStatus,
} from "@/utils/ory.frontend";
import { OryErrors } from "@/utils/ory/ory.errors";
import { faro } from "@/AppRouter";

export const OryRecoveryErrors = {
  ORY_INVALID_RECOVERY_CODE: "AUTHEXT012",
};

async function getRecoveryFlow() {
  const { data: recoveryFlow } = await ory.createBrowserRecoveryFlow(
    {
      returnTo: "",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  // Submit the login form
  const attributes = recoveryFlow.ui.nodes.find(
    (node) => node.group === "default"
  )?.attributes;
  const csrf_token = attributes.name === "csrf_token" ? attributes.value : null;
  return { recoveryFlow, csrf_token };
}

export function useCreateOryRecoveryFlow(onSuccess) {
  const action = useMutation({
    mutationFn: async () => await getRecoveryFlow(),
    onSuccess: async (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
  });

  return action;
}

export function useGetOryRecoveryFlow(queryParam, reactQueryOptions) {
  const { isLoading, isError, data, error, isFetching } = useQuery({
    queryKey: ["ory-recovery-flow", queryParam],
    queryFn: async () => {
      try {
        const response = await ory.getRecoveryFlow(
          {
            id: queryParam.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const attributes = response?.data?.ui?.nodes?.find(
          (node) => node.group === "default"
        )?.attributes;
        const csrf_token =
          attributes?.name === "csrf_token" ? attributes?.value : null;
        return { response, csrf_token };
      } catch (error) {
        if (process.env.ENV === "development") {
          console.error(error);
        }

        manageAxiosError(
          error,
          "Impossible de récupérer le flux de récupération de compte",
          "AUTHEXT008",
          "ory-recovery-flow"
        );
      }
    },
    ...reactQueryOptions,
  });

  return {
    isLoading: isLoading,
    isError: isError,
    data: data,
    error: error,
    isFetching: isFetching,
  };
}

async function handleOryRecovery({ flowId, code, email, csrf_token }) {
  const payload = (() => {
    if (email) return { method: "code", email: email, csrf_token: csrf_token };
    return { method: "code", code: code, csrf_token: csrf_token };
  })();

  try {
    const { data } = await ory.updateRecoveryFlow(
      {
        flow: flowId,
        updateRecoveryFlowBody: {
          ...payload,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const errorMessage = data.ui.messages.find(
      (message) => message.type === "error"
    );
    if (errorMessage) {
      if (errorMessage.id === OryErrors.INVALID_RECOVERY_CODE) {
        const fetchError = new FetchError(
          "Code de récupération invalide",
          400,
          OryRecoveryErrors.ORY_INVALID_RECOVERY_CODE,
          undefined,
          "ory-recovery-flow",
          true
        );

        faro.api.pushError(fetchError, {
          type: "ory",
        });

        throw fetchError;
      }

      const fetchError = new FetchError(
        "Impossible de récupérer le compte",
        500,
        "AUTHEXT011",
        undefined,
        "ory-recovery-flow"
      );

      faro.api.pushError(fetchError, {
        type: "ory",
      });

      throw fetchError;
    }

    return data;
  } catch (error) {
    if (process.env.ENV === "development") {
      console.error(error);
    }

    if (error instanceof FetchError) {
      throw error;
    }

    const globalError = getOryGlobalError(error);
    if (globalError) {
      //TODO!: fix it once Ory provides the answer
      if (globalError.id === "browser_location_change_required") {
        Promise.resolve();
        return;
      }

      const fetchError = new FetchError(
        `${globalError.message}: ${globalError.reason}`,
        status,
        "AUTHEXT009",
        undefined,
        "ory-recovery-flow",
        false
      );

      faro.api.pushError(fetchError, {
        type: "ory",
      });

      throw fetchError;
    }

    const firstError = getFirstOryError(error);
    const status = getOryResponseStatus(error);

    if (firstError) {
      const fetchError = new FetchError(
        firstError.text,
        status,
        "AUTHEXT010",
        undefined,
        "ory-recovery-flow",
        false
      );

      faro.api.pushError(fetchError, {
        type: "ory",
      });

      throw fetchError;
    }

    const fetchError = new FetchError(
      "Impossible de récupérer le compte",
      status,
      "AUTHEXT011",
      undefined,
      "ory-recovery-flow"
    );

    faro.api.pushError(fetchError, {
      type: "ory",
    });

    throw fetchError;
  }
}

export function useOryRecovery(
  queryKeyToInvalidate,
  multipleQueryKeysToInvalidate,
  onSuccess,
  onError
) {
  const queryClient = useQueryClient();

  const action = useMutation({
    mutationFn: async ({ flowId, code, email, csrf_token }) => {
      await handleOryRecovery({ flowId, code, email, csrf_token });
      /* If the email was provided in input, the action was a resend of the code, not a verification */
      return {
        resent: email ? true : false,
      };
    },
    onSuccess: (data) => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (
        multipleQueryKeysToInvalidate &&
        multipleQueryKeysToInvalidate.length > 0
      ) {
        multipleQueryKeysToInvalidate.forEach((queryKey) => {
          queryClient.invalidateQueries({
            queryKey: [...queryKey],
          });
        });
      }
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });

  return action;
}
