// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Wrap */
#wrap-feature-welcome-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/* Contenu texte, bouton, illustration */
#feature-welcome-screen {
  display: flex;
  align-items: center;
  gap: 4rem;

  width: 100%;
  max-width: 960px;
  padding: 4rem;
}
#welcome-screen-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
}

#welcome-screen-content-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/Presentation.css"],"names":[],"mappings":"AAAA,SAAS;AACT;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd;;AAEA,wCAAwC;AACxC;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;;EAET,WAAW;EACX,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,WAAW;AACb","sourcesContent":["/* Wrap */\n#wrap-feature-welcome-screen {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n}\n\n/* Contenu texte, bouton, illustration */\n#feature-welcome-screen {\n  display: flex;\n  align-items: center;\n  gap: 4rem;\n\n  width: 100%;\n  max-width: 960px;\n  padding: 4rem;\n}\n#welcome-screen-content {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n  gap: 1rem;\n}\n\n#welcome-screen-content-text {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
