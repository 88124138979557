// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --width-vh: 50vh; /* Set the desired width as a percentage of the viewport height */
}

#invoice-page-box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  display: flex;
  background-color: var(--background-color);
  height: 100vh;
  width: 100vw;
}

.invoice-creation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#invoice-creation-box-preview {
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  padding: 4rem 0 8rem 0;
  overflow-y: scroll;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/Invoice.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAE,iEAAiE;AACrF;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,yCAAyC;EACzC,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;;EAEb,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":[":root {\n  --width-vh: 50vh; /* Set the desired width as a percentage of the viewport height */\n}\n\n#invoice-page-box {\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 15;\n  display: flex;\n  background-color: var(--background-color);\n  height: 100vh;\n  width: 100vw;\n}\n\n.invoice-creation-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n#invoice-creation-box-preview {\n  width: 100%;\n  height: 100vh;\n\n  display: flex;\n  justify-content: center;\n  padding: 4rem 0 8rem 0;\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
