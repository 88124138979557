import { manageLidapResponse } from "@/utils/lidapi";
import { stringify } from "@/utils/stringify";
import { useQuery } from "@tanstack/react-query";

export function getRecentArticles({ enterpriseId, queryParam }) {
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  return {
    queryKey: ["articles", "recent", { enterpriseId }, queryParam],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/${enterpriseId}/articles/recent${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return manageLidapResponse(response, "Could not load recent articles");
    },
  };
}

export function useRecentArticles({
  enterpriseId,
  queryParam,
  reactQueryConfig,
}) {
  const { queryKey, queryFn } = getRecentArticles({ enterpriseId, queryParam });

  const query = useQuery({
    queryKey: queryKey,
    queryFn: queryFn,
    ...reactQueryConfig,
  });

  return query;
}

export function useArticles({
  enterpriseId,
  articleId,
  queryParam,
  reactQueryConfig,
}) {
  const articleParam = articleId ? `/${articleId}` : "";
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  const query = useQuery({
    queryKey: ["articles", { enterpriseId, articleId }, queryParam],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/${enterpriseId}/articles${articleParam}${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return manageLidapResponse(response, "Could not load invoice(s)");
    },
    ...reactQueryConfig,
  });

  return query;
}
