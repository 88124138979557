import React from "react";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import Modal from "@/components/elements/Modal/Modal";

export default function DeleteConfirmationModal({
  setDeleteItem,
  handleConfirm,
  message,
  loading,
}) {
  return (
    <Modal>
      <h1
        className="text-3xl"
        dangerouslySetInnerHTML={{ __html: message }}
      ></h1>
      <div className="flex gap-4">
        <ButtonTailwind
          variant="outlined"
          onClick={() => setDeleteItem(null)}
          disabled={loading}
        >
          Annuler
        </ButtonTailwind>
        <ButtonTailwind
          // variant={paymentDate ? "filled" : "disabled"}
          onClick={handleConfirm}
          loading={loading}
        >
          Supprimer
        </ButtonTailwind>
      </div>
    </Modal>
  );
}
