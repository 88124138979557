import { useUser } from "@/contexts/UserContext";
import { useRecentDocuments } from "@/hooks/useRecentDocuments";

export function useDefaultDocument({ documentType }) {
  const userContext = useUser();
  const lastDocumentCreated = useRecentDocuments({
    enterpriseId: userContext?.state?.user?.enterpriseId,
    queryParam: {
      documentType,
      pageNumber: 0,
      numberOfRecords: 1,
    },
  });
  const lastDocument = (() => {
    const results = lastDocumentCreated?.data?.payload?.results;
    if (results && results.length > 0) {
      return results[0];
    }
  })();

  return {
    defaultDocument: lastDocument,
    isDefaultDocumentLoading: lastDocumentCreated.isLoading,
  };
}
