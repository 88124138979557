import CenteredLoadingBlock from "@/components/elements/CenteredLoadingBlock";
import { useUserInfo } from "@/hooks/users/useLidapUser";
import React from "react";

import { createContext, useContext } from "react";
import { useOryAuth } from "./OryAuthContext";
// import z from "zod";

const UserContext = createContext();

// const userReducer = (state, action) => {
//   const userInStorage = localStorage.getItem("user") || "{}";
//   const user = JSON.parse(userInStorage);
//   const userSchema = z.object({
//     enterpriseId: z.string().nullable().optional(),
//     userId: z.string().nullable().optional(),
//     email: z.string().nullable().optional(),
//     isOnWhitelist: z.boolean().nullable().optional(),
//     isEmailVerified: z.boolean().nullable().optional(),
//     isAdmin: z.boolean().nullable().optional(),
//     commonName: z.string().nullable().optional(),
//     firstName: z.string().nullable().optional(),
//     lastName: z.string().nullable().optional(),
//     companyName: z.string().nullable().optional(),
//     country: z.string().nullable().optional(),
//     theme: z.string().nullable().optional(),
//   });

//   try {
//     userSchema.safeParse(user);
//   } catch (error) {
//     console.error("Error parsing user from storage", error);
//   }

//   switch (action.type) {
//     case "SET_USER":
//       localStorage.setItem("user", JSON.stringify(action.payload));
//       action.callback && action.callback();
//       return { ...state, user: action.payload };
//     case "UPDATE_USER":
//       localStorage.setItem(
//         "user",
//         JSON.stringify({
//           ...user,
//           ...action.payload,
//         })
//       );
//       action.callback && action.callback();
//       return { ...state, user: user };
//     case "SET_EMAIL_VERIFIED":
//       user.isEmailVerified = action.payload.isEmailVerified;
//       localStorage.setItem("user", JSON.stringify(user));
//       action.callback && action.callback();
//       return { ...state, user: user };
//     case "CLEAR_USER":
//       localStorage.removeItem("user");
//       action.callback && action.callback();
//       return { ...state, user: null };
//     // return {
//     //   ...state,
//     //   user: {
//     //     /* We indicate here defaults values to avoid errors at logout */
//     //     enterpriseId: "",
//     //     userId: "",
//     //     email: "",
//     //     isOnWhitelist: true,
//     //     isEmailVerified: true,
//     //     idAdmin: false,
//     //   },
//     // };
//     default:
//       return state;
//   }
// };

export const UserProvider = ({ children }) => {
  const auth = useOryAuth();
  const userInfo = useUserInfo({
    queryParam: {
      forceSearch: false,
    },
    reactQueryConfig: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: true,
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5,
      enabled: !!auth.session && !auth.logout.isLoading,
    },
  });

  const user = userInfo.data ? userInfo.data.payload : {};

  const state = {
    user,
    isLoading: userInfo.isInitialLoading,
    isError: userInfo.isError,
    error: userInfo.error,
    refetch: userInfo.refetch,
  };

  if (userInfo.isInitialLoading && !userInfo.data) {
    return <CenteredLoadingBlock />;
  }

  return (
    <UserContext.Provider value={{ state }}>{children}</UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export const useEnterpriseId = () => {
  const context = useUser();
  return context.state.user.enterpriseId;
};

export const useIsAdmin = () => {
  const context = useUser();
  return context.state.user.isAdmin === true;
};

export const useSubscription = () => {
  const context = useUser();
  const isLoading = context.state.isLoading;
  const subscription = context.state.user.subscription;
  const isActive =
    subscription &&
    (subscription.status === "active" || subscription.status === "trialing");
  const isTrialing = subscription && subscription.status === "trialing";
  const trialDays = subscription && subscription.trialDays;
  const isPaymentMethodSet = subscription && subscription.isPaymentMethodSet;
  const cancelAtPeriodEnd = subscription && subscription.cancelAtPeriodEnd;

  return {
    subscription,
    isActive,
    isTrialing,
    trialDays,
    isLoading,
    isPaymentMethodSet,
    cancelAtPeriodEnd,
  };
};

// export const UserContext = React.createContext();

// export default function UserProvider({ children }) {
//   const [user, setUser] = React.useState(null);
//   const { getUserData } = useGetUserSelf();

//   if (getUserData) {
//     setUser(getUserData);
//   }

//   return (
//     <UserContext.Provider value={{ user, setUser }}>
//       {children}
//     </UserContext.Provider>
//   );
// }

// UserContext.js
