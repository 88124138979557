import InputTailwind from "@/components/token/Input/inputTailwind";
import { validateEmail } from "./emailValidator";
import { useEffect } from "react";

export function Email({
  initialEmail,
  disabled,
  onChange,
  isLoading,
  required,
  name = "email",
  label,
  id,
  autocomplete = "email",
  displayError = true,
  placeholder,
  forceValidate,
}) {
  const email = initialEmail;
  const feedback = validateEmail(initialEmail, required);
  const handleEmailChange = (e) => {
    const value = e.target.value;
    const feedback = validateEmail(value, required);
    onChange({ success: feedback.success, data: value, isDirty: true });
  };

  useEffect(() => {
    if (forceValidate && !feedback.success) {
      onChange({
        success: feedback.success,
        isDirty: true,
      });
    }
  }, [forceValidate, feedback.success, onChange]);

  return (
    <InputTailwind
      label={label ? label : "Adresse e-mail"}
      type="email"
      placeholder={placeholder ? placeholder : "votre@email.com"}
      value={email}
      onChange={(e) => handleEmailChange(e)}
      loading={isLoading}
      disabled={disabled}
      error={feedback.success || !displayError ? null : feedback.message}
      isRequired={required}
      name={name}
      maxLength={254}
      id={id}
      autoComplete={autocomplete}
    ></InputTailwind>
  );
}
