import React, { useMemo } from "react";
import InputTailwind from "@/components/token/Input/inputTailwind";
import SelectMenu from "@/components/token/select/select";
import { vatDisclaimers } from "@/data/vatDisclaimers";
import { DocumentActions } from "@/components/elements/Document/DocumentForm";
import { formatDateToISO, transformDate } from "@/utils/date/dateTransformers";
import { DateDefaults } from "@/utils/date/DateDefaults";
import { DocumentAdditionalDataType } from "../document/DocumentAdditionalDataType";
import { DocumentAdditionalDataSubType } from "../document/DocumentAdditionalDataSubType";
import ValidatedTextArea from "@/components/token/textareas/ValidatedTextArea";
import { DocumentSettingsKey } from "../Settings/helpers/extractSetting";
import { useCommonDocumentForm } from "../document/useDocumentForm";
import { MyDateRangePickerV2 } from "../createInvoice/MyDateRangePickerV2";
import ValidatedInputTailwind from "@/components/token/Input/ValidatedInputTailwind";
import { extractDocumentAdditionalDataWithError } from "../document/helpers/extractDocumentAdditionalData";

export function LastStepCreditNoteFormV2({
  enterprise,
  documentData,
  setDocumentData,
  linkedDocument,
  lastDocumentOfSameType,
  documentSettings,
}) {
  const {
    documentConditions,
    conditionsType,
    vatDisclaimer,
    handleSettings,
    manageSettings,
    documentUserTitle,
    settingsBeingSaved,
    issuingDateAsMMDDYYYY,
    issuingDateMin,
  } = useCommonDocumentForm({
    documentData,
    setDocumentData,
    enterprise,
    lastDocumentOfSameType,
    documentSettings,
  });

  const creditNotePaymentApplicationMethod = useMemo(
    () =>
      extractDocumentAdditionalDataWithError({
        additionalDataArray: documentData.additionalData,
        additionalDataType:
          DocumentAdditionalDataType.CREDIT_NOTE_PAYMENT_APPLICATION_METHOD,
      }),
    [documentData.additionalData]
  );

  const issuingDateLinkedDocument = new Date(linkedDocument.issuingDate);
  /* Minimum between the issuing date of the linked document and the date of the last document of the same type */
  const minimumIssuingDate = transformDate(
    issuingDateLinkedDocument > issuingDateMin
      ? issuingDateLinkedDocument
      : issuingDateMin
  );

  /* Render */
  return (
    <div className="flex flex-col gap-8">
      <div className="w-full flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <MyDateRangePickerV2
            startDateLabel={"Date d'émission"}
            startDate={issuingDateAsMMDDYYYY}
            setStartDate={(date) =>
              setDocumentData({
                type: DocumentActions.SET_SIMPLE_FIELD,
                field: "issuingDate",
                payload: formatDateToISO(
                  date,
                  DateDefaults.TIMEZONE,
                  DateDefaults.LOCALE
                ),
              })
            }
            minStartDate={minimumIssuingDate}
            tooltipStartDate="La date d'émission ne peut pas être antérieure à la date d'émission du document lié ou à la date d'émission du dernier document du même type."
            isStartDateShown={true}
            isEndDateShown={false}
          />

          <ValidatedInputTailwind
            label="Intitulé du document"
            type="text"
            placeholder="Nommez votre document pour faciliter son identification"
            value={documentUserTitle.value}
            setInputValue={(value, error) => {
              const payload = {
                additionalDataType: DocumentAdditionalDataType.DOCUMENT_TITLE,
                additionalDataSubType:
                  DocumentAdditionalDataSubType.NOT_APPLICABLE,
                additionalData: value,
              };
              if (error) payload.error = error;

              setDocumentData({
                type: DocumentActions.SET_UNIQUE_ADDITIONAL_DATA,
                payload,
              });
            }}
            inputError={documentUserTitle.error}
            maxLength={60}
          ></ValidatedInputTailwind>

          <ValidatedInputTailwind
            label="Numéro de bon de commande (optionnel)"
            id="purchaseOrderIn"
            type="text"
            placeholder="Indiquez un numéro de bon de commande"
            value={documentData.purchaseOrder?.value}
            setInputValue={(value, error) => {
              const payload = {
                value,
              };
              if (error) payload.error = error;

              setDocumentData({
                type: DocumentActions.SET_SIMPLE_FIELD_WITH_ERROR,
                payload,
                field: "purchaseOrder",
              });
            }}
            inputError={documentData.purchaseOrder?.error}
            maxLength={60}
          ></ValidatedInputTailwind>

          <InputTailwind
            label="Numéro de bon de commande (optionnel)"
            id="purchaseOrderIn"
            type="text"
            placeholder="Indiquez un numéro de bon de commande"
            value={documentData.purchaseOrder}
            onChange={(event) =>
              setDocumentData({
                type: DocumentActions.SET_SIMPLE_FIELD,
                field: "purchaseOrder",
                payload: event.target.value,
              })
            }
            isRequired={false}
            icon={false}
          ></InputTailwind>
        </div>

        <ValidatedTextArea
          label="Conditions de paiement et mentions légales (optionnel)"
          placeholder="Mode de paiement, délai de paiement, frais applicables en cas de retard de paiement, etc..."
          value={documentConditions.value}
          setInputValue={(value, error) => {
            const payload = {
              additionalDataType: conditionsType,
              additionalDataSubType:
                DocumentAdditionalDataSubType.NOT_APPLICABLE,
              additionalData: value,
            };
            if (error) payload.error = error;

            setDocumentData({
              type: DocumentActions.SET_UNIQUE_ADDITIONAL_DATA,
              payload,
            });
          }}
          maxLength={500}
          save={true}
          onSave={(value) => {
            handleSettings({
              settingsKey: DocumentSettingsKey.DOCUMENT_CONDITIONS,
              settingsValue: value,
              error: documentConditions.error,
            });
          }}
          loading={
            manageSettings.isLoading &&
            settingsBeingSaved === DocumentSettingsKey.DOCUMENT_CONDITIONS
          }
          error={documentConditions.error}
        />
        <ValidatedInputTailwind
          label="Comment le crédit sera appliqué"
          type="text"
          placeholder="Remboursement, crédit sur futures transactions, etc..."
          value={creditNotePaymentApplicationMethod.value}
          setInputValue={(value, error) => {
            const payload = {
              additionalDataType:
                DocumentAdditionalDataType.CREDIT_NOTE_PAYMENT_APPLICATION_METHOD,
              additionalDataSubType:
                DocumentAdditionalDataSubType.NOT_APPLICABLE,
              additionalData: value,
            };
            if (error) payload.error = error;

            setDocumentData({
              type: DocumentActions.SET_UNIQUE_ADDITIONAL_DATA,
              payload,
            });
          }}
          inputError={creditNotePaymentApplicationMethod.error}
          maxLength={500}
        ></ValidatedInputTailwind>
        <SelectMenu
          selectedItem={vatDisclaimer}
          setSelectedItem={(v) =>
            setDocumentData({
              type: DocumentActions.SET_UNIQUE_ADDITIONAL_DATA,
              payload: {
                additionalDataType: DocumentAdditionalDataType.VAT_DISCLAIMER,
                additionalDataSubType:
                  DocumentAdditionalDataSubType.NOT_APPLICABLE,
                additionalData: v,
              },
            })
          }
          data={vatDisclaimers(enterprise.country)}
          label={"Mention de TVA"}
          placeholder={"Mention de TVA"}
          onSave={(value) => {
            handleSettings({
              settingsKey: DocumentSettingsKey.VAT_DISCLAIMER,
              settingsValue: value,
            });
          }}
          loading={
            manageSettings.isLoading &&
            settingsBeingSaved === DocumentSettingsKey.VAT_DISCLAIMER
          }
        />
      </div>
    </div>
  );
}
