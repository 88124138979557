// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Tailwind CSS (in your CSS file) */
.skeleton {
  background-color: #ddd;
  border-radius: 1px;
}
.skeleton.title {
  height: 20px;
  width: 200px;
}
.skeleton.text {
  height: 4px;
  width: 100%;
}
.skeleton.thumbnail {
  height: 100px;
  width: 100px;
}

/* Add the shimmer effect */
@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}
.skeleton {
  animation: shimmer 1.5s infinite linear;
  background: linear-gradient(to right, #ddd 8%, #eaeaea 18%, #ddd 33%);
  background-size: 200px 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/token/loading/SkeletonElement.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;EACE,sBAAsB;EACtB,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,YAAY;AACd;AACA;EACE,WAAW;EACX,WAAW;AACb;AACA;EACE,aAAa;EACb,YAAY;AACd;;AAEA,2BAA2B;AAC3B;EACE;IACE,6BAA6B;EAC/B;EACA;IACE,yCAAyC;EAC3C;AACF;AACA;EACE,uCAAuC;EACvC,qEAAqE;EACrE,2BAA2B;AAC7B","sourcesContent":["/* Tailwind CSS (in your CSS file) */\n.skeleton {\n  background-color: #ddd;\n  border-radius: 1px;\n}\n.skeleton.title {\n  height: 20px;\n  width: 200px;\n}\n.skeleton.text {\n  height: 4px;\n  width: 100%;\n}\n.skeleton.thumbnail {\n  height: 100px;\n  width: 100px;\n}\n\n/* Add the shimmer effect */\n@keyframes shimmer {\n  0% {\n    background-position: -200px 0;\n  }\n  100% {\n    background-position: calc(200px + 100%) 0;\n  }\n}\n.skeleton {\n  animation: shimmer 1.5s infinite linear;\n  background: linear-gradient(to right, #ddd 8%, #eaeaea 18%, #ddd 33%);\n  background-size: 200px 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
