import { Outlet } from "react-router-dom";
import SettingsMenu from "./SettingsMenu";
import ProtectedRoute from "@/utils/ProtectedRouteV2";

export function SettingsLayout() {
  return (
    <ProtectedRoute hideMenu={true} showRibbon={false}>
      <SettingsMenu>
        <Outlet />
      </SettingsMenu>
    </ProtectedRoute>
  );
}
