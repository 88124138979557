import { useMutation, useQueryClient } from "@tanstack/react-query";
import Lidapi from "@/utils/lidapi";

export function useCreateUpdateEmail(
  queryKeyToInvalidate,
  multipleQueryKeysToInvalidate,
  onSuccess
) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async ({ stakeholderId, email, id }) =>
      id
        ? await api.updateEmail(stakeholderId, id, email)
        : await api.createEmail(stakeholderId, email),
    onSuccess: () => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (
        multipleQueryKeysToInvalidate &&
        multipleQueryKeysToInvalidate.length > 0
      ) {
        multipleQueryKeysToInvalidate.forEach((queryKey) => {
          queryClient.invalidateQueries({
            queryKey: [...queryKey],
          });
        });
      }
      if (onSuccess) {
        onSuccess();
      }
    },
  });

  return action;
}
