import React from "react";
import CompteACrediter from "@/components/elements/CompteACrediter/CompteACrediter";
import InputTailwind from "@/components/token/Input/inputTailwind";
import TextArea from "@/components/token/textareas/textareas";
import DateRangePicker from "@/feature/createInvoice/DateRangePicker";
import SelectMenu from "@/components/token/select/select";
import { vatDisclaimers } from "@/data/vatDisclaimers";
import { useUser } from "@/contexts/UserContext";
import { useManageSettings } from "@/hooks/useSettings";
import { notify } from "@/utils/notify";

export default function LastStepInvoiceForm(props) {
  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;
  const country = userContext.state.user.country;

  const manageSettings = useManageSettings(["settings"], undefined, () => {
    notify({
      isSuccess: true,
      message: "Paramètres de facturation mis à jour avec succès",
    });
  });
  function handleSettings(conditionsMentions) {
    manageSettings.mutate({
      settingsType: "document",
      parentId: enterpriseId,
      settingsKey: "DOCUMENT_CONDITIONS",
      settingsValue: conditionsMentions,
    });
  }
  return (
    <div className="flex flex-col gap-8">
      <div className="w-full flex flex-col gap-4">
        <DateRangePicker
          issuingDate={props.issuingDate}
          setIssuingDate={props.setIssuingDate}
          dueDate={props.dueDate}
          setDueDate={props.setDueDate}
          labeLDueDate="Date limite de validité"
        />
        <InputTailwind
          label="Numéro de bon de commande (optionnel)"
          id="purchaseOrderIn"
          type="text"
          placeholder="Indiquez un numéro de bon de commande"
          value={props.PurchaseOrderNumber}
          onChange={(event) => props.setPurchaseOrderNumber(event.target.value)}
          isRequired={false}
          icon={false}
        ></InputTailwind>
      </div>
      <CompteACrediter
        paymentInformation={props.paymentInformation}
        accounts={props.accounts}
        accountsToCredit={props.accountsToCredit}
        setAccountsToCredit={props.setAccountsToCredit}
      />
      <TextArea
        label="Conditions de paiement et mentions légales (optionnel)"
        placeholder="Numéro de TVA intracommunaire, mode de paiement, délai de paiement, frais applicables en cas de retard de paiement, etc..."
        value={props.conditionsMentions}
        onChange={(e) => props.setConditionsMentions(e.target.value)}
        maxLength={500}
        save={true}
        onSave={handleSettings}
      />
      <SelectMenu
        selectedItem={props?.VATMentions}
        setSelectedItem={(e) => props?.setVATMentions(e)}
        data={vatDisclaimers(country)}
        label={"Mention de TVA"}
        placeholder={"Mention de TVA"}
      />
    </div>
  );
}
