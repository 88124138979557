import CouldNotLoad from "@/components/elements/CouldNotLoad";
import { Pagination } from "@/components/elements/Pagination/Pagination";
import SearchInput from "@/components/token/searchInput/searchInput";
import { flexRender } from "@tanstack/react-table";

// Composant de squelette pour les lignes du tableau
const SkeletonRow = () => (
  <div className="flex flex-col gap-8 w-full ">
    <div className="flex flex-row w-full justify-between">
      <div className="h-9 bg-neutral-100 rounded dark:bg-neutral-600 w-60 mb-2.5 animate-pulse"></div>
      <div className="w-32 h-9 bg-neutral-100 rounded dark:bg-neutral-700 animate-pulse"></div>
    </div>
    <div className="w-full p-4 space-y-4 border border-neutral-200 divide-y divide-neutral-200 sm:rounded-lg shadow animate-pulse dark:divide-neutral-700 md:p-6 dark:border-neutral-700">
      <div className="flex items-center justify-between">
        <div>
          <div className="h-2.5 bg-neutral-300 rounded-full dark:bg-neutral-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-neutral-200 rounded-full dark:bg-neutral-700"></div>
        </div>
        <div className="h-2.5 bg-neutral-300 rounded-full dark:bg-neutral-700 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-neutral-300 rounded-full dark:bg-neutral-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-neutral-200 rounded-full dark:bg-neutral-700"></div>
        </div>
        <div className="h-2.5 bg-neutral-300 rounded-full dark:bg-neutral-700 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-neutral-300 rounded-full dark:bg-neutral-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-neutral-200 rounded-full dark:bg-neutral-700"></div>
        </div>
        <div className="h-2.5 bg-neutral-300 rounded-full dark:bg-neutral-700 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-neutral-300 rounded-full dark:bg-neutral-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-neutral-200 rounded-full dark:bg-neutral-700"></div>
        </div>
        <div className="h-2.5 bg-neutral-300 rounded-full dark:bg-neutral-700 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-neutral-300 rounded-full dark:bg-neutral-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-neutral-200 rounded-full dark:bg-neutral-700"></div>
        </div>
        <div className="h-2.5 bg-neutral-300 rounded-full dark:bg-neutral-700 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-neutral-300 rounded-full dark:bg-neutral-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-neutral-200 rounded-full dark:bg-neutral-700"></div>
        </div>
        <div className="h-2.5 bg-neutral-300 rounded-full dark:bg-neutral-700 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-neutral-300 rounded-full dark:bg-neutral-600 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-neutral-200 rounded-full dark:bg-neutral-700"></div>
        </div>
        <div className="h-2.5 bg-neutral-300 rounded-full dark:bg-neutral-700 w-12"></div>
      </div>
    </div>
  </div>
);

export default function LidapTable({
  lidapTableInit,
  lidapTableBuild,
  additionalFilters = [],
  actionButtons = [],
  additionalActionsOnPaginate,
  trClass,
  tabFilters,
  tabFilter,
  setTabFilter,
}) {
  const defaultHeaderStyle =
    "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 sm:rounded-lg";
  const defaultCellStyle =
    "truncate py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6";

  const { query, table } = lidapTableBuild;
  if (query.isLoading) {
    return (
      <div className="flex flex-col h-full w-full p-16">
        <div className="min-w-full ">
          <div className="flex flex-col gap-8">
            {Array.from({ length: 1 }).map((_, index) => (
              <SkeletonRow key={index} cellCount={5 /* nombre de colonnes */} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (query.isError) {
    return (
      <CouldNotLoad
        message="Impossible de charger la liste"
        error={query.error}
      />
    );
  }

  return (
    <div className="flex flex-col w-full max-h-screen overflow-y-auto">
      <div className="flex flex-col w-full h-screen px-16 pt-16 bg-neutral-100 justify-between">
        <div className="flex flex-col gap-8 w-full pb-16">
          <div className="flex flex-row w-full justify-between">
            <div className="flex flex-row justify-between gap-3">
              {lidapTableInit.setGlobalFilter ? (
                <SearchInput
                  onChange={(e) =>
                    lidapTableInit.setGlobalFilter(e.target.value)
                  }
                  value={lidapTableInit.globalFilter}
                />
              ) : null}
              {additionalFilters.map((filter) => (
                <div key={filter.name} className="flex flex-row gap-3">
                  {filter.render}
                </div>
              ))}
            </div>
            {actionButtons.map((button) => (
              <div key={button.name} className="flex flex-row gap-3">
                {button.render}
              </div>
            ))}
          </div>
          {tabFilters ? (
            <div className="flex flex-row justify-between gap-3 w-full border-b border-neutral-300">
              <div className="flex">
                {tabFilters.tabs.map((tab) => (
                  <button
                    key={tab.name}
                    tabIndex={0}
                    className={`text-sm text-neutral-900 py-[10px] border-b-2 ${
                      tabFilter === tab.filter
                        ? "border-neutral-900"
                        : "border-transparent"
                    } mr-6`}
                    onClick={() => {
                      setTabFilter(tab.filter);
                      lidapTableBuild.table
                        .getColumn(tabFilters.filterColumn)
                        .setFilterValue(
                          tab.filter === tabFilters.defaultFilter
                            ? ""
                            : tab.filter
                        );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        setTabFilter(tab.filter);

                        lidapTableBuild.table
                          .getColumn(tabFilters.filterColumn)
                          .setFilterValue(
                            tab.filter === tabFilters.defaultFilter
                              ? ""
                              : tab.filter
                          );
                      }
                    }}
                  >
                    {tab.name}
                  </button>
                ))}
              </div>
            </div>
          ) : null}
          <div className=" w-full">
            <div className="overflow-visible bg-white shadow border border-[#e5e7eb] dark:border-[#2b2b2b] ring-opacity-5 sm:rounded-lg ">
              <table className="w-full divide-y divide-[#e5e7eb] dark:divide-[#2b2b2b]">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          scope="col"
                          className={
                            header.column.columnDef.className?.header ??
                            defaultHeaderStyle
                          }
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y divide-[#e5e7eb] dark:divide-[#2b2b2b] commented-bg-white">
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className={trClass}>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className={
                            cell.column.columnDef.className?.cell ??
                            defaultCellStyle
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            cell.column.columnDef.onClick
                              ? cell.column.columnDef.onClick(cell)
                              : undefined;
                          }}
                          id="lidap-table-cell"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
                {/* <tfoot>
                            {table.getFooterGroups().map((footerGroup) => (
                              <tr key={footerGroup.id}>
                                {footerGroup.headers.map((header) => (
                                  <th key={header.id}>
                                    {header.isPlaceholder
                                      ? null
                                      : flexRender(
                                          header.column.columnDef.footer,
                                          header.getContext()
                                        )}
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </tfoot> */}
              </table>
              {/* <InvoiceChaining
                          row={table
                            .getRowModel()
                            .rows.find(
                              (row) =>
                                row.getValue("invoiceNumber") === selectedClientId
                            )}
                          onClick={setSelectedClientId}
                          selectedClientId={selectedClientId}
                        /> */}
            </div>
          </div>
        </div>
        <div className="bg-neutral-100 sticky bottom-0 top-auto border-t border-[#e5e7eb] dark:border-[#2b2b2b] ">
          <div className="flex flex-row w-full justify-between gap-3 py-4">
            <Pagination
              currentPage={lidapTableInit.page}
              totalPages={lidapTableBuild.pageCount}
              resultsPerPage={lidapTableInit.numberOfRecords}
              totalResults={lidapTableBuild.totalNumberOfResults}
              setCurrentPage={(p) => {
                lidapTableInit.searchParams.set("page", p);
                lidapTableInit.setSearchParams(lidapTableInit.searchParams);
                lidapTableInit.setSelectedRowId(null);
                additionalActionsOnPaginate({
                  page: p,
                  results: lidapTableInit.numberOfRecords,
                });
              }}
              isLoading={query.isPreviousData}
              setResultsPerPage={(r) => {
                lidapTableInit.searchParams.set("results", r);
                lidapTableInit.setSearchParams(lidapTableInit.searchParams);
                additionalActionsOnPaginate({
                  page: lidapTableInit.page,
                  results: r,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
