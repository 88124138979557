// import { useUser } from "@/contexts/UserContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ory } from "./useOryLogin";
import {
  getFirstOryError,
  getOryGlobalError,
  getOryResponseStatus,
} from "@/utils/ory.frontend";
import FetchError from "@/utils/FetchError";
import { faro } from "@/AppRouter";

export const OrySignupErrors = {
  ORY_SIGNUP_GLOBAL_ERROR: "ORY_SIGNUP_GLOBAL_ERROR",
  ORY_SIGNUP_BREACHED_PASSWORD: "ORY_SIGNUP_BREACHED_PASSWORD",
  ORY_SIGNUP_ALREADY_EXISTS: "ORY_SIGNUP_ALREADY_EXISTS",
  ORY_SIGNUP_FIRST_ERROR: "ORY_SIGNUP_FIRST_ERROR",
  ORY_SIGNUP_UNKNOWN: "ORY_SIGNUP_UNKNOWN",
};

export const handleOrySignup = async ({ password, traits }) => {
  // Start a signup flow
  const { data: signupFlow } = await ory.createBrowserRegistrationFlow(
    undefined,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const attributes = signupFlow.ui.nodes.find(
    (node) => node.group === "default"
  )?.attributes;
  const csrf_token = attributes.name === "csrf_token" ? attributes.value : null;
  // Submit the signup form
  return ory.updateRegistrationFlow(
    {
      flow: signupFlow.id,
      updateRegistrationFlowBody: {
        csrf_token: csrf_token,
        method: "password",
        password: password,
        traits: {
          ...traits,
        },
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

function handleOrySignupError(error) {
  const status = getOryResponseStatus(error);

  const globalError = getOryGlobalError(error);
  if (globalError) {
    const fetchError = new FetchError(
      `${globalError.message}: ${globalError.reason}`,
      status,
      OrySignupErrors.ORY_SIGNUP_GLOBAL_ERROR,
      undefined,
      "ory-login-flow",
      true
    );

    faro.api.pushError(fetchError, {
      type: "ory",
    });

    throw fetchError;
  }

  const firstError = getFirstOryError(error);

  if (firstError) {
    const { errorCode, errorMessage } = (() => {
      if (firstError.id === 4000034) {
        return {
          errorCode: OrySignupErrors.ORY_SIGNUP_BREACHED_PASSWORD,
          errorMessage:
            "Ce mot de passe a été compromis sur d'autres plateformes. Veuillez en choisir un autre.",
        };
      } else if (firstError.id === 4000028) {
        return {
          errorCode: OrySignupErrors.ORY_SIGNUP_ALREADY_EXISTS,
          errorMessage: "Cette adresse email est déjà utilisée.",
        };
      }
      return {
        errorCode: OrySignupErrors.ORY_SIGNUP_FIRST_ERROR,
        errorMessage: firstError.text,
      };
    })();

    const fetchError = new FetchError(
      errorMessage,
      status,
      errorCode,
      undefined,
      "ory-login-flow",
      true
    );

    faro.api.pushError(fetchError, {
      type: "ory",
    });

    throw fetchError;
  }

  const fetchError = new FetchError(
    "Impossible d'authentifier l'utilisateur",
    status,
    OrySignupErrors.ORY_SIGNUP_UNKNOWN,
    undefined,
    "ory-login-flow"
  );

  faro.api.pushError(fetchError, {
    type: "ory",
  });

  throw fetchError;
}

export function useOrySignup(
  queryKeyToInvalidate,
  multipleQueryKeysToInvalidate,
  onSuccess,
  onError
) {
  const queryClient = useQueryClient();
  // const userContext = useUser();

  const action = useMutation({
    mutationFn: async (data) =>
      await handleOrySignup({
        password: data.password,
        traits: {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          commonName: data.commonName,
          hasAcceptedTOS: data.hasAcceptedTOS,
        },
      }).catch((error) => {
        handleOrySignupError(error);
      }),
    onSuccess: async (data) => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (
        multipleQueryKeysToInvalidate &&
        multipleQueryKeysToInvalidate.length > 0
      ) {
        multipleQueryKeysToInvalidate.forEach((queryKey) => {
          queryClient.invalidateQueries({
            queryKey: [...queryKey],
          });
        });
      }
      if (onSuccess) {
        onSuccess(data);
      }
      // const referrer = document.referrer;

      /* We only want to return to the previous page if it was a page from the app */
      // if (
      //   referrer.includes(window.location.origin) &&
      //   !referrer.includes("/forbidden", "/not-whitelisted", "/login", "/sign")
      // ) {
      //   navigate(-1);
      // } else {
      //   navigate("/home");
      // }
    },
    onError: async (error) => {
      if (onError) {
        onError(error);
      }
    },
  });

  return action;
}
