import { useQuery, useQueryClient } from "@tanstack/react-query";
import { stringify } from "@/utils/stringify";
import { manageLidapResponse } from "@/utils/lidapi";

export function getUserInfo({ queryParam }) {
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  return {
    queryKey: ["users", "info", queryParam],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/users/info${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return manageLidapResponse(response, "Could not load user info", true);
    },
  };
}

export function useUserInfo({ queryParam, reactQueryConfig }) {
  const queryClient = useQueryClient();
  const { queryFn, queryKey } = getUserInfo({ queryParam });
  const query = useQuery(queryKey, {
    queryFn: queryFn,
    initialData: () => {
      const dataFromCache = queryClient.getQueryData(queryKey);
      return dataFromCache;
    },
    ...reactQueryConfig,
  });

  return query;
}
