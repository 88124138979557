import React, { useCallback, useState } from "react";
import StepFormDocuments from "@/components/elements/StepFormDocuments";
import Box from "@mui/material/Box";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import SearchSelect from "@/components/token/select/SearchSelect";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useSearchParams } from "react-router-dom";
import { DocumentActions } from "@/components/elements/Document/DocumentForm";
import { useClients } from "@/hooks/useClients";
import ServiceFormV2 from "@/components/elements/ServiceForm/ServiceFormV2";
import { notify } from "@/utils/notify";
import { extractDocumentAdditionalData } from "../document/helpers/extractDocumentAdditionalData";
import { LastStepInvoiceFormV2 } from "./LastStepInvoiceFormV2";
import { DocumentStatus } from "@/components/token/documents/DocumentStatus";
import { DocumentAdditionalDataType } from "../document/DocumentAdditionalDataType";
import {
  DocumentCreationLabel,
  DocumentTypeLabel,
} from "@/components/token/documents/DocumentType";
import { useCommonDocumentForm } from "../document/useDocumentForm";
import { DocumentFlowModal } from "../document/DocumentFlowModal";

export default function InvoiceFormV2({
  enterprise,
  isDocumentCreationLoading,
  documentData,
  setDocumentData,
  clientId = null,
  setIsPageNew,
  articles = [],
  paymentInformation,
  onClientCreation = () => {},
  handleDocumentSubmit,
  isParametersOpen,
  setIsParametersOpen,
  lastDocumentOfSameType,
  documentSettings,
}) {
  /* Re-usable hook form */
  const {
    displayLeavingPopUp,
    handleServiceAddition,
    handleServiceRemoval,
    handleServiceSelection,
    isCreationModalOpen,
    setDisplayLeavingPopUp,
    setIsCreationModalOpen,
    validateServices,
  } = useCommonDocumentForm({
    documentData,
    setDocumentData,
    enterprise,
    documentSettings,
  });

  /* State */
  const [step, setStep] = useState(clientId ? 1 : 0);
  const [selectedClientId, setSelectedClientId] = useState(clientId);
  const [searchParams, setSearchParams] = useSearchParams();

  /* Derived state */
  const enterpriseId = enterprise.id;
  const documentType = documentData.documentType || DocumentType.INVOICE;

  /* Queries */
  const clients = useClients({
    enterpriseId,
    queryParam: {
      addresses: true,
      identifiers: true,
      emails: true,
      phones: false,
    },
  });

  const selectedClient = clients.clientsData?.payload?.find(
    (c) => c.id === selectedClientId
  );

  /* Handlers */
  const handleClientSelection = useCallback(
    (client) => {
      setSelectedClientId(client.id);
      setDocumentData({
        type: DocumentActions.SET_CLIENT,
        payload: client,
      });
      searchParams.set("clientId", client.id);
      setSearchParams(searchParams);
      setIsPageNew(false);
      if (step === 0) {
        setStep((prev) => prev + 1);
      }
    },
    [setDocumentData, searchParams, setSearchParams, step, setIsPageNew]
  );

  /* Form navigation */
  function toStep1() {
    if (!selectedClientId) {
      notify({
        isSuccess: false,
        message: "Vous devez sélectionner un client pour pouvoir continuer",
      });
      return;
    }

    setStep((prev) => prev + 1);
  }

  function toStep2() {
    validateServices({
      documentData,
      onSuccess: () => setStep((prev) => prev + 1),
    });
  }

  function handleSubmit() {
    const paymentMethod = extractDocumentAdditionalData({
      additionalDataArray: documentData.additionalData,
      additionalDataType: DocumentAdditionalDataType.PAYMENT_METHOD,
    });

    if (!paymentMethod) {
      notify({
        isSuccess: false,
        message: "Vous devez sélectionner un mode de paiement pour continuer",
      });
      return;
    }

    setIsCreationModalOpen(true);
  }

  const submitFunctions = [toStep1, toStep2, handleSubmit];

  /* Render */
  if (isParametersOpen) return null;

  return (
    <>
      <DocumentFlowModal
        displayLeavingPopUp={displayLeavingPopUp}
        handleDocumentSubmit={handleDocumentSubmit}
        isCreationModalOpen={isCreationModalOpen}
        isDocumentCreationLoading={isDocumentCreationLoading}
        setIsCreationModalOpen={setIsCreationModalOpen}
        setDocumentData={setDocumentData}
        setDisplayLeavingPopUp={setDisplayLeavingPopUp}
      />
      <StepFormDocuments
        docType={DocumentTypeLabel[documentType]}
        setStep={setStep}
        numberOfSteps={3}
        currentStep={step}
        TextSubmit={DocumentCreationLabel[documentType]}
        setStepBack={() => {
          if (step === 0) {
            setDisplayLeavingPopUp(true);
          } else {
            setStep(step - 1);
          }
        }}
        createDraft={() => handleDocumentSubmit(DocumentStatus.DRAFT)}
        setDisplayLeavingPopUp={setDisplayLeavingPopUp}
        draftDisplay={true}
        isDocumentCreationLoading={isDocumentCreationLoading}
        onSubmit={submitFunctions[step]}
        setParametersOpen={setIsParametersOpen}
      >
        <Box className="flex flex-col w-full gap-4">
          <Box className="flex flex-col w-full gap-2">
            {step === 0 || step === 1 ? (
              <SearchSelect
                label="Client"
                selectedValue={selectedClient}
                onSelect={handleClientSelection}
                displayCreationElement={onClientCreation}
                creationElement="Ajouter un client"
                options={
                  !clients.isInitialLoading ? clients.clientsData.payload : null
                }
                placeholder="Sélectionnez un client"
                accessor="name"
                loading={clients.isClientsLoading}
              />
            ) : null}
            {step === 1 ? (
              <>
                <Box className="flex flex-col w-full gap-4">
                  <SearchSelect
                    label="Service"
                    selectedValue={"services"}
                    onSelect={handleServiceSelection}
                    displayCreationElement={handleServiceAddition}
                    creationElement="Ajouter un service"
                    options={articles}
                    placeholder="Sélectionnez un service"
                    accessor="title"
                  />

                  <Box className="flex flex-col w-full gap-4">
                    {documentData.services.map((service) => (
                      <div
                        key={`service-div:${service.id}`}
                        className="w-full min-h-max"
                      >
                        <ServiceFormV2
                          enterprise={enterprise}
                          client={selectedClient}
                          service={service}
                          setService={(s) => {
                            setDocumentData({
                              type: DocumentActions.UPDATE_SERVICE,
                              payload: s,
                            });
                          }}
                          key={`service:${service.id}`}
                          isLoading={clients.isClientsLoading}
                          removeService={handleServiceRemoval}
                          duplicateService={handleServiceSelection}
                        />
                      </div>
                    ))}

                    <ButtonTailwind
                      variant="text"
                      onClick={handleServiceAddition}
                    >
                      <PlusIcon className="h-6 w-6 text-neutral-700" />
                      Ajouter un service
                    </ButtonTailwind>
                  </Box>
                </Box>
              </>
            ) : null}
            {step === 2 ? (
              <LastStepInvoiceFormV2
                enterprise={enterprise}
                documentData={documentData}
                setDocumentData={setDocumentData}
                paymentInformation={
                  paymentInformation.stakeholderPaymentInformation
                }
                lastDocumentOfSameType={lastDocumentOfSameType}
                documentSettings={documentSettings}
              />
            ) : null}
          </Box>
        </Box>
      </StepFormDocuments>
    </>
  );
}
