import InputTailwind from "@/components/token/Input/inputTailwind";
import { CRUDL } from "@/utils/crudl";
import { useEffect } from "react";

export function StreetName({
  initialStreetName,
  disabled,
  onChange,
  isLoading,
  required,
  name = "streetName",
  displayError = true,
  forceValidate,
  context,
}) {
  const streetName = initialStreetName;

  useEffect(() => {
    if (
      forceValidate &&
      (!(!required || initialStreetName) || context === CRUDL.CREATE)
    ) {
      onChange({
        success: !required || !!initialStreetName,
        isDirty: true,
        data: initialStreetName,
      });
    }
  }, [forceValidate, onChange, required, context, initialStreetName]);

  const handleStreetNameChange = (e) => {
    const value = e.target.value;
    onChange({
      success: !required || !!value,
      data: value,
      isDirty: true,
    });
  };

  return (
    <InputTailwind
      label="Adresse"
      type="text"
      placeholder="Votre rue et numéro"
      value={streetName}
      onChange={(e) => handleStreetNameChange(e)}
      loading={isLoading}
      disabled={disabled}
      error={
        required && !streetName && displayError ? "Ce champ est requis" : null
      }
      isRequired={required}
      name={name}
      maxLength={100}
    ></InputTailwind>
  );
}
