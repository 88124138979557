import CenteredLoadingBlock from "@/components/elements/CenteredLoadingBlock";
import CouldNotLoad from "@/components/elements/CouldNotLoad";
import { DocumentForm } from "@/components/elements/Document/DocumentForm";
import { LidapDocumentType } from "@/components/token/documents/DocumentType";
import { useEnterpriseId } from "@/contexts/UserContext";
import { useArticles } from "@/hooks/useArticles";
import { useClients } from "@/hooks/useClients";
import { useEnterprises } from "@/hooks/useEnterprises";
import { useImages } from "@/hooks/useImages";
import { useInvoices } from "@/hooks/useInvoices";
import { usePaymentInformation } from "@/hooks/usePaymentInformation";
import { usePhones } from "@/hooks/usePhones";
import { useRecentDocuments } from "@/hooks/useRecentDocuments";
import { useGetSettings } from "@/hooks/useSettings";
import { CRUDL } from "@/utils/crudl";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

export function ManageDocument({ context = CRUDL.CREATE }) {
  /* Context */
  const enterpriseId = useEnterpriseId();

  /* State */
  const [isPageNew, setIsPageNew] = useState(true);
  /* Params */
  const { documentNumber } = useParams();
  /* Search params */
  const [searchParams, setSearchParams] = useSearchParams();
  const documentType = searchParams.get("documentType");
  const preload = searchParams?.get("preload");
  const selectedClientId = searchParams.get("clientId");
  const linkedDocumentNumber = searchParams?.get("linkedDocument");
  const isParametersTab = searchParams.get("p");
  const isParametersOpen = isParametersTab === "true";
  const setIsParametersOpen = (b) => {
    searchParams.set("p", b);
    setSearchParams(searchParams);
  };

  /* Queries */
  const enterprise = useEnterprises({
    enterpriseId,
    queryParam: {
      emails: true,
      addresses: true,
      identifiers: true,
      paymentInformation: true,
    },
    reactQueryOptions: {
      enabled: !!enterpriseId,
    },
  });

  const phones = usePhones({
    stakeholderId: enterpriseId,
    reactQueryOptions: {
      refetchOnWindowFocus: false,
      enabled: !!enterpriseId,
    },
  });

  const companyLogo = useImages({
    enterpriseId,
    purpose: "company-logo",
    reactQueryOptions: {
      refetchOnWindowFocus: false,
      enabled: !!enterpriseId,
    },
    errorOnMissing: false,
  });

  const documentSettings = useGetSettings({
    parentId: enterpriseId,
    settingsType: "document",
    reactQueryConfig: {
      enabled: !!enterpriseId,
    },
  });

  const preloadedDocument = useInvoices(
    { enterpriseId, invoiceNumber: preload },
    {
      enabled: !!enterpriseId && !!preload,
    }
  );

  const draftDocument = useInvoices(
    {
      enterpriseId,
      invoiceNumber: documentNumber,
      queryParam: {
        links: true,
      },
    },
    {
      enabled: !!enterpriseId && !!documentNumber,
    }
  );

  const linkedDocumentFromDraft =
    draftDocument.data?.payload?.invoice?.linkedInvoices?.find(
      (d) => d.linkType === "REFUND"
    )?.linkedInvoice;

  const linkedDocument = useInvoices(
    {
      enterpriseId,
      invoiceNumber:
        linkedDocumentNumber || linkedDocumentFromDraft?.linkedInvoiceNumber,
    },
    {
      enabled:
        !!enterpriseId && (!!linkedDocumentNumber || !!linkedDocumentFromDraft),
    }
  );

  const lastDocumentOfSameType = useRecentDocuments({
    enterpriseId,
    queryParam: {
      documentType,
      pageNumber: 0,
      numberOfRecords: 1,
      confirmed: true,
    },
    reactQueryConfig: {
      enabled:
        !!enterpriseId &&
        (!!documentType ||
          (documentNumber &&
            !!draftDocument.data?.payload?.invoice?.documentType)),
    },
  });

  /* We take the client from the params in priority.
  Otherwise, if there is a linked document, it should be the same client.
  Finally, we take the client from the duplicated invoice (if any) */
  const searchClientId =
    selectedClientId ||
    linkedDocument.data?.payload?.invoice?.clientId ||
    preloadedDocument.data?.payload?.invoice?.clientId ||
    draftDocument.data?.payload?.invoice?.clientId;

  const selectedClient = useClients({
    enterpriseId,
    clientId: searchClientId,
    queryParam: {
      addresses: true,
      emails: true,
      identifiers: true,
    },
    reactQueryConfig: {
      enabled: !!enterpriseId && !!searchClientId && isPageNew,
    },
  });

  const articles = useArticles({
    enterpriseId,
    reactQueryOptions: {
      enabled: !!enterpriseId,
    },
  });

  const paymentInformation = usePaymentInformation({
    stakeholderId: enterpriseId,
    reactQueryConfig: {
      enabled:
        !!enterpriseId &&
        (!documentType || documentType !== LidapDocumentType.CREDIT_NOTE),
    },
  });

  /* This is the core data required for initializing the document */
  const isLoading =
    enterprise.isInitialLoading ||
    documentSettings.isLoading ||
    phones.isInitialLoading ||
    companyLogo.isInitialLoading ||
    articles.isInitialLoading ||
    paymentInformation.isInitialLoading ||
    (!!lastDocumentOfSameType.isInitialLoading && isPageNew) ||
    (!!documentNumber && draftDocument.isInitialLoading) ||
    (!!preload && preloadedDocument.isInitialLoading && isPageNew) ||
    (!!linkedDocumentNumber && linkedDocument.isInitialLoading && isPageNew) ||
    (!!searchClientId && selectedClient.isInitialLoading && isPageNew);

  const isError =
    enterprise.isError ||
    documentSettings.isError ||
    phones.isError ||
    companyLogo.isError ||
    articles.isError ||
    paymentInformation.isError ||
    lastDocumentOfSameType.isError ||
    (!!documentNumber && draftDocument.isError) ||
    (!!preload && preloadedDocument.isError) ||
    (!!linkedDocumentNumber && linkedDocument.isError) ||
    (!!searchClientId && selectedClient.isClientsError);

  const error =
    enterprise.error ||
    documentSettings.error ||
    phones.error ||
    companyLogo.error ||
    articles.error ||
    draftDocument.error ||
    preloadedDocument.invoicesError ||
    linkedDocument.invoicesError ||
    selectedClient.clientsError ||
    paymentInformation.error ||
    lastDocumentOfSameType.error;

  if (isError) {
    return (
      <CouldNotLoad
        error={error}
        message={"Une erreur s'est produite lors du chargement des données"}
      />
    );
  }

  return isLoading ? (
    <CenteredLoadingBlock />
  ) : (
    <DocumentForm
      context={context}
      companyLogo={companyLogo.data.payload}
      documentSettings={documentSettings.data.payload}
      documentType={
        documentType || draftDocument.data?.payload?.invoice?.documentType
      }
      enterprise={enterprise.data.payload}
      enterprisePhones={phones.data.payload.phones}
      draftDocument={documentNumber ? draftDocument.data.payload.invoice : null}
      preload={
        preload && preloadedDocument.data
          ? preloadedDocument.data.payload.invoice
          : undefined
      }
      linkedDocument={
        linkedDocumentNumber && linkedDocument.data
          ? linkedDocument.data.payload.invoice
          : linkedDocumentFromDraft
      }
      selectedClient={
        searchClientId && isPageNew
          ? selectedClient.clientsData.payload
          : undefined
      }
      setDocumentChoice={(choice) => {
        searchParams.set("documentType", choice);
        setSearchParams(searchParams);
      }}
      paymentInformation={
        documentType === LidapDocumentType.CREDIT_NOTE
          ? null
          : paymentInformation.data.payload
      }
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      setIsPageNew={setIsPageNew}
      articles={articles.data.payload}
      isParametersOpen={isParametersOpen}
      setIsParametersOpen={setIsParametersOpen}
      lastDocumentOfSameType={
        documentType ? lastDocumentOfSameType.data?.payload.results[0] : null
      }
    />
  );
}
