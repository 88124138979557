// StatusButton.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LidapToolTip } from "../tooltip/LidapToolTip";

export default function StatusButton({
  icon: Icon,
  tooltipText,
  onClick,
  linkTo,
  onMouseEnter = () => {},
  isLoading,
}) {
  const [isHovered, setIsHovered] = useState(false);
  // const Tooltip = ({ children }) => (
  //   <div className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
  //     <div className="bg-gray-800 text-white text-xs rounded py-1 px-2">
  //       {children}
  //     </div>
  //   </div>
  // );

  return (
    // <--- Missing return statement added here
    <div className="relative">
      {linkTo ? (
        <Link
          to={linkTo}
          className="flex items-center gap-4 hover:bg-gray-100 dark:hover:bg-neutral-900 p-2 px-4 select-none text-black border-neutral-200 border-r"
          style={{ minWidth: "50px", minHeight: "40px" }}
          onMouseEnter={() => {
            onMouseEnter();
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
        >
          <Icon className="h-4 w-4 text-neutral-600" aria-hidden="true" />
        </Link>
      ) : isLoading ? (
        <span
          className="flex items-center justify-center p-2 px-4 select-none border-neutral-200 border-r"
          style={{ minWidth: "50px", minHeight: "40px" }}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
        >
          <span className="h-4 w-4 border-2 border-t-2 border-t-neutral-600 border-neutral-200 rounded-full animate-spin"></span>
        </span>
      ) : (
        <button
          className="flex items-center hover:bg-gray-100 dark:hover:bg-neutral-900 p-2 px-4 select-none text-black border-neutral-200 border-r"
          onClick={onClick}
          style={{ minWidth: "50px", minHeight: "40px" }}
          disabled={isLoading}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
        >
          <Icon className="h-4 w-4 text-neutral-600" aria-hidden="true" />
        </button>
      )}
      <LidapToolTip
        toolTipText={tooltipText}
        isGrouped={false}
        isVisible={isHovered}
      />
    </div>
  );
}
