import React from "react";
import InvoiceIcon from "@/assets/Images/InvoiceIcon.svg";
import CreditIcon from "@/assets/Images/CreditIcon.svg";
import EstimateIcon from "@/assets/Images/EstimateIcon.svg";
import { useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { DocumentChoiceType } from "./DocumentChoiceType";

export default function DocumentChoice({ setChoice }) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col bg-neutral-100 items-center justify-center gap-3 w-full">
      <div
        className="absolute top-12 right-12 z-[2000] cursor-pointer h-12 w-12 flex justify-center items-center"
        onClick={() => navigate("/documents")}
      >
        <XMarkIcon className="h-6 w-6 text-gray-900" />
      </div>
      <DocumentChoiceType
        icon={EstimateIcon}
        name="Devis"
        onClick={() => setChoice("QUOTE")}
        alt="Devis"
      />
      <DocumentChoiceType
        icon={InvoiceIcon}
        name="Facture"
        onClick={() => setChoice("INVOICE")}
        alt="Facture"
      />
      <DocumentChoiceType
        icon={CreditIcon}
        name="Note de crédit"
        onClick={() => setChoice("CREDIT_NOTE")}
        alt="Note de crédit"
      />
    </div>
  );
}
