export function mergeDocumentAdditionalData(
  additionalDataArray1,
  additionalDataArray2
) {
  const from2to1 = additionalDataArray1.map((additionalData1) => {
    const additionalData2 = additionalDataArray2.find(
      (a) =>
        a.additionalDataType === additionalData1.additionalDataType &&
        a.additionalDataSubType === additionalData1.additionalDataSubType &&
        additionalData1.externalId === a.externalId
    );

    return {
      ...additionalData1,
      ...additionalData2,
    };
  });

  const from1to2 = additionalDataArray2
    .filter(
      (additionalData2) =>
        !additionalDataArray1.find(
          (a) =>
            a.additionalDataType === additionalData2.additionalDataType &&
            a.additionalDataSubType === additionalData2.additionalDataSubType &&
            a.externalId === additionalData2.externalId
        )
    )
    .map((additionalData2) => {
      return {
        additionalDataType: additionalData2.additionalDataType,
        additionalDataSubType: additionalData2.additionalDataSubType,
        additionalData: additionalData2.additionalData,
        externalId: additionalData2.externalId,
      };
    });

  return [...from2to1, ...from1to2];
}
